import { FC } from 'react';
import { createElement } from 'react';

interface Props {
  as?: string | React.ComponentType<any>;
  loading?: boolean;
  [key: string]: any;
}

export const Button: FC<Props> = ({
  cls,
  children,
  as,
  color,
  loading,
  ...props
}) => {
  return createElement(
    as || 'button',
    {
      className: 'app-button-primary text-center',
      ...props,
    },
    loading ? <>Loading...</> : children
  );
};
