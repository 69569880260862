import { API } from 'api';

import { EnrichedCurrencyInformation } from '../../types';
import { getBlockchainNetworkName } from '../get-blockchain-network-name';
import { getFxRate } from '../get-fx-rate';

export function enrichCurrencyInformation(
  currency: API.CurrencyInformation,
  fxRates: API.FxRate[]
): EnrichedCurrencyInformation {
  const currencyCode = currency.code as string;

  return {
    ...currency,
    code: currencyCode,
    showOhlcvChart:
      currency.type === API.CurrencyType.Digital ||
      currency.type === API.CurrencyType.Fund,
    displayCode: currency.displayCode ?? currencyCode,
    name: currency.name as string,
    network: getBlockchainNetworkName(currency.blockchain),
    fxRate: getFxRate(currencyCode, fxRates, currency.pricePrecision),
    isAssetOfTypeFiat: !!(currency.type === API.CurrencyType.Fiat),
    isAssetOfTypeFund: !!(currency.type === API.CurrencyType.Fund),
    isAssetOfTypeCrypto: !!(currency.type === API.CurrencyType.Digital),
  };
}
