import { FC } from 'react';
import { RowLabelContent } from 'common';
import { AppStore, DataStore } from '../../store';
import { Toggle } from '../forms/toggle';

export const DevTools: FC = () => {
  /**
   * Store
   */
  const devtoolsOptions = AppStore.useStoreState(s => s.devtoolsOptions);
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);

  const { setDevtoolsOptions } = AppStore.useStoreActions(_ => ({
    setDevtoolsOptions: _.setDevtoolsOptions,
  }));

  /**
   * DOM
   */
  if (!decodedToken) {
    return null;
  }
  return (
    <div className="flex flex-col">
      {/* header  */}
      <div className="px-10 flex flex-row items-center mt-10 mb-6">
        <p className="text-3xl">DevTools</p>
      </div>

      {/* content  */}
      <div className="mx-10 text-primary mb-10">
        {/* feature flags group */}
        <div>
          <RowLabelContent
            label={`Show formik debug`}
            content={
              <>
                <Toggle
                  checked={devtoolsOptions.showFormikDebug}
                  onChange={checked => {
                    setDevtoolsOptions({
                      ...devtoolsOptions,
                      showFormikDebug: checked,
                    });
                  }}
                />
              </>
            }
          />
          <RowLabelContent
            label={`Admin Account settings`}
            content={
              <>
                <a
                  className="underline"
                  target={'_blank'}
                  href={`https://admin.stabilthus.io/accounts/account?id=${decodedToken.accounts[0]}`}
                  rel="noreferrer"
                >
                  Change here
                </a>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};
