import { FC } from 'react';
import { Link } from 'react-router-dom';
import { AppIcon, useEnvironmentConfig } from 'common';
import { Auth0 } from '~/utils/auth0';
import { APP_ROUTES } from '~/routes';
import { ExternalSplashLayout } from '~/components/layouts/external-splash';

export const LoggedOut: FC = () => {
  /**
   * Hooks
   */
  const { REACT_APP_AUTH0_AUDIENCE } = useEnvironmentConfig();

  /**
   * DOM
   */
  return (
    <ExternalSplashLayout withLogoTitle={false} containerCls="mb-10">
      <div className="flex flex-col items-center">
        <AppIcon icon="circle-shield-check" size={48} />
        <h4 className="flex flex-col items-center">
          <span className="mt-3 text-xl font-bold text-primary">
            You have been logged out.
          </span>
          <span className="mt-1 text-sm text-grey-darker">
            To sign back in, click on the button below
          </span>
        </h4>
      </div>
      <button
        className="app-button-accent text-sm mt-6"
        onClick={async () => {
          await Auth0.login(REACT_APP_AUTH0_AUDIENCE!);
        }}
      >
        Sign in
      </button>
      <Link
        to={APP_ROUTES.NON_AUTH_CONTACT_US}
        className="absolute -bottom-10 text-white text-sm"
      >
        Are you having trouble logging in?{' '}
        <span className="font-bold">Contact support</span>
      </Link>
    </ExternalSplashLayout>
  );
};
