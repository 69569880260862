export enum APP_ROUTES {
  /**
   * Pages that DO NOT require
   * - authentication
   */
  NON_AUTH_HOME = '/',
  NON_AUTH_LOGIN_AUTH0 = '/login-auth0',
  NON_AUTH_LOGIN_LEGACY = '/login-legacy',
  NON_AUTH_LOGIN_2FA = '/login-2fa',
  NON_AUTH_ACCOUNT_DISABLED = '/account-disabled',
  NON_AUTH_ACCOUNT_LOCKED = '/account-locked',
  NON_AUTH_ACCOUNT_UNKNOWN = '/account-unknown',
  NON_AUTH_REGISTER = '/register',
  NON_AUTH_CONFIRM_EMAIL_ADDRESS = '/confirm-email-address',
  NON_AUTH_CONFIRM_TRANSFER = '/confirm-transfer',
  NON_AUTH_EMAIL_VERIFICATION_PENDING = '/email-verification-pending',
  NON_AUTH_FORGOT_PASSWORD = '/authentication/forgot-password',
  NON_AUTH_CONTACT_US = '/contact-us',
  NON_AUTH_LOGGED_OUT = '/logged-out',

  /**
   * Pages that are based on status code
   */
  AUTH_404 = '/404',
  NON_AUTH_429 = '/429',
  NON_AUTH_500x = '/500x',

  /**
   * Pages that DO require
   * - authentication
   */
  AUTH_EMAIL_VERIFICATION_SUCCESSFUL = '/authentication/email-verification-successful',
  AUTH_VERIFY_ADDRESS = '/verify-address',
  AUTH_REFER_A_FRIEND = '/refer-a-friend',
  AUTH_WELCOME_BONUS = '/welcome-bonus',
  // AUTH_HOME = '/portfolio',
  AUTH_PORTFOLIO = '/portfolio',
  AUTH_MARKETS = '/markets',
  AUTH_MARKETS_V2 = '/v2-markets',
  AUTH_ASSET = '/asset',
  AUTH_STATEMENTS = '/statements',
  AUTH_INVOICES = '/invoices',
  AUTH_SETTINGS = `/settings`,
  // admin - userType === ClientReadWrite
  AUTH_ADMIN = '/admin',
  AUTH_ADMIN_PENDING_APPROVALS = '/admin/pending-approvals',
  AUTH_ADMIN_FUND_ACCOUNT_OVERVIEW = '/admin/funds',
  AUTH_ADMIN_FUND_ACCOUNT_OVERVIEW_ACCOUNTS = '/admin/funds/:fundId/accounts',
  // kyc
  AUTH_KYC_INDEX = '/kyc',
  AUTH_KYC_SPLASH = '/kyc/splash',
  AUTH_KYC_WIZARD = '/kyc/wizard',
  AUTH_KYC_EDD = '/kyc/edd',
  // auth0
  AUTH_AUTH0_CALLBACK = '/oauth/callback',

  // old - below
  AUTH_DASHBOARD_PARAMS = '/:action?/:actionId?',

  // legacy - bookmarked routes
  AUTH_LEGACY_TRANSACTIONS = '/transactions',
  AUTH_LEGACY_DASHBOARD = '/dashboard',
}

export const errorRoutes = [
  APP_ROUTES.AUTH_404,
  APP_ROUTES.NON_AUTH_429,
  APP_ROUTES.NON_AUTH_500x,
];

export const externalRoutes = [
  APP_ROUTES.NON_AUTH_LOGIN_LEGACY,
  APP_ROUTES.NON_AUTH_REGISTER,
  APP_ROUTES.NON_AUTH_CONFIRM_EMAIL_ADDRESS,
  APP_ROUTES.NON_AUTH_CONFIRM_TRANSFER,
  APP_ROUTES.NON_AUTH_EMAIL_VERIFICATION_PENDING,
  APP_ROUTES.NON_AUTH_FORGOT_PASSWORD,
  APP_ROUTES.NON_AUTH_HOME,
  APP_ROUTES.NON_AUTH_LOGIN_2FA,
  APP_ROUTES.NON_AUTH_ACCOUNT_DISABLED,
  APP_ROUTES.NON_AUTH_ACCOUNT_LOCKED,
  APP_ROUTES.NON_AUTH_ACCOUNT_UNKNOWN,
  APP_ROUTES.NON_AUTH_CONTACT_US,
  APP_ROUTES.NON_AUTH_LOGGED_OUT,
  APP_ROUTES.AUTH_404,
  APP_ROUTES.NON_AUTH_429,
  APP_ROUTES.NON_AUTH_500x,
  APP_ROUTES.AUTH_AUTH0_CALLBACK,
];
