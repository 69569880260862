import { useState } from 'react';

function getCloseButton(el: HTMLElement) {
  if (
    el.hasAttribute('data-app-dialog-inner') &&
    el.hasAttribute('data-app-dialog-has-close')
  ) {
    return el.firstElementChild as HTMLElement;
  } else {
    if (!el.parentElement) {
      return null;
    }
    return getCloseButton(el.parentElement);
  }
}

export const useAppDialogHelper = () => {
  /**
   * State
   */
  const [closeBtn, setCloseBtn] = useState<HTMLElement | null>(null);

  /**
   * Return
   */
  return {
    init: (el: HTMLElement | null) => {
      if (!el) {
        return;
      }
      if (closeBtn) {
        return;
      }
      const btn = getCloseButton(el);
      if (!btn) {
        return;
      }
      setCloseBtn(btn);
    },
    dispose: () => {
      if (!closeBtn) {
        return;
      }
      setCloseBtn(null);
    },
    closeButton: {
      show: () => {
        if (!closeBtn) {
          return;
        }
        closeBtn.style.visibility = 'visible';
      },
      hide: () => {
        if (!closeBtn) {
          return;
        }
        closeBtn.style.visibility = 'hidden';
      },
    },
  };
};
