import React, { FC } from 'react';
import cx from 'classnames';

export interface WizardProgressProps {
  percent: number;
  cls?: string;
  rounded?: boolean;
  bgColor?: string;
  borderCls?: string;
  segments: number;
}

export const WizardProgress: FC<WizardProgressProps> = ({
  percent,
  cls,
  segments,
  rounded = false,
  bgColor = 'bg-gray-800',
  borderCls = 'border border-black border-1',
}) => {
  /**
   * DOM
   */
  const divisions = new Array(segments).fill(0);
  return (
    <div className={cx('w-full', cls)}>
      <div
        className={cx(bgColor, 'w-full overflow-hidden relative rounded-xl')}
      >
        <span
          className={cx('block bg-accent', {
            ['rounded']: rounded,
          })}
          style={{
            width: percent + '%',
            height: '8px',
            transition: 'width 500ms ease-in-out',
          }}
        />
        <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-row">
          {divisions.map((_item, index) => {
            return (
              <div
                key={`wiz-progress-segment-${index}`}
                className={cx(
                  'h-full flex-1',
                  borderCls,
                  { 'rounded-l-xl': index === 0 },
                  { 'rounded-r-xl': index === divisions.length - 1 }
                )}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
