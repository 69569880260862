import { API } from 'api';

export enum TransferFilterFormikField {
  filterAssetList = 'filterAssetList',
  filterType = 'filterType',
  filterStatus = 'filterStatus',
}

export enum TransferFilterDateFormikField {
  filterDateRange = 'filterDateRange',
  filterStartDate = 'filterStartDate',
  filterEndDate = 'filterEndDate',
  filterDatePeriod = 'filterDatePeriod',
}

export interface TransferFilterFormRangeProps {
  startDate: Date | null;
  endDate: Date | null;
}

export type DateRangeKey =
  | 'thisYear'
  | 'lastSixMonths'
  | 'lastTwelveMonths'
  | 'previousYear'
  | 'previousTwoYear';

export interface TransferFilterFormikProps {
  [TransferFilterFormikField.filterAssetList]: string[];
  [TransferFilterDateFormikField.filterDateRange]: TransferFilterFormRangeProps;
  [TransferFilterDateFormikField.filterStartDate]: Date | null;
  [TransferFilterDateFormikField.filterEndDate]: Date | null;
  [TransferFilterFormikField.filterType]: API.FilteringActivityLabel[];
  [TransferFilterFormikField.filterStatus]: API.TransactionStatusFilter[];
  [TransferFilterDateFormikField.filterDatePeriod]: DateRangeKey | null;
}
