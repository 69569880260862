import { EnrichedCurrencyInformation } from 'common';
import { FC } from 'react';

type Props = {
  ccyInfo?: EnrichedCurrencyInformation;
};

export const ActivityNetwork: FC<Props> = ({ ccyInfo }) => {
  if (!ccyInfo) {
    return <></>;
  }
  return <>{ccyInfo.network}</>;
};
