import { API } from 'api';
import { Enriched, EnrichedCurrencyInformation } from '../../types';
import { formatters } from '../formatters';
import numberParse from 'multi-number-parse';

export const enrichFiatWithdrawaSimulation = (
  simulation: API.FiatWithdrawalSimulation | null,
  currencies: EnrichedCurrencyInformation[],
  fiatCurrencyCodes: string[]
): Enriched.FiatWithdrawalSimulation | null => {
  if (!simulation || !simulation.currencyCode || !simulation.amount) {
    return null;
  }

  const currency = currencies.find(
    _currency => _currency.code === simulation.currencyCode
  );

  const displayCurrencyCode = currency?.displayCode ?? simulation.currencyCode;

  const feeWithCurrencyCode = formatters.getCurrency(
    simulation.fee,
    undefined,
    displayCurrencyCode,
    fiatCurrencyCodes
  );
  const amountReceivedWithCurrencyCode = formatters.getCurrency(
    simulation?.amount,
    undefined,
    displayCurrencyCode,
    fiatCurrencyCodes
  );
  const amountSent = `${
    numberParse(simulation.amount) || 0 + numberParse(simulation.fee || 0)
  }`;
  const amountSentWithCurrencyCode = formatters.getCurrency(
    amountSent,
    undefined,
    displayCurrencyCode,
    fiatCurrencyCodes
  );

  const fee = numberParse(simulation.fee ?? '0');

  return {
    ...simulation,
    formatted: {
      fee: formatters.getAmount(simulation.fee, undefined),
      feeWithCurrencyCode,
      feeWithCurrencyCodeAndSign:
        fee === 0
          ? feeWithCurrencyCode
          : formatters.forceSign(feeWithCurrencyCode, '-'),

      amountReceived: formatters.getAmount(simulation.amount, undefined),
      amountReceivedWithCurrencyCode,
      amountReceivedWithCurrencyCodeAndSign: formatters.forceSign(
        amountReceivedWithCurrencyCode,
        '+'
      ),

      amountSent,
      amountSentWithCurrencyCode,
      amountSentWithCurrencyCodeAndSign: formatters.forceSign(
        amountSentWithCurrencyCode,
        '-'
      ),
    },
  };
};
