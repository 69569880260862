export enum MarketsColumn {
  Asset = 'Asset',
  Price = 'Price',
  Price24HourPercentageChange = 'Price24HourPercentageChange',
  Price7DaysPercentageChange = 'Price7DaysPercentageChange',
  Price1MonthPercentageChange = 'Price1MonthPercentageChange',
}

export const MarketsHeadings = {
  [MarketsColumn.Asset]: 'Asset',
  [MarketsColumn.Price]: 'Price',
  [MarketsColumn.Price24HourPercentageChange]: '24h change',
  [MarketsColumn.Price7DaysPercentageChange]: '1W change',
  [MarketsColumn.Price1MonthPercentageChange]: '1M change',
};

export enum TransactionColumn {
  TransactionType = 'TransactionType',
  AmountCurrency = 'AmountCurrency',
  Date = 'Date',
  Status = 'Status',
  FillPrice = 'FillPrice',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ACCOUNT_DETAIL_TAB {
  HOLDINGS = 'Holdings',
  ACTIVITY = 'Activity',
}
