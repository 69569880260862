import { CSSProperties, FC, useEffect, useState } from 'react';
import cx from 'classnames';
import { COPY_TEXT_OPTION } from '../../constants';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMountedState, useUnmount } from 'react-use';
import { AppIcon, TIMERS } from 'common';

interface Props {
  text: string | null | undefined;
  showText?: boolean;
  showTextAfterCopy?: boolean;
  showCopyText?: boolean;
  beforeCopyText?: string;
  afterCopyText?: string;
  cls?: string;
  textCls?: string;
  btnCls?: string;
  btnStyle?: CSSProperties;
  afterCopyCls?: string;
}

export const CopyableText: FC<Props> = ({
  text,
  showText = true,
  showTextAfterCopy = true,
  showCopyText = true,
  beforeCopyText = 'Copy',
  afterCopyText = 'Copied',
  textCls = 'flex-row items-center break-all font-bold text-primary',
  btnCls = 'text-md text-gray-400 px-1.5 py-2',
  btnStyle = undefined,
  cls,
  afterCopyCls,
}: Props) => {
  /**
   * vars
   */
  let timeout: any;

  /**
   * State
   */
  const [copied, setCopied] = useState<boolean>(false);

  /**
   * Methods
   */
  const onCopyClicked = () => {
    setCopied(true);
    timeout = setTimeout(reset, TIMERS.COPY_FEEDBACK);
  };

  const reset = () => {
    if (!timeout || !isMounted()) {
      return;
    }
    setCopied(false);
    clearTimeout(timeout);
  };

  /**
   * Hooks
   */
  const isMounted = useMountedState();
  useUnmount(() => reset());
  useEffect(() => reset(), [timeout]);

  /**
   * DOM
   */
  if (!text) {
    return null;
  }
  let isTextShown = showText;
  if (copied && !showTextAfterCopy) {
    isTextShown = false;
  }

  return (
    <span data-testid={'copyable-text'}>
      <CopyToClipboard
        text={text}
        onCopy={() => onCopyClicked()}
        options={COPY_TEXT_OPTION.PLAIN_TEXT}
      >
        <div
          data-testid="copy-text-content"
          className={cx(
            'flex hover:text-primary cursor-default items-center gap-2',
            cls
          )}
        >
          {isTextShown && (
            <div className={cx('inline-block', textCls)}>{text}</div>
          )}
          <button tabIndex={-1} aria-hidden style={btnStyle}>
            <span
              data-testid="copy-button"
              className={cx(
                btnCls,
                'flex items-center items-center border rounded bg-white text-primary font-bold',
                {
                  'gap-1.5': copied,
                }
              )}
            >
              <AppIcon
                size={24}
                bg="bg-transparent"
                icon={copied ? 'green-tick' : 'copy'}
              />
              {showCopyText && (
                <>
                  {copied ? (
                    <span className={cx(afterCopyCls)}>{afterCopyText}</span>
                  ) : (
                    beforeCopyText
                  )}
                </>
              )}
            </span>
          </button>
        </div>
      </CopyToClipboard>
    </span>
  );
};
