import { FC, useCallback, useEffect } from 'react';
import { AppLoader } from '~/components/app-loader';
import { SettingsAddressBook } from '~/components/settings/address-book';
import { AppStore, DataStore } from '~/store';

import { SettingsLayout, SettingsMenuIds } from './layout';

export const AddressBook: FC = () => {
  /**
   * Store
   */
  const decodedTokenAccountId = DataStore.useStoreState(
    s => s.user.decodedTokenAccountId
  );
  const withdrawalAddresses = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.withdrawalAddresses
  );
  const isLoading = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.busy
  );

  const getWithdrawalAddresses = DataStore.useStoreActions(
    a => a.settings.cryptoAddresses.getWithdrawalAddresses
  );
  const dashboardSelectedDialogType = AppStore.useStoreState(
    s => s.dashboardSelectedDialogType
  );

  /**
   * Hooks
   */
  useEffect(() => {
    handleGetWithdrawalAddresses();
  }, []);

  /**
   * Methods
   */
  const handleGetWithdrawalAddresses = useCallback(() => {
    if (!decodedTokenAccountId) {
      return;
    }
    getWithdrawalAddresses({
      accountId: decodedTokenAccountId,
    });
  }, [getWithdrawalAddresses, decodedTokenAccountId]);

  /**
   * DOM
   */
  return (
    <SettingsLayout
      hasPaddings={false}
      menuId={SettingsMenuIds.CRYPTO_ADDRESSES}
    >
      {isLoading && !dashboardSelectedDialogType ? (
        <AppLoader spinnerTop="104px" />
      ) : (
        <SettingsAddressBook
          addresses={withdrawalAddresses}
          onAddNewAddress={handleGetWithdrawalAddresses}
        />
      )}
    </SettingsLayout>
  );
};
