import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { Error } from '../../common/error';
import { AppStore, DataStore } from '../../../store';
import { FormHeader, SendDestinationType, useSegmentTrack } from 'common';
import { SegmentTrackType } from 'common';
import { DetailsCrypto } from './details/crypto';
import { SendSteps } from './steps';
import { createSendHelper } from './helper';
import { getAccountInfo } from '~/utils/get-account-details';
import { Auth0 } from '~/utils/auth0';
import { TransactionPeriodNotice } from '../shared/transaction-period-notice';

export const Preview: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.send.error);
  const simulation = DataStore.useStoreState(s => s.send.simulation);
  const formValues = DataStore.useStoreState(s => s.send.formValues);
  const selectedWithdrawalAddress = AppStore.useStoreState(
    s => s.selectedWithdrawalAddress
  );
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const createSend = DataStore.useStoreActions(a => a.send.create);
  const is2FaEnabled = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.is2FaEnabled
  );

  /**
   * Hooks
   */
  useSegmentTrack(SegmentTrackType.StartWithdrawalStep2, simulation, {
    onlyIf: !!simulation,
  });

  /**
   * Methods
   */
  const onCancelPreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    goToNamedStep(SendSteps.Form);
  };
  const onRequestWithdrawal = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    const isAuth0 = await Auth0.isAuthenticated();
    const shouldMfa = is2FaEnabled || isAuth0;
    if (shouldMfa) {
      if (isAuth0) {
        const token = await Auth0.triggerMfa();
        if (!token) {
          return;
        }
      } else {
        goToNamedStep(SendSteps.PreviewTwoFaWidget);
        return;
      }
    }

    await createSendHelper(formValues, null, createSend, step =>
      goToNamedStep(step)
    );
  };

  /**
   * DOM
   */
  if (!accountDetail) {
    return null;
  }
  const isTransfer =
    formValues?.destinationType === SendDestinationType.transfer;
  const isDigital =
    !formValues?.fromAsset?.currency.isAssetOfTypeFiat &&
    !formValues?.fromAsset?.currency.isAssetOfTypeFund;
  const withdrawDisabled =
    (!isTransfer && isDigital && !simulation) || error !== null;
  const showWarningMessage = !isTransfer && !selectedWithdrawalAddress;
  return (
    <div data-testid="send-preview" className="flex flex-col">
      {/* header  */}
      <FormHeader
        title={`Send ${formValues?.fromAsset?.currency.displayCode || ''}`}
        accountInfo={getAccountInfo(accountDetail.account, 'From')}
        onBack={onCancelPreview}
      />

      {/* content  */}
      <div className="mx-6 sm:mx-10 text-primary">
        <DetailsCrypto
          showRecepientAddress
          actionNoteLabel={showWarningMessage}
        />
      </div>
      <TransactionPeriodNotice
        cls="mt-4 mb-8"
        workflow={'workflow-send'}
        accountType={accountDetail?.account?.accountType}
      />
      {/* api errors  */}
      <Error message={error} cls="mx-6 md:mx-10" />

      {/* actions  */}
      <div className="flex border-t border-grey-bright bg-gray-100 rounded-b mt-4 py-6 px-6">
        {/* cancel button  */}
        <button
          className="app-button-outline w-full mr-3 flex-1"
          onClick={onCancelPreview}
        >
          Cancel
        </button>
        {/* withdraw button  */}
        <button
          disabled={withdrawDisabled}
          type="button"
          className="app-button-accent flex-1 flex items-center justify-center"
          onClick={onRequestWithdrawal}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};
