import React from 'react';
import { API } from 'api';
import cx from 'classnames';
import {
  AllowedWorkflowType,
  AppIcon,
  AppIconType,
  getActivityStatus,
  useNoticeCopy,
} from 'common';

interface Props {
  cls?: string;
  workflow: AllowedWorkflowType | null;
  accountType: API.AccountType | undefined;
  status?:
    | API.TradeStatus
    | API.TransferStatus
    | API.WithdrawalStatus
    | API.FiatWithdrawalStatus
    | API.TransactionStatusFilter
    | null;
}

export const TransactionPeriodNotice: React.FC<Props> = ({
  cls,
  status,
  workflow,
  accountType,
}) => {
  const showNotice = status ? getActivityStatus(status) === 'Pending' : !status;
  if (!showNotice || !workflow) return null;

  /**
   * Hooks
   */
  const workflowInfo = useNoticeCopy(workflow, accountType);
  if (!workflowInfo) return null;
  const { icon, title, content } = workflowInfo;
  return (
    <div
      className={cx(
        cls,
        'bg-warning-fair text-warning-dark px-4 py-3 gap-3 flex flex-col rounded-lg mx-6 sm:mx-10'
      )}
    >
      <div className="flex flex-col sm:flex-row items-center gap-2">
        <AppIcon size={24} icon={icon as AppIconType} bg="bg-transparent" />
        <span className="text-sm font-bold text-center sm:text-left">
          {title}
        </span>
      </div>
      <p className="text-sm text-grey-darker text-center sm:text-left">
        {content}
      </p>
    </div>
  );
};
