export enum WithdrawCashSteps {
  Form = 'withdraw-cash-form',
  AddBank = `withdraw-cash-add-bank`,
  Preview = 'withdraw-cash-preview',
  Validate2FA = 'withdraw-cash-validate-2fa',
  Summary = 'withdraw-summary',
  Cancelled = 'withdraw-cancelled',
  ConfirmCancellation = 'withdraw-confirm-cancel',
  // TODO: review the duplicated steps AccountLocked, Preview, Form and Summary that could be in a base Step enum
  AccountLocked = 'account-locked',
}
