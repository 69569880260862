import { API } from 'api';
import { AccountTypeLabel } from 'common';

export const getAccountType = (
  acc: API.AccountProperties | null | undefined
) => {
  if (!acc) {
    return '';
  }
  return AccountTypeLabel[acc.accountType];
};

export const getAccountInfo = (
  acc: API.AccountProperties | null | undefined,
  prefix?: string | null
): string => {
  if (!acc) {
    return '';
  }

  const result: string[] = [];
  if (prefix) {
    result.push(prefix);
  }
  result.push(getAccountType(acc));
  if (acc.accountNumber) {
    result.push(acc.accountNumber);
  }

  return result.join(' ');
};
