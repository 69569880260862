import React from 'react';
import cx from 'classnames';

export const textSelectedItemTemplate = (
  item: string | null,
  placeHolder = 'Please select'
) => {
  if (!item) {
    return (
      <p data-testid="select-a-value" className="text-grey text-sm font-bold">
        {placeHolder}
      </p>
    );
  }
  return <p className="font-bold">{item}</p>;
};

export const textItemTemplate = (
  item: string,
  selected: boolean,
  active: boolean,
  asCustomSelect = false,
  cls = 'px-3 py-1'
) => {
  const bgColor = asCustomSelect
    ? 'hover-bg-grey-brighter'
    : 'hover-bg-grey-bright';
  return (
    <div
      className={cx(
        cls,
        'flex justify-between items-center text-secondary font-bold',
        { [bgColor]: selected },
        { [bgColor]: active }
      )}
    >
      {item}
    </div>
  );
};
