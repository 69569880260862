import {
  AccountTypePermissionsProps,
  AppContainer,
  AppIcon,
  AppIconType,
  IconSize,
  ContentCardProps,
  getWidgetCopyLink,
  useCopy,
  useUrls,
} from 'common';
import { FC, isValidElement } from 'react';
import { string } from 'is';
import cx from 'classnames';

type AboutAssetProps = {
  image?: string;
  iconBg?: string;
  iconCls?: string;
  icon?: AppIconType;
  iconSize?: IconSize;
  iconHeight?: IconSize;
  content: JSX.Element;
  withBorder?: boolean;
  title: string | JSX.Element | undefined;
};

interface Props extends AccountTypePermissionsProps {
  card?: ContentCardProps[];
  title?: string;
  showHelpInfo?: boolean;
  showAccountTypeInfo?: boolean;
}

interface AccountInfoProps {
  aboutFaqUrl?: string;
  aboutHelpUrl?: string;
  proTradingUrl?: string;
  aboutCustodyUrl?: string;
  aboutTradingtsUrl?: string;
  hasRelationshipManager?: boolean;
  discoverMore: ContentCardProps[];
}

const discoverMoreContent = (
  link: string | undefined,
  data: ContentCardProps
) => (
  <span
    className={cx('flex gap-3', {
      'flex-row justify-between w-full': !data?.ctaTitle,
      'flex-col': data?.ctaTitle,
    })}
  >
    {string(data?.content) ? (
      <span className="text-left">{data?.content}</span>
    ) : (
      <ul className="text-left list-disc list-inside lg:list-outside lg:ml-4 mt-1 md:pr-2.5">
        {(data?.content as string[])?.map(item => <li key={item}>{item}</li>)}
      </ul>
    )}
    {!!link && (
      <div className="flex right-2.5 relative">
        <a
          href={link}
          target="_blank"
          className="flex flex-row items-center ml-2.5 text-sm font-bold text-primary gap-x-2"
          rel="noreferrer"
        >
          <span>{data?.ctaTitle}</span>
          <AppIcon
            icon="chevron-right"
            size={14}
            bg="bg-transparent"
            cls="mt-0.5"
            fill="fill-current-color-primary-darkest"
          />
        </a>
      </div>
    )}
  </span>
);

const AboutAccountType = ({
  icon,
  image,
  title,
  iconBg,
  content,
  iconCls,
  withBorder = false,
  iconSize,
  iconHeight,
}: AboutAssetProps) => {
  return (
    <div
      className={cx(
        'flex flex-col sm:items-start sm:flex-row py-6 px-6 lg:px-8 gap-4',
        {
          'border-t bright-gray': withBorder,
        }
      )}
    >
      {!!icon && (
        <span className={iconCls}>
          <AppIcon
            bg={iconBg}
            icon={icon}
            size={iconSize}
            height={iconHeight}
            cls="inline-block svg-stretch-w-h"
          />
        </span>
      )}
      {!!image && (
        <img
          className="sm:max-h-110 sm:max-w-153 h-full w-full"
          src={image}
          alt={title as string}
        />
      )}

      <span className="w-full">
        <span className="flex flex-col pb-1 text-sm text-primary">
          {isValidElement(title) ? (
            <>{title}</>
          ) : (
            <span className="font-bold">{title}</span>
          )}
        </span>

        <span className="text-sm text-grey-darker">{content}</span>
      </span>
    </div>
  );
};

const AccountTypeInfo = ({
  discoverMore,
  proTradingUrl,
}: AccountInfoProps &
  Pick<
    Props,
    | 'hasAccountOfTypeCustody'
    | 'hasAccountOfTypeTrading'
    | 'hasAccountOfTypeProTrading'
  >) => {
  return (
    <>
      {discoverMore?.map(cardItem => {
        const isActiveTrader = cardItem.id === 'activeTrader';
        const updatedLink = isActiveTrader
          ? proTradingUrl
          : getWidgetCopyLink(
              cardItem?.id,
              cardItem.url,
              process.env.REACT_APP_BASE_URL
            );

        return (
          <AboutAccountType
            withBorder
            key={cardItem.id}
            iconSize={153}
            iconHeight={110}
            title={cardItem.title}
            image={cardItem.imageWeb}
            iconCls="bg-grey-bright rounded justify-center flex"
            content={discoverMoreContent(updatedLink, {
              ...cardItem,
              ctaTitle: isActiveTrader
                ? 'Go to Active Trader'
                : cardItem?.ctaTitle,
            })}
          />
        );
      })}
    </>
  );
};

export const WidgetPortfolioHome: FC<
  Props & Pick<AccountInfoProps, 'hasRelationshipManager'>
> = ({
  card,
  title = '',
  showHelpInfo = false,
  showAccountTypeInfo = false,
}) => {
  if (!!title && !card?.length) {
    return null;
  }
  /**
   * Hooks
   */
  const { helpUrl, faqUrl, proTradingUrl } = useUrls();
  const {
    portfolio: {
      support: { help, faqs },
    },
  } = useCopy();

  /**
   * DOM
   */
  const aboutHelpUrl = `${helpUrl}/hc/en-us/requests/new`;

  return (
    <AppContainer bg="bg-white" cls="" withBorder containerWidth="lg">
      {!!title && (
        <div className="py-4">
          <span className="text-primary font-bold px-4 md:px-8">{title}</span>
        </div>
      )}
      {showAccountTypeInfo && (
        <AccountTypeInfo
          proTradingUrl={proTradingUrl}
          discoverMore={card as ContentCardProps[]}
        />
      )}

      {showHelpInfo && (
        <>
          {/** White glove support **/}
          <AboutAccountType
            icon="lifebuoy"
            title={help.title}
            withBorder
            content={
              <div className="pr-2.5">
                <span>
                  {/* eslint-disable-next-line */}
                  {help.content}
                </span>

                <div className="flex right-2.5 relative mt-3">
                  <a
                    href={aboutHelpUrl}
                    target="_blank"
                    className="flex flex-row items-center ml-2.5 text-sm font-bold text-primary gap-x-2"
                    rel="noreferrer"
                  >
                    <span>{help.urlText}</span>
                    <AppIcon
                      icon="chevron-right"
                      size={14}
                      bg="bg-transparent"
                      cls="mt-0.5"
                      fill="fill-current-color-primary-darkest"
                    />
                  </a>
                </div>
              </div>
            }
          />

          {/** FAQs **/}
          <AboutAccountType
            icon="graduation-cap"
            title={faqs.title}
            withBorder
            content={
              <>
                <span>{faqs.content}</span>
                <div className="flex right-2.5 relative mt-3">
                  <a
                    href={faqUrl}
                    target="_blank"
                    className="flex flex-row items-center ml-2.5 text-sm font-bold text-primary gap-x-2"
                    rel="noreferrer"
                  >
                    <span>{help.urlText}</span>
                    <AppIcon
                      icon="chevron-right"
                      size={14}
                      bg="bg-transparent"
                      cls="mt-0.5"
                      fill="fill-current-color-primary-darkest"
                    />
                  </a>
                </div>
              </>
            }
          />
        </>
      )}
    </AppContainer>
  );
};
