import React, { useState, useEffect, useMemo, useRef } from 'react'
import { getCSSVariableValue } from '~/utils/get-css-variable-value'

export const AnimatedAmount = ({ value }: {value: string | number}) => {
  const [displayValue, setDisplayValue] = useState(value);
  const [animation, setAnimation] = useState('');
  const isFirstRender = useRef(true)

  const fadeOutDuration = useMemo(() => {
    return parseInt(getCSSVariableValue('--fade-out-duration'));
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setDisplayValue(value);
    } else {
      setAnimation('animate-fadeOut');
      const id = setTimeout(() => {
        setDisplayValue(value);
        setAnimation('animate-fadeIn');
      }, fadeOutDuration);
      return () => clearTimeout(id);
    }
  }, [value]);

  return (
    <span className={animation}>
      {displayValue}
    </span>
  );
};