import React, { FC } from 'react';
import cx from 'classnames';
// Note: importing the main entry point of `react-use` brings in browser & window polyfills which will break react-native app's common package usage
import useMeasure from 'react-use/lib/useMeasure';
import useWindowSize from 'react-use/lib/useWindowSize';
import { BaseComponentProps } from '../../types';

export interface AppDialogInnerProps extends BaseComponentProps {
  cls: string;
  showClose?: boolean;
}

export const AppDialogInner: FC<AppDialogInnerProps> = ({
  children,
  cls,
  showClose,
}) => {
  /**
   * Hooks
   */
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const { height: windowHeight } = useWindowSize();

  /**
   * DOM
   */
  const isTallerThanViewport = height > windowHeight - 100;
  return (
    <div
      ref={ref}
      data-app-dialog-inner
      {...(showClose && {
        'data-app-dialog-has-close': showClose,
      })}
      className={cx(
        cls,
        { 'overflow-visible': !isTallerThanViewport },
        { 'overflow-y-auto': isTallerThanViewport }
      )}
    >
      {children}
    </div>
  );
};
