import { AppIcon } from 'common';
import { FC, memo, useMemo } from 'react';
import cx from 'classnames';
import { StepWizardChildProps } from 'react-step-wizard';
import { widgetContent } from './widget-user-actions';

export const WidgetStepsNavigation: FC<Partial<StepWizardChildProps>> = memo(
  ({
    currentStep,
    totalSteps,
    goToStep,
    nextStep,
    previousStep,
    firstStep,
    lastStep,
  }) => {
    if (!goToStep) {
      return null;
    }

    const stepsNav = useMemo(() => {
      if (!currentStep || !previousStep || !nextStep || !firstStep || !lastStep)
        return;
      return {
        next: currentStep === totalSteps ? firstStep : nextStep,
        back: currentStep === 1 ? lastStep : previousStep,
      };
    }, [currentStep, totalSteps]);

    return (
      <div className="mt-6">
        <p className="flex justify-between">
          <button
            onClick={() => stepsNav?.back()}
            className="border hover-bg-grey-brighter bright-gray flex items-center rounded-full p-2.5 "
          >
            <AppIcon
              icon="chevron-back"
              bg="bg-transparent"
              fill="fill-current-color-primary-darkest"
              size={12}
            />
          </button>
          <span className="flex flex-row gap-x-2">
            {widgetContent()?.map((_widget, key) => (
              <span
                key={key}
                onClick={() => goToStep(key + 1)}
                className={cx(
                  'p-1 h-2 mt-2.5 rounded-full cursor-pointer hover-bg-grey-bright',
                  {
                    'bg-primary': Number(currentStep || 0) === Number(key + 1),
                    'bg-gray-300': Number(currentStep || 0) !== Number(key + 1),
                  }
                )}
              />
            ))}
          </span>
          <button
            onClick={() => stepsNav?.next()}
            className="border hover-bg-grey-brighter bright-gray flex items-center rounded-full p-2.5 "
          >
            <AppIcon
              icon="chevron-right"
              bg="bg-transparent"
              fill="fill-current-color-primary-darkest"
              size={12}
            />
          </button>
        </p>
      </div>
    );
  }
);
