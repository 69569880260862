import { FC } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

interface Props {
  icon?: JSX.Element;
  iconCls?: string;
  iconInteractive?: boolean;
  cls?: string;
  text: string;
  textCls?: string;
  textHidden?: boolean;
  onClick?: () => void;
  to?: string;
  testid?: string;
}

export const AppRoundButton: FC<Props> = ({
  icon,
  iconCls = 'bg-black',
  iconInteractive = true,
  cls = '',
  text,
  textCls,
  textHidden = false,
  onClick,
  to,
  testid = 'app-round-button-icon',
}) => {
  /**
   * DOM
   */
  const containerCls = cx(
    'flex items-center cursor-pointer bg-transparent',
    cls
  );
  const content = (
    <>
      <button
        type="button"
        tabIndex={0}
        className={cx(
          'flex items-center justify-center rounded-full',
          { 'ring-pink hover-bg-accent': iconInteractive },
          { 'focus:outline-none': !iconInteractive },
          iconCls
        )}
        style={{
          width: 24,
          height: 24,
        }}
      >
        {/* icon  */}
        {icon && <>{icon}</>}
      </button>
      {/* text  */}
      <p
        className={cx('text-sm font-bold ml-2', textCls, {
          'hidden sm:block': textHidden,
        })}
      >
        {text}
      </p>
    </>
  );

  if (to) {
    return (
      <Link to={to} className={containerCls} data-testid={testid}>
        {content}
      </Link>
    );
  }

  return (
    <div
      role="button"
      className={containerCls}
      onClick={e => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {content}
    </div>
  );
};
