import { FC, useEffect } from 'react';
import cx from 'classnames';
import { API } from 'api';
import { DataStore } from '../../store';
import { EnrichedCryptoAddress } from 'common';

interface DataPointProps {
  title: string;
  value: string | JSX.Element;
  w?: number;
}

const DataPoint: FC<DataPointProps> = ({ title, value }) => {
  return (
    <div className={cx('mb-6')}>
      <p className="text-gray-400">{title}</p>
      {typeof value === 'string' ? <p className="font-bold">{value}</p> : value}
    </div>
  );
};

interface Props {
  withdrawalAddress?: EnrichedCryptoAddress;
  id?: string | null;
  accountId?: string | null;
}

export const ShowWithdrawalAddressDetails: FC<Props> = ({
  withdrawalAddress,
  accountId,
  id,
}) => {
  /**
   * Store
   */
  const getWithdrawalAddress = DataStore.useStoreActions(
    s => s.settings.cryptoAddresses.getWithdrawalAddress
  );
  const withdrawalAddressStore = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.withdrawalAddress
  );
  const addHeader = DataStore.useStoreActions(s => s.addHeader);
  const removeHeader = DataStore.useStoreActions(s => s.removeHeader);

  useEffect(() => {
    if (id && accountId) {
      (async () => {
        addHeader({
          'x-impersonated-account-id': accountId,
        });
        await getWithdrawalAddress(id);
        removeHeader('x-impersonated-account-id');
      })();
    }
  }, [id, accountId]);

  const address = withdrawalAddressStore || withdrawalAddress;
  if (!address) {
    return null;
  }
  return (
    <>
      {id && <h4 className="text-xl font-bold  mb-10">{address?.label}</h4>}
      <div className="flex flex-col flex-wrap w-full">
        <DataPoint title="Account name" value={address?.label || ''} />
        <DataPoint title="Address" value={address?.address || ''} />
        <DataPoint title="Network" value={address.currencyCode || ''} />
      </div>
    </>
  );
};
