import { StepWizardProps } from 'react-step-wizard';

export const NOOP = () => {};

export const isDev = process.env.NODE_ENV === 'development';

export const TRANSITION_PROPS = {
  enterRight: 'transition-all ease-in',
  enterLeft: 'transition-all ease-in',
  exitRight: 'transition-all ease-out',
  exitLeft: 'transition-all ease-out',
};

export const COPY_TEXT_OPTION = Object.freeze({
  PLAIN_TEXT: { format: 'text/plain' },
  HTML_TEXT: { format: 'text/html' },
});

export const defaultStepWizardProps: Partial<StepWizardProps> = Object.freeze({
  transitions: {},
  isLazyMount: true,
  isHashEnabled: false,
});

export const HERO = {
  CONTENT_CARD_WIDTH: 240,
};
