export enum ActionId {
  Buy = 'Buy',
  Sell = 'Sell',
  Send = 'Send',
  Receive = 'Receive',
  AddCash = 'AddCash',
  WithdrawCash = 'WithdrawCash',
  Transfer = 'Transfer',
}

export enum ActionIcon {
  Buy = 'buy',
  Sell = 'sell',
  Send = 'send',
  Receive = 'receive',
  AddCash = 'buy-plus',
  WithdrawCash = 'sell-minus',
  Transfer = 'transfer',
}

export enum ActionLabel {
  Buy = 'Buy',
  Sell = 'Sell',
  Send = 'Send',
  Receive = 'Receive',
  AddCash = 'Add cash',
  WithdrawCash = 'Withdraw\ncash',
  Transfer = 'Transfer',
}
