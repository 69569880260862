import { useContext, useMemo } from 'react';
import { EnrichedCurrencyInformation } from '../types';
import { CurrencyInfoContext } from '../contexts';

export const useCurrencyInfoContext = (
  ccyCode: string | undefined | null
): EnrichedCurrencyInformation | null => {
  /**
   * Hooks
   */
  const currencies = useContext(CurrencyInfoContext);
  const ccyInfo = useMemo(() => {
    if (!currencies || !ccyCode) {
      return null;
    }
    return currencies.find(
      ccy => ccy.code.toUpperCase() === ccyCode.toUpperCase()
    );
  }, [ccyCode, currencies]);

  /**
   * Result
   */
  return ccyInfo || null;
};
