import { ApiResponse } from '../api/api-response';
import { Helpers } from 'easy-peasy';
import { DataModel } from '../store/data-store';
import { Injections } from '../store/types';
import { getApiErrorCode } from './get-api-error-code';
import { getApiErrorMessage } from './get-api-error-message';

export type Response = Partial<ApiResponse<unknown>>;
export interface ICallback<TResult extends Response> {
  execute: () => Promise<
    Pick<TResult, 'isSuccessful' | 'errorMessage' | 'errorCode'>
  >;

  onSucccess: (response: TResult) => void;
  onError?: (message: string | null, errorCode: number | null) => void;
}

/**
 * @deprecated
 */
export const runThunkUnmanaged = async (
  helpers: Helpers<object, DataModel, Injections>,
  callback: Omit<ICallback<Response>, 'onSucccess'>,
  setBusy = true,
  clearError = true,
  headers: Record<string, unknown> | null = null
) => {
  const actions = helpers.getStoreActions();
  const { injections } = helpers;
  let result: {
    isSuccessful: boolean;
    errorMessage: string | null | undefined;
  } = {
    isSuccessful: false,
    errorMessage: null,
  };

  try {
    setBusy && actions.setBusy(true);
    clearError && actions.setError(null);

    if (headers) {
      injections.apiClient.setAdditionalHeaders(headers);
    }

    const { isSuccessful, errorMessage } = await callback.execute();
    result = { isSuccessful: isSuccessful || false, errorMessage };

    if (!isSuccessful) {
      actions.setError(errorMessage);
    }
  } catch (error) {
    const errorCode = getApiErrorCode(error);
    const message = getApiErrorMessage(error);
    actions.setError(message);
    callback.onError && callback.onError(message, errorCode);
    result = { isSuccessful: false, errorMessage: message };
  } finally {
    setBusy && actions.setBusy(false);
  }

  return result;
};
