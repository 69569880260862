import React, { createContext, useContext, FC } from 'react';
import { ComputedConfig, EnvConfig, ServerConfig } from '../config/env-config';
import { configuration } from '../config.local';
import { API } from 'api';
import camelcase from 'camelcase';
import { BaseComponentProps } from '../types';

export interface ConfigContextProps {
  // Config we get from the server. API Keys, and other settings
  getSystemConfiguration: () => ServerConfig;

  // Config we get from environment variable. build hash
  getEnvironmentConfiguration: () => EnvConfig;

  // Config that is set at runtime. API Url
  getComputedConfiguration: () => ComputedConfig;
}

export const ConfigContext = createContext<undefined | ConfigContextProps>(
  undefined
);

const systemConfiguration = configuration as ServerConfig;
let computedConfiguration = null as ComputedConfig | null;

export const setComputedConfig = (computedConfig: ComputedConfig) => {
  computedConfiguration = computedConfig;
};

export const loadSystemConfig = async (apiClient: API.StablehouseClient) => {
  const config = await apiClient.getFrontendConfiguration();

  if (!config.result || !config.result.values) {
    throw new Error('Could not load configuration from the backend');
  }

  config.result.values.forEach(cfg => {
    if (cfg.value && cfg.key) {
      systemConfiguration[camelcase(cfg.key)] = cfg.value;
    }
  });
};

export const ConfigContextProvider: FC<
  BaseComponentProps & { mode?: 'mock' | undefined }
> = ({ children, mode }) => {
  if (mode === 'mock') {
    console.warn('Using mock configuration for Storybook');
    return (
      <ConfigContext.Provider
        value={{
          getEnvironmentConfiguration: () => {
            return process.env as EnvConfig;
          },
          getSystemConfiguration: () => {
            return {
              publicUrl: 'https://www.stablehouse.com',
              webAppUrl: 'http://localhost:6001', // todo: get storybook launch URL ?
            } as ServerConfig;
          },
          getComputedConfiguration: () => {
            return { tenant: 'Stablehouse' } as ComputedConfig;
          },
        }}
      >
        {children}
      </ConfigContext.Provider>
    );
  }
  if (systemConfiguration === null) {
    throw new Error('serverConfiguration has not been initialized yet.');
  }
  if (computedConfiguration === null) {
    throw new Error('serverConfiguration has not been initialized yet.');
  }

  return (
    <ConfigContext.Provider
      value={{
        getEnvironmentConfiguration: () => {
          return process.env as EnvConfig;
        },
        getSystemConfiguration: () => {
          return systemConfiguration!;
        },
        getComputedConfiguration: () => {
          return computedConfiguration!;
        },
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const FallbackConfigContextProvider: FC<BaseComponentProps> = ({
  children,
}) => {
  console.warn('Using fallback configuration.');
  // In case we cannot load the config for a reason or another,
  // we will use just enough config to display an error page
  return (
    <ConfigContext.Provider
      value={{
        getEnvironmentConfiguration: () => {
          return process.env as EnvConfig;
        },
        getSystemConfiguration: () => {
          return {
            publicUrl: 'https://www.stablehouse.com',
            webAppUrl: window.location.origin,
          } as ServerConfig;
        },
        getComputedConfiguration: () => {
          // TODO(Hadrien): @jey Should we force the tenant here to allow proper theming down into 500 page
          return { tenant: 'Stablehouse' } as ComputedConfig;
        },
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useSystemConfig = () => {
  const ctx = useContext(ConfigContext);
  if (!ctx) {
    throw new Error('Config context is not defined');
  }
  return ctx?.getSystemConfiguration();
};

export const useEnvironmentConfig = () => {
  const ctx = useContext(ConfigContext);
  if (!ctx) {
    throw new Error('Config context is not defined');
  }
  return ctx?.getEnvironmentConfiguration();
};

export const useComputedConfig = () => {
  const ctx = useContext(ConfigContext);
  if (!ctx) {
    throw new Error('Config context is not defined');
  }
  return ctx?.getComputedConfiguration();
};
