import { useEnvironmentConfig, useSystemConfig } from 'common';
import { useEffectOnce } from 'react-use';
import { initializeDatadog } from '../utils/datadog';

export function useBrowserDatadog() {
  const {
    datadogApplication: applicationId,
    datadogKey: clientToken,
    envName: env,
  } = useSystemConfig();

  const { REACT_APP_SHA } = useEnvironmentConfig();

  const version = REACT_APP_SHA || 'TEST';

  useEffectOnce(() => {
    initializeDatadog({ applicationId, clientToken, env, version });
  });
}
