import { ThunkCreator } from 'easy-peasy';
import { API } from 'api';
import {
  API_ERROR_CODES,
  EnrichedAccountDetail,
  EnrichedCurrencyInformation,
  EnrichedInternalTransfer,
} from 'common';
import { TransferSteps } from './steps';
import { getAccountType } from '~/utils/get-account-details';

export const createTransfer = async (
  create: ThunkCreator<string | null, any>,
  googleAuthenticatorCode: string | null = null,
  goToNamedStep: (step: string) => void
) => {
  const { isSuccessful, errorCode, result } = await create(
    googleAuthenticatorCode
  );
  if (!isSuccessful) {
    if (errorCode) {
      if (errorCode === API_ERROR_CODES.USER_ACOUNT_LOCKED) {
        goToNamedStep(TransferSteps.AccountLocked);
      }
      if (errorCode === API_ERROR_CODES.WITHDRAW_TRANSFER_ABORTED) {
        goToNamedStep(TransferSteps.Form);
      }
      return;
    }
  }

  const isPendingEmailConfirm =
    result?.status === API.TransferStatus.WaitingForConfirmation;

  if (isPendingEmailConfirm && result?.requireEmailCodeVerification) {
    goToNamedStep(TransferSteps.EmailCode);
    return;
  }

  goToNamedStep(TransferSteps.Summary);
};

export const getCryptoReceipt = (
  selectedCurrency: EnrichedCurrencyInformation | undefined,
  accountDetail: EnrichedAccountDetail | null,
  transfer: EnrichedInternalTransfer | null,
  destinationAccountDetail: API.AccountDetail | null
) => {
  const network = selectedCurrency?.network || '';
  const originAccount = {
    label: accountDetail?.account?.label || '',
    type: getAccountType(accountDetail?.account),
    number: accountDetail?.account?.accountNumber || '',
    amount: transfer?.formattedAmount ?? '',
  };
  const destinationAccount = {
    label: destinationAccountDetail?.account?.label || '',
    type: getAccountType(destinationAccountDetail?.account),
    number: destinationAccountDetail?.account?.accountNumber || '',
    amount: transfer?.sendingAmount ?? '',
  };
  const isInvoiced = transfer?.feeMode === API.WithdrawalFeeChargeMode.Invoiced;
  const fees = {
    amount:
      (isInvoiced ? transfer?.formattedFeeUsd : transfer?.formattedFee) ?? '',
    amountUSD: (isInvoiced ? '' : transfer?.formattedFeeUsd) ?? '',
  };
  return { originAccount, destinationAccount, network, fees, ...transfer };
};
