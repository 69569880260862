import { FC } from 'react';
import { AppIcon, AppIconType } from 'common';
import { FormFooter } from './form-footer';

interface ConfirmCancelProps {
  onNoActionClick: () => void;
  onSubmitClick: () => void;
  icon?: AppIconType;
  title?: string;
  error?: string | null;
  subTitle?: string;
  noActionText?: string;
  submitText?: string;
}

export const ConfirmCancel: FC<ConfirmCancelProps> = ({
  onNoActionClick,
  onSubmitClick,
  icon = 'info',
  error,
  title = 'Are you sure you want to cancel request?',
  subTitle = 'If you cancel, your request will no longer be processed and funds will be returned to your account.',
  noActionText = 'Continue to process',
  submitText = 'Cancel request',
}) => {
  const handleOnSubmit: React.MouseEventHandler = e => {
    e.preventDefault();
    onSubmitClick();
  };

  const handleOnCancel: React.MouseEventHandler = e => {
    e.preventDefault();
    onNoActionClick();
  };

  /**
   * DOM
   */
  return (
    <div className="flex flex-col">
      {/* header  */}
      <div className="flex flex-col items-center text-center px-10 gap-y-4">
        {/* <AppIcon icon="info" size={48} cls="mt-10 mb-5" /> */}
        <AppIcon icon={icon} size={48} cls="mt-10" />
        <div className="flex flex-col gap-y-3 items-center text-center">
          <h4 className="px-4 typo-20 font-bold">{title}</h4>
          <p className="text-grey-darker mb-10 w-96">{subTitle}</p>
        </div>
      </div>

      {/* actions  */}
      <FormFooter
        error={error}
        cancelText={noActionText}
        submitText={submitText}
        onCancel={handleOnCancel}
        onSubmit={handleOnSubmit}
        submitCls="app-button-failure"
      />
    </div>
  );
};
