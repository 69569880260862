import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FallbackProps } from 'react-error-boundary';
import cx from 'classnames';
import { AppIcon } from 'common';
import { APP_ROUTES } from '~/routes';
import { AppContainer } from '../app-container';

export const GenericError: FC<
  Partial<FallbackProps & { containerCls?: string }>
> = ({ error, containerCls }) => {
  /**
   * DOM
   */
  return (
    <AppContainer
      cls={cx(
        'flex-1 text-primary flex flex-col justify-center items-center text-primary text-center',
        containerCls
      )}
    >
      <AppIcon
        icon="information-circle"
        size={48}
        bg="bg-accent-active"
        cls="fill-current-color p-2 justify-center items-center"
      />
      <p className="mt-6 text-xl font-bold">Something went wrong</p>
      <p className="mt-1 text-grey-darker">
        We couldn’t load this page, please try again later
        {error && <pre className="mt-2 text-grey-darker">{error.message}</pre>}
      </p>

      <Link
        role="button"
        className="app-button-primary w-full mt-6"
        style={{ width: 275 }}
        to={APP_ROUTES.AUTH_PORTFOLIO}
      >
        Back to home page
      </Link>
    </AppContainer>
  );
};
