export enum FilterField {
  filterAccountId = 'filterAccountId',
  filterDateRange = 'filterDateRange',
}

export enum DateFilterField {
  filterStartDate = 'filterStartDate',
  filterEndDate = 'filterEndDate',
}

export interface FilterProps {
  [FilterField.filterDateRange]: string;
  [DateFilterField.filterStartDate]: Date | null;
  [DateFilterField.filterEndDate]: Date | null;
  [FilterField.filterAccountId]: string;
}
