import { FC, forwardRef, MutableRefObject, useState } from 'react';
import ReactToPdf from 'react-to-pdf';
import cx from 'classnames';
interface Props {
  fileName: string;
  node: MutableRefObject<HTMLDivElement | null | undefined>;
  isProcessing: boolean;
  setIsProcessing: (value: boolean) => void;
  cls?: string;
  buttonLabel?: string;
}

export const ExportPdfButton: FC<Props> = forwardRef(
  ({
    cls,
    node,
    fileName,
    isProcessing,
    setIsProcessing,
    buttonLabel = 'Export PDF',
  }) => {
    /**
     * DOM
     */
    return (
      <ReactToPdf
        targetRef={node}
        filename={fileName}
        onComplete={() => {
          setIsProcessing(false);
        }}
      >
        {(options: any) => (
          <button
            className={cx('app-button-primary', cls)}
            onClick={() => {
              setIsProcessing(true);
              options.toPdf();
            }}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : buttonLabel}
          </button>
        )}
      </ReactToPdf>
    );
  }
);
