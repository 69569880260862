import { FC, isValidElement } from 'react';
import cx from 'classnames';
import { Widgetcard, WidgetCardProps } from './widget-card';
import { AppIcon, AppIconType } from 'common';

export type DefaultProps = {
  image?: string;
  imgCls?: string;
  icon?: JSX.Element | AppIconType;
  title?: string | JSX.Element;
  titleCls?: string;
  titleSpacingCls?: string;
  ctaSpacingCls?: string;
};

type Props = {
  cta?: string | JSX.Element;
  ctaCls?: string;
  ctaUrl?: string;
  ctaIcon?: AppIconType;
  content?: string | JSX.Element;
} & DefaultProps & WidgetCardProps;

export const WidgetDefault: FC<Props> = ({
  cta,
  content,
  ctaUrl,
  ctaIcon = 'arrow-external',
  ctaCls = 'app-button-outline',
  titleCls = 'text-xl',
  ...rest
}) => {
  /**
   * DOM
   */
  return (
    <Widgetcard
      {...rest}
      subText={content}
      titleCls={titleCls}
      iconBg="bg-transparent"
      ctaText={
        <>
          {isValidElement(cta) ? (
            cta
          ) : (
            <a
              target="_blank"
              rel="noreferrer"
              href={ctaUrl}
              className={cx(ctaCls, ' text-sm w-336')}
            >
              {cta}
              {ctaIcon && (
                <AppIcon cls="ml-1 sm:ml-2" icon={ctaIcon} bg="" size={16} />
              )}
            </a>
          )}
        </>
      }
    />
  );
};
