import React, { FC } from 'react';
import cx from 'classnames';
import { BaseComponentProps } from '../../types';

type Props = {
  title?: string | undefined;
  withBorder?: boolean;
  headerPadding?: string;
  asStickyHeader?: boolean;
} & BaseComponentProps;

export const DashboardHeader: FC<Props> = ({
  title,
  children,
  headerPadding,
  withBorder = false,
  asStickyHeader,
}) => {
  /**
   * DOM
   */

  return (
    <header
      className={cx('flex flex-col', headerPadding, {
        'border-1 bright-gray rounded-t border-b-0': withBorder,
        'sticky top-0 bg-white z-40': asStickyHeader,
      })}
    >
      {!!title && (
        <p className="text-xl font-bold text-black my-8 px-4 md:px-8">
          {title}
        </p>
      )}
      {children}
    </header>
  );
};
