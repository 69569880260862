import './wdyr';
import 'dotenv/config';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import dynamicImportPolyfill from 'dynamic-import-polyfill';
import { AppBootstrapper } from './app/bootstrapper';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

dynamicImportPolyfill.initialize();

if (!window.ResizeObserver) {
  (async () => {
    window.ResizeObserver = (await import('resize-observer-polyfill')).default;
  })();
}

ReactDOM.render(
  <StrictMode>
    <AppBootstrapper />
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
