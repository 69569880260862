import { Transaction, Enriched, isTransfer, isInternalTransfer } from 'common';
import { API } from 'api';
import { Error } from '../../../common/error';
import { DataStore } from '../../../../store';
import { ExportPdfButton } from '~/components/common/export-pdf-button';
import { MutableRefObject } from 'react';

interface Props {
  exportNode: MutableRefObject<HTMLDivElement | null | undefined>;
  activity: API.ActivityTransaction | null;
  transaction: Transaction | null;
  onCancel: () => void;
  isPdfProcessing: boolean;
  setIsPdfProcessing: (value: boolean) => void;
}

const DefaultFooter = ({
  exportNode,
  activity,
  isPdfProcessing,
  setIsPdfProcessing,
}: Props) => {
  return (
    <div className="flex flex-col items-stretch border-t border-grey-bright bg-gray-100 rounded-b px-10 py-6 mt-4">
      <ExportPdfButton
        node={exportNode}
        fileName={
          activity?.type
            ? `${activity?.type}-${Date.now()}`
            : `transaction-${Date.now()}`
        }
        isProcessing={isPdfProcessing}
        setIsProcessing={setIsPdfProcessing}
      />
    </div>
  );
};

const CancelRequestFooter = ({
  onCancel,
}: Props & {
  isTransfer: boolean;
}) => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.error);

  /**
   * DOM
   */
  return (
    <>
      {/* api error  */}
      <Error message={error} cls="mx-10 my-4" />

      {/* cancel btn  */}
      <div className="flex flex-col items-stretch border-t border-grey-bright bg-gray-100 rounded-b px-10 py-6">
        <button className="app-button-failure" onClick={onCancel} tabIndex={-1}>
          Cancel this transaction
        </button>
      </div>
    </>
  );
};

export const RecordsFooter = (props: Props) => {
  /**
   * Vars
   */
  const { activity, transaction } = props;

  /**
   * Store
   */
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  /**
   * DOM
   */
  const isWithdrawal = activity?.type === API.ActivityType.Withdrawal;
  const isTransferType = isTransfer(activity) || isInternalTransfer(activity);
  const isFiatWithdrawal = activity?.type === API.ActivityType.FiatWithdrawal;
  const canCancel =
    transaction &&
    (
      transaction as
        | Enriched.Withdrawal
        | Enriched.Transfer
        | API.FiatWithdrawal
    ).canCancel &&
    clientUserType !== 'admin';
  if (!(isWithdrawal || isTransferType || isFiatWithdrawal) || !transaction) {
    return <DefaultFooter {...props} />;
  }
  if (!canCancel) {
    return <DefaultFooter {...props} />;
  }
  return <CancelRequestFooter {...props} isTransfer={isTransferType} />;
};
