import React from 'react';
import { FC, useEffect, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { TwoFaWidget } from '~/components/two-fa-widget';
import { DataStore } from '~/store';
import { createFiatWithdrawalHelper } from './helper';
import { FormFooter } from '../shared/form-footer';
import { WithdrawCashSteps } from './steps';
import { getAccountInfo } from '~/utils/get-account-details';
import { FormHeader } from 'common';

export const Validate2FA: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.withdrawCash.error);
  const setError = DataStore.useStoreActions(a => a.withdrawCash.setError);
  const formValues = DataStore.useStoreState(s => s.withdrawCash.formValues);
  const createFiatWithdrawal = DataStore.useStoreActions(
    a => a.withdrawCash.create
  );
  const is2FaEnabled = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.is2FaEnabled
  );
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);

  /**
   * State
   */
  const [twoFaCode, setTwoFaCode] = useState<string>('');

  /**
   * Hooks
   */
  useEffect(() => {
    setError(null);
  }, [twoFaCode]);

  if (!goToNamedStep) {
    return null;
  }

  /**
   * Methods
   */
  const onCancelPreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    goToNamedStep(WithdrawCashSteps.Form);
  };
  const onRequestWithdrawal = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    createFiatWithdrawalHelper(
      formValues,
      createFiatWithdrawal,
      twoFaCode || null,
      goToNamedStep
    );
  };

  /**
   * DOM
   */
  if (!accountDetail) {
    return null;
  }
  const askFor2fa = !!accountDetail.settings?.ask2FaForFiatWithdrawals;
  const submitDisabled = error !== null || (askFor2fa && !twoFaCode);

  return (
    <div className="flex flex-col">
      {/* header  */}
      <FormHeader
        onBack={onCancelPreview}
        title=""
        accountInfo={getAccountInfo(accountDetail.account, 'From')}
      />

      {/* content  */}
      <div className="mx-10 text-primary">
        {/* two fa widget  */}
        <TwoFaWidget
          askFor2FA={askFor2fa}
          isEnabled={is2FaEnabled}
          onChange={code => setTwoFaCode(code)}
          error={error}
        />
      </div>

      {/* actions  */}
      <FormFooter
        onSubmit={onRequestWithdrawal}
        onCancel={onCancelPreview}
        isSubmitDisabled={submitDisabled}
        submitCls="app-button-black"
        submitText="Withdraw"
      />
    </div>
  );
};
