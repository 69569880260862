import { FC } from 'react';
import { API } from 'api';
import cx from 'classnames';
import { AppTooltip } from '../../app-tooltip';
import {
  Timeline,
  TimelineProps,
} from '~/utils/get-activity-records/shared/timeline';
import { InvoiceStatusTitle, StatusIndicator, getActivityStatus } from 'common';

interface Props {
  data?: API.ActivityTransaction & API.Timeline;
}

export const CellActivityStatus: FC<Props & TimelineProps> = ({
  cls = `flex flex-row w-full items-center rounded-t bg-grey-brightest border-grey-bright border p-4 items-center`,
  ...rest
}) => {
  /**
   * DOM
   */

  const updatedStatus = getActivityStatus(rest?.status || '');
  const { data } = rest;
  if (!rest?.timeline) {
    return (
      <>
        <div className="break-word">
          <div className="flex flex-row justify-end items-center gap-x-1">
            {data?.isWaitingForConfirmations && (
              <ConfirmationTooltip status={updatedStatus} />
            )}
            <StatusIndicator
              value={updatedStatus}
              title={updatedStatus}
              cls={cx(cls, 'rounded-b')}
            />
          </div>
          {data?.isWaitingForConfirmations && (
            <div className="text-gray-400 text-xs">
              {data.confirmations} of {data.confirmationsNeeded} Confirmation
              {data.confirmationsNeeded != 1 && 's'}
            </div>
          )}
        </div>
      </>
    );
  }
  return <Timeline {...rest} cls={cls} />;
};

const ConfirmationTooltip: FC<Pick<TimelineProps, 'status'>> = ({ status }) => {
  return (
    <AppTooltip
      effect="float"
      content={
        <div className="flex flex-col text-left">
          {status && (
            <StatusIndicator
              value={status as never}
              title={InvoiceStatusTitle[status]}
            />
          )}
          <span className="text-left">
            The network confirmations listed show the required number we need
            from the blockchain to deem your transaction as validated. These
            confirmations will update in live time.
          </span>
        </div>
      }
    />
  );
};
