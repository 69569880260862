export enum MarketTableColumnId {
  Asset = 'Asset',
  Price = 'Price',
  Change24h = 'Change24h',
  Volume24h = 'Volume24h',
  MarketCap = 'MarketCap',
  Action = 'Action',
}

export const MarketTableColumnTitle: Record<MarketTableColumnId, string> = {
  [MarketTableColumnId.Asset]: 'Asset',
  [MarketTableColumnId.Price]: 'Price',
  [MarketTableColumnId.Change24h]: '24h change',
  [MarketTableColumnId.Volume24h]: '24h volume',
  [MarketTableColumnId.MarketCap]: 'Market cap',
  [MarketTableColumnId.Action]: '',
};

export type MarketTableColumn = {
  title: string;
  id: MarketTableColumnId;
  sortable: boolean;
  width: number | string;
};
