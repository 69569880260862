import { FC } from 'react';
import cx from 'classnames';
import { API } from 'api';
import iban from 'iban';
import { getMaskedUntilValue } from '../../utils/get-masked-until-value';

interface Props {
  account: API.BankAccount | null | undefined;
  cls?: string;
  clsBankInfo?: string;
  showBankName?: boolean;
  showMasked?: boolean;
}

export const BankAccountInfo: FC<Props> = ({
  account,
  cls = 'flex flex-col items-start',
  clsBankInfo,
  showBankName = false,
  showMasked = true,
}) => {
  /**
   * DOM
   */
  if (!account || !account.bankAccountDetails?.accountNumber) {
    return <>N/A</>;
  }
  const isIban = !!iban.isValid(account.bankAccountDetails?.accountNumber);
  const prefix = isIban ? `IBAN` : `Acc.`;
  const suffix = getMaskedUntilValue(account.bankAccountDetails.accountNumber);
  return (
    <span className={cx(cls)}>
      {showBankName && (
        <span className="text-sm text-primary font-bold">
          {account.bankAccountDetails.bankName}&nbsp;
        </span>
      )}
      {!showMasked && (
        <span className={cx('text-xs font-normal text-primary', clsBankInfo)}>
          {account.bankAccountDetails.accountNumber}
        </span>
      )}
      {showMasked && (
        <span className={cx('text-xs font-normal text-primary', clsBankInfo)}>
          {prefix} {suffix}
        </span>
      )}
    </span>
  );
};

export const BankAccountMultiLine: FC<Props> = ({ account, cls }) => {
  /**
   * DOM
   */
  if (!account || !account.bankAccountDetails?.accountNumber) {
    return <>N/A</>;
  }
  const isIban = !!iban.isValid(account.bankAccountDetails?.accountNumber);
  const prefix = isIban ? `IBAN` : `Account number`;
  const suffix = account.bankAccountDetails.accountNumber;
  return (
    <div className={cx('flex flex-col', cls)}>
      <span className="text-primary font-bold">{suffix}</span>
      <span className="text-xs text-gray-400">{prefix}</span>
    </div>
  );
};
