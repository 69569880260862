import { API } from 'api';
import { humanize } from 'common';
import { ActivityAmount, ActivityDate, ActivityHash } from '../shared';
import { CellActivityStatus } from '~/components/transactions/cells';

export const getYieldPayoutRecords = (activity: API.ActivityTransaction) => {
  return {
    '': <CellActivityStatus {...activity} status={'Confirmed'} />,
    Amount: (
      <ActivityAmount
        sign="+"
        amount={activity.amount1}
        decimals={activity.currency1?.decimals}
        code={activity.currency1?.displayCode}
      />
    ),
    Date: <ActivityDate date={activity.creationDate} />,
  };
};

export const getRewardRecords = (activity: API.ActivityTransaction) => {
  let humanizedRewardName = humanize(activity.rewardName!.toString());
  switch (activity.rewardName) {
    case API.RewardName.MatchInKind:
      humanizedRewardName = '';
      break;
    case API.RewardName.ReferrerDepositAndHoldCreditsReferrer:
      humanizedRewardName = 'Referrer';
      break;
    case API.RewardName.ReferrerDepositAndHoldCreditsReferree:
      humanizedRewardName = 'Referee';
      break;
    case API.RewardName.Promocode:
      humanizedRewardName = 'Promocode';
      break;
  }

  return {
    '': <CellActivityStatus {...activity} status={'Confirmed'} />,
    ...(humanizedRewardName && { 'Reward type': `${humanizedRewardName}` }),
    Date: <ActivityDate date={activity.creationDate} />,
  };
};

export const getEarnedFeeRecords = (activity: API.ActivityTransaction) => {
  return {
    '': <CellActivityStatus {...activity} status={'Confirmed'} />,
    Amount: (
      <ActivityAmount
        sign="+"
        amount={activity.amount1}
        decimals={activity.currency1?.decimals}
        code={activity.currency1?.displayCode}
      />
    ),
    Date: <ActivityDate date={activity.creationDate} />,
    // Note: @raph asked me to use id here as ActivityTransaction does not have txId
    'Transaction hash': <ActivityHash hash={activity.id} />,
  };
};
