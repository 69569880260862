import { FC } from 'react';
import { API } from 'api';
import cx from 'classnames';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import { Disclosure } from '@headlessui/react';
import StepLabel from '@mui/material/StepLabel';
import {
  AppIcon,
  StatusIndicator,
  TitlePosition,
  getActivityStatus,
} from 'common';

export type TimelineProps = {
  cls?: string;
  timeline?: API.Timeline | null;
  status?: string | 'Confirmed' | 'Cancelled' | 'Pending' | API.TimelineStatus;
};

export const Timeline: FC<TimelineProps> = ({ cls, status, timeline }) => {
  const updatedStatus = getActivityStatus(status || timeline?.status || '');

  return (
    <div className="mx-auto w-full rounded-2xl bg-white">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={cx(cls, {
                'rounded-b': !open,
                'justify-center': !timeline,
                'justify-between': timeline,
              })}
            >
              <StatusIndicator
                value={updatedStatus}
                title={updatedStatus || timeline?.title}
                titlePosition={TitlePosition.RTL}
              />
              {!!timeline && (
                <AppIcon
                  size={18}
                  bg="bg-transparent"
                  icon="chevron-down"
                  fill="fill-current-color-dark"
                  cls={cx({
                    'rotate-180 transform': open,
                  })}
                />
              )}
            </Disclosure.Button>

            {!!timeline && (
              <Disclosure.Panel
                className={cx(
                  'bg-grey-brightest border-grey-bright border p-4',
                  {
                    'rounded-b border-t-0': open,
                  }
                )}
              >
                <Stepper orientation="vertical">
                  {timeline?.instances?.map((step, i) => (
                    <Step
                      key={`${step?.description}-${i}`}
                      active={step?.period === API.TimelineItemPeriod.Present}
                      completed={step?.period === API.TimelineItemPeriod.Past}
                    >
                      <StepLabel
                        style={{
                          padding: 0,
                        }}
                        StepIconProps={{
                          style: {
                            paddingRight: 0,
                          },
                        }}
                        StepIconComponent={() => (
                          <>
                            {step?.period === API.TimelineItemPeriod.Past && (
                              <AppIcon
                                size={23}
                                icon="check"
                                bg="bg-primary"
                                fill="fill-current-color-white"
                              />
                            )}
                            {step?.period ===
                              API.TimelineItemPeriod.Present && (
                              <AppIcon
                                size={23}
                                icon="in-progress"
                                cls="animate-pulse"
                              />
                            )}
                            {step?.period === API.TimelineItemPeriod.Future && (
                              <AppIcon size={23} icon="blank-icon" bg="" />
                            )}
                          </>
                        )}
                      >
                        <span
                          className={cx('p-0 flex flex-col ', {
                            'opacity-50':
                              step?.period === API.TimelineItemPeriod.Future,
                          })}
                        >
                          <span className="typo-b3 font-bold text-primary">
                            {step?.displayName}
                          </span>
                          <span className="typo-b4 text-grey-darker">
                            {step?.description}
                          </span>
                        </span>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Disclosure.Panel>
            )}
          </>
        )}
      </Disclosure>
    </div>
  );
};
