import {
  getStorageKeys,
  useComputedConfig,
  useEnvironmentConfig,
  useRecaptcha,
  useSystemConfig,
  useUrls,
} from 'common';
import { Formik, FormikHelpers } from 'formik';
import { FC, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Error } from '../components/common/error';
import { TextField } from '../components/forms/text-field';
import { ExternalSplashLayout } from '../components/layouts/external-splash';
import { API_ERROR_CODES, ERROR_MESSAGES } from 'common';
import { BaseForm } from '../definitions/base-form';
import { APP_ROUTES } from '../routes';
import { DataStore } from '../store';
import { clearFormErrors } from '../utils/handle-errors';
import { nameof } from '../utils/typescript-helpers';
import { storageHelper } from '../utils/storage-helper';
import {
  usePasswordLabel,
  useTogglePassword,
} from '../hooks/use-toggle-password';
import { ChooseTenant } from '../components/devtools/choose-tenant';
import { Auth0 } from '~/utils/auth0';

interface LoginForm extends BaseForm {
  email: string;
  password: string;
  code?: string;
}

const LoginSchema = Yup.object().shape({
  [nameof<LoginForm>('email')]: Yup.string()
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .required(ERROR_MESSAGES.REQUIRED_VALUE),
  [nameof<LoginForm>('password')]: Yup.string().required(
    ERROR_MESSAGES.REQUIRED_VALUE
  ),
  show2Fa: Yup.boolean(),
});

const RegisterLink: FC = () => {
  /**
   * Hooks
   */
  const { tenant } = useComputedConfig();
  const { webSiteUrl } = useUrls();

  /**
   * DOM
   */
  const contactUsUrl = `${webSiteUrl}/#get-started`;
  const LinkDom = ({ title = 'Contact us' }) => {
    return (
      <a
        href={contactUsUrl}
        target="_blank"
        className="underline font-bold text-primary"
        rel="noreferrer"
      >
        {title}
      </a>
    );
  };
  return (
    <div className="text-sm  text-gray-300 gap-1.5 flex justify-center">
      Don&#39;t have an account?
      <LinkDom title="Register" />
    </div>
  );
};

const Auth0Login: FC = () => {
  /**
   * Hooks
   */
  const { REACT_APP_AUTH0_AUDIENCE } = useEnvironmentConfig();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await Auth0.login(REACT_APP_AUTH0_AUDIENCE!);
    })();
  }, []);

  /**
   * DOM
   */
  return null;
};

const LegacyLogin: FC = () => {
  /**
   * Store
   */
  const authenticate = DataStore.useStoreActions(a => a.user.authenticate);
  const error = DataStore.useStoreState(_ => _.error);
  const setError = DataStore.useStoreActions(_ => _.setError);

  /**
   * Hooks
   */
  const { REACT_APP_BUILD_ENV_NAME, REACT_APP_AUTH0_AUDIENCE } =
    useEnvironmentConfig();
  const { recaptchaWebappPublicKey } = useSystemConfig();
  const navigate = useNavigate();
  const { getRecaptchaToken } = useRecaptcha({
    key: recaptchaWebappPublicKey,
  });
  const computedConfig = useComputedConfig();
  const [showPassword, toggleShowPassword, passwordType] = useTogglePassword();
  const router = useLocation();
  const { tenant } = useComputedConfig();

  /**
   * Methods
   */
  const onFormSubmit = async (
    values: LoginForm,
    helper: FormikHelpers<LoginForm>
  ) => {
    setError(null);
    clearFormErrors(helper);

    console.log('Starting authentication');

    const res = await authenticate({
      email: values.email,
      password: values.password,
      recaptchaToken: await getRecaptchaToken(),
      tenant: null,
      targetPath: (router.state as { from: { pathname: string } })?.from
        ?.pathname,
      siteCode: computedConfig.tenant,
    });

    const needsChallenge = Boolean(res?.result?.challenge && !res?.result.jwt);
    if (needsChallenge) {
      navigate(APP_ROUTES.NON_AUTH_LOGIN_2FA);
      return;
    }
    if (res?.result?.jwt) {
      // TODO: this needs to be removed from here after we find a way to store this flag through the store for both mobile and web
      localStorage.removeItem(
        getStorageKeys('web', REACT_APP_BUILD_ENV_NAME)?.user2FABypassed
      );
      storageHelper.alerts.remove();
      storageHelper.jwt.set(res.result.jwt);
    }

    if (res?.errorCode === API_ERROR_CODES.USER_ACOUNT_DISABLED) {
      setError(null);
      navigate(APP_ROUTES.NON_AUTH_ACCOUNT_DISABLED);
      return;
    }

    if (res?.errorCode === API_ERROR_CODES.USER_ACOUNT_LOCKED) {
      setError(null);
      navigate(APP_ROUTES.NON_AUTH_ACCOUNT_LOCKED);
      return;
    }

    if (res?.errorCode === API_ERROR_CODES.LOGIN_UNSUPPORTED_AUTHENTICATION) {
      setError(null);
      await Auth0.login(REACT_APP_AUTH0_AUDIENCE!);
      return;
    }
  };

  /**
   * DOM
   */
  const initialValues: LoginForm = {
    email: '',
    password: '',
  };
  return (
    <ExternalSplashLayout title="Sign in">
      <Formik<LoginForm>
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={onFormSubmit}
        enableReinitialize
      >
        {({ handleSubmit, errors, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="mt-6">
            {/* api error  */}
            {error && <Error testid="authentication-failed" message={error} />}

            {/* email address  */}
            <div className="flex flex-col">
              <TextField
                label="Email"
                data-testid="login-email"
                name={nameof<LoginForm>('email')}
                type="email"
                autoComplete="email"
              />
            </div>

            {/* password  */}
            <div className="col-span-2 flex flex-col">
              <TextField
                label={
                  <div className="flex justify-between mt-6 mb-0.5">
                    <span>Password</span>
                    <Link
                      to={APP_ROUTES.NON_AUTH_FORGOT_PASSWORD}
                      className="hover:underline text-gray-300 hover:text-primary"
                    >
                      Forgot password?
                    </Link>
                  </div>
                }
                data-testid="login-password"
                name={nameof<LoginForm>('password')}
                type={passwordType}
                autoComplete="current-password"
              />
            </div>

            {/* tenant chooser  */}
            <ChooseTenant value={computedConfig.tenant} />

            {/* actions  */}
            <div className="flex flex-col gap-y-6">
              {/* forgot password  */}
              <div>
                {usePasswordLabel('', showPassword, toggleShowPassword)}
              </div>

              {/* TODO: formik isSubmitting should be a wired up to busy state  */}
              <button
                data-testid="login-button"
                type="submit"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                className="app-button-accent"
              >
                Sign in
              </button>
              <RegisterLink />
            </div>
          </form>
        )}
      </Formik>
    </ExternalSplashLayout>
  );
};

export const Login: FC<{ legacyAuth?: boolean }> = ({ legacyAuth = false }) => {
  /**
   * Hooks
   */
  const { tenant } = useComputedConfig();

  /**
   * DOM
   */
  if (!tenant) {
    return null;
  }
  if (tenant === 'Apex' || !!legacyAuth) {
    return <LegacyLogin />;
  }
  return <Auth0Login />;
};
