import { AdditionalHeaderKeys, AdditionalHeaderTypes } from '../store/types';

export const removeHeader = (
  headers: AdditionalHeaderKeys,
  key: AdditionalHeaderTypes
) => {
  const returnHeaders = { ...headers };
  if (Object.keys(returnHeaders).length && returnHeaders[key]) {
    delete returnHeaders[key];
  }
  return returnHeaders;
};

export const removeImpersonation = (headers: AdditionalHeaderKeys) => {
  return removeHeader(headers, 'x-impersonated-account-id');
};
