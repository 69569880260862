export const dataTestIds = {
  components: {
    appChevronDivider: {
      root: 'app-chevron-divider',
    },
    appHeader: {
      root: 'app-header',
    },
    appRoundButton: {
      root: 'app-round-button',
    },
    appValidationWrapper: {
      root: 'app-validation-wrapper',
    },
    portfolioHero: {
      root: 'portfolio-hero',
      listAccounts: 'portfolio-hero-list-accounts',
      depositButton: 'portfolio-hero-deposit-button',
      withdrawButton: 'portfolio-hero-withdraw-button',
      convertButton: 'portfolio-hero-convert-button',
    },
    iconContent: {
      root: 'icon-content',
    },
    transactions: {
      cellActivityAmountCurrency: {
        root: 'cell-activity-amount-currency',
      },
      cellActivityDate: {
        root: 'cell-activity-date',
      },
      cellActivityType: {
        root: 'cell-activity-type',
      },
      transactionsPendingActivities: {
        root: 'transactions-pending-activities',
      },
      transactionsProcessedActivities: {
        root: 'transactions-processed-activities',
      },
    },
  },
};
