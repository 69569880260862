import { useEffect, useState } from 'react';
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { APP_ROUTES } from '~/routes';

let client: Auth0Client | null = null;

const getClient = async () => {
  if (client) {
    return client;
  }
  return initializeAuth0Client();
};

// This is for an edge case when the user clicks the "Log in with another account" during the MFA step up.
// This will open the stablehouse app inside the popup which is not intended.
// If this happens, close the window
const closeSelf = async () => {
  if (window.opener && window.opener !== window) {
    const parent = window.self;
    parent.opener = window.self;
    parent.close();
  }
};

// This will decide if we should show the "Logged out" page.
// This page is only visible if we have been successfuly logged in once
const isLoggedOut = async () => {
  return hasBeenLoggedInOnce() && !(await isAuthenticated());
};

const hasBeenLoggedInOnce = () => {
  return !!localStorage.getItem('last-auth0-login-date');
};

const isAuthenticated = async () => {
  const client = await getClient();
  const isAuthenticated = await client.isAuthenticated();

  if (isAuthenticated) {
    localStorage.setItem('last-auth0-login-date', new Date().toJSON());
  }

  return isAuthenticated;
};

const useIsAuthenticated = () => {
  const [result, setResult] = useState<
    'unknown' | 'authenticatedWithAuth0' | 'notAuthenticatedWithAuth0'
  >('unknown');
  useEffect(() => {
    isAuthenticated().then(isAuthenticated => {
      setResult(
        isAuthenticated ? 'authenticatedWithAuth0' : 'notAuthenticatedWithAuth0'
      );
    });
  }, []);
  return result;
};

const login = async (audience: string, email?: string) => {
  const target = window.location.origin + APP_ROUTES.AUTH_AUTH0_CALLBACK;
  const client = await getClient();
  await client.loginWithRedirect({
    authorizationParams: {
      redirect_uri: target,
      audience: audience,
      login_hint: email,
      prompt: 'login',
    },
  });
};

const logout = async () => {
  const client = await getClient();
  await client.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
};

const initializeAuth0Client = async () => {
  if (client) {
    return client;
  }

  const {
    REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENTID,
  } = process.env;

  const target = window.location.origin + APP_ROUTES.AUTH_AUTH0_CALLBACK;
  client = await createAuth0Client({
    domain: REACT_APP_AUTH0_DOMAIN!,
    clientId: REACT_APP_AUTH0_CLIENTID!,
    authorizationParams: {
      redirect_uri: target,
      audience: REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid profile email',
    },

    // The default setting is 'Memory' which -as the name implies- doesn't work when you refresh the page.
    // WTF Auth0?
    //
    cacheLocation: 'localstorage',
  });

  // For debugging purposes.
  // Can be safely removed.
  window['auth0client'] = client;

  return client;
};

const triggerMfa = async () => {
  try {
    return await client?.getTokenWithPopup(
      {
        authorizationParams: {
          redirect_uri: window.location.origin + APP_ROUTES.AUTH_AUTH0_CALLBACK,
          scope: 'openid profile email',
          acr_values:
            'http://schemas.openid.net/pape/policies/2007/06/multi-factor',
        },
      },
      {
        // We need a large timeout, otherwise stepup MFA might fail with no reason if the user is a little slow to grab his phone.
        // The problem is the timeout fails without any notice, and as a user it gets very confusing.
        // See https://stablehouse.atlassian.net/browse/SH-6844
        // The default of 2 minutes is way to short.
        timeoutInSeconds: 7 * 24 * 3600,
      }
    );
  } catch (error) {
    // Do not crash the app if the user closes the popup.
    // Instead, prevent going to the next step
    return;
  }
};

const forceSetMfa = async () => {
  return await client?.loginWithRedirect({
    authorizationParams: {
      redirect_uri: window.location.origin + APP_ROUTES.AUTH_AUTH0_CALLBACK,
      scope: 'openid profile email',
      acr_values:
        'http://schemas.openid.net/pape/policies/2007/06/multi-factor',
    },
  });
};

export const Auth0 = {
  getClient,
  closeSelf,
  isLoggedOut,
  hasBeenLoggedInOnce,
  isAuthenticated,
  useIsAuthenticated,
  login,
  logout,
  triggerMfa,
  forceSetMfa,
};
