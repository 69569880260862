import { API } from 'api';
import { ActivityAmount, ActivityDate, ActivityDescription } from '../shared';
import { CellActivityStatus } from '~/components/transactions/cells';

export const getPaymentReceivedRecords = (
  activity: API.ActivityTransaction
) => {
  return {
    '': (
      <CellActivityStatus
        {...activity}
        status={activity.statusFilter as string}
      />
    ),
    Amount: (
      <ActivityAmount
        sign="+"
        amount={activity.amount1}
        decimals={activity.currency1?.decimals}
        code={activity.currency1?.displayCode}
      />
    ),
    Description: <ActivityDescription value={activity.description} />,
    Date: <ActivityDate date={activity.creationDate} />,
  };
};
