import { FC, useEffect } from 'react';
import { AppStore, DataStore } from '../../../store';
import { useSegmentTrack, FundShowDepositDetails } from 'common';
import { SegmentTrackType } from 'common';
import { defaultStepWizardProps } from '../../../constants';
import StepWizard from 'react-step-wizard';
import { Form } from './form';
import { Preview } from './preview';
import { AddCashSteps } from './steps';

export const AddCashWorkflow: FC<FundShowDepositDetails> = props => {
  /**
   * Store
   */
  const { resetState, setError } = DataStore.useStoreActions(s => ({
    resetState: s.addCash.resetState,
    setError: s.addCash.setError,
  }));
  const { setDashboardSelectedAsset } = AppStore.useStoreActions(a => ({
    setDashboardSelectedAsset: a.setDashboardSelectedAsset,
  }));

  /**
   * Hooks
   */
  useSegmentTrack(SegmentTrackType.StartDeposit);
  useEffect(() => {
    // clean up
    () => {
      setDashboardSelectedAsset(null);
      resetState();
    };
  }, []);

  /**
   * DOM
   */
  return (
    <div className="relative text-primary h-full">
      <StepWizard
        {...defaultStepWizardProps}
        className="h-full"
        onStepChange={() => setError(null)}
      >
        <Form stepName={AddCashSteps.Form} {...props} />
        <Preview stepName={AddCashSteps.Preview} {...props} />
      </StepWizard>
    </div>
  );
};
