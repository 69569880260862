export const industries = [
  'Agriculture, Food and Natural Resources',
  'Architecture and Construction',
  'Education and Training',
  'Business Management and Administration',
  'Arts, Audio/Video Technology and Communications',
  'Finance',
  'Government and Public Administration',
  'Health Science',
  'Hospitality and Tourism',
  'Human Services',
  'Information Technology',
  'Law, Public Safety, Corrections and Security',
  'Manufacturing',
  'Marketing, Sales and Service',
  'Science, Technology, Engineering and Mathematics',
  'Transportation, Distribution and Logistics',
];
