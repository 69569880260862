import { BalanceChange } from '../../types';
import { formatters } from '../formatters';
import { DEFAULTS } from '../../constants';

export function createBalanceChange(
  _value: string | null,
  _percentage: string | null,
  fiatCurrencyCodes: string[]
): BalanceChange | null {
  if (!_value || !_percentage || !fiatCurrencyCodes.length) {
    return null;
  }
  const percentage = Number(_percentage ?? '0');
  const value = Number(_value ?? '0');

  return {
    percentage,
    percentageFormatted: formatters.forceSign(
      `${formatters.getAmount(percentage, DEFAULTS.DECIMAL_SCALE)}%`,
      percentage > 0 ? '+' : ''
    ),
    value,
    valueFormatted: `${value > 0 ? '+' : ''}${formatters.getCurrency(
      value,
      DEFAULTS.DECIMAL_SCALE,
      'USD',
      fiatCurrencyCodes
    )}`,
  };
}
