import { getSecureKey } from './get-secure-key';

export const getStorageKeys = (platform: 'mobile' | 'web', env: string) => {
  return {
    confirmReferredModal: getSecureKey(platform, env, 'confirm-referred-modal'),
    thanksForWaitingModal: getSecureKey(
      platform,
      env,
      'thanks-for-waiting-modal'
    ),
    user2FABypassed: getSecureKey(platform, env, 'user-2fa-bypassed'),
    verifyAddressBypassed: getSecureKey(
      platform,
      env,
      'verify-address-bypassed'
    ),
  };
};
