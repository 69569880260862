import { API } from 'api';

export enum SIGN {
  NONE = '',
  PLUS = '+',
  MINUS = '-',
}

export const activitySign: Record<API.ActivityType, SIGN> = {
  Trade: SIGN.PLUS,
  Reward: SIGN.PLUS,
  Unknown: SIGN.NONE,
  Deposit: SIGN.PLUS,
  Payment: SIGN.MINUS,
  Transfer: SIGN.NONE,
  EarnedFee: SIGN.PLUS,
  TransferIn: SIGN.PLUS,
  YieldPayout: SIGN.PLUS,
  Withdrawal: SIGN.MINUS,
  ChargedFee: SIGN.MINUS,
  FiatDeposit: SIGN.PLUS,
  TransferOut: SIGN.MINUS,
  InvoicePayment: SIGN.NONE,
  FiatWithdrawal: SIGN.MINUS,
  PaymentReceived: SIGN.PLUS,
  InternalTransfer: SIGN.NONE,
  InternalTransferIn: SIGN.PLUS,
  InternalTransferOut: SIGN.MINUS,
  FundSubscription: SIGN.NONE,
  FundRedemption: SIGN.NONE,
};
