import { FC } from 'react';
import {
  AppIcon,
  getCurrencyInfoForCode,
  getFundFromCurrencyCode,
  getSendAmountWithdrawn,
  getSendAmountSent,
  getSendCurrencyCode,
  getSendFees,
} from 'common';
import { RowLabelContent, Note } from 'common';
import { IconContent } from '../../../icon-content';
import { DataStore } from '../../../../store';
import { API } from 'api';
import { CopyableText } from '~/components/copyable-text';
import { getAccountInfo } from '~/utils/get-account-details';
import { CellActivityDate } from '~/components/transactions/cells';

type Props = {
  actionNoteLabel?: boolean;
  amountToSendLabel?: boolean;
  whitelistAddressLabel?: boolean;
  feesLabel?: boolean;
  amountWithdrawnLabel?: boolean;
  showRecepientAddress?: boolean;
  referenceCodeLabel?: boolean;
  transactionHashLabel?: boolean;
} & Partial<API.Withdrawal | API.Transfer>;

export const DetailsCrypto: FC<Props> = ({
  actionNoteLabel = true,
  amountToSendLabel = true,
  whitelistAddressLabel = true,
  feesLabel = true,
  amountWithdrawnLabel = true,
  showRecepientAddress = false,
  referenceCodeLabel = false,
  transactionHashLabel = false,
  ...rest
}) => {
  /**
   * Store
   */
  const accountAssets = DataStore.useStoreState(
    s => s.portfolio.accountDetail?.assets
  );
  const currencies = DataStore.useStoreState(s => s.metaData.currencies);
  const simulation = DataStore.useStoreState(s => s.send.simulation);
  const formValues = DataStore.useStoreState(s => s.send.formValues);

  /**
   * DOM
   */
  if (!formValues || !accountAssets || !simulation) {
    return null;
  }
  const ccyCode = getSendCurrencyCode(formValues, simulation);
  const ccyInfo = getCurrencyInfoForCode(ccyCode, currencies);
  const fund = getFundFromCurrencyCode(ccyCode, accountAssets);
  const feeLabel = getSendFees(ccyInfo?.displayCode, formValues, simulation);
  const amountSentWithCurrencyCode = getSendAmountSent(simulation);
  const amountWithdrawnWithCurrencyCode = getSendAmountWithdrawn(simulation);
  const { whitelistAddress } = formValues;
  return (
    <div>
      {actionNoteLabel && (
        <Note cls="mb-8">
          <IconContent
            cls="ml-3 mr-5 mt-3 mb-2"
            icon={
              <AppIcon
                bg="bg-transparent"
                icon="information-circle"
                size={19}
              />
            }
            iconColor="black"
            mt=""
            content={
              <div className="flex flex-col text-grey-darker">
                <b className="inline-block mb-1 text-primary">
                  Please remember
                </b>
                <span>
                  Make sure to provide an address that is under your control.
                  Sending to an address that is not yours may result in the loss
                  of your funds.
                </span>
              </div>
            }
          />
        </Note>
      )}

      {/* amount to send  */}
      {amountToSendLabel && (
        <RowLabelContent
          showColon={false}
          label={`Amount`}
          content={<div>{amountWithdrawnWithCurrencyCode}</div>}
        />
      )}
      <RowLabelContent
        showColon={false}
        label={`Network`}
        content={fund?.currency.network || ''}
      />
      {/* fees  */}
      {feesLabel && !!simulation?.amount && (
        <RowLabelContent
          showColon={false}
          label={`Fee`}
          content={<span className="flex flex-col">{feeLabel}</span>}
        />
      )}
      {/* to account  */}
      {amountWithdrawnLabel && (
        <RowLabelContent
          showColon={false}
          cls="mb-3"
          label={`To`}
          content={
            <span className="flex flex-col text-right">
              <div className="text-right mb-2">
                <span className="text-success gap-y-3">+</span>
                {amountSentWithCurrencyCode}
              </div>
              {!showRecepientAddress && (
                <>
                  <span>
                    {whitelistAddressLabel && whitelistAddress
                      ? whitelistAddress.label
                      : fund?.account?.label}
                  </span>
                  <span className="font-normal">
                    {getAccountInfo(fund?.account)}
                  </span>
                </>
              )}
              {showRecepientAddress && (
                <>
                  <span className="text-primary font-bold">
                    {simulation.formatted.destination.label}
                  </span>

                  <CopyableText
                    cls="mt-1.5"
                    text={simulation.formatted.destination.address}
                    showCopyText={false}
                    textCls="flex-row items-center text-grey-darker font-normal text-right break-all"
                  />
                </>
              )}
            </span>
          }
        />
      )}
      {/* reference code  */}
      {referenceCodeLabel && (
        <RowLabelContent
          showColon={false}
          label={`Reference code`}
          content={
            <CopyableText
              showCopyText={false}
              text={rest.humanReadableIdentifier}
              textCls="font-bold uppercase"
            />
          }
        />
      )}

      {/* transaction hash  */}
      {transactionHashLabel && (
        <RowLabelContent
          showColon={false}
          label={`Transaction hash`}
          content={
            <CopyableText
              showCopyText={false}
              text={rest.humanReadableIdentifier}
              textCls="font-bold uppercase"
            />
          }
        />
      )}

      {/* date of transaction   */}
      {rest?.creationDate && (
        <RowLabelContent
          showColon={false}
          label={`Date of transaction`}
          content={
            <CellActivityDate
              date={rest?.creationDate}
              cls="text-sm items-end"
            />
          }
        />
      )}

      {!!formValues?.description?.length && (
        <RowLabelContent
          showColon={false}
          label={`Description`}
          content={<span>{formValues?.description}</span>}
        />
      )}
    </div>
  );
};
