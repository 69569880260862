import { API } from 'api';
import { Enriched, EnrichedCurrencyInformation } from '../../types';
import { formatters } from '../formatters';
import { DEFAULTS } from '../../constants';
import { createSendDestination } from './create-send-destination';

export const enrichTransferSimulation = (
  simulation: API.TransferSimulation | null,
  currencies: EnrichedCurrencyInformation[],
  fiatCurrencyCodes: string[]
): Enriched.TransferSimulation | null => {
  if (!simulation) {
    return null;
  }

  const currency =
    currencies.find(({ code }) => code === simulation.currencyCode) ?? null;

  if (!currency) {
    return null;
  }

  const amountWithCurrencyCode = formatters.getCurrency(
    simulation.amount,
    currency.decimals,
    currency.displayCode,
    fiatCurrencyCodes
  );

  const amountWithCurrencyCodeAndSign = formatters.forceSign(
    amountWithCurrencyCode,
    '-'
  );

  const amountUsdWithCurrencyCode = formatters.getCurrency(
    simulation.amountUsd,
    DEFAULTS.DECIMAL_SCALE,
    'USD',
    fiatCurrencyCodes
  );

  const feeWithCurrencyCode = formatters.getCurrency(
    simulation.fee,
    currency.decimals,
    currency.displayCode,
    fiatCurrencyCodes
  );

  const feeUsdWithCurrencyCode = formatters.getCurrency(
    simulation.feeUsd,
    DEFAULTS.DECIMAL_SCALE,
    'USD',
    fiatCurrencyCodes
  );

  const sendingAmountWithCurrencyCode = formatters.getCurrency(
    simulation.sendingAmount,
    currency.decimals,
    currency.displayCode ?? currency.code,
    fiatCurrencyCodes
  );

  const sendingAmountUsdWithCurrencyCode = formatters.getCurrency(
    simulation.sendingAmountUsd,
    DEFAULTS.DECIMAL_SCALE,
    'USD',
    fiatCurrencyCodes
  );

  const totalAmountUsdWithCurrencyCode = formatters.getCurrency(
    simulation.totalAmountUsd,
    DEFAULTS.DECIMAL_SCALE,
    'USD',
    fiatCurrencyCodes
  );

  return {
    ...simulation,
    destination: simulation.destinationTag ?? '',
    isExternal: false,
    formatted: {
      amountWithCurrencyCode,
      amountWithCurrencyCodeAndSign,
      amountUsdWithCurrencyCode,
      destination: createSendDestination(simulation, currency),
      sendingAmountWithCurrencyCode,
      sendingAmountUsdWithCurrencyCode,
      feeWithCurrencyCode,
      feeUsdWithCurrencyCode,
      totalAmountUsdWithCurrencyCode,
    },
  };
};
