import {
  authorizePusher,
  EnrichedAccountDetailAsset,
  EnrichedAssetHolding,
  EnrichedCryptoAddress,
} from 'common';
import { action, Action, thunk, Thunk } from 'easy-peasy';
import Pusher from 'pusher-js';
import { apiClient } from '../api/client';
import { DASHBOARD_SELECTED_DIALOG_TYPE } from './types';

interface DevtoolsOptions {
  showFormikDebug: boolean;
}

export interface AppModel {
  pusher: Pusher | null;
  devtoolsOptions: DevtoolsOptions;
  dashboardSelectedAsset:
    | EnrichedAccountDetailAsset
    | EnrichedAssetHolding
    | null;
  selectedCurrencyCode: string | null;
  dashboardSelectedDialogType: DASHBOARD_SELECTED_DIALOG_TYPE | null;
  selectedWithdrawalAddress: EnrichedCryptoAddress | null;
  // action
  setPusher: Action<AppModel, Pusher>;
  setDevtoolsOptions: Action<AppModel, DevtoolsOptions>;
  setDashboardSelectedDialogType: Action<
    AppModel,
    DASHBOARD_SELECTED_DIALOG_TYPE
  >;
  setDashboardSelectedAsset: Action<
    AppModel,
    EnrichedAccountDetailAsset | EnrichedAssetHolding | null
  >;
  setSelectedCurrencyCode: Action<AppModel, string | null>;
  setSelectedWithdrawalAddress: Action<AppModel, EnrichedCryptoAddress | null>;
  // thunk
  initPusher: Thunk<
    AppModel,
    {
      pusherKey: string;
      pusherCluster: string;
      env: string;
      accountIds: string[];
      userId: string;
    },
    undefined,
    AppModel
  >;
}

export const appModel: AppModel = {
  pusher: null,
  devtoolsOptions: {
    showFormikDebug: false,
  },
  dashboardSelectedAsset: null,
  dashboardSelectedDialogType: null,
  selectedCurrencyCode: null,
  selectedWithdrawalAddress: null,
  // action
  setPusher: action((state, payload) => {
    state.pusher = payload;
  }),
  setDevtoolsOptions: action((state, payload) => {
    state.devtoolsOptions = payload;
  }),
  setDashboardSelectedDialogType: action((state, payload) => {
    state.dashboardSelectedDialogType = payload;
  }),
  setDashboardSelectedAsset: action((state, payload) => {
    state.dashboardSelectedAsset = payload;
  }),
  setSelectedCurrencyCode: action((state, payload) => {
    state.selectedCurrencyCode = payload;
  }),
  setSelectedWithdrawalAddress: action((state, payload) => {
    state.selectedWithdrawalAddress = payload;
  }),
  // thunk
  initPusher: thunk((actions, payload, { getStoreState }) => {
    const storeState = getStoreState();
    if (storeState.pusher) {
      return;
    }
    console.log('pusher - init');
    const pusher = new Pusher(payload.pusherKey, {
      cluster: payload.pusherCluster,
      forceTLS: true,
      wsHost: 'pusher.stablehouse.com',
      authorizer: channel =>
        authorizePusher(
          channel,
          apiClient,
          payload.env,
          payload.accountIds,
          payload.userId
        ),
    });
    pusher.connection.bind('error', function (err: any) {
      if (err?.error?.data?.code === 4004) {
        console.error('Pusher limit reached!!');
      } else {
        console.error('Pusher error', err);
      }
    });
    actions.setPusher(pusher);
  }),
};
