import { useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

export function useInit(init: () => Promise<void>) {
  const [isInitialized, setInitialized] = useState(false);

  useEffectOnce(() => {
    async function main() {
      try {
        await init();
      } finally {
        setInitialized(true);
      }
    }

    main();
  });

  return isInitialized;
}
