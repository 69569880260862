import { Navigate } from 'react-router-dom';
import { APP_ROUTES } from '~/routes';

export const PreventNavigation = ({ validate, children }) => {
  if (validate()) {
    return <>{children}</>;
  } else {
    return <Navigate to={APP_ROUTES.AUTH_404} replace />;
  }
};
