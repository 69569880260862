import { FC } from 'react';
import { SettingsNotifications } from '~/components/settings/settings-notification';
import { SettingsLayout, SettingsMenuIds } from './layout';

export const Notifications: FC = () => {
  /**
   * DOM
   */
  return (
    <SettingsLayout
      header="Email notifications"
      menuId={SettingsMenuIds.NOTIFICATIONS}
    >
      <SettingsNotifications />
    </SettingsLayout>
  );
};
