import { API } from 'api';
import { ERROR_MESSAGES } from '../../../constants';
import { EnrichedAccountDetailAsset } from '../../../types';
import { validateAmountWithInBalance } from '../../validations/amount-within-balance';
import { validateDestination } from '../../validations/destination';
import { SendFormikField, SendFormikProps, SendDestinationType } from './types';
import parseNumber from 'multi-number-parse';

export const validateSendDestinationForm = async (
  destinationType: SendDestinationType,
  destination: string,
  blockchain: string,
  apiClient: API.StablehouseClient,
  accountId: string
) => {
  const errors: Record<string, string> = {};

  // destination address
  if (!destination || !destinationType) {
    errors[SendFormikField.destination] = ERROR_MESSAGES.REQUIRED_VALUE;
  } else {
    const { isValid, message } = await validateDestination(
      destination,
      blockchain,
      apiClient,
      accountId,
      destinationType
    );
    if (!isValid) {
      errors[SendFormikField.destination] =
        message || ERROR_MESSAGES.INVALID_DESTINATION;
    }
  }

  return errors;
};

export const validateSendAmountForm = (
  amount: string | null,
  fromAsset: EnrichedAccountDetailAsset | null
) => {
  const errors: Record<string, string> = {};

  // amount
  if (amount === null || amount === undefined || amount.length <= 0) {
    errors[SendFormikField.amount] = ERROR_MESSAGES.REQUIRED_VALUE;
  } else {
    if (parseNumber(amount) <= 0) {
      errors[SendFormikField.amount] = ERROR_MESSAGES.AMOUNT_MORE_THAN_0;
    }
    const isWithinBalance = validateAmountWithInBalance(amount, fromAsset);
    if (!isWithinBalance) {
      errors[SendFormikField.amount] = ERROR_MESSAGES.INSUFFICIENT_BALANCE;
    }
  }

  return errors;
};

const validate = async (
  {
    fromAsset,
    destinationType,
    destination,
    amount,
    whitelistAddress,
    description,
  }: SendFormikProps,
  blockchain: string,
  apiClient: API.StablehouseClient,
  accountId: string,
  useWhitelistAddress: boolean
) => {
  const errors: Record<string, unknown> = {};

  // fromSingleFund
  if (!fromAsset) {
    errors[SendFormikField.fromAsset] = ERROR_MESSAGES.REQUIRED_VALUE;
  } else {
    if (!fromAsset.hasBalance) {
      errors[SendFormikField.fromAsset] = ERROR_MESSAGES.INSUFFICIENT_BALANCE;
    }
  }

  // withdraw method
  if (!destinationType) {
    errors[SendFormikField.destinationType] = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  if (description) {
    if (description.length > 1024) {
      errors[SendFormikField.description] = ERROR_MESSAGES.MAX_1024_CHARACTERS;
    }
  }

  if (useWhitelistAddress) {
    // white list address
    if (!whitelistAddress) {
      errors[SendFormikField.whitelistAddress] = ERROR_MESSAGES.REQUIRED_VALUE;
    }
  } else {
    // destination
    Object.assign(
      errors,
      await validateSendDestinationForm(
        destinationType,
        destination,
        blockchain,
        apiClient,
        accountId
      )
    );
  }

  // amount
  Object.assign(errors, validateSendAmountForm(amount, fromAsset));

  return errors;
};

export const validateSendFormik = validate;
