import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppStore, DataStore } from '../../../store';
import { AppIcon } from 'common';

export const Cancelled: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  if (!goToNamedStep) {
    return null;
  }
  /**
   * Store
   */
  const setDashboardSelectedAsset = AppStore.useStoreActions(
    a => a.setDashboardSelectedAsset
  );
  const setSelectedDialogType = AppStore.useStoreActions(
    s => s.setDashboardSelectedDialogType
  );
  const resetState = DataStore.useStoreActions(a => a.send.resetState);
  const selectedWithdrawalAddress = AppStore.useStoreActions(
    s => s.setSelectedWithdrawalAddress
  );

  /**
   * DOM
   */
  return (
    <div className="flex flex-col">
      {/* header  */}
      <div className="flex flex-col items-center px-10 mb-12 gap-y-1">
        <AppIcon icon="check-success" size="lg" cls="mt-10" />
        <div className="flex flex-col gap-y-2">
          <h4 className="text-center font-bold text-lg sm:text-xl px-10">
            Your send transaction has been successfully canceled
          </h4>
          <p className="flex justify-center text-grey-darker text-center px-0 sm:px-8">
            Your send request will no longer be processed and your funds will be
            returned to your account.
          </p>
        </div>
      </div>

      {/* actions  */}
      <div className="flex border-t border-grey-bright bg-gray-100 rounded-b mt-4 py-6 px-6">
        {/* close button  */}
        <button
          role="button"
          type="button"
          className="w-full flex-1 app-button-primary"
          onClick={e => {
            e.preventDefault();
            resetState();
            selectedWithdrawalAddress(null);
            setSelectedDialogType(null);
            setDashboardSelectedAsset(null);
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};
