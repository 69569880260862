import { FC } from 'react';
import StepWizard from 'react-step-wizard';
import { defaultStepWizardProps } from '../../../constants';
import { FilterMain } from './filter-main';
import { FilterType } from './filter-type';
import { FilterStatus } from './filter-status';
import { TransactionFilterSteps } from './steps';
import { FilterProps } from './use-filter-transactions';
import { FilterTransactionAsset } from './filter-transaction-asset';

export const FilterNavigation: FC<FilterProps> = ({ onClose }) => (
  <div className="relative text-primary h-full">
    {/* wizard  */}
    <StepWizard {...defaultStepWizardProps} className="h-full">
      <FilterMain
        onClose={onClose}
        stepName={TransactionFilterSteps.FilterMain}
      />
      <FilterTransactionAsset stepName={TransactionFilterSteps.FilterAsset} />
      <FilterType stepName={TransactionFilterSteps.FilterType} />
      <FilterStatus stepName={TransactionFilterSteps.FilterStatus} />
    </StepWizard>
  </div>
);
