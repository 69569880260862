export enum AddCryptoAddressSteps {
  Intro = 'add-crypto-address-intro',
  Form = 'add-crypto-address-form',
  Validate2FA = 'add-crypto-address-validate-2fa',
  Code = 'add-crypto-address-code',
  Summary = 'add-crypto-address-summary',
  Cancelled = 'add-crypto-address-cancelled',
  ConfirmCancellation = 'add-crypto-address-confirm-cancel',
  // TODO: review the duplicated steps AccountLocked, Preview, Form and Summary that could be in a base Step enum
  AccountLocked = 'account-locked',
}
