import { useState } from 'react'
import { useInterval } from 'usehooks-ts'

export function useMaintenanceInterval(webClient, pingInterval = 5000) {
  /**
   * State
   */
  const [isMaintenance, setMaintenance] = useState(false);

  /**
   * Hooks
   */
  useInterval(
    () => {
      async function ping() {
        try {
          await webClient.ping();

          setMaintenance(false);
        } catch {
          setMaintenance(true);
        }
      }
      // When in maintenance mode, we ping BE every 5s
      ping()
    },
    // useInterval clears interval when timeout is null
    isMaintenance ? pingInterval : null
  );

  return {
    isMaintenance,
    setMaintenance,
  };
}