import { API } from 'api';

import { Enriched } from '../../types';

export function enrichSimplifiedAssetList(
  assets: API.SimplifiedListAsset[]
): Enriched.SimplifiedListAsset[] {
  const result: Enriched.SimplifiedListAsset[] = [];

  for (const asset of assets) {
    if (asset.code && asset.displayCode && asset.name) {
      result.push({
        ...asset,
        code: asset.code,
        displayCode: asset.displayCode,
        name: asset.name,
      });
    }
  }

  return result;
}
