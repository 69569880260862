import { API } from 'api';

export const isFiatCurrency = (
  currencyCode: string,
  currencies: API.CurrencyInformation[] | null
) => {
  if (!currencies) {
    return false;
  }
  const currency = currencies.find(({ code }) => code === currencyCode);
  if (!currency) {
    return false;
  }
  return currency.type === API.CurrencyType.Fiat;
};
