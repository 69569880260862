const loadedFiles: any = {};

export async function loadJavascript(file: string): Promise<void> {
  if (loadedFiles && loadedFiles.file) {
    return;
  }
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = function () {
      window.setTimeout(resolve);
      loadedFiles[file] = true;
    };
    script.onerror = reject;
    script.src = file;

    document.head.appendChild(script);
  });
}

export async function loadRecaptcha() {
  if (document === undefined || window === undefined) {
    return;
  }
  await loadJavascript(
    'https://www.google.com/recaptcha/api.js?render=explicit'
  );
  await new Promise<void>((resolve, reject) => {
    if (window.grecaptcha.render) {
      resolve();
    } else if (!window.grecaptcha.render && window.grecaptcha.ready) {
      window.grecaptcha.ready(resolve);
    } else {
      reject(new Error());
    }
  });
}
