import { useEffect } from 'react';
import { FC } from 'react';
import { API } from 'api';
import { DataStore } from '../../store';
import { Error } from '../common/error';
import { Toggle } from '../forms/toggle';

export const SettingsNotifications: FC = () => {
  /**
   * Store
   */

  const error = DataStore.useStoreState(s => s.error);
  const notificationSettings = DataStore.useStoreState(
    s => s.settings.notificationsSettings
  );
  const loadNotificationsSettingsThunk = DataStore.useStoreActions(
    a => a.settings.loadNotificationsSettings
  );
  const updateNotificationsSettingsThunk = DataStore.useStoreActions(
    a => a.settings.updateNotificationsSettings
  );

  useEffect(() => {
    loadNotificationsSettingsThunk({});
  }, []);

  const updateValue = async (
    key: keyof API.NotificationsSettings,
    newValue: boolean
  ) => {
    const cloned = {
      ...notificationSettings,
      [key]: newValue,
    } as API.NotificationsSettings;
    await updateNotificationsSettingsThunk(cloned);
  };

  const notifications: {
    key: keyof API.NotificationsSettings;
    label: string;
  }[] = [
    { key: 'receiveNewLoginNotifications', label: 'Sign ins' },
    { key: 'receiveMarketingUpdates', label: 'Marketing messages' },
    { key: 'receiveTradeEmailNotifications', label: 'Trades' },
  ];

  /**
   * DOM
   */
  return (
    <div>
      {error && <Error message={error} />}
      {notificationSettings && (
        <div className="flex flex-col gap-y-6 my-4">
          {notifications.map(notification => (
            <div key={notification.key} className="flex justify-between">
              <div className="text-base font-bold">{notification.label}</div>
              <div>
                <Toggle
                  checked={notificationSettings![notification.key]}
                  onChange={checked => updateValue(notification.key, checked)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
