import { API } from 'api';
// Do not shorten imports to avoid circular dependency
import { DataStoreType } from '../store/data-store';
import { EmptyMarketFilters } from '../store/market/store';
import { isCryptoMarketType } from '../store/market/utils';

type UseMarketType = {
  mainTabs: [
    API.MarketAssetType.Crypto,
    API.MarketAssetType.Fund,
    API.MarketAssetType.Bond,
    API.MarketAssetType.Fiat,
  ];
  typeTags: {
    id: string;
    label: string;
    filters: API.MarketListAssetsRequest;
  }[];
  isTabSelected: (type: API.MarketAssetType) => boolean;
  isTagSelected: (filters: API.MarketListAssetsRequest) => boolean;
};

export const useMarkets = (DataStore: DataStoreType): UseMarketType => {
  const assetsListFilters = DataStore.useStoreState(
    a => a.market.assetsListFilters
  );
  return {
    mainTabs: [
      API.MarketAssetType.Crypto,
      API.MarketAssetType.Fund,
      API.MarketAssetType.Bond,
      API.MarketAssetType.Fiat,
    ],
    // TODO(Hadrien): This should be broken into 2 : types & categories on the BE
    isTabSelected: (type: API.MarketAssetType) =>
      !!assetsListFilters?.types?.length &&
      (assetsListFilters.types.includes(type) ||
        (type === API.MarketAssetType.Crypto &&
          assetsListFilters?.types?.some(t => isCryptoMarketType(t)))),
    typeTags: [
      {
        id: 'all',
        label: 'All',
        filters: {
          ...EmptyMarketFilters,
        },
      },
      {
        id: 'stablecoins',
        label: 'Stablecoins',
        filters: {
          ...EmptyMarketFilters,
          types: [API.MarketAssetType.Stablecoin],
        },
      },
      {
        id: 'memecoins',
        label: 'Memecoins',
        filters: {
          ...EmptyMarketFilters,
          types: [API.MarketAssetType.Memecoin],
        },
      },
      {
        id: 'trending',
        label: 'Trending',
        filters: {
          ...EmptyMarketFilters,
          types: [API.MarketAssetType.Trending],
        },
      },
      // To test filters
      // {
      //   id: 'btc',
      //   label: 'BTC',
      //   filters: { ...EmptyMarketFilters, search: 'BTC' },
      // },
      // {
      //   id: 'bitcoin',
      //   label: 'Bitcoin',
      //   filters: { ...EmptyMarketFilters, search: 'bitcoin' },
      // },
    ],
    isTagSelected: (filters: API.MarketListAssetsRequest) =>
      !!filters.types?.some(t => assetsListFilters?.types?.includes(t)),
  };
};
