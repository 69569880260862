import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLoader } from '~/components/app-loader';
import { APP_ROUTES } from '~/routes';
import { DataStore } from '~/store';
import { Auth0 } from '~/utils/auth0';
import { storageHelper } from '~/utils/storage-helper';
import { apiClient } from '../api/client';
import { ApiError } from 'common';

export const OauthCallback = () => {
  /**
   * Store
   */
  const setJwt = DataStore.useStoreActions(a => a.user.setJwt);

  /**
   * Hooks
   */
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        const client = await Auth0.getClient();
        await client.handleRedirectCallback();
        const isAuthenticated = await client.isAuthenticated();

        if (!isAuthenticated) {
          console.log('Redirect to callback but client was not authenticated');
          navigate(APP_ROUTES.NON_AUTH_LOGGED_OUT);
          return;
        }

        console.log('Redirect callback succeeded');
        let identity = await apiClient.identity();
        storageHelper.jwt.set(identity.result!.jwt!);
        setJwt(identity.result!.jwt!);

        console.log('Redirecting to dashboard');
        navigate(APP_ROUTES.AUTH_PORTFOLIO);
      } catch (e: any) {
        console.error('Oauth', e);
        if (e instanceof ApiError && e?.status === 401) {
          // This occurs if you try to login with a user from another Env
          navigate(APP_ROUTES.NON_AUTH_ACCOUNT_UNKNOWN);
        } else {
          navigate(APP_ROUTES.NON_AUTH_LOGGED_OUT);
        }
      }
    })();
  }, []);

  /**
   * DOM
   */
  return <AppLoader spinnerTop="104px" />;
};
