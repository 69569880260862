import React from 'react';
import { AppIcon } from 'common';

interface NoFundsProps {
  title?: string;
  message?: string;
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const NotAllowed: React.FC<NoFundsProps> = ({
  title = `Oops!`,
  message = `You are not authorized to perform this operation`,
  onClose,
}) => (
  <div className="flex flex-col">
    {/* header  */}
    <h3 className="px-10">&nbsp;</h3>

    {/* content  */}
    <div className="flex flex-col align-middle items-center p-10 pb-5">
      <AppIcon size="lg" icon="cross" />
      <>
        <div className="text-2xl font-bold mt-5">{title}</div>
        <div className="text-gray-400 mt-6 text-center">{message}.</div>
      </>
    </div>
    {/* actions  */}
    <div className="flex border-t border-grey-bright bg-gray-100 rounded-b mt-4 py-8 px-10">
      <button
        role="button"
        type="button"
        className="app-button-primary w-full flex-1"
        onClick={onClose}
      >
        Close
      </button>
    </div>
  </div>
);
