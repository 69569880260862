import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import ReactTooltip from 'react-tooltip';
import { AppRoundButton } from '../app-round-button';
import { DataStore } from '../../store';
import { AppLogo } from '../app-logo';
import { useEnvironmentConfig } from 'common';
import { AppIcon } from 'common';
import { useLogout } from '../../hooks/use-authentication-hooks';

interface Props {
  userIcon?: boolean;
}

export const OnboardingAppHeader: FC<Props> = ({ userIcon = true }) => {
  /**
   * Store
   */
  const username = DataStore.useStoreState(s => s.user.decodedToken?.username);
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);

  /**
   * Hooks
   */
  const logout = useLogout();
  const navigate = useNavigate();
  const { REACT_APP_SHA } = useEnvironmentConfig();

  /**
   * DOM
   */
  return (
    <nav className="bg-primary text-white">
      {/* container  */}
      <div className="py-5">
        <div className="flex items-center h-20">
          {/* logo  */}
          <div className="flex items-center flex-1 justify-center">
            <div className="flex-shrink-0">
              <Link
                to={
                  isAuthenticated
                    ? APP_ROUTES.AUTH_PORTFOLIO
                    : APP_ROUTES.NON_AUTH_HOME
                }
                className="focus:ring-transparent focus:outline-none"
              >
                <AppLogo cls="h-8 w-auto" variant="light" />
              </Link>
            </div>
          </div>
        </div>

        {/* user menu  */}
        <div className="my-4 flex items-center justify-end">
          {/* user & dropdown  */}
          <div className="flex items-center">
            {/* menu  */}
            {userIcon && (
              <div className="relative inline-block p-1">
                <span className="group flex py-1">
                  <div
                    data-tip
                    data-for="userMenu"
                    className="flex flex-row items-center cursor-pointer hover-text-accent text-gray-400 hover:text-primary z-10"
                  >
                    <AppIcon
                      cls="fill-current-color h-5 w-5 hover-fill-accent"
                      icon="user"
                      bg="bg-transparent"
                      size="sm"
                    />
                  </div>
                  <ReactTooltip
                    id="userMenu"
                    className="app-tooltip"
                    effect="solid"
                    event="click"
                    globalEventOff="click"
                    place="bottom"
                  >
                    <div className="text-left">
                      {username && (
                        <div className="py-1.5 px-3.5">
                          <span className="group flex py-1 items-center">
                            Welcome, {username}&nbsp;&nbsp;
                          </span>
                        </div>
                      )}

                      <hr className="bg-gray-300" />

                      {/* logout  */}
                      <AppRoundButton
                        cls={'py-1.5 px-3.5 border-grey-bright'}
                        icon={<></>}
                        iconCls={`bg-transparent`}
                        iconInteractive={false}
                        text="Sign out"
                        textCls={`text-gray-400 hover:text-primary`}
                        onClick={() => {
                          logout();
                          navigate(APP_ROUTES.NON_AUTH_HOME);
                        }}
                      />

                      {/* build id  */}
                      {REACT_APP_SHA && (
                        <>
                          <hr className="bg-gray-300" />
                          <p
                            className={
                              'py-1.5 px-3.5 pointer-none text-xs font-light text-right'
                            }
                          >
                            Version: {REACT_APP_SHA}
                          </p>
                        </>
                      )}
                    </div>
                  </ReactTooltip>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
