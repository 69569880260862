export enum ChartTimeRange {
  H1 = '1H',
  H24 = '24H',
  W1 = '1W',
  M1 = '1M',
  M3 = '3M',
  Y1 = '1Y',
  Y3 = '3Y',
}

export enum ChartSeriesType {
  CandleStick = 'CandleStick',
  Area = 'Area',
}

export const ChartTimeRanges = [
  ChartTimeRange.H1,
  ChartTimeRange.H24,
  ChartTimeRange.W1,
  ChartTimeRange.M1,
  ChartTimeRange.M3,
  ChartTimeRange.Y1,
  ChartTimeRange.Y3,
];

export const FundChartTimeRanges = [
  ChartTimeRange.M1,
  ChartTimeRange.M3,
  ChartTimeRange.Y1,
  ChartTimeRange.Y3,
];

export type TradingViewLineChartData = {
  time: number;
  value: number;
};

export type TradingViewCandleStickChartData = {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
};

export type TradingViewChartData = {
  currencyCode?: string;
  timeRange?: ChartTimeRange;
  seriesType?: ChartSeriesType;
  areaChartDataPoints: TradingViewLineChartData[] | null;
  candleStickChartDataPoints?: TradingViewCandleStickChartData[] | null;
  decimalPrecision?: number;
  errored: boolean;
};
