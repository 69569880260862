export const getActivityStatus = (status: string) => {
  if (
    [
      'paid',
      'mining',
      'success',
      'signing',
      'executed',
      'complete',
      'confirmed',
      'completed',
      'security hold',
      'sourcing liquidity',
    ].includes(status.toLowerCase())
  ) {
    return 'Confirmed';
  }

  if (
    [
      'error',
      'cancelled',
      'failed',
      'rejected',
      'refunded',
      'failure',
    ].includes(status.toLowerCase())
  ) {
    return 'Cancelled';
  }

  return 'Pending';
};
