import { FC, TextareaHTMLAttributes } from 'react';
import { InputProps, getInputStyle } from 'common';

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextAreaInput: FC<TextAreaProps & InputProps> = ({
  errored = false,
  ...rest
}) => (
  <textarea
    id={rest.name}
    data-testid={rest.name}
    {...(rest as TextAreaProps)}
    className={getInputStyle(errored)}
  />
);
