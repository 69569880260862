import { FC } from 'react';
import { CopyableText } from '~/components/copyable-text';

type Props = {
  hash: string | null;
};

export const ActivityHash: FC<Props> = ({ hash }) => {
  if (!hash) {
    return <span className="text-gray-400">-</span>;
  }

  return (
    <CopyableText text={hash} showTextAfterCopy={true} showCopyText={false} />
  );
};
