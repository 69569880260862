import { ChangeEvent, FC } from 'react';
import { Field, FieldProps } from './field';
import { NumberFormatProps } from 'react-number-format';
import { InputProps, NumberInput } from 'common';

type Props = FieldProps & NumberFormatProps & InputProps;

export const NumberField: FC<Props> = ({
  label,
  name,
  onChange: handleChange,
  validator,
  validate,
  ...rest
}) => {
  /**
   * DOM
   */
  return (
    <Field name={name} label={label} validator={validator} validate={validate}>
      {(field, meta, helpers) => {
        const hasError = !!meta.error && !!meta.touched;
        return (
          <NumberInput
            {...rest}
            {...field}
            errored={Boolean(hasError)}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              if (handleChange) {
                handleChange(ev);
              }
              helpers.setValue(ev.target.value);
            }}
          />
        );
      }}
    </Field>
  );
};
