import { useRef } from 'react';

export const useDebounce = (timeout: number) => {
  const fetchIdRef = useRef(0);

  const debounce = (callBack: () => void) => {
    const fetchId = ++fetchIdRef.current;
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        callBack();
      }
    }, timeout);
  };

  return [debounce] as const;
};
