import { API } from 'api';
import { FC } from 'react';
import { AccountEdit } from './account-edit';
import { Divider } from '../shared';
import { ThunkResult, AccountTypeLabel, Enriched } from 'common';

type AccountsType = {
  type: string;
  accounts: Enriched.ListAccountItem[];
};

interface SettingsAccountLabelsProps {
  accountsType: AccountsType[];
  error?: string | null;
  onAccountLabelChange: (
    accountId: string,
    value: string
  ) => Promise<ThunkResult<void>>;
  accountLabelsErrors: Record<string, string | null>;
  clearError: (accountId: string) => void;
}

export const SettingsAccountLabels: FC<SettingsAccountLabelsProps> = ({
  accountsType,
  onAccountLabelChange,
  accountLabelsErrors = {},
  clearError,
}) => {
  /** Handlers */
  const handleOnLabelChange = async (accountId: string, value: string) =>
    onAccountLabelChange(accountId, value);

  const handleClearError = (accountId: string) => () => clearError(accountId);

  /**
   * DOM
   */
  return (
    <div>
      {/* content */}
      {accountsType && (
        <div className="flex flex-col">
          <span className="text-grey-darker text-base font-normal px-4 sm:px-8 py-6">
            Tap on the accounts to change the label. This will affect how
            they’re displayed throughout the app.
          </span>
          {accountsType.map(({ type, accounts }) => (
            <div key={type} className="flex flex-col border-t-1">
              <div className="flex flex-col items-start">
                <span className="flex flex-row gap-x-2 items-center text-primary font-bold px-4 sm:px-8 py-2">
                  {/*@TODO - enable for re-ordering feature
                  <AppIcon size={32} bg="bg-transparent" icon="re-order" /> */}
                  {AccountTypeLabel[type as API.AccountType]}
                </span>
                <Divider />
              </div>
              {accounts.map(({ account }, index) => (
                <div key={account?.accountId || index}>
                  <div className="px-4 sm:px-8 py-3">
                    <AccountEdit
                      accountId={account?.accountId || ''}
                      accountName={account?.label || ''}
                      accountNumber={account?.accountNumber || ''}
                      error={accountLabelsErrors[account?.accountId || '']}
                      onChange={handleOnLabelChange}
                      clearError={handleClearError(account?.accountId || '')}
                    />
                  </div>
                  {index !== accounts.length - 1 && <Divider />}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
