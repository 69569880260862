import React, { FC } from 'react';
import { OpenLayout } from '../layouts/open-layout';
import { FallbackCta } from '~/components/404/fallback-cta';
import { TIMERS, useMaintenanceInterval } from 'common'
import { apiClient } from '~/api/client';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '~/routes';
import { useEffectOnce } from 'react-use';

export const Unrestricted500x: FC = () => {
  const navigate = useNavigate();
  const { isMaintenance } = useMaintenanceInterval(apiClient);

  useEffectOnce(() => {
    if (!isMaintenance) {
      setTimeout(() => {
        navigate(APP_ROUTES.NON_AUTH_HOME);
      }, TIMERS.EXIT_500)
    }
  });

  return (
    <OpenLayout
      icon="manage-history"
      title="We will be back"
      cta={
        <FallbackCta
          href="https://t.me/stablehouse"
          label="Reach out on Telegram"
        />
      }
      withAppWrapper
    >
      XBTO performs regular maintenance to ensure the best experience possible
      for you. We’ll be back online shortly. If you have any questions, please
      reach out on Telegram
    </OpenLayout>
  );
};
