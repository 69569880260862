import React, { FC } from 'react';
import cx from 'classnames';
import { BaseComponentProps } from '../../types';

type StatusIndicatorProps = {
  cls?: string;
  title?: string;
  iconCls?: string;
  titleCls?: string;
  titlePosition?: TitlePosition;
  value: 'Confirmed' | 'Cancelled' | 'Pending';
} & BaseComponentProps;

export enum TitlePosition {
  LTR = 'LTR',
  RTL = 'RTL',
}

export const StatusIndicator: FC<StatusIndicatorProps> = ({
  cls,
  title,
  value,
  iconCls,
  titleCls = 'text-sm font-bold',
  titlePosition = TitlePosition.LTR,
}) => {
  /**
   * DOM
   */
  return (
    <div
      className={cx(cls, 'flex items-center gap-x-2 justify-center', {
        'flex-row': titlePosition === TitlePosition.LTR,
        'flex-row-reverse': titlePosition === TitlePosition.RTL,
      })}
    >
      <span className={cx(titleCls)}>{title}</span>{' '}
      <span
        className={cx(iconCls, 'w-2 h-2 rounded-full', {
          'bg-warning': value === 'Pending',
          'bg-success': value === 'Confirmed',
          'bg-failure': value === 'Cancelled',
        })}
      ></span>
    </div>
  );
};
