import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '~/routes';
import { DataStore } from '~/store';

export const Default = () => {
  /**
   * Store
   */
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  /**
   * Hooks
   */
  const navigate = useNavigate();
  useEffect(() => {
    if (clientUserType === 'admin') {
      navigate(APP_ROUTES.AUTH_ADMIN);
      return;
    }
    navigate(`${APP_ROUTES.AUTH_PORTFOLIO}/overview`);
  }, [clientUserType, navigate]);

  /**
   * DOM
   */
  return null;
};
