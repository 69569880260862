import { FC } from 'react';

interface RowSectionProps {
  title: string;
  description: JSX.Element | string;
}

export const RowSection: FC<RowSectionProps> = ({ title, description }) => (
  <div className="flex flex-col text-right text-lg">
    <p className="font-bold text-sm">{title}</p>
    <p className="text-sm font-normal text-grey-darker">{description}</p>
  </div>
);
