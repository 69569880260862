import { FC, useCallback, useEffect, useMemo } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppStore, DataStore } from '../../../store';
import {
  EnrichedCryptoAddress,
  SearchableModal,
  SendFormikField,
  SendFormikProps,
  addressBookContentTemplate,
} from 'common';
import { FormikProps } from 'formik';

export const AddressBook: FC<
  Partial<StepWizardChildProps> &
    Pick<FormikProps<SendFormikProps>, 'setFieldValue'>
> = ({ previousStep, setFieldValue }) => {
  if (!previousStep) {
    return null;
  }
  /**
   * Store
   */
  const decodedTokenAccountId = DataStore.useStoreState(
    s => s.user.decodedTokenAccountId
  );
  const formValues = DataStore.useStoreState(s => s.send.formValues);
  const withdrawalAddresses = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.withdrawalAddresses
  );

  const getWithdrawalAddresses = DataStore.useStoreActions(
    a => a.settings.cryptoAddresses.getWithdrawalAddresses
  );
  const setSelectedWithdrawalAddress = AppStore.useStoreActions(
    a => a.setSelectedWithdrawalAddress
  );
  const verifiedWithdrawalAddresses = DataStore.useStoreState(
    a => a.settings.cryptoAddresses.verifiedWithdrawalAddresses
  );

  /**
   * Hooks
   */
  useEffect(() => {
    if (!decodedTokenAccountId) {
      return;
    }
    getWithdrawalAddresses({
      accountId: decodedTokenAccountId,
    });
  }, [decodedTokenAccountId]);

  const filteredData = useMemo(() => {
    return withdrawalAddresses.filter(
      ({ currencyCode }) =>
        currencyCode === formValues?.fromAsset?.currency.code
    );
  }, [withdrawalAddresses, formValues?.fromAsset?.currency.code]);

  const onCancelPreview = useCallback(() => {
    setSelectedWithdrawalAddress(null);
    setFieldValue(SendFormikField.destination, '');
    previousStep();
  }, [formValues]);

  const handleItemSelection = useCallback(
    (address: EnrichedCryptoAddress) => {
      setSelectedWithdrawalAddress(address);
      setFieldValue(
        SendFormikField.whitelistAddress,
        verifiedWithdrawalAddresses.find(
          ad => ad.id === address.id && !ad.isVerifying
        )
      );
      setFieldValue(SendFormikField.destination, address.address);
      previousStep();
    },
    [formValues]
  );

  /**
   * DOM
   */

  return (
    <SearchableModal
      values={filteredData}
      onClose={onCancelPreview}
      onItemSelection={handleItemSelection}
      // @ts-ignore
      contentTemplate={addressBookContentTemplate}
      defaultSearchFilter={['address', 'label', 'currencyCode']}
    />
  );
};
