import { API_ERROR_CODES, REGEX } from 'common';
import { FC, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppStore, DataStore } from '../../../store';
import { VerifyCode } from '../../verify-code';
import { SendSteps } from './steps';

export const Code: FC<Partial<StepWizardChildProps>> = ({ goToNamedStep }) => {
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.send.error);
  const withdrawal = DataStore.useStoreState(s => s.send.withdrawal);
  const confirmCode = DataStore.useStoreActions(a => a.send.confirmCode);
  const resendCode = DataStore.useStoreActions(a => a.send.resendCode);
  const setSelectedDialogType = AppStore.useStoreActions(
    a => a.setDashboardSelectedDialogType
  );

  /**
   * State
   */
  const [cancelled, setCancelled] = useState(false);

  /**
   * DOM
   */
  if (!withdrawal) {
    return null;
  }
  return (
    <VerifyCode
      acceptedCharacters={REGEX.ALPHANUMERIC}
      titleMessage="Check email for verification number"
      error={error}
      onClose={() => {
        goToNamedStep(SendSteps.ConfirmCancellation);
      }}
      onSubmit={async code => {
        const { isSuccessful, errorCode } = await confirmCode(code);
        if (!isSuccessful) {
          if (errorCode) {
            if (
              errorCode ===
              API_ERROR_CODES.ACCOUNT_LOCKED_FAILED_CONFIRM_ATTEMPTS
            ) {
              goToNamedStep(SendSteps.AccountLocked);
            }
            if (errorCode === API_ERROR_CODES.WITHDRAW_TRANSFER_ABORTED) {
              setCancelled(true);
            }
          }
          return;
        }
        goToNamedStep(SendSteps.Summary);
      }}
      onResend={async () => {
        await resendCode();
      }}
      cancelled={cancelled}
      onCancelled={() => {
        setSelectedDialogType(null);
      }}
      submitButtonText="Send"
    />
  );
};
