import { FC } from 'react';
import { FormikProps } from 'formik';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppIcon, TransferFilterFormikProps } from 'common';

export const WorkflowCompletedStatus: FC<
  Partial<StepWizardChildProps & FormikProps<TransferFilterFormikProps>>
> = ({ nextStep }) => {
  if (!nextStep) {
    return null;
  }

  /**
   * Hooks
   */
  const timeout = setTimeout(async () => {
    nextStep();
    clearTimeout(timeout);
  }, 1000);

  return (
    <div className="flex flex-col py-40 px-8 w-full items-center gap-3">
      <AppIcon icon="green-tick" size={125} bg="bg-transparent" />
      <h3 className="font-semibold text-xl md:text-2xl">Done</h3>
    </div>
  );
};
