import { FC, useEffect, useState } from 'react';
import {
  useComputedConfig,
  useSystemConfig,
  getThemeForTenant,
  AppTenant,
} from 'common';
import { AppStore, DataStore } from '../store';
import { AppRoot } from './root';

export const AppHydrator: FC = () => {
  /**
   * Store
   */
  const accountIds = DataStore.useStoreState(s => s.portfolio.accountIds);
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);

  const initPusher = AppStore.useStoreActions(_ => _.initPusher);
  const { pusherCluster, pusherKey } = useSystemConfig();
  const { envName } = useSystemConfig();

  /**
   * State
   */
  const [ready, setReady] = useState(false);

  /**
   * Hooks
   */
  const { tenant } = useComputedConfig();
  useEffect(() => {
    if (!tenant) {
      return;
    }
    const themeName = getThemeForTenant(tenant as AppTenant);
    import(`common/dist/theme/${themeName}/index.scss`)
      .then(() => {
        setReady(true);
      })
      .catch(error => {
        console.error(`Could not load theme for tenant - ${tenant}: ${error}`);
      });
  }, [tenant]);
  useEffect(() => {
    if (!isAuthenticated || !decodedToken || accountIds.length <= 0) {
      return;
    }
    initPusher({
      pusherCluster,
      pusherKey,
      env: envName,
      accountIds,
      userId: decodedToken.id,
    });
  }, [isAuthenticated, decodedToken, accountIds, initPusher]);

  /**
   * DOM
   */
  if (!ready) {
    return null;
  }
  return <AppRoot />;
};
