import { FC, useRef, useState } from 'react';
import { useSegmentTrack, SegmentTrackType, FormHeader } from 'common';
import { DataStore } from '~/store';
import { getAccountInfo } from '~/utils/get-account-details';
import { getTradeRecords } from '~/utils/get-activity-records/trade';
import { Receipt } from '~/components/receipt';
import { ExportPdfButton } from '~/components/common/export-pdf-button';
import { TransactionPeriodNotice } from '../shared/transaction-period-notice';
import { StepWizardChildProps } from 'react-step-wizard';

export const Summary: FC<Partial<StepWizardChildProps>> = () => {
  /**
   * Vars
   */
  const ref = useRef<HTMLDivElement | null>(null);

  /**
   * State
   */
  const [isPdfProcessing, setIsPdfProcessing] = useState(false);

  /**
   * Store
   */
  const trade = DataStore.useStoreState(s => s.buySell.trade);
  const currencies = DataStore.useStoreState(s => s.metaData.currencies);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const formValues = DataStore.useStoreState(s => s.buySell.formValues);

  /**
   * Hooks
   */
  useSegmentTrack(SegmentTrackType.TradeStep3, trade, { onlyIf: !!trade });

  /**
   * DOM
   */
  if (!trade || !accountDetail) {
    return null;
  }
  const account = accounts.find(
    a => a.account?.accountNumber === accountDetail.account?.accountNumber
  );
  const records = getTradeRecords(
    trade,
    trade.status,
    currencies,
    account,
    formValues.side
  );
  return (
    <>
      <div className="flex flex-col text-primary w-full mx-auto" ref={ref}>
        {/* header  */}
        <FormHeader
          title={`${formValues?.side} ${trade?.currencyFrom?.displayCode}`}
          accountInfo={getAccountInfo(accountDetail.account, 'from')}
        />
        <Receipt
          records={records}
          // TODO - right side binding
          type={'workflow-buy'}
          ccyCodes={[trade.currencyFrom?.code, trade.currencyTo?.code]}
        />
      </div>
      <TransactionPeriodNotice
        cls="mb-8"
        status={trade?.status}
        // TODO - right side binding
        workflow={'workflow-buy'}
        accountType={accountDetail?.account?.accountType}
      />
      <div className="flex flex-col sm:flex-row gap-4 border-t border-grey-bright bg-grey-brightest rounded-b py-9 px-8 sm:px-10">
        {/* export  */}
        <ExportPdfButton
          node={ref}
          buttonLabel="Export"
          isProcessing={isPdfProcessing}
          setIsProcessing={setIsPdfProcessing}
          cls="button-lg sm:button-xl text-md sm:text-lg w-full flex-1"
          fileName={`Trade_${trade?.currencyFrom?.displayCode}-${Date.now()}`}
        />
      </div>
    </>
  );
};
