import { API } from 'api';
import parseNumber from 'multi-number-parse';
import { FIAT_SYMBOLS } from '../../../constants';
import { EnrichedCurrencyInformation } from '../../../types';
import { formatAmount } from '../../format-amount';
import { WithdrawCashFormikProps } from '../withdraw-cash/types';

export const getFiatWithdrawalRemainingBalance = (
  ccyInfo: EnrichedCurrencyInformation | undefined,
  fundBalance: number | 0,
  formValues: WithdrawCashFormikProps | null,
  simulation: API.FiatWithdrawalSimulation | null
) => {
  if (simulation) {
    return formatAmount(
      Number(fundBalance) -
        (Number((simulation as API.FiatWithdrawalSimulation)?.amount) +
          Number((simulation as API.FiatWithdrawalSimulation)?.fee)),
      ccyInfo?.decimals
    );
  }
  return formatAmount(
    parseNumber(fundBalance || 0) - parseNumber(formValues?.amount || 0),
    ccyInfo?.decimals
  );
};

export const _getFiatWithdrawalAmountSent = (
  formValues: WithdrawCashFormikProps | null,
  simulation: API.FiatWithdrawalSimulation | null
) => {
  return simulation ? simulation.amount : formValues?.amount;
};

export const getFiatWithdrawalAmountSent = (
  ccyInfo: EnrichedCurrencyInformation | undefined,
  formValues: WithdrawCashFormikProps | null,
  simulation: API.FiatWithdrawalSimulation | null,
  showSymbol = true
) => {
  const amount = _getFiatWithdrawalAmountSent(formValues, simulation);
  const symbol = showSymbol ? FIAT_SYMBOLS[ccyInfo?.code || 'EUR'] : '';

  return `${symbol}${formatAmount(amount, ccyInfo?.decimals)}`;
};

export const getFiatWithdrawalAmountReceive = (
  ccyInfo: EnrichedCurrencyInformation | undefined,
  formValues: WithdrawCashFormikProps | null,
  simulation: API.FiatWithdrawalSimulation | null,
  showSymbol = true
) => {
  const amount = simulation
    ? Number(simulation.amount) - Number(simulation.fee)
    : Number(formValues?.amount);
  const symbol = showSymbol ? FIAT_SYMBOLS[ccyInfo?.code || 'EUR'] : '';

  return `${symbol}${formatAmount(amount, ccyInfo?.decimals)}`;
};

export const getFiatWithdrawalAmountWithdrawn = (
  ccyInfo: EnrichedCurrencyInformation | undefined,
  formValues: WithdrawCashFormikProps | null,
  simulation: API.FiatWithdrawalSimulation | null,
  showSymbol = true
) => {
  const amount = simulation
    ? Number(simulation.amount) + Number(simulation.fee)
    : Number(formValues?.amount);
  const symbol = showSymbol ? FIAT_SYMBOLS[ccyInfo?.code || 'EUR'] : '';

  return `${symbol}${formatAmount(amount, ccyInfo?.decimals)}`;
};

export const getFiatWithdrawalFees = (
  ccyCode: string | null | undefined,
  simulation: API.FiatWithdrawalSimulation | null,
  showSymbol = true
) => {
  if (simulation) {
    const symbol = ccyCode && showSymbol ? FIAT_SYMBOLS[ccyCode] : '';
    return `${symbol}${simulation.fee}`;
  }
  return `-`;
};
