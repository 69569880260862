import { FC } from 'react';
import { AppIcon } from 'common';
import { FilterTags } from './filter-tags';
import { API } from 'api';
import cx from 'classnames';

interface Props {
  handleClick: () => void;
  values:
    | API.FilteringActivityLabel[]
    | API.TransactionStatusFilter[]
    | API.TransactionStatusFilter[]
    | string[]
    | undefined;
  label: string;
  cls?: string;
  displayNumber?: number;
  placeHolder?: string;
}

export const FilterInput: FC<Props> = ({
  handleClick,
  cls = 'mb-5',
  label,
  values,
  displayNumber,
  placeHolder,
}) => {
  /**
   * DOM
   */

  return (
    <div className={cx('flex flex-col flex-1', cls)}>
      <label className="text-gray-400 mb-2">{label}</label>
      <div
        className="app-input flex flex-row items-center justify-between cursor-pointer"
        onClick={handleClick}
      >
        <span className="flex text-base font-bold gap-1 overflow-hidden">
          {values?.length ? (
            <FilterTags list={values} displayNumber={displayNumber} />
          ) : (
            <span className="text-grey">{placeHolder}</span>
          )}
        </span>
        <button className="ml-2" onClick={handleClick}>
          <AppIcon icon="chevron-down-dark" size={25} bg="bg-transparent" />
        </button>
      </div>
    </div>
  );
};
