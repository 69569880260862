import { API } from 'api';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { DataStore } from '~/store';

export const useGetTransactions = () => {
  /**
   * Store
   */
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);
  const decodedTokenAccountId = DataStore.useStoreState(
    s => s.user.decodedTokenAccountId
  );

  const { getPaginatedActivities } = DataStore.useStoreActions(_ => ({
    getPaginatedActivities: _.transactions.getPaginatedActivities,
  }));

  /**
   * Vars
   */
  const { id, fundAccountId } = useParams();
  /**
   * Methods
   */
  const getTransactions = useCallback(
    async (options?: Partial<API.GetActivityTransactionsRequest> | null) => {
      if (clientUserType === 'admin' && !fundAccountId) return;
      await getPaginatedActivities({
        isBackgroundXHR: false,
        clearError: true,
        request: {
          ...options,
        } as Partial<API.GetActivityTransactionsRequest>,
        accountId: id || decodedTokenAccountId,
        impersonatedAccountId: fundAccountId,
        applyLoading: true,
      });
    },

    [id, clientUserType, fundAccountId, decodedTokenAccountId]
  );

  /**
   * Return
   */
  return { getTransactions };
};
