import copy from '../data/generated/copy.json';

export enum CTA_WIDGETS_KEY {
  TWO_FA = 'TWO_FA',
  BUY = 'BUY',
  GET_VERIFIED = 'GET_VERIFIED',
  GIVE_FEEDBACK = 'GIVE_FEEDBACK',
  MAKE_DEPOSIT = 'MAKE_DEPOSIT',
  REFER = 'REFER',
  RATE_APP = 'RATE_APP',
  RISK_MANAGEMENT = 'RISK_MANAGEMENT',
  LEARNING_HUB = 'LEARNING_HUB',
}

export type CopyDynamicTextParts = {
  value: string;
  type: string | 'bold' | 'normal';
}[];

export const useCopy = () => {
  return copy;
};
