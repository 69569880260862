export enum SegmentTrackType {
  TradeStep1 = 'TradeStep1',
  TradeStep2 = 'TradeStep2',
  TradeStep3 = 'TradeStep3',
  StartDeposit = 'StartDeposit',
  StartWithdrawalStep1 = 'StartWithdrawalStep1',
  StartWithdrawalStep2 = 'StartWithdrawalStep2',
  StartWithdrawalStep3 = 'StartWithdrawalStep3',
  DepositSelectsCurrency = 'DepositSelectsCurrency',

  KycSkippedAndGoesToDashboard = 'KycSkippedAndGoesToDashboard',
  KycProgress = 'KycProgress',
  KycProgressStep1Country = 'KycProgressStep1Country',
  KycProgressStep2UsCitizen = 'KycProgressStep2UsCitizen',
  KycProgressStep3Address = 'KycProgressStep3Address',
  KycEddProgressStep4ProofOfAddress = 'KycEddProgressStep4ProofOfAddress',
  KycProgressStep5DateOfBirth = 'KycProgressStep5DateOfBirth',
  KycProgressStep6SourceOfWealth = 'KycProgressStep6SourceOfWealth',
  KycProgressStep7EmploymentStatus = 'KycProgressStep7EmploymentStatus',
  KycProgressStep8Pep = 'KycProgressStep8Pep',
  KycProgressStep9Onfido = 'KycProgressStep9Onfido',
  KycProgressStep10Complete = 'KycProgressStep10Complete',

  KycOnfidoProgress = 'KycOnfidoProgress',

  KycEddProgress = 'KycEddProgress',
  KycEddProgressStep2Employment = 'KycEddProgressStep2Employment',
  KycEddProgressStep3TradingVolume = 'KycEddProgressStep3TradingVolume',
  KycEddProgressStep4TradingFrequency = 'KycEddProgressStep4TradingFrequency',
  KycEddProgressStep5Complete = 'KycEddProgressStep5Complete',

  FiatDepositsDisplayBankingInformations = 'FiatDepositsDisplayBankingInformations',
  FiatDepositsConfirmsTransfer = 'FiatDepositsConfirmsTransfer',

  CardDepositDisplaysConfirmationPage = 'CardDepositDisplaysConfirmationPage',
}
