import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PageLayout, PageTitle } from '../components/layouts/page';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { BaseForm } from '../definitions/base-form';
import { nameof } from '../utils/typescript-helpers';
import { parse } from 'query-string';
import { clearFormErrors } from '../utils/handle-errors';
import { Error } from '../components/common/error';
import {
  ContactUsTopic,
  ERROR_MESSAGES,
  sendApiV1Contact,
  SendContactForm,
  useComputedConfig,
  useRecaptcha,
  useSystemConfig,
} from 'common';
import { TextField } from '../components/forms/text-field';
import { TextAreaField } from '../components/forms/textarea-field';
import { SelectField } from '../components/forms/select-field';
import { DataStore } from '~/store';

interface ContactUsForm extends BaseForm {
  topic: ContactUsTopic;
  firstname: string;
  lastname: string;
  email: string;
  message: string;
}

interface ContactUsParsedInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface ContactUsParsedQuery extends ContactUsParsedInfo {
  topic?: ContactUsTopic;
  message?: string;
  redirectUrl?: string;
  info?: string;
}

const schema = Yup.object().shape({
  [nameof<ContactUsForm>('topic')]: Yup.string().required(
    ERROR_MESSAGES.REQUIRED_VALUE
  ),
  [nameof<ContactUsForm>('firstname')]: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED_VALUE)
    .min(2, ERROR_MESSAGES.MIN_2_CHARACTERS)
    .max(2000, ERROR_MESSAGES.MAX_2000_CHARACTERS),
  [nameof<ContactUsForm>('lastname')]: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED_VALUE)
    .min(2, ERROR_MESSAGES.MIN_2_CHARACTERS)
    .max(2000, ERROR_MESSAGES.MAX_2000_CHARACTERS),
  [nameof<ContactUsForm>('email')]: Yup.string()
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .required(ERROR_MESSAGES.REQUIRED_VALUE)
    .max(2000, ERROR_MESSAGES.MAX_2000_CHARACTERS),
  [nameof<ContactUsForm>('message')]: Yup.string()
    .required(ERROR_MESSAGES.REQUIRED_VALUE)
    .max(2000, ERROR_MESSAGES.MAX_2000_CHARACTERS),
});

const ContactUsAcknowledgement: FC<{
  onReset: () => void;
  redirectUrl?: string;
}> = ({ onReset, redirectUrl }) => {
  /**
   * Hooks
   */
  useEffect(() => {
    if (!redirectUrl) {
      return;
    }
    const interval = setInterval(
      () => (window.location.href = redirectUrl),
      3000 // redirect after 3 secs if redirectUrl provided
    ); //
    return () => clearInterval(interval);
  }, [redirectUrl]);

  /**
   * DOM
   */
  return (
    <>
      <PageTitle icon="check-filled" lead="You should hear from us very soon.">
        That&apos;s sent!
      </PageTitle>

      <div className="flex flex-col items-stretch">
        <button
          role="button"
          type="button"
          className="app-button-outline"
          onClick={onReset}
        >
          Send another enquiry
        </button>
      </div>
    </>
  );
};

export const ContactUs: FC = () => {
  /**
   * Store
   */
  const email = DataStore.useStoreState(s => s.user.decodedToken?.email);
  const lastName = DataStore.useStoreState(s => s.user.decodedToken?.lastName);
  const firstName = DataStore.useStoreState(
    s => s.user.decodedToken?.firstName
  );

  /**
   * Hooks
   */
  const { recaptchaWebappPublicKey } = useSystemConfig();
  const location = useLocation();
  const { apiUrl } = useComputedConfig();
  const [formStatus, setFormStatus] = useState({
    showForm: true,
    error: '',
  });
  const { getRecaptchaToken } = useRecaptcha({
    key: recaptchaWebappPublicKey,
  });

  /**
   * Methods
   */
  const onFormSubmit = async (
    values: ContactUsForm,
    helper: FormikHelpers<ContactUsForm>
  ) => {
    clearFormErrors(helper);

    helper.setSubmitting(true);
    try {
      const body: SendContactForm = {
        name: `${values.firstname} ${values.lastname}`,
        email: values.email,
        content: `Message:\n\n${values.message}`,
        recaptchaToken: await getRecaptchaToken(),
        topic: values.topic,
        company: null,
        country: null,
        website: null,
      };
      const res = await sendApiV1Contact(window.apiTarget || apiUrl, body);

      if (res.isSuccessful) {
        setFormStatus({ showForm: false, error: '' });
      } else {
        setFormStatus({
          showForm: true,
          error: 'Something went wrong. Please try again.',
        });
      }
    } catch (error) {
      setFormStatus({
        showForm: true,
        error: 'Something went wrong. Please try again.',
      });
    }

    helper.setSubmitting(false);
  };

  const getParsedInfo = (
    parsed: ContactUsParsedQuery
  ): ContactUsParsedInfo | null => {
    if (!parsed.info) {
      return null;
    }
    try {
      const decoded = atob(parsed.info);
      const result = JSON.parse(decoded) as ContactUsParsedInfo;
      console.log(result);
      return result;
    } catch (error) {
      console.error(`Failed decoding base64 string`, error);
      return null;
    }
  };

  // DOM
  const topics = Object.values(ContactUsTopic);
  const parsed: ContactUsParsedQuery = parse(location.search);
  const parsedInfo = getParsedInfo(parsed);
  const topic =
    !parsed || !parsed.topic
      ? ContactUsTopic.GENERAL_ENQUIRY
      : topics.find(t => t === parsed.topic) || ContactUsTopic.GENERAL_ENQUIRY;
  const initialValues: ContactUsForm = {
    topic,
    firstname: parsed.firstName || parsedInfo?.firstName || firstName || '',
    lastname: parsed.lastName || parsedInfo?.lastName || lastName || '',
    email: parsed.email || parsedInfo?.email || email || '',
    message: parsed.message || '',
  };

  const getForm = () => {
    return (
      <>
        {/* sep  */}
        <hr className="my-8 bg-gray-400 w-full" />
        <Error message={formStatus.error} cls="mx-10" />

        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onFormSubmit}
          enableReinitialize
        >
          {({ errors, isSubmitting }) => (
            <Form>
              <SelectField
                cls="bg-white"
                label="Topic"
                name="topic"
                values={topics}
              />

              <TextField label="First name" name="firstname" />

              <TextField label="Last name" name="lastname" />

              <TextField label="Email" name="email" type="email" />

              <TextAreaField
                className="app-input"
                label="Message"
                name="message"
                rows={5}
              />

              {/* TODO: formik isSubmitting should be a wired up to busy state  */}
              <button
                type="submit"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                className="app-button-accent my-4"
                style={{ width: '100%' }}
              >
                Send
              </button>
            </Form>
          )}
        </Formik>
      </>
    );
  };

  let content;
  if (formStatus.showForm) {
    content = (
      <>
        <PageTitle
          icon="chat-filled"
          lead="Contact us using the form and we'll get back to you as soon as possible."
        >
          Talk to us
        </PageTitle>

        {/* form  */}
        {getForm()}
      </>
    );
  } else {
    content = (
      <ContactUsAcknowledgement
        onReset={() => setFormStatus({ showForm: true, error: '' })}
        redirectUrl={parsed.redirectUrl}
      />
    );
  }

  return (
    <PageLayout bg="grey-brightest">
      <div
        className={`mx-auto max-w-${formStatus.showForm ? 'xl' : 'sm'} px-5`}
      >
        {content}
      </div>
    </PageLayout>
  );
};
