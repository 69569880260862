import { FC } from 'react';
import { Popover } from '@headlessui/react';
import { format, set } from 'date-fns';
import { DateRangePicker, Range } from 'react-date-range';
import { AppIcon, DATE_FORMATS, useMediaQuery } from 'common';
interface Props {
  dateStart: Date | null | undefined;
  dateEnd: Date | null | undefined;
  onChange?: (selectedDates: Range) => void;
  direction?: 'vertical' | 'horizontal' | null;
}

export const DateFilter: FC<Props> = ({
  dateStart,
  dateEnd,
  onChange,
  direction,
}) => {
  const nowDate = new Date();
  const selectionDate: Range[] = [
    {
      startDate: dateStart || nowDate,
      endDate: dateEnd || nowDate,
      key: 'selection',
    },
  ];

  /**
   * Hooks
   */
  const { XS } = useMediaQuery();

  /**
   * DOM
   */
  const calendarDirection = direction
    ? direction
    : !XS
      ? 'horizontal'
      : 'vertical';
  return (
    <>
      <div className="text-gray-400 flex items-center">
        <p className="flex gap-x-0.5 mb-2">
          <label className="text-gray-400">Date range</label>
          {(dateStart || dateEnd) && (
            <>
              &#40;
              <button
                onClick={() => {
                  if (!onChange) return;
                  onChange({
                    startDate: undefined,
                    endDate: undefined,
                  } as Range);
                }}
                className="text-grey-darker font-semibold text-sm hover:underline"
              >
                reset
              </button>
              &#41;
            </>
          )}
        </p>
      </div>
      <Popover className="relative">
        <Popover.Button className="app-input relative cursor-pointer">
          <div className="flex items-center justify-between">
            <p className="truncate text-left text-base font-bold">
              {dateStart && dateEnd ? (
                <span className="text-primary">
                  {format(new Date(dateStart), DATE_FORMATS.DD_MMM_YYYY)}
                  &nbsp;-&nbsp;
                  {format(new Date(dateEnd), DATE_FORMATS.DD_MMM_YYYY)}
                </span>
              ) : (
                <span className="text-grey">Choose date range</span>
              )}
            </p>
            <AppIcon icon="calendar-caret" bg="transparent" size={18} />
          </div>
        </Popover.Button>
        {/* @ts-ignore */}
        <Popover.Panel
          className="absolute z-20 bg-white rounded-4 overflow-auto shadow-xl border border-black focus:border-black outline-none focus:outline:none h-auto"
          as="div"
        >
          <DateRangePicker
            moveRangeOnFirstSelection={false}
            months={2}
            onChange={item => {
              if (!onChange) return;
              onChange({
                startDate: item.selection.startDate,
                endDate:
                  item.selection.endDate &&
                  set(item.selection.endDate, {
                    hours: 23,
                    minutes: 59,
                    seconds: 59,
                    milliseconds: 999,
                  }),
              });
            }}
            ranges={selectionDate}
            maxDate={nowDate}
            direction={calendarDirection}
          />
        </Popover.Panel>
      </Popover>
    </>
  );
};
