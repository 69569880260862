import { API } from 'api';
import {
  Transaction,
  AllowedWorkflowType,
  Enriched,
  isInternalTransfer,
} from 'common';
import { FC, useMemo, useRef, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { Receipt, ReceiptType } from '~/components/receipt';
import { DataStore } from '~/store';
import { getActivityRecords } from '~/utils/get-activity-records';
import { RecordsFooter } from './footer';
import { TransactionSummarySteps } from '../steps';
import { TransactionPeriodNotice } from '~/components/workflows/shared/transaction-period-notice';

type Props = {
  activity: Enriched.ActivityTransaction | null;
  transaction: Transaction | null;
} & Partial<StepWizardChildProps>;

const getType = (type: API.ActivityType): ReceiptType | null => {
  if (type === API.ActivityType.Trade) {
    // TODO: right side binding
    return 'workflow-buy';
  }
  if (type === API.ActivityType.Withdrawal) {
    return 'workflow-send';
  }
  if (
    type === API.ActivityType.InternalTransfer ||
    type === API.ActivityType.InternalTransferIn ||
    type === API.ActivityType.InternalTransferOut
  ) {
    return 'workflow-transfer';
  }
  if (type === API.ActivityType.Deposit) {
    return 'workflow-receive';
  }
  if (type === API.ActivityType.FiatDeposit) {
    return 'workflow-add-cash';
  }
  if (type === API.ActivityType.FiatWithdrawal) {
    return 'workflow-withdraw-cash';
  }

  if (type === API.ActivityType.YieldPayout) {
    return 'yield-payout';
  }

  if (type === API.ActivityType.EarnedFee) {
    return 'earned-fee';
  }
  if (type === API.ActivityType.Reward) {
    return 'reward';
  }
  if (type === API.ActivityType.ChargedFee) {
    return 'charged-fee';
  }
  if (type === API.ActivityType.InvoicePayment) {
    return 'invoice-payment';
  }
  return null;
};

const getWorkflowNoticeType = (
  type: API.ActivityType
): AllowedWorkflowType | null => {
  if (type === API.ActivityType.Trade) {
    // TODO: right side binding
    return 'workflow-buy';
  }
  if (type === API.ActivityType.Withdrawal) {
    return 'workflow-send';
  }
  if (
    type === API.ActivityType.InternalTransfer ||
    type === API.ActivityType.InternalTransferIn ||
    type === API.ActivityType.InternalTransferOut
  ) {
    return 'workflow-transfer';
  }
  if (type === API.ActivityType.Deposit) {
    return 'workflow-receive';
  }
  if (type === API.ActivityType.FiatWithdrawal) {
    return 'workflow-withdraw-cash';
  }

  return null;
};

export const Records: FC<Props> = ({
  goToNamedStep,
  activity,
  transaction,
}) => {
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Store
   */
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const currencies = DataStore.useStoreState(s => s.metaData.currencies);
  /**
   * Vars
   */
  const ref = useRef<HTMLDivElement | null>(null);

  const sourceListAccount = useMemo(() => {
    if (isInternalTransfer(activity)) {
      return accounts.find(
        a =>
          a.account?.accountId ===
          (transaction as unknown as API.InternalTransfer).sourceAccountId
      );
    }

    return accounts.find(
      a => a.account?.accountNumber === activity?.accountNumber
    );
  }, []);

  const destinationListAccount = useMemo(() => {
    if (isInternalTransfer(activity)) {
      accounts.find(
        a =>
          a.account?.accountId ===
          (transaction as unknown as API.InternalTransfer).destinationAccountId
      );
    }

    return undefined;
  }, []);

  /**
   * State
   */
  const [isPdfExporting, setIsPdfExporting] = useState(false);

  /**
   * DOM
   */
  if (!currencies || !activity) {
    return null;
  }

  const records = getActivityRecords(
    activity,
    transaction,
    currencies,
    sourceListAccount,
    destinationListAccount
  );
  const ccyCodes = [
    activity?.currency1?.code || '',
    activity?.currency2?.code || undefined,
  ];
  const type = getType(activity.type);
  const workflowType = getWorkflowNoticeType(activity.type);

  return (
    <div>
      <div ref={ref} data-testid="transaction-summary">
        <Receipt
          hideCcyInfo={isPdfExporting}
          records={records}
          title={activity.title || activity.type || 'Activity'}
          type={type}
          ccyCodes={ccyCodes}
        />
        <TransactionPeriodNotice
          cls="mt-4 mb-8"
          workflow={workflowType}
          status={activity?.statusFilter}
          accountType={activity?.accountType}
        />
      </div>
      <RecordsFooter
        exportNode={ref}
        activity={activity}
        transaction={transaction}
        onCancel={() =>
          goToNamedStep(TransactionSummarySteps.ConfirmCancellation)
        }
        isPdfProcessing={isPdfExporting}
        setIsPdfProcessing={setIsPdfExporting}
      />
    </div>
  );
};
