import { FC } from 'react';
import { Note } from 'common';
import { IconContent } from '../icon-content';
import { DataStore } from '../../store';
import { API } from 'api';
import { AppIcon } from 'common';

export const AdminLevelAccess: FC = () => {
  /**
   * Store
   */
  const globalAppSettings = DataStore.useStoreState(
    s => s.settings.globalAppSettings
  );
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  /**
   * DOM
   */
  if (clientUserType !== 'admin') {
    return null;
  }
  const isFullAdmin =
    globalAppSettings?.userType === API.UserType.ClientAdminReadWrite;
  const isReadOnly =
    globalAppSettings?.userType === API.UserType.ClientAdminReadOnly;
  return (
    <div>
      {/* heading  */}
      <h5 className="mt-5 font-bold">Admin level</h5>

      {/* content  */}
      {isFullAdmin && (
        <>
          <Note bgColor={'bg-accent-light'} cls="py-4 pl-4 mt-4">
            <IconContent
              icon={
                <AppIcon
                  icon="verification"
                  cls="fill-success"
                  size="sm"
                  bg="bg-transparent"
                />
              }
              content={
                <div className="text-primary font-bold ml-2 text-base">
                  You currently have full admin access.
                </div>
              }
            />
          </Note>
        </>
      )}
      {isReadOnly && (
        <>
          <Note bgColor={'bg-accent-light'} cls="py-4 pl-4 mt-4">
            <IconContent
              icon={
                <AppIcon
                  icon="verification"
                  cls="fill-warning"
                  size="sm"
                  bg="bg-transparent"
                />
              }
              content={
                <div className="text-primary font-bold ml-2 text-base">
                  You currently have read-only admin access.
                </div>
              }
            />
          </Note>
        </>
      )}
    </div>
  );
};
