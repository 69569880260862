import { FC } from 'react';
import cx from 'classnames';

import { API } from 'api';
import { Error } from '../common/error';
import { DataStore } from '../../store';
import { AppIcon } from 'common';

interface Props {
  apiKeyId: string;
  onClose: () => void;
}

export const DeleteApiKey: FC<Props> = ({ apiKeyId, onClose }) => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.error);
  const deleteApiKey = DataStore.useStoreActions(a => a.settings.deleteApiKey);
  const getApiKeys = DataStore.useStoreActions(a => a.settings.getApiKeys);

  /**
   * Methods
   */
  const handleDelete = async () => {
    const body: API.DeleteApiKeyRequest = {
      key: apiKeyId,
    };
    const done = await deleteApiKey(body);
    if (!done) {
      return;
    }
    getApiKeys();
    onClose();
  };

  /**
   * DOM
   */
  return (
    <div>
      <div className="flex flex-col items-center px-10">
        <AppIcon icon="lock-2fa" cls="mt-10 mb-2" size="lg" />
        <h4 className="text-center mb-4">
          Are you sure you want
          <br />
          to delete this API Key?
        </h4>
      </div>

      {/* api errors  */}
      <Error message={error} cls={`mx-10`} />

      {/* actions   */}
      <div className="flex border-t border-grey-bright bg-gray-100 rounded-b py-6 px-10">
        {/* cancel button  */}
        <button
          className="app-button-outline w-full mr-3 flex-1"
          onClick={e => {
            e.preventDefault();
            onClose();
          }}
        >
          Cancel
        </button>
        {/* del button  */}
        <button
          type="button"
          className={cx(
            'flex-1 flex items-center justify-center app-button-accent'
          )}
          onClick={e => {
            e.preventDefault();
            handleDelete();
          }}
        >
          Yes, I&apos;m sure
        </button>
      </div>
    </div>
  );
};
