import { FC, memo } from 'react';
import {
  FilterField,
  dateRanges,
  SelectInput,
  textSelectedItemTemplate,
  textItemTemplate,
} from 'common';
import { FiltersProp } from './filter-navigation';
import { DataStore } from '~/store';

interface Props {
  handleSelection: (key?: FilterField, val?: string) => void;
}

const DateFilterOptions = Object.entries(dateRanges).map(
  ([, value]) => value?.text
);

export const FilterMain: FC<FiltersProp & Props> = memo(
  ({ handleSelection, ...rest }) => {
    const accountNumbers = DataStore.useStoreState(
      s => s.portfolio.accountNumbers
    );
    return (
      <div className="flex flex-col">
        <div className="p-6 border-b">
          <h3 className="font-bold text-base">Filter</h3>
        </div>
        <div className="flex flex-col gap-6 p-6">
          <div className="flex flex-col flex-1">
            <label
              className="pb-1 text-sm text-grey-darker"
              htmlFor={FilterField.filterDateRange}
            >
              Date range
            </label>
            <SelectInput
              name={FilterField.filterDateRange}
              placeHolder="Choose date range"
              values={DateFilterOptions || []}
              value={rest[FilterField.filterDateRange]}
              onChange={value =>
                handleSelection(FilterField.filterDateRange, value)
              }
              getSelectedItemTemplate={textSelectedItemTemplate}
              getItemTemplate={textItemTemplate}
            />
          </div>
          <div className="flex flex-col flex-1">
            <label
              className="pb-1 text-sm text-grey-darker"
              htmlFor={FilterField.filterAccountId}
            >
              Account
            </label>
            <SelectInput
              name={FilterField.filterAccountId}
              placeHolder="Choose account"
              values={accountNumbers}
              value={rest[FilterField.filterAccountId]}
              getSelectedItemTemplate={textSelectedItemTemplate}
              getItemTemplate={textItemTemplate}
              onChange={value => {
                handleSelection(FilterField.filterAccountId, value);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
);
