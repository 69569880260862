import { API_ERROR_CODES, REGEX } from 'common';
import { FC, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppStore, DataStore } from '../../../store';
import { VerifyCode } from '../../verify-code';
import { TransferSteps } from './steps';

export const Code: FC<Partial<StepWizardChildProps>> = ({ goToNamedStep }) => {
  if (!goToNamedStep) {
    return null;
  }
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.transfer.error);
  const transfer = DataStore.useStoreState(s => s.transfer.transfer);
  const confirmCode = DataStore.useStoreActions(a => a.transfer.confirmCode);
  const resendCode = DataStore.useStoreActions(a => a.transfer.resendCode);
  const setIsConfirmedCode = DataStore.useStoreActions(
    a => a.transfer.setIsConfirmedCode
  );
  const setSelectedDialogType = AppStore.useStoreActions(
    a => a.setDashboardSelectedDialogType
  );

  /**
   * Handlers
   */

  const handleOnClose = async () => {
    goToNamedStep(TransferSteps.ConfirmCancellation);
  };

  const handleSubmit = async (code: string) => {
    const { isSuccessful, errorCode } = await confirmCode(code);
    if (!isSuccessful) {
      setIsConfirmedCode(false);
      if (errorCode) {
        if (
          errorCode === API_ERROR_CODES.ACCOUNT_LOCKED_FAILED_CONFIRM_ATTEMPTS
        ) {
          goToNamedStep(TransferSteps.AccountLocked);
        }
        if (errorCode === API_ERROR_CODES.WITHDRAW_TRANSFER_ABORTED) {
          setCancelled(true);
        }
      }
      return;
    }
    setIsConfirmedCode(true);
    goToNamedStep(TransferSteps.Summary);
  };

  const handleOnResendCode = async () => {
    await resendCode();
  };

  const handleOnCancelled = () => {
    setSelectedDialogType(null);
  };

  /**
   * State
   */
  const [cancelled, setCancelled] = useState(false);

  /**
   * DOM
   */
  if (!transfer) {
    return null;
  }
  return (
    <VerifyCode
      acceptedCharacters={REGEX.ALPHANUMERIC}
      titleMessage="Check email for verification number"
      submitButtonText="Transfer"
      error={error}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
      onResend={handleOnResendCode}
      cancelled={cancelled}
      onCancelled={handleOnCancelled}
    />
  );
};
