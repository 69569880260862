import { createContext } from 'react';
import { SegmentTrackType } from '../utils/analytics/events';
import { BaseBrazeApi } from './braze-context';

export interface AnalyticsContextProps {
  braze?: BaseBrazeApi;
  getEnvironmentName: () => string;
  track: (
    type: SegmentTrackType,
    properties?: Record<string, any>,
    callback?: () => void
  ) => void;
  page: (page: string) => void;
  identify: (userId: string, traits?: Record<string, unknown>) => void;
}

export const AnalyticsContext = createContext<
  undefined | AnalyticsContextProps
>(undefined);
