import { useMemo } from 'react';
import { useCopy } from './use-copy';
import { DataStoreType } from '../store';
import { ContentCardsSections, filterContentCards } from '../utils';

export function useContentCards(
  DataStore: DataStoreType,
  key: ContentCardsSections
) {
  /**
   * Store
   **/
  const hasAccountOfTypeCustody = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeCustody
  );
  const hasAccountOfTypeProTrading = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeProTrading
  );
  const hasAccountOfTypeTrading = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeTrading
  );

  /**
   * Hooks
   */
  const { contentCards } = useCopy();
  const upsellCardsFiltered = useMemo(
    () =>
      filterContentCards(contentCards[key].content, {
        hasAccountOfTypeTrading,
        hasAccountOfTypeCustody,
        hasAccountOfTypeProTrading,
      }),
    [
      key,
      contentCards,
      hasAccountOfTypeTrading,
      hasAccountOfTypeCustody,
      hasAccountOfTypeProTrading,
    ]
  );

  return {
    title: contentCards[key]?.title || '',
    content: upsellCardsFiltered,
  };
}
