import React, { FC } from 'react';
import cx from 'classnames';

export interface Props {
  apy?: number;
  size?: 'normal' | 'small';
  prefix?: string;
}

export const EarnApy: FC<Props> = ({ apy = 0, prefix, size = 'normal' }) => {
  /**
   * DOM
   */
  const hasAPY = !!(apy > 0);
  if (!hasAPY) {
    return null;
  }

  const items = [`${apy}%`, `APY`];
  if (prefix) {
    items.unshift(prefix);
  }
  const value = items.join(' ');
  return (
    <span
      className={cx('rounded-full badge-info text-xs flex items-center font-bold', {
        'ml-2 px-4 py-2': size === 'normal',
        'ml-1 px-2 py-1': size === 'small',
      })}
    >
      {value}
    </span>
  );
};
