import { useState } from 'react';
import { SortDirection } from './cell';
import {
  EnrichedCurrencyInformation,
  SORT_ASSETS_FIELDS,
  SORT_DIR,
  orderBy,
} from 'common';
import { SortInfo } from '~/store/types';
import { MarketsColumn } from '~/config';

export const useAppTableSort = <C,>(intialSort?: SortInfo<C>) => {
  /**
   * State
   */
  const [sortInfo, setSortInfo] = useState<SortInfo<C> | null>(
    intialSort || null
  );

  /**
   * DOM
   */
  const columnProps = {
    sort: sortInfo,
    onSort: (value: SortInfo<C>) => setSortInfo(value),
  };

  const sortAndFilterMarketsData = (
    currencies: EnrichedCurrencyInformation[],
    sortInfo: SortInfo<MarketsColumn> | null
  ) => {
    const result = currencies.filter(currency => currency.code);

    if (!sortInfo) {
      return result;
    }

    const sortDir =
      sortInfo?.direction === SortDirection.ASC ? SORT_DIR.ASC : SORT_DIR.DESC;

    if (sortInfo?.column === MarketsColumn.Asset) {
      return orderBy(result, sortDir, SORT_ASSETS_FIELDS.DISPLAY_CODE);
    }

    if (sortInfo?.column === MarketsColumn.Price) {
      return orderBy(result, sortDir, SORT_ASSETS_FIELDS.PRICE);
    }

    if (sortInfo?.column === MarketsColumn.Price24HourPercentageChange) {
      return orderBy(
        result,
        sortDir,
        SORT_ASSETS_FIELDS.PERCENTAGE_CHANGE_1_DAY
      );
    }

    if (sortInfo?.column === MarketsColumn.Price7DaysPercentageChange) {
      return orderBy(
        result,
        sortDir,
        SORT_ASSETS_FIELDS.PERCENTAGE_CHANGE_1_WEEK
      );
    }

    if (sortInfo?.column === MarketsColumn.Price1MonthPercentageChange) {
      return orderBy(
        result,
        sortDir,
        SORT_ASSETS_FIELDS.PERCENTAGE_CHANGE_1_MONTH
      );
    }

    // Default: ORDER BY name ASC
    return orderBy(result, SORT_DIR.ASC);
  };

  /**
   * Return
   */
  return {
    columnProps,
    sortInfo,
    setSortInfo,
    sortAndFilterMarketsData,
  };
};
