import { FC } from 'react';

import { Field, FieldProps } from './field';
import { CharacterInput, CharacterInputProps } from '../character-input';
import { getInputStyle } from 'common';

type Props = FieldProps &
  Omit<CharacterInputProps, 'value' | 'onChange'> & {
    value?: any;
    onChange?: (newValue: any) => void;
    disabled?: boolean;
    hasError?: boolean;
  };

export const CharacterInputField: FC<Props> = ({
  label,
  name,
  onChange: handleChange,
  hasError,
  ...rest
}) => {
  return (
    <Field name={name} label={label}>
      {(field, meta, helpers) => {
        const errored = Boolean(meta.error && meta.touched) || !!hasError;
        const cls = getInputStyle(errored);
        return (
          <CharacterInput
            cls={cls}
            {...field}
            onChange={(newValue: any) => {
              if (handleChange) {
                handleChange(newValue);
              }
              helpers.setValue(newValue);
              helpers.setTouched(true);
            }}
            {...rest}
          />
        );
      }}
    </Field>
  );
};
