import React, { FC, useEffect, useState } from 'react'
import { Unrestricted500x } from '~/components/404/unrestricted-500x'
import { AppRootHelmet } from '~/app/helmet'
import { AppTenant, getThemeForTenant, useComputedConfig } from 'common'

export const UnrestrictedMaintenance: FC = () => {
	const [ready, setReady] = useState(false)
	const { tenant } = useComputedConfig()
	const themeName = getThemeForTenant(tenant as AppTenant)

	useEffect(() => {
		if (!tenant) {
			return
		}
		const themeName = getThemeForTenant(tenant as AppTenant)
		import(`common/dist/theme/${themeName}/index.scss`)
			.then(() => {
				setReady(true)
			})
			.catch(error => {
				console.error(`Could not load theme for tenant - ${tenant}: ${error}`)
			})
	}, [tenant])

	return (
		ready ? (
			<>
				<AppRootHelmet tenant={tenant} themeName={themeName} />
				<Unrestricted500x />
			</>
		) : null
	)
}