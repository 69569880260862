import { API } from 'api';
import { TradeFormValues } from './types';

export const getBuySellSidePreference = (values: TradeFormValues) => {
  if (values.side === API.Side.Buy) {
    if (values.amountSide === 'fromAssetAmount') {
      return API.TradeSide.To;
    }
    return API.TradeSide.From;
  }

  // Sell
  if (values.amountSide === 'fromAssetAmount') {
    return API.TradeSide.From;
  }
  return API.TradeSide.To;
};
