import { FC, useState } from 'react';
import QRCode from 'qrcode.react';
import { CopyableText } from '../../../copyable-text';
import { IconContent } from '../../../icon-content';
import { ReceiveMethod } from 'common';
import { DataStore } from '../../../../store';
import { NewAddressInfoCard } from '../new-address-info-card';
import { ReceiveProps } from '..';
import { AppLoader } from '~/components/app-loader';
import { Error } from '~/components/common/error';

export const DetailsCrypto: FC<Partial<ReceiveProps>> = ({
  chooseCurrency,
  chooseNetwork,
}) => {
  /**
   * Store
   */
  const details = DataStore.useStoreState(s => s.receive.details);
  const formValues = DataStore.useStoreState(s => s.receive.formValues);
  const tag = DataStore.useStoreState(s => s.settings.tag);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const error = DataStore.useStoreState(s => s.receive.error);

  /**
   * State
   */
  const [showAddressInfoContent, setShowAddressInfoContent] = useState(
    !!accountDetail?.settings?.showFireblocksAlert
  );
  const handleShowAddressInfoClick = () => setShowAddressInfoContent(false);

  /**
   * DOM
   */
  return (
    <div className="flex flex-col text-primary mx-10">
      {/* summary  */}
      <div className="flex flex-col text-primary mb-4">
        {chooseCurrency}

        {chooseNetwork}
        {error && <Error message={error} />}
      </div>

      {/* address details  */}
      {!error && (
        <div className="border bg-gray-100 text-primary py-6 rounded-4 min-h-200 relative">
          {/* new address info content */}
          {showAddressInfoContent ? (
            <NewAddressInfoCard onClick={handleShowAddressInfoClick} />
          ) : details?.depositAddress ? (
            <div className="flex flex-col items-center">
              <QRCode
                value={details.depositAddress}
                size={164}
                className="border-white border-8"
              />
              <p className="my-0 mt-4 mb-1.5 font-bold text-grey-darker">
                {formValues?.receiveMethod === ReceiveMethod.crypto && (
                  <>Wallet address</>
                )}
                {formValues?.receiveMethod === ReceiveMethod.transfer && (
                  <>Your stabletag username</>
                )}
              </p>
              <CopyableText
                text={
                  formValues?.receiveMethod === ReceiveMethod.crypto
                    ? details.depositAddress
                    : tag
                }
                beforeCopyText="Copy"
                btnCls="px-3.5 py-4"
                cls="flex flex-col items-center"
              />
            </div>
          ) : (
            <AppLoader bgColor={'bg-transparent'} spinnerTop="50%" />
          )}
        </div>
      )}
      {!showAddressInfoContent && (
        <div className="text-primary p-2 rounded-4 my-2">
          <IconContent
            iconColor="black"
            content={
              <>
                <b className="text-primary">Important</b>
                <br />
                Only send {formValues?.toAsset?.displayCode} on the{' '}
                {formValues?.toAsset?.network} network to this
                address. XBTO is not responsible for any losses incurred from
                deposits made to the wrong address or network.
              </>
            }
          />
        </div>
      )}
    </div>
  );
};
