import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '../../../store';
import { TransferSteps } from './steps';
import { CryptoReceipt } from '../shared/crypto-receipt';
import { FormFooter } from '../shared/form-footer';
import { createTransfer, getCryptoReceipt } from './helpers';
import { Auth0 } from '~/utils/auth0';
import { FormHeader, useCurrencyInfoContext } from 'common';

export const Preview: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  /**
   * Store
   */
  const is2FaEnabled = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.is2FaEnabled
  );
  const currencies = DataStore.useStoreState(s => s.metaData.currencies);
  const transfer = DataStore.useStoreState(s => s.transfer.transfer);
  const error = DataStore.useStoreState(s => s.transfer.error);
  const sourceAccountDetail = DataStore.useStoreState(
    s => s.transfer.sourceAccountDetail
  );
  const destinationAccountDetail = DataStore.useStoreState(
    s => s.transfer.destinationAccountDetail
  );

  const create = DataStore.useStoreActions(a => a.transfer.create);

  /**
   * Variables
   */
  const selectedCurrency = currencies.find(
    c => c.code === transfer?.currencyCode
  );
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Methods
   */
  const onCancelPreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    goToNamedStep(TransferSteps.Form);
  };
  const onNextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isAuth0 = await Auth0.isAuthenticated();
    const shouldMfa = is2FaEnabled || isAuth0;
    if (shouldMfa) {
      if (isAuth0) {
        const token = await Auth0.triggerMfa();
        if (!token) {
          return;
        }
      } else {
        goToNamedStep(TransferSteps.Validate2FA);
        return;
      }
    }

    createTransfer(create, null, goToNamedStep);
  };

  /**
   * DOM
   */
  const receipt = getCryptoReceipt(
    selectedCurrency,
    sourceAccountDetail,
    transfer,
    destinationAccountDetail
  );
  const currency = useCurrencyInfoContext(
    transfer?.currencyCode || receipt.currencyCode
  );
  return (
    <div className="flex flex-col">
      {/* header  */}
      <FormHeader
        onBack={onCancelPreview}
        title={`Transfer ${currency?.displayCode?.toLocaleUpperCase() || ''}`}
      />

      {/* content  */}
      <div className="px-10">
        <CryptoReceipt {...receipt} description={transfer?.description} />
      </div>

      {/* actions  */}
      <FormFooter
        error={error}
        onSubmit={onNextStep}
        onCancel={onCancelPreview}
        submitCls="app-button-accent"
        submitText="Confirm"
      />
    </div>
  );
};
