import { API } from 'api';
import { Enriched } from '../../types';
import { formatters } from '../formatters';
import numberParse from 'multi-number-parse';

export const enrichFiatWithdrawal = (
  withdrawal: API.FiatWithdrawal | API.FiatWithdrawalWithTimeline | null,
  fiatCurrencyCodes: string[]
): Enriched.FiatWithdrawal | null => {
  if (!withdrawal || !withdrawal.amount || !withdrawal.currency?.code) {
    return null;
  }

  const hasTimeline =
    (withdrawal as API.FiatWithdrawalWithTimeline).timeline || undefined;

  const feeWithCurrencyCode = formatters.getCurrency(
    withdrawal?.fee,
    withdrawal.currency.decimals,
    withdrawal.currency.displayCode ?? withdrawal.currency.code,
    fiatCurrencyCodes
  );
  const amountReceivedWithCurrencyCode = formatters.getCurrency(
    withdrawal?.amount,
    withdrawal.currency.decimals,
    withdrawal.currency.displayCode ?? withdrawal.currency.code,
    fiatCurrencyCodes
  );
  const amountSent = `${
    numberParse(withdrawal.amount) || 0 + numberParse(withdrawal.fee || 0)
  }`;
  const amountSentWithCurrencyCode = formatters.getCurrency(
    amountSent,
    withdrawal.currency.decimals,
    withdrawal.currency.displayCode ?? withdrawal.currency.code,
    fiatCurrencyCodes
  );

  const fee = numberParse(withdrawal.fee ?? '0');

  return {
    ...withdrawal,
    timeline: hasTimeline
      ? (withdrawal as API.FiatWithdrawalWithTimeline).timeline
      : null,
    formatted: {
      fee: formatters.getAmount(withdrawal.fee, withdrawal.currency.decimals),
      feeWithCurrencyCode,
      feeWithCurrencyCodeAndSign:
        fee === 0
          ? feeWithCurrencyCode
          : formatters.forceSign(feeWithCurrencyCode, '-'),

      amountReceived: formatters.getAmount(
        withdrawal.amount,
        withdrawal.currency.decimals
      ),
      amountReceivedWithCurrencyCode,
      amountReceivedWithCurrencyCodeAndSign: formatters.forceSign(
        amountReceivedWithCurrencyCode,
        '+'
      ),

      amountSent,
      amountSentWithCurrencyCode,
      amountSentWithCurrencyCodeAndSign: formatters.forceSign(
        amountSentWithCurrencyCode,
        '-'
      ),
    },
  };
};
