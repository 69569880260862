import * as React from 'react';
import { Switch } from '@headlessui/react';
import cx from 'classnames';

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const $ = {
  container: { height: 27, width: 67 },
};

export const Toggle: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <Switch
      checked={checked}
      onChange={() => onChange(!checked)}
      className={cx(
        'flex flex-row items-center justify-between rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        {
          'bg-green-700 pl-2 pr-1 flex-row-reverse': checked,
          'bg-gray-400 pl-1 pr-2': !checked,
        }
      )}
      style={$.container}
    >
      <div className="w-5 h-5 bg-white rounded-full" />
      <div className="text-sm text-white font-bold">
        {checked ? 'ON' : 'OFF'}
      </div>
    </Switch>
  );
};
