import { FC, useEffect, useMemo, useRef } from 'react';
import StepWizard from 'react-step-wizard';
import { Form } from './form';
import { Summary } from './summary';
import { defaultStepWizardProps } from '../../../constants';
import { Preview } from './preview';
import { Code } from './code';
import { ConfirmCancelSend } from './confirm-cancel';
import { Cancelled } from './cancelled';
import { AppStore, DataStore } from '../../../store';
import { SendSteps } from './steps';
import { AccountLocked } from '../shared/account-locked';
import { AddressBook } from './address-book';
import { PreviewTwoFaWidget } from './preview-two-fa-widget';
import {
  SendDestinationType,
  SendFormikProps,
  TIMERS,
  useAppDialogHelper,
  validateSendFormik,
} from 'common';
import { AddCryptoAddress } from './add-crypto-address';
import { Formik } from 'formik';
import { apiClient } from '~/api/client';
import { shallowEqualObjects } from 'shallow-equal';
import debounce from 'lodash.debounce';

export const WorkflowSend: FC = () => {
  /**
   * Vars
   */
  const ref = useRef<HTMLDivElement | null>(null);

  /**
   * Store
   */
  const setError = DataStore.useStoreActions(a => a.send.setError);
  const resetState = DataStore.useStoreActions(a => a.send.resetState);
  const setDashboardSelectedAsset = AppStore.useStoreActions(
    a => a.setDashboardSelectedAsset
  );
  const setSelectedWithdrawalAddress = AppStore.useStoreActions(
    s => s.setSelectedWithdrawalAddress
  );
  const formValues = DataStore.useStoreState(s => s.send.formValues);
  const dashboardSelectedAsset = AppStore.useStoreState(
    s => s.dashboardSelectedAsset
  );
  const verifiedWithdrawalAddresses = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.verifiedWithdrawalAddresses
  );
  const selectedWithdrawalAddress = AppStore.useStoreState(
    s => s.selectedWithdrawalAddress
  );
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const simulate = DataStore.useStoreActions(a => a.send.simulate);
  const setFormValues = DataStore.useStoreActions(a => a.send.setFormValues);

  /**
   * Hooks
   */
  const appDialogHelper = useAppDialogHelper();
  useEffect(() => {
    appDialogHelper.init(ref.current);

    return () => {
      console.log(`Send funds dialog => reset`);
      setDashboardSelectedAsset(null);
      resetState();
      setSelectedWithdrawalAddress(null);
    };
  }, []);

  /**
   * DOM
   */
  const initAddressesList = (code?: string) => {
    return verifiedWithdrawalAddresses.filter(
      ({ currencyCode, isVerifying }) => currencyCode === code && !isVerifying
    );
  };
  const handleSendDestinationType = (code: string | undefined) => {
    return initAddressesList(code).length > 0
      ? SendDestinationType.book
      : SendDestinationType.crypto;
  };

  const fromAsset = formValues?.fromAsset || dashboardSelectedAsset || null;

  const networkName =
    formValues?.network || fromAsset?.currency.network || null;
  const initialDestinationType = formValues?.destination
    ? formValues?.destinationType
    : handleSendDestinationType(fromAsset?.currency.code);
  const initialValues: SendFormikProps = {
    destinationType: initialDestinationType,
    fromAsset: fromAsset,
    network: networkName,
    destination: selectedWithdrawalAddress
      ? selectedWithdrawalAddress?.address
      : formValues?.destination || '',
    amount: formValues?.amount || null,
    whitelistAddress:
      selectedWithdrawalAddress || formValues?.whitelistAddress || null,
    description: formValues?.description || '',
  };

  /**
   * Methods
   */
  const debouncedSimulate = useMemo(
    () => debounce(simulate, TIMERS.SIMULATE_DEBOUNCE),
    []
  );
  const onFormSubmit = async values => {
    setError(null);
    setFormValues(values);
  };
  const onFormValidate = (values: SendFormikProps) => {
    setError(null);
    const useWhitelistedAddress =
      !accountDetail?.canWithdrawToNonWhitelistedAddress;
    return validateSendFormik(
      values,
      values.fromAsset?.currency?.blockchain || '',
      apiClient,
      accountDetail?.account?.accountId || '',
      useWhitelistedAddress
    ).then(async (errors = {}) => {
      if (shallowEqualObjects(formValues, values)) {
        return errors;
      }
      const hasErrors = Object.keys(errors).length > 0;

      if (!hasErrors) {
        const noDebounce = !!(
          values?.fromAsset?.currency.isAssetOfTypeFiat ||
          values.destinationType === SendDestinationType.transfer
        );
        if (noDebounce) {
          await simulate(values);
        } else {
          await debouncedSimulate(values);
        }
      }
      return errors;
    });
  };

  return (
    <Formik<SendFormikProps>
      initialValues={initialValues}
      validate={onFormValidate}
      validateOnBlur={false}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={onFormSubmit}
    >
      {props => {
        return (
          <div className="relative text-primary h-full" ref={ref}>
            <StepWizard
              {...defaultStepWizardProps}
              className="h-full"
              onStepChange={({ activeStep }) => {
                setError(null);

                const closeBtnHiddenSteps = [4, 5, 6];
                if (!closeBtnHiddenSteps.includes(activeStep)) {
                  appDialogHelper.closeButton.show();
                  return;
                }
                appDialogHelper.closeButton.hide();
              }}
            >
              {/* step: 1 */}
              <Form stepName={SendSteps.Form} {...props} />
              {/* step: 2 */}
              <AddressBook
                stepName={SendSteps.AddressBook}
                setFieldValue={props.setFieldValue}
              />
              {/* step: 3 */}
              <Preview stepName={SendSteps.Preview} />
              {/* step: 4 */}
              <PreviewTwoFaWidget stepName={SendSteps.PreviewTwoFaWidget} />
              {/* step: 5 */}
              <Code stepName={SendSteps.Code} />
              {/* step: 6 */}
              <ConfirmCancelSend stepName={SendSteps.ConfirmCancellation} />
              {/* step: 7 */}
              <Cancelled stepName={SendSteps.Cancelled} />
              {/* step: 8 */}
              <Summary stepName={SendSteps.Summary} />
              {/* step: 9 */}
              <AccountLocked stepName={SendSteps.AccountLocked} />
              {/* step: 10 */}
              <AddCryptoAddress stepName={SendSteps.AddCryptoAddress} />
            </StepWizard>
          </div>
        );
      }}
    </Formik>
  );
};
