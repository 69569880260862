import { useCallback, useEffect, useMemo, useState } from 'react';

import { API } from 'api';
import { DataStoreType } from '../store';
import { TIMERS } from '../constants';

export function useBanners(DataStore: DataStoreType) {
  const banners = DataStore.useStoreState(s => s.alerts.banners);
  const [bannerIndex, setBannerIndex] = useState(0);

  const dismissMessage = DataStore.useStoreActions(
    a => a.alerts.dismissMessage
  );

  const handleDismiss = useCallback(
    async (userMessageId: string, arg?: () => void) => {
      const result = await dismissMessage({ userMessageId });
      if (result.isSuccessful) {
        arg?.();
      }
    },
    [dismissMessage]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBannerIndex(i => (i >= banners.length - 1 ? 0 : i + 1));
    }, TIMERS.BANNER_REFRESH);
    return () => {
      clearInterval(intervalId);
    };
  }, [banners.length]);

  return useMemo(() => {
    const banner = banners.at(bannerIndex) ?? null;
    const alertLevel = banner?.alertLevel;

    const isLightBg =
      alertLevel &&
      [
        API.MessageTemplateAlertLevel.Warning,
        API.MessageTemplateAlertLevel.Brand
      ].includes(alertLevel);

    return {
      banner,
      isLightBg,
      handleDismiss,
      hasMultiple: !!banners.length,
    };
  }, [bannerIndex, banners, dismissMessage]);
}
