import appboy from '@braze/web-sdk';
import { BaseBrazeApi, useSystemConfig } from 'common';
import { useEffect, useState } from 'react';

declare global {
  interface Window {
    appboy?: typeof appboy;
  }
}

export class BrazeApi implements BaseBrazeApi {
  private _api?: typeof appboy;
  constructor(api?: typeof appboy) {
    this._api = api;
  }

  _dismissedCards: string[] = [];

  get appboy() {
    return this._api;
  }

  get appboyLoadedSuccessfuly() {
    return !!(this._api && this._api.getCachedContentCards);
  }

  get appboyFailedtoLoad() {
    return !this._api || !this._api.getCachedContentCards;
  }

  logCustomEvent(eventName: string, eventData: any) {
    if (this.appboyLoadedSuccessfuly && this._api) {
      this._api.logCustomEvent(eventName, eventData);
    }
  }

  changeUser(userId: string, traits?: Record<string, unknown>) {
    if (this.appboyLoadedSuccessfuly && this._api) {
      return this._api.changeUser(userId, traits as any);
    }
  }
  getUser() {
    if (this.appboyLoadedSuccessfuly && this._api) {
      return this._api.getUser();
    }
  }

  getContentCards = () => {
    if (!this.appboyLoadedSuccessfuly) {
      return [];
    }

    const cachedContentCards = this._api!.getCachedContentCards();
    return (
      (cachedContentCards && cachedContentCards.cards).filter(
        i => this._dismissedCards.indexOf(i.id!) === -1
      ) || []
    );
  };

  getMainContentCard = () => {
    const contentCards = this.getContentCards();
    const pinnedCard = contentCards.find(i => i.pinned);
    return pinnedCard || contentCards[0];
  };

  dismissContentCard = (card: appboy.Card) => {
    this._dismissedCards.push(card.id!);

    if (this.appboyLoadedSuccessfuly) {
      this._api!.logCardDismissal(card);
      this._api!.requestContentCardsRefresh();
    }
  };

  getCardType = (card: appboy.Card) => {
    const type = (card as any).qc;
    switch (type) {
      case 'ab-captioned-image':
        return CardTypes.CaptionedImage;
      case 'ab-classic-card':
        return CardTypes.ClassicCard;
      case 'ab-banner':
        return CardTypes.Banner;
      default:
        return CardTypes.CaptionedImage;
    }
  };
}

export enum CardTypes {
  CaptionedImage = 'CaptionedImage',
  ClassicCard = 'ClassicCard',
  Banner = 'Banner',
}

export const useBraze = () => {
  const [braze, setBraze] = useState<undefined | BrazeApi>(undefined);
  const { brazeEndpoint, brazeKey } = useSystemConfig();

  /**
   * Hooks
   */

  useEffect(() => {
    if (brazeKey && brazeEndpoint) {
      // initialize the SDK
      appboy.initialize(brazeKey, {
        baseUrl: brazeEndpoint,
      });

      setBraze(new BrazeApi(appboy));

      // optionally show all In-App Messages without custom handling
      appboy.display.automaticallyShowNewInAppMessages();

      // start (or continue) a session
      appboy.openSession();
    } else {
      console.log("Either BRAZE_KEY or BRAZE_ENDPOINT weren't set.");
      setBraze(new BrazeApi());
    }
  }, []);
  return braze;
};
