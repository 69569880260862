import { AppTenant } from 'common';

interface InitialConfig {
  apiTarget: string | null;
  tenant: AppTenant;
}

export async function getInitialConfig(): Promise<InitialConfig> {
  switch (document.location.host) {
    case 'app.stablehouse.io':
      return {
        apiTarget: 'https://api.stablehouse.io',
        tenant: 'Stablehouse',
      };
    case 'app.stablehouse.com':
      return {
        apiTarget: 'https://api.stablehouse.io',
        tenant: 'Stablehouse',
      };
    case 'theapexchange.com':
      return {
        apiTarget: 'https://api.stablehouse.com',
        tenant: 'Apex',
      };
    case 'rj.stablehouse.com':
      return {
        apiTarget: 'https://api.stablehouse.com',
        tenant: 'Rj',
      };
  }

  // temporary support for docker mode
  if (window.apiTarget && window.envs && window.envs.REACT_APP_TENANT) {
    return {
      apiTarget: window.apiTarget,
      tenant: window.envs.REACT_APP_TENANT,
    };
  }

  const data = await fetch('/').then(i => {
    return {
      apiTarget:
        i.headers.get('x-api-target') ||
        i.headers.get('x-amz-meta-x-api-target'),
      tenant: (i.headers.get('x-tenant') ||
        i.headers.get('x-amz-meta-x-tenant') ||
        'Stablehouse') as AppTenant,
    };
  });

  return data;
}
