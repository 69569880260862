import { EnrichedCurrencyInformation } from '../types';

export const getCurrencyInfoForCode = (
  code: string | null | undefined,
  currencies: EnrichedCurrencyInformation[] | null
): EnrichedCurrencyInformation | undefined => {
  if (!currencies || !code) {
    return undefined;
  }
  const ccy = currencies.find(ccy => {
    return ccy.code === code;
  });
  if (!ccy) {
    return undefined;
  }
  return ccy;
};
