import { FC } from 'react';
import cx from 'classnames';
import useDigitInput from 'react-digit-input';
import { REGEX, useMediaQuery } from 'common';

export interface CharacterInputProps {
  value: string;
  length: number;
  onChange: (value: string) => void;
  acceptedCharacters?: RegExp;
  name?: string;
  cls?: string;
}

export const CharacterInput: FC<CharacterInputProps> = ({
  value,
  length,
  onChange,
  acceptedCharacters = REGEX.ONLY_DIGITS,
  name = '',
  cls = '',
}) => {
  /**
   * State
   */
  const digits = useDigitInput({
    acceptedCharacters,
    length,
    value,
    onChange,
  });

  /**
   * Hooks
   */
  const { XS } = useMediaQuery();

  /**
   * DOM
   */
  return (
    <div className="flex flex-row items-center justify-center gap-1 sm:gap-2">
      {digits.map((digit, index) => {
        const overrideStyle = XS
          ? {
              paddingLeft: 4,
              paddingRight: 4,
            }
          : undefined;
        return (
          <input
            name={name}
            key={`digit-${index}`}
            className={cx(
              'app-input-character uppercase placeholder-gray-300',
              {
                'ml-1.5': index !== 0,
                'px-1': XS,
              },
              cls
            )}
            style={overrideStyle}
            inputMode="decimal"
            placeholder="0"
            {...digit}
            autoFocus={index === 0}
          />
        );
      })}
    </div>
  );
};
