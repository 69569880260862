import { FC, useMemo } from 'react';
import { useMediaQuery } from 'common';

interface Props {
  list: string[];
  displayNumber?: number;
}

export const FilterTags: FC<Props> = ({ list, displayNumber = 3 }) => {
  /**Sort by length to better calculate tag odering */
  const sortedList = useMemo(
    () => list?.sort((a, b) => a.length - b.length),
    [list]
  );
  const { XS } = useMediaQuery();

  /**
   * DOM
   */

  const handleList = useMemo(() => {
    const maxDisplayValue = XS ? displayNumber : displayNumber + 2;
    const displayButtons = sortedList.slice(0, maxDisplayValue);
    const hiddenButtons = sortedList.slice(maxDisplayValue, sortedList?.length);

    return {
      displayButtons,
      hiddenButtons,
    };
  }, [list, displayNumber, XS]);

  return (
    <span className="flex text-sm font-bold gap-1 overflow-hidden">
      {handleList?.displayButtons?.map((asset: string) => {
        const isMultiText = asset?.indexOf(' ') >= 0;
        const displayText = isMultiText ? asset?.split(' ') : asset;
        return (
          <span
            key={asset}
            className="bg-grey-brighter py-1 px-2 rounded text-primary mt-0.5"
          >
            {isMultiText ? (
              <span className="flex flex-row gap-x-1">
                <span>{displayText[0]}</span>
                <span>{displayText[1]}</span>
              </span>
            ) : (
              displayText
            )}
          </span>
        );
      })}
      {!!handleList?.hiddenButtons?.length && (
        <span className="py-1.5 px-1 items-center text-xs mt-0.5">
          &#43;{handleList?.hiddenButtons?.length}
        </span>
      )}
    </span>
  );
};
