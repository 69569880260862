import { Enriched, EnrichedCurrencyInformation } from 'common';
import { FC } from 'react';
import { ConversionRate } from '~/components/conversion-rate';

type Props = {
  trade: Enriched.Trade;
  currencies: EnrichedCurrencyInformation[];
};

export const ActivityRate: FC<Props> = ({ trade, currencies }) => {
  return (
    <ConversionRate
      interactive={false}
      fxRate={trade.fxDetails?.rate}
      fxRateContra={trade.fxDetails?.rateContra}
      fxSymbol={trade.fxDetails?.baseCurrency}
      currencies={currencies}
      currencyFrom={trade.currencyFrom?.code}
      currencyTo={trade.currencyTo?.code}
    />
  );
};
