import { FC, useEffect, useRef } from 'react';
import cx from 'classnames';
import { AppIcon, BaseComponentProps, useComputedConfig } from 'common';
import { AppLogo } from '~/components/app-logo';

interface Props extends BaseComponentProps {
  title?: string;
  onScrollEl?: (el: HTMLDivElement) => void;
  withLogoTitle?: boolean;
  containerCls?: string;
}

export const ExternalSplashLayout: FC<Props> = ({
  title,
  children,
  onScrollEl,
  withLogoTitle = true,
  containerCls,
}) => {
  /**
   * Vars
   */
  const scrollRef = useRef<HTMLDivElement>(null);
  const { tenant } = useComputedConfig();

  /**
   * Hooks
   */
  useEffect(() => {
    if (scrollRef.current && onScrollEl) {
      onScrollEl(scrollRef.current);
    }
  }, [scrollRef.current]);

  /**
   * DOM
   */
  return (
    <div className="w-screen h-screen overflow-hidden bg-primary flex flex-col align-center justify-center text-primary">
      <div className="my-auto flex flex-col justify-center items-center gap-8">
        <AppLogo
          variant="light"
          logoType="header"
          fill="none"
          withTitle={withLogoTitle}
        />
        <div
          className={cx(
            'relative w-full max-w-400 flex flex-col self-center bg-white p-9 rounded-lg',
            {
              'border shadow-sm': tenant === 'Apex', //this could be better
            },
            containerCls
          )}
        >
          {title && (
            <p className="text-center font-bold text-xl text-primary">
              {title}
            </p>
          )}
          <>{children}</>
        </div>
        <AppIcon
          size={110}
          height={37}
          bg="bg-transparent"
          icon="logo-powered-by-stablehouse-stacked"
        />
      </div>
    </div>
  );
};
