import { Outlet } from 'react-router';
import { RequireAuth } from '~/components/auth/require-auth';

export const Root = () => {
  /**
   * DOM
   */
  return (
    <RequireAuth>
      <Outlet />
    </RequireAuth>
  );
};
