import { ThunkCreator } from 'easy-peasy';
import {
  API_ERROR_CODES,
  CreateWithdrawCashPayload,
  WithdrawCashFormikProps,
} from 'common';
import { WithdrawCashSteps } from './steps';

export const createFiatWithdrawalHelper = async (
  formValues: WithdrawCashFormikProps | null,
  create: ThunkCreator<CreateWithdrawCashPayload>,
  _googleAuthenticatorCode: string | null, // depreacated (Auth0)
  goToNamedStep: (step: string) => void
) => {
  if (!formValues) {
    return;
  }

  const { isSuccessful, errorCode } = await create(formValues);

  if (!isSuccessful) {
    if (errorCode && errorCode === API_ERROR_CODES.USER_ACOUNT_LOCKED) {
      goToNamedStep(WithdrawCashSteps.AccountLocked);
    }
    return;
  }
  goToNamedStep(WithdrawCashSteps.Summary);
};
