import { FC } from 'react';

import { useComputedConfig } from 'common';
import { StablehouseFooter } from '~/components/app-footer/stablehouse-footer';
import { ApexFooter } from '~/components/app-footer/apex-footer';

export const AppFooter: FC = () => {
  const { tenant } = useComputedConfig();
  if (tenant === 'Apex') return <ApexFooter />;

  return <StablehouseFooter />;
};
