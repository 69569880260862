import { API } from 'api';
import {
  QuorumOperationModalKind,
  AppIcon,
  AppDialog,
  useAppDialog,
} from 'common';
import { FC, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApprovalConfirmationModal } from '../../../components/approval-confirmation-modal';
import { AddCrytpoAddress } from '../../../components/crypto-addresses/add';
import { ShowCryptoAddress } from '../../../components/crypto-addresses/show';
import { SummaryApproval } from '../../../components/dialogs/summary-approval';
import { FundAddressesList } from '../../../components/fund-addresses-list';
import { APP_ROUTES } from '../../../routes';
import { DataStore } from '../../../store';
import { AppLoader } from '~/components/app-loader';

export const AdminFundAddresses: FC = () => {
  /**
   * Store
   */
  const busy = DataStore.useStoreState(s => s.busy);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const decodedTokenAccountId = DataStore.useStoreState(
    s => s.user.decodedTokenAccountId
  );
  const withdrawalAddresses = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.withdrawalAddresses
  );
  const globalAppSettings = DataStore.useStoreState(
    s => s.settings.globalAppSettings
  );
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  const { getWithdrawalAddresses, deleteWithdrawalAddressOperation } =
    DataStore.useStoreActions(_ => ({
      getWithdrawalAddresses: _.settings.cryptoAddresses.getWithdrawalAddresses,
      deleteWithdrawalAddressOperation:
        _.settings.cryptoAddresses.deleteWithdrawalAddressOperation,
    }));

  /**
   *  State
   */
  const [showAddAccountDialog, setShowAddAccountDialog] =
    useState<boolean>(false);
  const [
    isConfirmationApprovalDialogOpen,
    setIsConfirmationApprovalDialogOpen,
  ] = useState(false);
  const [toShowAddressId, setToShowAddressId] = useState<string | null>(null);
  const [showAddress, setShowAddress] = useState(false);

  /**
   * Hooks
   */
  const { fundId, fundAccountId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (showAddAccountDialog) {
      return;
    }
    if (!decodedTokenAccountId) {
      return;
    }
    getWithdrawalAddresses({
      accountId: fundAccountId || decodedTokenAccountId,
      impersonatedAccountId: fundId || '',
    });
  }, [fundId, fundAccountId, showAddAccountDialog]);
  const { showDialog, hideDialog } = useAppDialog();

  /**
   * Vars
   */
  const isEmpty = (withdrawalAddresses || []).length === 0;
  const toShowAddress = (withdrawalAddresses || []).find(
    address => address.id === toShowAddressId
  );

  /**
   * Methods
   */
  const closeAddAccountModal = useCallback(
    success => {
      setShowAddAccountDialog(false);
      if (success) {
        setIsConfirmationApprovalDialogOpen(true);
      }
    },
    [setShowAddAccountDialog, setIsConfirmationApprovalDialogOpen]
  );
  const closeAddConfirmationModal = useCallback(() => {
    setIsConfirmationApprovalDialogOpen(false);
    navigate(APP_ROUTES.AUTH_ADMIN + '/pending-approvals');
  }, [setIsConfirmationApprovalDialogOpen]);
  const handleDeleteWithdrawalAddressOperation = useCallback(
    async addressId => {
      if (!addressId) {
        hideDialog();
        return;
      }
      const response = await deleteWithdrawalAddressOperation(addressId);
      if (!response) return;
      showDialog(<SummaryApproval onClose={hideDialog} />);
    },
    [showDialog, hideDialog, deleteWithdrawalAddressOperation]
  );
  const handleDelete = useCallback(() => {
    setShowAddress(false);
    showDialog(
      <ApprovalConfirmationModal
        createWithdrawalAddressData={toShowAddress}
        kind={QuorumOperationModalKind.ConfirmDeleteWithdrawalAddress}
        onClose={handleDeleteWithdrawalAddressOperation}
      />
    );
  }, [
    toShowAddress,
    setShowAddress,
    showDialog,
    handleDeleteWithdrawalAddressOperation,
  ]);

  /**
   * DOM
   */
  if (busy) {
    return <AppLoader isFixed spinnerTop="104px" />;
  }

  return (
    <>
      <AppDialog
        isOpen={showAddAccountDialog}
        onClose={() => {
          setShowAddAccountDialog(false);
        }}
      >
        <AddCrytpoAddress
          onClose={closeAddAccountModal}
          impersonatedAccountId={accountDetail?.account?.accountId}
        />
      </AppDialog>
      {/* view bank account dialog  */}
      <AppDialog isOpen={showAddress} onClose={() => setShowAddress(false)}>
        <ShowCryptoAddress
          address={toShowAddress}
          onClose={() => setShowAddress(false)}
          onDelete={handleDelete}
        />
      </AppDialog>
      {/* confirmation */}
      <AppDialog
        isOpen={isConfirmationApprovalDialogOpen}
        onClose={() => setIsConfirmationApprovalDialogOpen(false)}
      >
        <ApprovalConfirmationModal
          createWithdrawalAddressData={toShowAddress}
          kind={QuorumOperationModalKind.AddWithdrawalAddress}
          onClose={closeAddConfirmationModal}
        />
      </AppDialog>
      <div className="rounded-4 bg-white flex-1">
        <div className="flex flex-row border border-b-0 rounded-t justify-between items-center py-5 px-4 md:px-8">
          <p className="font-bold text-primary">Crypto addresses</p>
          {clientUserType !== 'admin' ||
            (globalAppSettings?.userType ===
              API.UserType.ClientAdminReadWrite && (
              <button
                className="app-button-outline text-xs sm:text-sm"
                onClick={e => {
                  e.preventDefault();
                  setShowAddAccountDialog(true);
                }}
              >
                <span className="hidden sm:inline">Add an address</span>
                <span className="sm:hidden">Add new</span>{' '}
                <AppIcon cls="ml-2" icon="plus" bg="" size={18} />
              </button>
            ))}
        </div>
        <FundAddressesList
          onClickAddress={addressId => {
            setToShowAddressId(addressId);
            setShowAddress(!!addressId);
          }}
          addresses={withdrawalAddresses}
        />
      </div>
    </>
  );
};
