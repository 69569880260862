import { FC, MouseEventHandler, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { API } from 'api';
import { AddBankAccount } from '~/components/bank-accounts/add';
import { VerifyCode } from '~/components/verify-code';
import { WithdrawCashSteps } from './steps';
import { DataStore } from '../../../store';
import { FormHeader, WithdrawCashFormikProps } from 'common';

export const AddBank: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  /**
   * Store
   */
  const formValues = DataStore.useStoreState(s => s.withdrawCash.formValues);
  const setFormValues = DataStore.useStoreActions(
    a => a.withdrawCash.setFormValues
  );
  const verifyBankAccount = DataStore.useStoreActions(
    a => a.settings.verifyBankAccount
  );
  const error = DataStore.useStoreState(s => s.withdrawCash.error);
  const getBankAccounts = DataStore.useStoreActions(
    a => a.settings.getBankAccounts
  );
  const resendBankAccountVerificationCode = DataStore.useStoreActions(
    a => a.settings.resendBankAccountVerificationCode
  );

  /**
   * State
   */
  const [toVerifyBank, setToVerifyBank] = useState<API.BankAccount | null>(
    null
  );

  if (!goToNamedStep) {
    return null;
  }

  /**
   * Methods
   */
  const verifyBankAndSetFormValues = (verified: boolean) => {
    if (verified) {
      const newFormValues: WithdrawCashFormikProps = {
        bankAccount: {
          id: toVerifyBank?.id || '',
          currency: toVerifyBank?.currency || null,
          bankAccountDetails: toVerifyBank?.bankAccountDetails || null,
          status: API.CustomerBankAccountStatus.Ready,
        },
        fromAsset: formValues?.fromAsset || null,
        amount: formValues?.amount || null,
        description: formValues?.description || null,
      };
      setFormValues(newFormValues);
    }
    setToVerifyBank(null);
    handleOnAddBankClose(null);
  };

  const handleOnAddBankClose = (bank: API.BankAccount | null) => {
    if (!bank) {
      goToNamedStep(WithdrawCashSteps.Form);
    } else {
      setToVerifyBank(bank);
    }
  };

  const handleOnSubmit = async (code: string) => {
    if (toVerifyBank) {
      const body: API.VerifyBankAccountRequest = {
        bankAccountId: toVerifyBank.id,
        appAuthenticatorCode: null,
        code,
      };
      const done = await verifyBankAccount(body);
      if (!done) {
        return;
      }
      getBankAccounts();
      verifyBankAndSetFormValues(true);
    }
  };

  const handleOnResend = async () => {
    if (toVerifyBank) {
      const body: API.ResendBankAccountVerificationCodeRequest = {
        bankAccountId: toVerifyBank.id,
      };
      const done = await resendBankAccountVerificationCode(body);
      if (!done) {
        return;
      }
    }
  };

  const handleOnCloseHeader: MouseEventHandler = e => {
    e.preventDefault();
    handleOnAddBankClose(null);
  };

  if (toVerifyBank) {
    return (
      <VerifyCode
        titleMessage="Check email for verification number"
        error={error}
        onClose={verifyBankAndSetFormValues}
        submitButtonText={`Confirm and continue`}
        onSubmit={handleOnSubmit}
        onResend={handleOnResend}
      />
    );
  }

  /**
   * DOM
   */
  return (
    <AddBankAccount
      header={
        <FormHeader title="Add bank details" onBack={handleOnCloseHeader} />
      }
      saveButtonCls="app-button-primary"
      saveButtonText="Save and continue"
      onClose={handleOnAddBankClose}
    />
  );
};
