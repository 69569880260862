import { FC } from 'react';
import cx from 'classnames';
import { API } from 'api';
import { getApiErrorMessage, Note } from 'common';

interface Props {
  cls?: string;
  testid?: string;
  message: Error | string | null | API.ApiResponse | unknown;
}

export const Error: FC<Props> = ({ cls, testid, message }) => {
  if (!message) {
    return null;
  }
  const msg = getApiErrorMessage(message);
  return (
    <Note cls={cx('my-2', cls)} data-testid={testid}>
      <p className="text-xs text-failure ml-1 font-bold">{msg}</p>
    </Note>
  );
};
