import { Field } from 'formik';
import { useToggle } from 'react-use';

export const useTogglePassword = () => {
  /**
   * State
   */
  const [showPassword, toggleShowPassword] = useToggle(false);

  /**
   * Vars
   */
  const passwordType = showPassword ? 'text' : 'password';

  return [showPassword, toggleShowPassword, passwordType] as const;
};

export const usePasswordLabel = (
  label: string,
  showPassword: boolean,
  toggleShowPassword: (nextValue?: any) => void
) => {
  return (
    <div className="flex flex-wrap justify-between">
      <span>{label}</span>
      <span>
        <label>
          <span className="text-gray-400 typo-b5 font-bold mr-2">
            Show password
          </span>
          <Field
            className="app-input-checkbox"
            type="checkbox"
            name="showPassword"
            checked={showPassword}
            onChange={toggleShowPassword}
          />
        </label>
      </span>
    </div>
  );
};
