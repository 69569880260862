import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '../../../store';
import { DetailsBank } from './details/bank';
import { FundShowDepositDetails, FormHeader } from 'common';
import { AddCashSteps } from './steps';
import { getAccountInfo } from '~/utils/get-account-details';

export const Preview: FC<
  Partial<StepWizardChildProps & FundShowDepositDetails>
> = ({ goToNamedStep, fundDetailsType, onClose }) => {
  /**
   * Store
   */
  const formValues = DataStore.useStoreState(s => s.addCash.formValues);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);

  /**
   * Methods
   */
  const onBack = () => {
    if (fundDetailsType && onClose) {
      onClose();
    }
    if (formValues) {
      if (goToNamedStep) {
        goToNamedStep(AddCashSteps.Form);
      }
    }
  };

  /**
   * DOM
   */
  if (!formValues || !accountDetail) {
    return null;
  }
  const title = fundDetailsType
    ? `Bank deposit details`
    : `Add ${formValues.toAsset?.displayCode}`;
  return (
    <div className="flex flex-col text-primary">
      {/* header  */}
      <FormHeader
        title={title}
        accountInfo={getAccountInfo(accountDetail.account, 'To')}
        onBack={onBack}
      />

      {/* summary  */}
      <DetailsBank />
    </div>
  );
};
