import { FILTER_ASSETS_BY } from '../config';
import { EnrichedAccountDetailAsset } from '../types';

function contains(value: string, search: string) {
  return value.toLowerCase().includes(search.toLowerCase());
}

export const filterFunds = (
  funds: EnrichedAccountDetailAsset[],
  filterBy: FILTER_ASSETS_BY,
  search: string
) => {
  return funds.filter(fund => {
    let ok = false;

    if (filterBy === FILTER_ASSETS_BY.ALL) {
      ok = true;
    }

    if (filterBy === FILTER_ASSETS_BY.CASH) {
      ok = fund.currency.isAssetOfTypeFiat;
    }

    if (filterBy === FILTER_ASSETS_BY.CRYPTO) {
      ok = fund.currency.isAssetOfTypeCrypto;
    }

    if (filterBy === FILTER_ASSETS_BY.STABLECOIN) {
      ok = fund.currency.isStablecoin;
    }

    if (ok && search) {
      return (
        contains(fund.currency.code, search) ||
        contains(fund.currency.name, search)
      );
    }

    return ok;
  });
};
