import { DependencyList, useEffect } from 'react';
import {
  StablehousePusherChannel,
  subscribePusher,
} from '../utils/subscribe-pusher';

export const usePusher = <T,>(
  pusher: any | null,
  channel: StablehousePusherChannel | null | undefined,
  envName: string,
  callback: (err: null | unknown, data?: T) => void,
  accountId?: string,
  suffix?: string | null,
  dependencies: DependencyList = []
) => {
  /**
   * Hooks
   */
  useEffect(() => {
    if (!channel) {
      return;
    }

    const unsubscribe = subscribePusher(
      pusher,
      channel,
      envName,
      callback,
      accountId,
      suffix
    );

    return () => {
      if (!unsubscribe) {
        return;
      }
      unsubscribe();
    };
  }, dependencies);
};
