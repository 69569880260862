import { API } from 'api';
import { runApi } from '../utils';
import { ApiThunk, AppComputed } from './types';
import { BaseModel, createBaseModel } from './base-store';
import { action, Action, computed, thunk, ThunkOn, thunkOn } from 'easy-peasy';
import { DataModel } from './data-store';

type UserApiAlertThunk<Req, Result = unknown> = ApiThunk<
  UserAlertsModel,
  Req,
  Result
>;
type UserMessage = API.UserUnseenMessageTemplateDto;
type UserAlertAction<Payload = void> = Action<UserAlertsModel, Payload>;
type UserAlertComputed<Result> = AppComputed<UserAlertsModel, Result>;

type UserMessageBase = Pick<
  API.UserUnseenMessageTemplateDto,
  'id' | 'messageKind'
> & {
  dismissable: boolean;
  acceptable: boolean;
  permanent: boolean;
};

export type UserBanner = UserMessageBase &
  Pick<API.UserUnseenMessageTemplateDto, 'alertLevel'> & {
    title: string | null;
    text: string | null;
    linkText: string | null;
    linkUrl: string | null;
  };

export type UserSplashScreen = UserMessageBase & {
  title: string | null;
  subtitle: string | null;
  text: string | null;
  pdfUrl: string | null;
  ackText: string | null;
};

export interface UserAlertsModel extends BaseModel {
  getMessages: UserApiAlertThunk<void, UserMessage[] | null>;
  acceptMessage: UserApiAlertThunk<API.AcceptUserMessageRequest>;
  dismissMessage: UserApiAlertThunk<API.DismissUserMessageRequest>;

  _setMessages: UserAlertAction<UserMessage[]>;
  _messages: UserMessage[] | null;
  banners: UserAlertComputed<UserBanner[]>;
  splashScreens: UserAlertComputed<UserSplashScreen[]>;
  refreshMessages: ThunkOn<UserAlertsModel, UserAlertsModel, DataModel>;
}

export const userAlertsModel: UserAlertsModel = {
  ...createBaseModel(),
  _messages: null,
  _setMessages: action((state, value) => {
    state._messages = value;
  }),
  banners: computed([state => state._messages ?? []], _messages => {
    return _messages
      .filter(
        _message => _message.messageKind === API.MessageTemplateKind.Banner
      )
      .map(
        ({
          id,
          alertLevel,
          messageKind,
          behavior,
          bannerLinkText: linkText,
          bannerLinkUrl: linkUrl,
          bannerText: text,
          bannerTitle: title,
        }) => ({
          id,
          alertLevel,
          messageKind,
          dismissable: behavior === API.MessageBehavior.Dismissable,
          acceptable: behavior === API.MessageBehavior.Acceptable,
          permanent: behavior === API.MessageBehavior.Permanent,
          linkText,
          linkUrl,
          text,
          title,
        })
      );
  }),
  splashScreens: computed([state => state._messages ?? []], _messages => {
    return _messages
      .filter(
        _message =>
          _message.messageKind === API.MessageTemplateKind.SplashScreen
      )
      .map(
        ({
          id,
          messageKind,
          behavior,
          splashScreenAckText: ackText,
          splashScreenPdfUrl: pdfUrl,
          splashScreenSubtitle: subtitle,
          splashScreenText: text,
          splashScreenTitle: title,
        }) => ({
          id,
          messageKind,
          dismissable: behavior === API.MessageBehavior.Dismissable,
          acceptable: behavior === API.MessageBehavior.Acceptable,
          permanent: behavior === API.MessageBehavior.Permanent,
          ackText,
          pdfUrl,
          subtitle,
          text,
          title,
        })
      );
  }),

  getMessages: thunk(async (actions, _void, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.userMessages();
      },
      result => {
        actions._setMessages(result);
      }
    );
  }),
  acceptMessage: thunk(async (actions, { userMessageId }, helpers) => {
    return runApi(actions, helpers, () => {
      return helpers.injections.apiClient.accept({ userMessageId });
    });
  }),
  dismissMessage: thunk(async (actions, { userMessageId }, helpers) => {
    return runApi(actions, helpers, () => {
      return helpers.injections.apiClient.dismiss({ userMessageId });
    });
  }),
  refreshMessages: thunkOn(
    (actions, storeActions) => [
      actions.acceptMessage,
      actions.dismissMessage,
      storeActions.portfolio.getPortfolio,
    ],
    async actions => {
      await actions.getMessages();
    }
  ),
};
