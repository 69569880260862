import { FC, useCallback, useState } from 'react';
import { ConfirmCancel } from '~/components/workflows/shared/confirm-cancel';
import { SuccessfulOperationStep } from '~/components/workflows/shared/successful-operation-step';
import { DataStore } from '~/store';
import { AppLoader } from '../../app-loader';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '~/routes';

interface DeleteAddressEntryModalProps {
  cls?: string;
  addressId: string;
  onClose: () => void;
}

export const DeleteAddressEntryModal: FC<DeleteAddressEntryModalProps> = ({
  onClose,
  addressId,
}) => {
  const loading = DataStore.useStoreState(s => s.settings.cryptoAddresses.busy);
  const error = DataStore.useStoreState(s => s.settings.cryptoAddresses.error);
  const deleteWithdrawalAddress = DataStore.useStoreActions(
    s => s.settings.cryptoAddresses.deleteWithdrawalAddress
  );
  const [success, setSuccess] = useState(false);

  /**
   * Handlers
   */
  const navigate = useNavigate();
  const handleRemoveAddress = useCallback(async () => {
    const result = await deleteWithdrawalAddress(addressId);
    if (result.isSuccessful) {
      navigate(APP_ROUTES.AUTH_SETTINGS + '/crypto-addresses');
      setSuccess(true);
    }
  }, [navigate, setSuccess, deleteWithdrawalAddress]);

  if (success) {
    return (
      <div className="flex flex-col text-primary">
        {loading && <AppLoader bgColor={'bg-transparent'} spinnerTop="0%" />}
        <SuccessfulOperationStep
          title=" This address has been removed from your address book"
          description="This address will no longer show up in your address book and you will no
          longer be able to send to this address."
          onBack={onClose}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col text-primary">
      {loading && <AppLoader bgColor={'bg-transparent'} spinnerTop="0%" />}
      <ConfirmCancel
        title="Do you want to remove this address from your address book?"
        subTitle="This address will no longer show up in your address book and you will no longer
          be able to send to this address."
        noActionText="Keep address"
        submitText="Remove"
        error={error}
        onNoActionClick={onClose}
        onSubmitClick={handleRemoveAddress}
      />
    </div>
  );
};
