import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { StepWizardChildProps } from 'react-step-wizard';
import { APP_ROUTES } from '~/routes';
import { Widgetcard } from './widget-card';
import { WidgetStepsNavigation } from './widget-steps-navigation';

// TODO(Hadrien): Should this be removed ?
export const WidgetGetVerified: FC<Partial<StepWizardChildProps>> = memo(
  ({ ...rest }) => {
    /**
     * Hooks
     */
    const navigate = useNavigate();

    /**
     * DOM
     */
    return (
      <Widgetcard
        icon="percent"
        titleCls="text-xl"
        title="Get verified"
        subText="Get verified now to make your first deposit!"
        ctaText="Get verified"
        handleButtonClick={() => navigate(APP_ROUTES.AUTH_KYC_INDEX)}
      >
        <WidgetStepsNavigation {...rest} />
      </Widgetcard>
    );
  }
);
