import { FC } from 'react';
import cx from 'classnames';
import { AppIcon, ValidateAddressResponseProps } from 'common';

export const AddressVerificationNote: FC<ValidateAddressResponseProps> = ({
  variant,
  message,
}) => {
  return (
    <div
      className={cx(
        'flex flex-row mt-1 justify-between items-center px-4 py-3 gap-3 rounded-lg',
        {
          'bg-failure-light text-failure': variant === 'error',
          'bg-success-light  text-success': variant === 'success',
          'bg-warning-fair text-warning-dark': variant === 'warning',
        }
      )}
    >
      <span className="text-sm font-bold">{message}</span>
      <AppIcon
        size={24}
        bg="bg-transparent"
        cls="fill-current-color"
        icon="information-circle"
      />
    </div>
  );
};
