import { FC } from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { DATE_FORMATS, dataTestIds } from 'common';

interface Props {
  date: Date | null;
  cls?: string;
}

export const CellActivityDate: FC<Props> = ({
  date,
  cls = 'text-sm items-start',
}) => {
  /**
   * DOM
   */
  if (!date) {
    return null;
  }
  return (
    <div
      data-testid={dataTestIds.components.transactions.cellActivityDate.root}
      className={cx('flex flex-col text-primary font-bold', cls)}
    >
      <span>{format(new Date(date), DATE_FORMATS.RECEIPT_Z)}</span>
      <span className="text-xs font-normal text-grey-darker">
        {format(new Date(date), DATE_FORMATS.H_MM_AAA_Z)}
      </span>
    </div>
  );
};
