import {
  BankAccountFormikField,
  countries,
  Country,
  BankAccountFormikProps,
} from 'common';
import { useFormikContext } from 'formik';
import { DataStore } from '../../../store';
import {
  fiatFundSelectedItemTemplate,
  fiatFundItemTemplate,
  countrySelectedItemTemplate,
  countryItemTemplate,
} from '../../app-selector/templates';
import { SelectField } from '../../forms/select-field';

export const CountryCcyInfo = () => {
  /**
   * Store
   */
  const withdrawableFiatCurrencies = DataStore.useStoreState(
    s => s.metaData.withdrawableFiatCurrencies
  );

  /**
   * Hooks
   */
  const { setFieldValue } = useFormikContext<BankAccountFormikProps>();

  /**
   * Methods
   */
  const getSelectedCountry = (value: string) => {
    return (
      countries.find(x => x.code === value) || {
        code: '',
        name: '',
      }
    );
  };

  const handleChangeBankCountry = (country: Country) => {
    setFieldValue(BankAccountFormikField.bankCountry, country.code);
  };

  const handleChangeCurrency = (currencyCode: string) => {
    setFieldValue(BankAccountFormikField.currencyCode, currencyCode);

    // Clear `intermediaryBankIdentifier` on currency change
    setFieldValue(BankAccountFormikField.intermediaryBankIdentifier, '', false);
  };

  /**
   * DOM
   */
  const currencyCodes = withdrawableFiatCurrencies.map(c => c.code);

  return (
    <div className="flex flex-col gap-5 mb-5">
      <span className="text-primary font-bold text-base">
        Bank account details
      </span>
      {/* account currency  */}
      <div>
        <SelectField
          name={BankAccountFormikField.currencyCode}
          label="Account currency"
          values={currencyCodes}
          getSelectedItemTemplate={fiatFundSelectedItemTemplate}
          getItemTemplate={fiatFundItemTemplate}
          onChange={handleChangeCurrency}
        />
      </div>
      {/* country  */}
      <div>
        <SelectField
          name={BankAccountFormikField.bankCountry}
          label="Bank Country"
          values={countries}
          getSelectedItemTemplate={countrySelectedItemTemplate}
          getItemTemplate={countryItemTemplate}
          getSelectedItem={getSelectedCountry}
          disableHelpers
          onChange={handleChangeBankCountry}
        />
      </div>
    </div>
  );
};
