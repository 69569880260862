import { ERROR_MESSAGES, TOAST_OPTIONS } from 'common';
import { ToastOptions, toast } from 'react-toastify';
import { getPdfAsBlob } from './get-pdf-as-blob';
import { saveBlobAsFile } from './save-blob-as-file';

export const pdfDownloadMessage = (
  isSuccessful: boolean,
  uri: string | null | undefined,
  errorMessage: string | null | undefined,
  type: string,
  toastMessage = 'Could not download PDF',
  fileName?: string | undefined
) => {
  if (!isSuccessful || !uri) {
    toast.warn(
      errorMessage || ERROR_MESSAGES.DEFAULT,
      TOAST_OPTIONS as ToastOptions
    );
    return;
  }
  if (type === 'view') {
    window.open(uri, '_blank');
    return;
  }
  if (type === 'download') {
    getPdfAsBlob(uri, (blob, err) => {
      const defaultFileName =
        fileName || new URL(uri).pathname.split('/').pop() || '';
      if (!blob || !!err) {
        toast.error(
          `${toastMessage} - ${defaultFileName}`,
          TOAST_OPTIONS as ToastOptions
        );
        return;
      }
      saveBlobAsFile(blob, defaultFileName);
    });
  }
};
