import { API } from 'api';
import {
  DateRangeKey,
  filterAssetBy,
  TransferFilterFormikField,
  TransferFilterFormikProps,
  TransferFilterFormRangeProps,
  useFilterCurrencies,
} from 'common';
import { DataStore } from '~/store';
import { useMemo, useState } from 'react';

export const initialFormState = {
  filterAssetList: [],
  filterDateRange: {
    startDate: null,
    endDate: null,
  },
  filterStartDate: null,
  filterEndDate: null,
  filterDatePeriod: null,
  filterType: [],
  filterStatus: [],
};

export interface FilterProps {
  onClose: (option: boolean) => void;
  getTransactions?: (
    options?: Partial<API.GetActivityTransactionsRequest> | null,
    hardReload?: any
  ) => void;
}

export type TRANSACTIONS_SORT_PARAMS_KEYS = keyof API.GetActivityHistoryRequest;

type FilterList =
  | string[]
  | API.FilteringActivityLabel[]
  | API.TransactionStatusFilter[];
type FilterValues = Record<TransferFilterFormikField, FilterList>;
type FilterListValues =
  | string
  | API.FilteringActivityLabel
  | API.TransactionStatusFilter
  | FilterList;

const allowedKeys = [
  TransferFilterFormikField.filterAssetList,
  TransferFilterFormikField.filterStatus,
  TransferFilterFormikField.filterType,
];

export function useFilterTransactions() {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.error);
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);
  const formValues = DataStore.useStoreState(a => a.transactions.formValues);
  const preFormValues = DataStore.useStoreState(
    a => a.transactions.preFormValues
  );
  const setFormValues = DataStore.useStoreActions(
    a => a.transactions.setFormValues
  );
  const setPreFormValues = DataStore.useStoreActions(
    a => a.transactions.setPreFormValues
  );
  const setFilterCount = DataStore.useStoreActions(
    a => a.transactions.setFilterCount
  );

  /**
   * Hooks
   */
  const { currencies, filterBy, search, setFilterBy, setSearch } =
    useFilterCurrencies(DataStore, [], { filterByAccountType: true });

  const [buttonFilter, setButtonFilter] = useState<DateRangeKey | null>(
    preFormValues?.filterDatePeriod
  );
  const [filterValues, setFilterValues] = useState<FilterValues>({
    filterType: preFormValues?.filterType,
    filterStatus: preFormValues?.filterStatus,
    filterAssetList: preFormValues?.filterAssetList,
  });

  const handleFilterValuesUpdate = (
    value: FilterListValues,
    key: TransferFilterFormikField
  ) => {
    const values = filterValues[key];
    const updatedValue = Array.isArray(value)
      ? value
      : values?.includes(value as never)
        ? values.filter((item: string) => item !== value)
        : [...values?.map(item => item), value];

    setFilterValues({ ...filterValues, [key]: updatedValue });
  };

  const onResetForm = (value?: TransferFilterFormikProps) => {
    setButtonFilter(null);
    setPreFormValues(value || initialFormState);
  };

  const filteredCurrencies = useMemo(() => {
    return currencies.filter(
      _currency =>
        filterAssetBy(filterBy, _currency) &&
        (_currency.code.toLocaleLowerCase()?.includes(search) ||
          _currency.name.toLocaleLowerCase()?.includes(search))
    );
  }, [currencies, search, filterBy]);

  const assetListFilteredValues = useMemo(() => {
    return filterValues?.filterAssetList?.filter(item =>
      filteredCurrencies?.some(({ code }) => item === code)
    );
  }, [filteredCurrencies, filterValues?.filterAssetList]) as string[];

  const handleFilterSelection = (key: TransferFilterFormikField) =>
    setPreFormValues({ ...preFormValues, [key]: filterValues[key] });

  const handleDateSelection = (
    selectedDates: TransferFilterFormRangeProps,
    key: DateRangeKey | null
  ) => {
    setPreFormValues({
      ...preFormValues,
      filterDateRange: selectedDates,
      filterStartDate: selectedDates?.startDate,
      filterEndDate: selectedDates?.endDate,
      filterDatePeriod: key,
    });
    setButtonFilter(key);
  };

  const handleApplyFilters = (formData?: TransferFilterFormikProps) => {
    if (!formData) return;
    const data = formData;
    let filterCount = 0;
    allowedKeys?.map(item => {
      if (data[item]?.length > 0) {
        filterCount += 1;
      }
    });
    if (data?.filterStartDate) {
      filterCount += 1;
    }

    setFormValues(formData);
    setFilterCount(filterCount);
    setPreFormValues(formData || initialFormState);
    return;
  };

  const currencyCodeFilterItems = useMemo(() => {
    return currencies
      .filter(_asset =>
        filteredCurrencies.find(({ code }) => code === _asset.code)
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [currencies, filteredCurrencies]);

  return {
    formValues,
    preFormValues,
    onResetForm,
    resetFilters: () => {
      setFormValues(initialFormState);
      setPreFormValues(initialFormState);
      setFilterCount(0);
    },
    handleDateSelection,
    handleFilterSelection,
    filteredCurrencies,
    currencyCodeFilterItems,
    handleApplyFilters,
    filterBy,
    setFilterBy,
    setSearch,
    search,
    filterAssetList: filterValues?.filterAssetList as string[],
    filterStatus: filterValues?.filterStatus as API.TransactionStatusFilter[],
    filterType: filterValues?.filterType as API.FilteringActivityLabel[],
    handleFilterValuesUpdate,
    error,
    clientUserType,
    buttonFilter,
    assetListFilteredValues,
  };
}
