import React, { FC } from 'react';
import cx from 'classnames';
import { AppIcon } from '../app-icon';

export interface Props {
  label: string | JSX.Element;
  content: string | JSX.Element;
  cls?: string | null | undefined;
  showColon?: boolean;
  labelCls?: string;
  borderCls?: string;
  labelIcon?: boolean;
}

export const RowLabelContent: FC<Props> = ({
  label,
  content,
  labelIcon,
  cls = undefined,
  showColon = false,
  labelCls = 'text-sm',
  borderCls = 'border-t',
}) => {
  /**
   * DOM
   */
  return (
    <div className={cx('flex flex-row py-3 text-sm', borderCls, cls)}>
      <p className={cx('flex-1 text-gray-400 text-left mr-2', labelCls)}>
        <span>
          {label}
          {showColon && ':'}
        </span>
        {labelIcon && <AppIcon icon="holdings-info" />}
      </p>
      <div className="flex items-center break-all justify-end font-bold">
        {content}
      </div>
    </div>
  );
};
