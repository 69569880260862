import { useSystemConfig } from 'common';
import { useEffect, useState } from 'react';

export const useHeap = () => {
  /**
   * State
   */
  const { heapKey } = useSystemConfig();
  const [heap, setHeap] = useState<any>(undefined);
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    if (heap || !heapKey || !window.heap) {
      console.error('Could not initalize heap');
      return;
    }
    if (
      window.heap &&
      window.heap.load &&
      typeof window.heap.load === 'function'
    ) {
      window.heap.load(heapKey);
      setHeap(window.heap);
    }
  }, [heapKey]);

  // the heap value in the window object changes over time, so we need to keep track of it.
  useEffect(() => {
    window._heap = window.heap;
    Object.defineProperty(window, 'heap', {
      get: function () {
        return window._heap;
      },
      set: function (value) {
        window._heap = value;
        setHeap(value);
        setCounter(counter + 1);
      },
    });
  }, []);

  return heap;
};
