import { useAppDialogHelper } from 'common';
import { FC, memo, useEffect, useRef } from 'react';
import StepWizard from 'react-step-wizard';
import { defaultStepWizardProps } from '../../../constants';
import { AppStore, DataStore } from '../../../store';
import { AccountLocked } from '../shared/account-locked';
import { CancelledTransfer } from './cancelled-transfer';
import { Code } from './code';
import { ConfirmCancelTransfer } from './confirm-cancel';
import { Form } from './form';
import { Preview } from './preview';
import { TransferSteps } from './steps';
import { Summary } from './summary';
import { Validate2FA } from './validate-2fa';

export const TransferWorkflow: FC = memo(() => {
  /**
   * Vars
   */
  const ref = useRef<HTMLDivElement | null>(null);

  /**
   * Store
   */
  const setError = DataStore.useStoreActions(a => a.setError);
  const resetState = DataStore.useStoreActions(a => a.transfer.reset);
  const { setDashboardSelectedAsset } = AppStore.useStoreActions(_ => ({
    setDashboardSelectedAsset: _.setDashboardSelectedAsset,
  }));

  /**
   * Hooks
   */
  const appDialogHelper = useAppDialogHelper();
  useEffect(() => {
    appDialogHelper.init(ref.current);

    // clean up
    return () => {
      resetState();
      setDashboardSelectedAsset(null);
      appDialogHelper.dispose();
    };
  }, []);

  /**
   * DOM
   */
  return (
    <div className="relative text-primary h-full" ref={ref}>
      <StepWizard
        {...defaultStepWizardProps}
        className="h-full"
        onStepChange={({ activeStep }) => {
          setError(null);

          const closeBtnHiddenSteps = [3, 4, 5];
          if (!closeBtnHiddenSteps.includes(activeStep)) {
            appDialogHelper.closeButton.show();
            return;
          }
          appDialogHelper.closeButton.hide();
        }}
      >
        {/* step: 1 */}
        <Form stepName={TransferSteps.Form} />
        {/* step: 2 */}
        <Preview stepName={TransferSteps.Preview} />
        {/* step: 3 */}
        <Validate2FA stepName={TransferSteps.Validate2FA} />
        {/* step: 4 */}
        <Code stepName={TransferSteps.EmailCode} />
        {/* step: 5 */}
        <ConfirmCancelTransfer stepName={TransferSteps.ConfirmCancellation} />
        {/* step: 6 */}
        <CancelledTransfer stepName={TransferSteps.Cancelled} />
        {/* step: 7 */}
        <Summary stepName={TransferSteps.Summary} />
        {/* step: 8 */}
        <AccountLocked stepName={TransferSteps.AccountLocked} />
      </StepWizard>
    </div>
  );
});
