import numbro from 'numbro';
import esES from 'numbro/languages/es-ES';
import frFR from 'numbro/languages/fr-FR';
import numberParse from 'multi-number-parse';
import { DEFAULTS, FIAT_SYMBOLS } from '../constants';
import { getFallbackMantissa } from './format-amount';

numbro.registerLanguage(esES);
numbro.registerLanguage(frFR);

const _setLocale = (locale: string) => {
  numbro.setLanguage(locale, 'en-US');
};

const _formatDefaults: numbro.Format = {
  thousandSeparated: true,
  mantissa: DEFAULTS.DECIMAL_SCALE,
  trimMantissa: true, // Note: Product requirement to trim trailing zeros - got from @dipen
};

const _getAmount = (
  amount: string | number | undefined | null,
  decimals: number | undefined,
  isFiat = false
) => {
  const value = numberParse(amount ?? '0');

  return numbro(value).format({
    ..._formatDefaults,
    mantissa: decimals,
    trimMantissa: isFiat ? false : true,
  });
};

const _getCurrency = (
  amount: string | number | undefined | null,
  decimals: number | undefined,
  ccyCode: string,
  fiatCurrencyCodes: string[],
  preferNonFiatSymbol = false
) => {
  const value = numberParse(amount || 0);
  const isFiat = fiatCurrencyCodes.includes(ccyCode);
  const hasFiatSymbol = !!FIAT_SYMBOLS[ccyCode];
  const currencySymbol = preferNonFiatSymbol
    ? ccyCode
    : FIAT_SYMBOLS[ccyCode] || ccyCode;

  if (isFiat && !hasFiatSymbol) {
    // Note: should enhance this to a granular throw - for future
    // BMD, CAD, CHF, JPY, XAU
    // console.info(`Bug: FIAT_SYMBOLS enumeration does not cater for ${ccyCode}`);
  }

  const fallbackMatissa = getFallbackMantissa(value);
  const mantissa =
    typeof decimals !== 'undefined'
      ? decimals
      : isFiat
        ? DEFAULTS.DECIMAL_SCALE
        : fallbackMatissa;

  const shouldPrefix = isFiat && !preferNonFiatSymbol;

  return numbro(value).formatCurrency({
    ..._formatDefaults,
    mantissa,
    trimMantissa: false,
    currencyPosition: shouldPrefix ? 'prefix' : 'postfix',
    currencySymbol: currencySymbol,
    spaceSeparatedCurrency: !shouldPrefix,
  });
};

const _forceSign = (value: string, sign: string): string => {
  return `${sign}${value}`;
};

function getFiatAmount(value: string | number | undefined | null) {
  return _getAmount(value, DEFAULTS.DECIMAL_SCALE, true);
}

export const formatters = {
  setLocale: _setLocale,
  getAmount: _getAmount,
  getFiatAmount,
  getCurrency: _getCurrency,
  forceSign: _forceSign,
};
