import { ERROR_MESSAGES } from '../constants';
import { API } from 'api';

/**
 * Business logic around trade simulation
 * @param simulation
 * @returns
 */
export const getTradeSimulationError = (
  simulation: API.TradeSimulation | null
) => {
  if (!simulation) {
    return ERROR_MESSAGES.DEFAULT;
  }

  let errorMessage = `Cannot trade between ${simulation.currencyFrom} and ${simulation.currencyTo}`;
  /**
   * It is assumed that everything is an `ON_EXCHANGE` at this point
   */
  /**
   * !canTrade
   * - maxAmountFrom !== null -> You can only trade upto maxAmountFrom currencyFrom
   * - maxAmountTo !== null -> You can trade upto maxAmountTo currencyTo
   * - Cannot API.Trade between PAX and SEUR
   */
  if (simulation?.maxAmountFrom) {
    errorMessage = `Maximum allowed amount is: ${simulation?.maxAmountFrom} ${simulation?.currencyFrom}`;
  }

  if (simulation?.maxAmountTo) {
    errorMessage = `Maximum allowed amount is: ${simulation?.maxAmountTo} ${simulation?.currencyTo}`;
  }

  return errorMessage;
};
