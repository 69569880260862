import { AllowedWorkflowType } from '../types';

export const getWorkflowName = (type: AllowedWorkflowType) => {
  switch (type) {
    case 'workflow-buy':
      return 'Buy';
    case 'workflow-sell':
      return 'Sell';
    case 'workflow-send':
      return 'Send';
    case 'workflow-receive':
      return 'Receive';
    case 'workflow-add-cash':
      return 'Add cash';
    case 'workflow-withdraw-cash':
      return 'Withdraw cash';
    case 'workflow-transfer':
      return 'Transfer';
    default:
      return '';
  }
};
