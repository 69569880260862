import { FC } from 'react';
import cx from 'classnames';
import { BaseComponentProps } from 'common';

interface CaptionProps extends BaseComponentProps {
  className?: string;
  textColor?: string;
}

export const Caption: FC<CaptionProps> = ({
  className,
  textColor = 'text-gray-400',
  ...rest
}) => {
  return <div className={cx(className, textColor, 'text-sm')} {...rest} />;
};
