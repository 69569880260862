import { RadioGroup } from '@headlessui/react';
import cx from 'classnames';

interface Props<T> {
  item: T;
  itemTemplate?: (i: T) => JSX.Element;
  items: T[];
  disabled?: boolean;
  onSelected: (item: T) => void;
  type?: 'button' | 'pill';
  containerPadding?: string;
  itemPadding?: string;
}

export const SegmentedControl = <T,>({
  item,
  items,
  onSelected,
  itemTemplate,
  disabled,
  type = 'pill',
  containerPadding = 'p-0.5',
  itemPadding = 'px-4 py-2',
}: Props<T>) => {
  /**
   * DOM
   */
  if (!items || !items.length) {
    return null;
  }
  const asButton = type === 'button';
  const isDisabled = !item || disabled;
  return (
    <RadioGroup
      value={item}
      disabled={isDisabled}
      onChange={onSelected}
      className={cx(containerPadding, {
        'flex flex-row w-full border-grey-bright bg-gray-100 rounded-md border':
          type === 'pill',
        'flex whitespace-nowrap overflow-auto scrollbar-hide gap-2': asButton,
        'opacity-25 cursor-not-allowed': isDisabled,
      })}
    >
      {items.map(arrItem => {
        return (
          <RadioGroup.Option
            value={arrItem}
            key={JSON.stringify(arrItem)}
            className={cx('flex flex-row items-center', {
              'w-full': !asButton,
              'w-auto': asButton,
            })}
          >
            {({ checked }) => {
              const tpl = itemTemplate ? itemTemplate(arrItem) : arrItem;
              return (
                <span
                  className={cx(
                    'flex w-full justify-center items-center rounded-md cursor-pointer text-sm font-bold',
                    {
                      'h-10': type === 'pill',
                      'bg-primary text-white': checked,
                    },
                    itemPadding
                  )}
                >
                  <>{tpl}</>
                </span>
              );
            }}
          </RadioGroup.Option>
        );
      })}
    </RadioGroup>
  );
};
