import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { OpenLayout } from '../layouts/open-layout';

export const AccountUnknown: FC = () => {
  const navigate = useNavigate();

  const handleGoToSignInPage = useCallback(() => {
    navigate(APP_ROUTES.NON_AUTH_HOME);
  }, []);

  /**
   * DOM
   */
  return (
    <OpenLayout
      icon="negative-circle-shield"
      title="Unknown account"
      cta={
        <button
          role="button"
          className="app-button-primary mt-12"
          style={{ width: 275 }}
          onClick={handleGoToSignInPage}
        >
          Go to sign in
        </button>
      }
      withAppWrapper
    >
      <span>Are you on the right env ?</span>
    </OpenLayout>
  );
};
