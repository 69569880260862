import { FC } from 'react';
import { APP_ROUTES } from '../../routes';
import { Link } from 'react-router-dom';
import { useUrls } from 'common';
import { AppContainer } from '../app-container';
import { AppLogo } from '../app-logo';

export const ApexFooter: FC = () => {
  /**
   * Store
   */
  const { faqUrl, privacyUrl, termsUrl } = useUrls();

  /**
   * DOM
   */
  return (
    <div
      data-testid="app-footer"
      className="relative border-t py-12 text-footer bg-footer"
    >
      <AppContainer>
        <div className="flex flex-col md:flex-row md:justify-between items-center">
          <a href={APP_ROUTES.AUTH_PORTFOLIO} className="cursor-pointer">
            <div className="flex flex-col md:justify-between items-center  md:items-baseline gap-2 md:flex-col">
              <span className="text-xs">Powered by</span>
              <AppLogo variant="dark" cls="h-8 w-auto" />
            </div>
          </a>
          <div className="flex mt-6 md:mt-0 flex-col sm:flex-row flex-start gap-6 text-lg sm:text-base items-center hidden">
            {/* faq  */}
            <a
              href={faqUrl}
              rel="help"
              className="flex flex-row hover:underline"
            >
              <p className="font-bold ml-1">Support &amp; FAQs</p>
            </a>
            {/* contact  */}
            <Link
              to={APP_ROUTES.NON_AUTH_CONTACT_US}
              className="flex flex-row hover:underline"
            >
              <p className="font-bold ml-1">Contact us</p>
            </Link>
          </div>
        </div>
      </AppContainer>
      <AppContainer cls="text-sm text-gray-400 mt-6">
        <div className="flex flex-col lg:flex-row lg:justify-between items-center">
          <div className="flex mt-6 md:mt-0 flex-row items-center text-sm">
            Stablehouse Ltd
          </div>

          <div className="flex flex-row justify-between  text-sm">
            <a
              href={termsUrl}
              className="mr-3 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Terms of use
            </a>
            {`|`}
            <a
              href={privacyUrl}
              className="ml-3 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </AppContainer>
    </div>
  );
};
