import { ERROR_MESSAGES } from '../../../constants';
import { ReceiveFormikField, ReceiveFormikProps, ReceiveMethod } from './types';

const validate = ({ receiveMethod, toAsset }: ReceiveFormikProps) => {
  const errors: Record<string, unknown> = {};

  if (!receiveMethod) {
    errors[ReceiveFormikField.receiveMethod] = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  if (receiveMethod === ReceiveMethod.crypto && !toAsset) {
    errors[ReceiveFormikField.toAsset] = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  return errors;
};

export const validateReceiveFormik = validate;
