import { AppIcon } from 'common';
import { FC } from 'react';
import { FormFooter } from '../shared/form-footer';
import { ClosableStepProps } from './types';

export const Summary: FC<ClosableStepProps> = ({ onClose }) => {
  return (
    <div className="pt-12">
      <div className="flex flex-col items-center px-10">
        <AppIcon icon="check-success" size="lg" />
        <h4 className="text-center px-10 pt-8 font-semibold">
          The address has been added
        </h4>
        <p className="mt-2 mb-10 text-center text-gray-400">
          Please ensure that external crypto wallet address details are correct
          - incorrect details could result in loss of assets.
        </p>
      </div>
      <FormFooter submitText="Close" onSubmit={onClose} />
    </div>
  );
};
