import { useEffect } from 'react';

export const useValidateOnLoad = (
  initialValues: Object,
  validateForm: () => void,
  callback?: () => boolean
) => {
  /**
   * Hooks
   */
  useEffect(() => {
    if (!initialValues) {
      return;
    }
    const shouldValidate = callback
      ? callback()
      : !Object.values(initialValues).some(
          x => x === null || x === '' || x === undefined
        );
    if (!shouldValidate) {
      return;
    }
    validateForm();
  }, []);

  /**
   * DOM
   */
  return null;
};
