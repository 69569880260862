import { FC } from 'react';
import cx from 'classnames';

import { API } from 'api';
import { IconContent } from '../icon-content';
import { BankStatus } from './bank-status';
import { AppIcon, Note } from 'common';
import { DataStore } from '../../store';
import { ShowBankAccountDetails } from './show-details';

interface DataPointProps {
  title: string;
  value: string | JSX.Element;
  w?: number;
}

interface Props {
  account: API.BankAccount | undefined;
  onClose: () => void;
  onDelete: (bankId: string) => void;
  onVerify: (bankId: string) => void;
  isApex?: boolean;
  cls?: string;
}

export const ShowBankAccount: FC<Props> = ({
  account,
  onClose,
  onDelete,
  onVerify,
  cls,
}) => {
  /**
   * Store
   */
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);
  const accountSettings = DataStore.useStoreState(
    s => s.settings.globalAppSettings
  );

  /**
   * Methods
   */
  const handleDelete = async () => {
    if (account) {
      onDelete(account.id);
    }
  };

  const handleVerify = async () => {
    if (account) {
      onVerify(account.id);
    }
    onClose();
  };

  /**
   * DOM
   */
  if (!account || !accountSettings) {
    return null;
  }

  const isVerified = account.status === API.CustomerBankAccountStatus.Ready;
  const details = account.bankAccountDetails;
  const isFundManager = clientUserType === 'fund-manager';
  return (
    <div>
      <div className={cx(cls, 'flex flex-col items-center px-10')}>
        <AppIcon icon="bank-account" cls="mt-14 mb-4" bg="" size={40} />
        <h4 className="text-xl md:text-3xl font-bold text-center mb-6">
          {details?.bankName}
        </h4>

        {!isVerified && !isFundManager && (
          <Note
            textSize="base"
            cls="w-full flex flex-row justify-center rounded-4 py-2 mb-6"
          >
            <BankStatus bankId={account.id} status={account.status} />
          </Note>
        )}

        {!isVerified && !isFundManager && (
          <IconContent
            cls="mb-6"
            iconColor="black"
            content={
              <p className="text-primary">
                We have sent you a verification request to your registered
                Stablehouse email to confirm these bank details. Please click
                the verify button below to send the verification email again.
              </p>
            }
          />
        )}

        <ShowBankAccountDetails bankAccount={account} />

        {/* delete button  */}
        {!isFundManager && (
          <button
            type="button"
            className="app-button-outline failure hover-bg-grey-brighter mb-6"
            onClick={handleDelete}
            style={{ width: '100%' }}
          >
            Remove this account
          </button>
        )}
      </div>

      {/* actions   */}
      <div className="flex flex-col sm:flex-row gap-3 border-t border-grey-bright bg-gray-100 rounded-b py-6 px-10">
        {/* cancel button  */}
        <button
          className="app-button-outline w-full sm:mr-3 flex-1"
          onClick={onClose}
        >
          Close window
        </button>
        {/* verify button  */}
        {!isFundManager && (
          <>
            {!isVerified && (
              <button
                type="button"
                className={cx(
                  'flex-1 flex items-center justify-center app-button-accent'
                )}
                onClick={handleVerify}
                disabled={isVerified}
              >
                Verify now
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
