import { API } from 'api';
import { subHours, subWeeks, subYears, subMonths, getUnixTime } from 'date-fns';
import parseNumber from 'multi-number-parse';
import {
  ChartTimeRange,
  TradingViewCandleStickChartData,
  TradingViewLineChartData,
} from '../types';
import { getFallbackMantissa } from './format-amount';

export const getChartingOptions = (
  timeRange?: ChartTimeRange | undefined,
  type: 'balance' | 'asset' = 'asset',
  ccyCode?: string
): API.OhlcvsRequest | API.GetAccountBalanceHistoryRequest => {
  const now = new Date();

  if (timeRange === ChartTimeRange.Y3) {
    if (type === 'balance') {
      return {
        startDate: subYears(now, 3),
        endDate: now,
        resolution: API.GetAccountBalanceHistoryResolution.Week1,
      };
    }
    return {
      base: ccyCode,
      quote: 'USD',
      startDate: subYears(now, 3),
      endDate: now,
      resolution: API.CandleResolutions.Days1,
    } as API.OhlcvsRequest;
  }

  if (timeRange === ChartTimeRange.Y1) {
    if (type === 'balance') {
      return {
        startDate: subYears(now, 1),
        endDate: now,
        resolution: API.GetAccountBalanceHistoryResolution.Week1,
      };
    }
    return {
      base: ccyCode,
      quote: 'USD',
      startDate: subYears(now, 1),
      endDate: now,
      resolution: API.CandleResolutions.Days1,
    } as API.OhlcvsRequest;
  }

  if (timeRange === ChartTimeRange.M3) {
    if (type === 'balance') {
      return {
        startDate: subMonths(now, 3),
        endDate: now,
        resolution: API.GetAccountBalanceHistoryResolution.Week1,
      };
    }
    return {
      base: ccyCode,
      quote: 'USD',
      startDate: subMonths(now, 3),
      endDate: now,
      resolution: API.CandleResolutions.Days1,
    } as API.OhlcvsRequest;
  }

  if (timeRange === ChartTimeRange.M1) {
    if (type === 'balance') {
      return {
        startDate: subMonths(now, 1),
        endDate: now,
        resolution: API.GetAccountBalanceHistoryResolution.Day1,
      };
    }
    return {
      base: ccyCode,
      quote: 'USD',
      startDate: subMonths(now, 1),
      endDate: now,
      resolution: API.CandleResolutions.Days1,
    } as API.OhlcvsRequest;
  }

  if (timeRange === ChartTimeRange.W1) {
    if (type === 'balance') {
      return {
        startDate: subWeeks(now, 1),
        endDate: now,
        resolution: API.GetAccountBalanceHistoryResolution.Hour1,
      };
    }
    return {
      base: ccyCode,
      quote: 'USD',
      startDate: subWeeks(now, 1),
      endDate: now,
      resolution: API.CandleResolutions.Days1,
    } as API.OhlcvsRequest;
  }

  if (timeRange === ChartTimeRange.H24) {
    if (type === 'balance') {
      return {
        startDate: subHours(now, 24),
        endDate: now,
        resolution: API.GetAccountBalanceHistoryResolution.Minute5,
      };
    }
    return {
      base: ccyCode,
      quote: 'USD',
      startDate: subHours(now, 24),
      endDate: now,
      resolution: API.CandleResolutions.Hours1,
    } as API.OhlcvsRequest;
  }

  if (type === 'balance') {
    return {
      startDate: subHours(now, 1),
      endDate: now,
      resolution: API.GetAccountBalanceHistoryResolution.Minute1,
    };
  }
  return {
    base: ccyCode,
    quote: 'USD',
    startDate: subHours(now, 1),
    endDate: now,
    resolution: API.CandleResolutions.Minutes5,
  } as API.OhlcvsRequest;
};

export const getOhlcvChartingData = (
  items: API.Ohlcv[] | null,
  fxRate: number | undefined
) => {
  if (!items || !items.length) {
    return null;
  }

  const areaChartDataPoints: TradingViewLineChartData[] = [];
  const candleStickChartDataPoints: TradingViewCandleStickChartData[] = [];
  let decimalPrecision = 0;

  const arrLength = items.length - 1;
  for (const [index, item] of items.entries()) {
    if (!item || !item.timestamp) {
      continue;
    }
    // timestamp is TZ string from talos - convert to unix timestamp for lightweight chart to understand
    const date = new Date(item.timestamp);
    const unixTime = getUnixTime(date);
    let precision = 0;

    const isLastItem = index === arrLength;
    // This is to match the last data point's fx price to be the same as our fx rate
    if (isLastItem && fxRate) {
      areaChartDataPoints.push({
        time: unixTime,
        value: parseNumber(fxRate),
      });
      candleStickChartDataPoints.push({
        time: unixTime,
        open: parseNumber(item.open || 0),
        high: parseNumber(item.high || 0),
        low: parseNumber(item.low || 0),
        close: parseNumber(fxRate),
      });

      precision = getFallbackMantissa(fxRate);
    } else {
      areaChartDataPoints.push({
        time: unixTime,
        value: parseNumber(item.close || 0),
      });
      candleStickChartDataPoints.push({
        time: unixTime,
        open: parseNumber(item.open || 0),
        high: parseNumber(item.high || 0),
        low: parseNumber(item.low || 0),
        close: parseNumber(item.close || 0),
      });

      precision = getFallbackMantissa(item.close);
    }
    decimalPrecision =
      precision > decimalPrecision ? precision : decimalPrecision;
  }

  return {
    areaChartDataPoints,
    candleStickChartDataPoints,
    decimalPrecision,
  };
};

export const getBalanceHistoryChartingData = (
  result: API.GetAccountBalanceHistoryResponse | null,
  latestBalance: string | null | undefined
) => {
  if (!result) {
    return null;
  }

  const items = result.datapoints;
  if (!items) {
    return null;
  }

  const areaChartDataPoints: TradingViewLineChartData[] = [];
  const arrLength = items.length - 1;
  let decimalPrecision = 0;

  for (const [index, item] of items.entries()) {
    if (!item || !item.date) {
      continue;
    }
    // timestamp is TZ string from talos - convert to unix timestamp for lightweight chart to understand
    const date = new Date(item.date);
    const unixTime = getUnixTime(date);
    let precision = 0;

    const isLastItem = index === arrLength;
    if (isLastItem && latestBalance) {
      areaChartDataPoints.push({
        time: unixTime,
        value: parseNumber(latestBalance),
      });
      precision = getFallbackMantissa(latestBalance);
    } else {
      areaChartDataPoints.push({
        time: unixTime,
        value: parseNumber(item.value),
      });
      precision = getFallbackMantissa(item.value);
    }
    decimalPrecision =
      precision > decimalPrecision ? precision : decimalPrecision;
  }

  return {
    areaChartDataPoints,
    decimalPrecision,
  };
};
