import { FC } from 'react';
import { ERROR_MESSAGES } from 'common';
import { nameof } from '../../utils/typescript-helpers';
import { Error } from '../common/error';
import { API } from 'api';
import { Form, Formik } from 'formik';
import { CharacterInput } from '../character-input';
import { TimedButton } from '../timed-button';
import { AppIcon } from 'common';

interface FormProps {
  code: string;
}

interface Props {
  titleMessage: string;
  error: Error | string | null | API.ApiResponse | unknown;
  onClose: (verified: boolean) => void;
  cancelled?: boolean;
  onCancelled?: () => void;
  submitButtonText?: string;
  onSubmit: (code: string) => void;
  onResend: () => void;
  acceptedCharacters?: RegExp;
}

const VERIFY_CODE_LENGTH = 6;

export const VerifyCode: FC<Props> = ({
  error,
  onResend,
  onSubmit,
  submitButtonText = `Confirm`,
  onClose,
  titleMessage,
  acceptedCharacters,
  cancelled,
  onCancelled,
}) => {
  /**
   * Methods
   */
  const onFormValidate = (values: FormProps) => {
    const errors: Record<string, string> = {};
    if (!values.code) {
      errors['code'] = ERROR_MESSAGES.REQUIRED_VALUE;
    }
    const code = values.code.trim();
    if (code.length !== VERIFY_CODE_LENGTH) {
      errors['code'] = ERROR_MESSAGES.VERIFY_CODE_ALLOWED_LENGTH;
    }
    return errors;
  };
  const onFormSubmit = async (values: FormProps) => {
    onSubmit(values.code.toUpperCase());
  };

  /**
   * DOM
   */
  const initialValues: FormProps = {
    code: '',
  };
  return (
    <div>
      {/* header  */}
      <div className="px-10 flex flex-row items-center mt-10">&nbsp;</div>
      {/* copy  */}
      <div className="flex flex-col items-center px-10">
        <AppIcon icon="envelope" size="lg" />
        <h4 className="text-center px-10 pt-8 font-semibold">{titleMessage}</h4>
        <p className="mt-2 mb-10 text-center text-gray-400">
          We sent you a verification email containing a {VERIFY_CODE_LENGTH}{' '}
          digit code. Please fill it in here to proceed with the transaction.
        </p>
      </div>

      {/* api errors  */}
      <div className="flex flex-col items-center">
        <Error message={error} cls={`mx-10`} />
      </div>

      <Formik<FormProps>
        initialValues={initialValues}
        validate={onFormValidate}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange
        onSubmit={onFormSubmit}
      >
        {({ values, isSubmitting, isValid, setFieldValue, submitForm }) => {
          /**
           * Form Methods
           */
          const onCodeChanged = async (value: string) => {
            await setFieldValue(nameof<FormProps>('code'), value, true);
          };

          /**
           * Form DOM
           */
          return (
            <Form>
              {/* content  */}
              <div className="px-10 mb-10">
                <CharacterInput
                  cls="font-bold"
                  acceptedCharacters={acceptedCharacters}
                  value={values.code}
                  length={VERIFY_CODE_LENGTH}
                  onChange={onCodeChanged}
                />
                <div className="flex flex-col items-center mt-10">
                  <TimedButton
                    preContent={<>Can&apos;t find it?</>}
                    content={`Resend Email`}
                    onClick={onResend}
                  />
                </div>
              </div>

              {/* actions  */}
              <div className="flex border-t border-grey-bright bg-gray-100 rounded-b mt-4 py-6 px-10">
                {cancelled && (
                  <>
                    <button
                      className="app-button-outline w-full mr-3 flex-1"
                      onClick={onCancelled}
                    >
                      Go to Dashboard
                    </button>
                  </>
                )}
                {!cancelled && (
                  <>
                    <button
                      type="button"
                      className="app-button-outline w-full mr-3 flex-1"
                      onClick={e => {
                        e.preventDefault();
                        onClose(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="app-button-accent flex-1 flex items-center justify-center"
                      disabled={isSubmitting || !isValid || !values.code}
                      onClick={e => {
                        e.preventDefault();
                        submitForm();
                      }}
                    >
                      {submitButtonText}
                    </button>
                  </>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
