import { FC } from 'react';
import { SecurityVerificationDetails } from '~/components/settings/security-verification-details';
import { SettingsUserProfile } from '../../components/settings/settings-user-profile';
import { SettingsLayout, SettingsMenuIds } from './layout';

export const Details: FC = () => {
  /**
   * DOM
   */
  return (
    <SettingsLayout
      header={
        <div className="flex flex-row justify-between items-center">
          <p className="font-bold typo-20">Profile details</p>
          <SecurityVerificationDetails />
        </div>
      }
      menuId={SettingsMenuIds.DETAILS}
    >
      <SettingsUserProfile />
    </SettingsLayout>
  );
};
