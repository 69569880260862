import { FC, useMemo } from 'react';
import cx from 'classnames';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppStore, DataStore } from '../../../store';
import { API } from 'api';
import { getTransferRecords } from '~/utils/get-activity-records/transfer';
import { getAccountInfo } from '~/utils/get-account-details';
import { Receipt } from '~/components/receipt';
import { TransferSteps } from './steps';
import {
  StablehousePusherChannel,
  createEnrichedInternalTransfer,
  usePusher,
  useSystemConfig,
  FormHeader,
} from 'common';

export const Summary: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Store
   */
  const sourceAccountDetail = DataStore.useStoreState(
    s => s.transfer.sourceAccountDetail
  );
  const destinationAccountDetail = DataStore.useStoreState(
    s => s.transfer.destinationAccountDetail
  );
  const transfer = DataStore.useStoreState(s => s.transfer.transfer);
  const currencies = DataStore.useStoreState(s => s.metaData.currencies);
  const fiatCurrencyCodes = DataStore.useStoreState(
    s => s.metaData.fiatCurrencyCodes
  );
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);

  const pusher = AppStore.useStoreState(s => s.pusher);
  const setTransfer = DataStore.useStoreActions(s => s.transfer.setTransfer);

  /**
   * Vars
   */
  const sourceListAccount = useMemo(() => {
    return accounts.find(
      a =>
        a.account?.accountNumber === sourceAccountDetail?.account?.accountNumber
    );
  }, []);

  const destinationListAccount = useMemo(() => {
    return accounts.find(
      a =>
        a.account?.accountNumber ===
        destinationAccountDetail?.account?.accountNumber
    );
  }, []);

  /**
   * Methods
   */
  const handleCancel = () => goToNamedStep(TransferSteps.ConfirmCancellation);

  /**
   * Hooks
   */
  const { envName } = useSystemConfig();
  usePusher(
    pusher,
    StablehousePusherChannel.TRANSFER_STATUS,
    envName,
    (err, data) => {
      if (err) {
        console.error(`FAILED - Pusher for getting TRANSFER status updates`);
        return;
      }
      setTransfer(
        createEnrichedInternalTransfer(
          data as API.InternalTransferWithTimeline,
          currencies,
          fiatCurrencyCodes
        )
      );
    },
    transfer?.accountId,
    transfer?.id
  );

  /**
   * DOM
   */
  if (!transfer || !accountDetail) {
    return null;
  }
  const isPending =
    transfer?.status === API.TransferStatus.Pending ||
    transfer?.status === API.TransferStatus.WaitingForConfirmation;
  const records = getTransferRecords(
    transfer,
    isPending ? 'Pending' : transfer.status,
    currencies,
    sourceListAccount,
    destinationListAccount
  );
  return (
    <div
      className={cx('flex flex-col text-primary', {
        'mb-8': !transfer?.canCancel,
      })}
    >
      {/* header  */}
      <FormHeader
        title={`Transfer ${transfer.currencyCode}`}
        accountInfo={getAccountInfo(accountDetail.account, 'From')}
      />

      <Receipt
        records={records}
        type={'workflow-transfer'}
        ccyCodes={[transfer.currencyCode]}
      />

      {/* actions  */}
      {transfer?.canCancel && (
        <div className="flex flex-col border-t border-grey-bright bg-gray-100 rounded-b mt-4 py-6 px-6">
          <button
            role="button"
            type="button"
            className="app-button-failure w-full flex-1"
            onClick={handleCancel}
          >
            Cancel this transaction
          </button>
        </div>
      )}
    </div>
  );
};
