import { SORT_DIR } from '../config';
import _orderBy from 'lodash.orderby';
import _get from 'lodash.get';

const sortSelector = (attr: string) => (f: unknown) => {
  const value = _get(f, attr, '');
  if (typeof value === 'number') {
    return value || 0;
  }
  if (typeof value === 'string') {
    return value.toLocaleLowerCase();
  }
  return value;
};

export const orderBy = <T = unknown>(
  list: T[],
  orderDir: SORT_DIR | SORT_DIR[],
  attr: string | string[] | ((it: T) => T | string | number | never) = ''
) => {
  if (typeof attr === 'string') {
    return _orderBy(list, sortSelector(attr), orderDir);
  }
  if (Array.isArray(attr) && attr.some(a => a.includes('.'))) {
    return _orderBy(
      list,
      attr.map(a => sortSelector(a)),
      orderDir
    );
  }
  return _orderBy(list, attr, orderDir);
};
