import { Link } from 'react-router-dom';
import { ReactComponent as CrossBlackRounded } from '../../assets/icons/cross-black-rounded.svg';
import { APP_ROUTES } from '../../routes';

interface Props {
  onClose?: () => void;
}

export const DepositsDisabled: React.FC<Props> = ({ onClose }) => {
  /**
   * DOM
   */
  return (
    <div className="flex flex-col align-middle items-center p-10 pb-5">
      <CrossBlackRounded width={20} />
      <>
        <div className="text-2xl text-center font-bold mt-5">
          New deposits are temporarily disabled
        </div>
        <div className="text-gray-400 mt-6 text-center overflow-y-scroll max-h-56">
          Stablehouse strives to offer a safe place to empower people to earn
          and grow their wealth. Over the past weeks, we have received great
          feedback on how our community would like to grow with us.
          <br />
          <br />
          Stablehouse operates under strict requirements from the Bermuda
          Monetary Authority (BMA). This is to ensure your assets are safe at
          all times. Last month, we reached the temporary limit set by our
          regulators.
          <br />
          <br />
          While we work closely with the BMA to get these limits lifted, we have
          to temporarily limit our assets under management. As a result, we have
          taken the decision to temporarily pause deposits on your account. This
          is a necessary step to ensure you are able to keep earning yield on
          your current deposit.
          <br />
          <br />
          We expect to turn deposits back on very soon. In the meantime you can
          continue to trade on the platform and benefit from our competitive
          rates.
          <br />
          <br />
          We thank you for your support, patience, and understanding. For any
          further information, please contact our support team who are here to
          answer any questions that you may have.
        </div>
        <button
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
          className="app-button-primary inline-block mt-6 mb-4 font-bold"
        >
          I understand
        </button>
        <div>
          <Link className="underline" to={APP_ROUTES.NON_AUTH_CONTACT_US}>
            or contact support
          </Link>
        </div>
      </>
    </div>
  );
};
