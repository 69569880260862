export enum TransferSteps {
  Form = 'transfer-form',
  Preview = 'transfer-preview',
  EmailCode = 'transfer-code',
  Validate2FA = 'transfer-validate-2fa',
  Summary = 'transfer-summary',
  Cancelled = 'transfer-cancelled',
  ConfirmCancellation = 'transfer-confirm-cancel',
  // TODO: review the duplicated steps AccountLocked, Preview, Form and Summary that could be in a base Step enum
  AccountLocked = 'account-locked',
}
