import {
  ERROR_MESSAGES,
  SendDestinationType,
  AddAddressFormikProps,
  AddAddressFormikField,
  validateDestinationTag,
} from 'common';
import { useCallback } from 'react';
import { DataStore } from '~/store';
import _debounce from 'lodash/debounce';
import { apiClient } from '~/api/client';

export const useValidateAddress = (accountId: string) => {
  const errors: Record<string, unknown> = {};
  /**
   * Store
   */
  const validateAddress = DataStore.useStoreActions(
    s => s.settings.cryptoAddresses.validateAddress
  );
  const validatedAddress = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.validatedAddress
  );
  const currencies = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.allowedCurrencies
  );

  /**
   * Hooks
   */
  const debouncedValidateAddress = useCallback(
    async (
      values: AddAddressFormikProps,
      destinationType?: SendDestinationType
    ) => {
      if (!values.network) {
        errors[AddAddressFormikField.network] = ERROR_MESSAGES.REQUIRED_VALUE;
      }
      if (!values.label) {
        errors[AddAddressFormikField.label] = ERROR_MESSAGES.REQUIRED_VALUE;
      }
      if (values.network) {
        if (!values.walletAddress) {
          errors[AddAddressFormikField.walletAddress] =
            ERROR_MESSAGES.REQUIRED_VALUE;
        } else {
          const blockchainCode = currencies.find(
            _currency => _currency.code === values.currency?.code
          )?.blockchain;

          if (destinationType === SendDestinationType.transfer) {
            return validateDestinationTag(
              values.walletAddress,
              accountId,
              apiClient
            );
          }

          if (blockchainCode) {
            await validateAddress({
              silent: [
                validatedAddress?.requestedAddress,
                validatedAddress?.normalizedAddress,
              ].includes(values.walletAddress),
              blockchainCode,
              address: values.walletAddress,
            });
          }
        }
      }
    },
    [
      errors,
      accountId,
      currencies,
      ERROR_MESSAGES,
      validateAddress,
      validateDestinationTag,
    ]
  );

  /**
   * Result
   */
  return { errors, validatedAddress, debouncedValidateAddress };
};
