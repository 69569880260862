import { ListOfAssets } from './list';
import { RequireAuth } from '~/components/auth/require-auth';

export const MarketsV2Page = {
  ListOfAssets: () => (
    <RequireAuth>
      <ListOfAssets />
    </RequireAuth>
  ),
};
