import { FILTER_ASSETS_BY } from '../config';
import {
  EnrichedAccountDetailAsset,
  EnrichedCurrencyInformation,
} from '../types';
import fuzzy from 'fuzzy';

export function _searchCurrency(
  currency: EnrichedCurrencyInformation,
  search: string
) {
  const codeMatch = fuzzy.test(search, currency.code);
  const displayCodeMatch = currency.displayCode
    ? fuzzy.test(search, currency.displayCode)
    : true;
  const nameMatch = fuzzy.test(search, currency.name);

  return codeMatch || displayCodeMatch || nameMatch;
}

export const searchOrFilterAsset = (
  asset: EnrichedAccountDetailAsset,
  search = '',
  filter: FILTER_ASSETS_BY = FILTER_ASSETS_BY.ALL
) => {
  if (filter === FILTER_ASSETS_BY.CASH && !asset.currency.isAssetOfTypeFiat) {
    return false;
  }
  if (filter === FILTER_ASSETS_BY.CRYPTO && asset.currency.isAssetOfTypeFiat) {
    return false;
  }
  if (filter === FILTER_ASSETS_BY.STABLECOIN && !asset.currency.isStablecoin) {
    return false;
  }

  const match = !!_searchCurrency(asset.currency, search);

  return match;
};
