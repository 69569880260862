import React from 'react';

// icons
import { ReactComponent as LockTwoFaIcon } from '../../assets/icons/lock-2fa.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as HamburgerMenuIcon } from '../../assets/icons/hamburger-menu.svg';
import { ReactComponent as HamburgerCloseIcon } from '../../assets/icons/hamburger-close.svg';
import { ReactComponent as SortAscIcon } from '../../assets/icons/sort-asc.svg';
import { ReactComponent as SortDescIcon } from '../../assets/icons/sort-desc.svg';
import { ReactComponent as InformationIcon } from '../../assets/icons/information.svg';
import { ReactComponent as InstitutionsIcon } from '../../assets/icons/institutions.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as CrossCircleIcon } from '../../assets/icons/cross-circle.svg';
import { ReactComponent as PercentIcon } from '../../assets/icons/percent.svg';
import { ReactComponent as CardRoundedAccentIcon } from '../../assets/icons/card-rounded-accent.svg';
import { ReactComponent as CheckShieldIcon } from '../../assets/icons/checkout-check.svg';
import { ReactComponent as KycShieldIcon } from '../../assets/icons/kyc-shield.svg';
import { ReactComponent as CheckShieldWhiteIcon } from '../../assets/icons/check-shield-white.svg';
import { ReactComponent as CrossShieldWhiteIcon } from '../../assets/icons/cross-shield-white.svg';
import { ReactComponent as NegativeCheckShieldIcon } from '../../assets/icons/negative-check-shield.svg';
import { ReactComponent as Pencil } from '../../assets/icons/pencil.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-plus.svg';
import { ReactComponent as GreenTickIcon } from '../../assets/icons/green-tick.svg';
import { ReactComponent as Amex } from '../../assets/icons/amex.svg';
import { ReactComponent as DinersClub } from '../../assets/icons/dinersclub.svg';
import { ReactComponent as Jcb } from '../../assets/icons/jcb.svg';
import { ReactComponent as Mastercard } from '../../assets/icons/mastercard.svg';
import { ReactComponent as Visa } from '../../assets/icons/visa.svg';
import { ReactComponent as DepositIcon } from '../../assets/icons/deposit.svg';
import { ReactComponent as WithdrawIcon } from '../../assets/icons/withdraw.svg';
import { ReactComponent as ConvertIcon } from '../../assets/icons/convert.svg';
import { ReactComponent as PercentInCircleIcon } from '../../assets/icons/percent-in-circle.svg';
import { ReactComponent as ChatIcon } from '../../assets/icons/chat.svg';
import { ReactComponent as DollarSignCircleIcon } from '../../assets/icons/dollar-sign-circle.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as ThumbsUp } from '../../assets/icons/thumbs-up.svg';
import { ReactComponent as Pin } from '../../assets/icons/pin.svg';
import { ReactComponent as FiatGroupIcons } from '../../assets/icons/fiat-group-icons.svg';
import { ReactComponent as FiatCryptoGroupIcons } from '../../assets/icons/fiat-crypto-group-icons.svg';
import { ReactComponent as HeartInCircle } from '../../assets/icons/heart-in-circle.svg';
import { ReactComponent as BtcInCircle } from '../../assets/icons/btc-in-circle.svg';
import { ReactComponent as ShieldInCircle } from '../../assets/icons/shield-in-circle.svg';
import { ReactComponent as PinkDoubleQuote } from '../../assets/icons/pink-double-quote.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-right-in-circle.svg';
import { ReactComponent as ReferredIcon } from '../../assets/icons/referred.svg';
import { ReactComponent as CheckSimple } from '../../assets/icons/check-simple.svg';
import { ReactComponent as ChatCheck } from '../../assets/icons/chat-check.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as BankAccount } from '../../assets/icons/bank-account.svg';
import { ReactComponent as VerficationIcon } from '../../assets/icons/verification.svg';
import { ReactComponent as PadlockUnlockIcon } from '../../assets/icons/padlock-unlock.svg';
import { ReactComponent as PadlockIcon } from '../../assets/icons/padlock.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as InterestIcon } from '../../assets/icons/interest.svg';
import { ReactComponent as CsvIcon } from '../../assets/icons/csv.svg';
import { ReactComponent as AirPlaneIcon } from '../../assets/icons/air-plane.svg';
import { ReactComponent as CrossWithCircle } from '../../assets/icons/cross-with-circle.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import { ReactComponent as CircleShieldCross } from '../../assets/icons/circle-shield-cross.svg';
import { ReactComponent as MobileIcon } from '../../assets/icons/mobile.svg';
import { ReactComponent as QuestionDotIcon } from '../../assets/icons/question.svg';
import { ReactComponent as GiftIcon } from '../../assets/icons/gift.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as RedeemIcon } from '../../assets/icons/redeem.svg';
import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import { ReactComponent as WorkflowAddCash } from '../../assets/icons/workflow-add-cash.svg';
import { ReactComponent as WorkflowBuy } from '../../assets/icons/workflow-buy.svg';
import { ReactComponent as WorkflowSell } from '../../assets/icons/workflow-sell.svg';
import { ReactComponent as WorkflowTransfer } from '../../assets/icons/workflow-transfer.svg';
import { ReactComponent as WorkflowReceive } from '../../assets/icons/workflow-receive.svg';
import { ReactComponent as WorkflowSend } from '../../assets/icons/workflow-send.svg';
import { ReactComponent as Send } from '../../assets/icons/send.svg';
import { ReactComponent as WorkflowWithdrawCash } from '../../assets/icons/workflow-withdraw-cash.svg';
import { ReactComponent as ChatFilled } from '../../assets/icons/chat-filled.svg';
import { ReactComponent as CheckFilled } from '../../assets/icons/check-filled.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as PlusAccent } from '../../assets/icons/plus-accent.svg';
import { ReactComponent as NegativeCircleFlag } from '../../assets/icons/negative-circle-flag.svg';
import { ReactComponent as FlagOutline } from '../../assets/icons/flag-outline.svg';
import { ReactComponent as NegativeCircleShield } from '../../assets/icons/negative-circle-shield.svg';
import { ReactComponent as LogoCircle } from '../../assets/icons/logo-circle.svg';
import { ReactComponent as NegativeChatCheck } from '../../assets/icons/negative-chat-check.svg';
import { ReactComponent as ChevronDownDark } from '../../assets/icons/chevron-down-dark.svg';
import { ReactComponent as ChevronUpAccent } from '../../assets/icons/chevron-up-accent.svg';
import { ReactComponent as EmailChecked } from '../../assets/icons/email-checked.svg';
import { ReactComponent as EmailCheckedLight } from '../../assets/icons/email-checked-light.svg';
import { ReactComponent as IdCard } from '../../assets/icons/id-card.svg';
import { ReactComponent as StablehouseLettermark } from '../../assets/icons/stablehouse-lettermark.svg';
import { ReactComponent as SocialTwitter } from '../../assets/icons/social-twitter.svg';
import { ReactComponent as SocialLinkedIn } from '../../assets/icons/social-linkedin.svg';
import { ReactComponent as SocialFacebook } from '../../assets/icons/social-facebook.svg';
import { ReactComponent as SocialTelegram } from '../../assets/icons/social-telegram.svg';
import { ReactComponent as NegativeCircleShieldCheck } from '../../assets/icons/negative-circle-shield-check.svg';
import { ReactComponent as NegativePeopleVoices } from '../../assets/icons/negative-people-voices.svg';
import { ReactComponent as NegativePencil } from '../../assets/icons/negative-pencil.svg';
import { ReactComponent as NegativeTwitter } from '../../assets/icons/negative-twitter.svg';
import { ReactComponent as NegativeReferAFriend } from '../../assets/icons/negative-refer-a-friend.svg';
import { ReactComponent as Envelope } from '../../assets/icons/envelope.svg';
import { ReactComponent as ManageHistory } from '../../assets/icons/manage-history.svg';
import { ReactComponent as Telegram } from '../../assets/icons/telegram.svg';
import { ReactComponent as ExclamationIcon } from '../../assets/icons/exclamation.svg';
import { ReactComponent as ExclamationCircleIcon } from '../../assets/icons/exclamation-circle.svg';
import { ReactComponent as CryptoAddressesIcon } from '../../assets/icons/crypto-addresses.svg';
import { ReactComponent as MailNotifications } from '../../assets/icons/mail-notifications.svg';
import { ReactComponent as CloseButtonIcon } from '../../assets/icons/close-button.svg';
import { ReactComponent as PencilEdit } from '../../assets/icons/pencil-edit.svg';
import { ReactComponent as CalendarDate } from '../../assets/icons/calendar-date.svg';
import { ReactComponent as Balance } from '../../assets/icons/balance.svg';
import { ReactComponent as InsightsPulse } from '../../assets/icons/insights-pulse.svg';
import { ReactComponent as InsightsUpdate } from '../../assets/icons/insights-update.svg';
import { ReactComponent as TwitterGray } from '../../assets/icons/twitter-gray.svg';
import { ReactComponent as IosAppStore } from '../../assets/icons/ios-app-store.svg';
import { ReactComponent as GoogleAppStore } from '../../assets/icons/google-app-store.svg';
import { ReactComponent as StablehouseFooterLogo } from '../../assets/icons/logo-text-black.svg';
import { ReactComponent as PofBlackShieldCheck } from '../../assets/icons/pof-black-shield-check.svg';
import { ReactComponent as PofWhiteShieldCheck } from '../../assets/icons/pof-white-shield-check.svg';
import { ReactComponent as PofWhiteShieldCross } from '../../assets/icons/pof-white-shield-cross.svg';
import { ReactComponent as TriangleDown } from '../../assets/icons/triangle-down.svg';
import { ReactComponent as SolutionsCustody } from '../../assets/icons/solutions-custody.svg';
import { ReactComponent as SolutionsTrading } from '../../assets/icons/solutions-trading.svg';
import { ReactComponent as SolutionsWhiteLabel } from '../../assets/icons/solutions-white-label.svg';
import { ReactComponent as ClientBuilding } from '../../assets/icons/client-building.svg';
import { ReactComponent as ClientIntermediaries } from '../../assets/icons/client-intermediaries.svg';
import { ReactComponent as ClientInvestors } from '../../assets/icons/client-investors.svg';
import { ReactComponent as StablehouseLettermarkDark } from '../../assets/icons/stablehouse-lettermark-dark.svg';
import { ReactComponent as BmaLogo } from '../../assets/icons/bma.svg';
import { ReactComponent as SearchInput } from '../../assets/icons/search-input.svg';
import { ReactComponent as ManageSearch } from '../../assets/icons/manage-search.svg';
import { ReactComponent as SearchOff } from '../../assets/icons/search-off.svg';
import { ReactComponent as StackedCoins } from '../../assets/icons/stacked-coins.svg';
import { ReactComponent as KeyFactsPencil } from '../../assets/icons/key-facts-pencil.svg';
import { ReactComponent as WhyRelevantPin } from '../../assets/icons/why-relevant-pin.svg';
import { ReactComponent as ChevronBack } from '../../assets/icons/chevron-back.svg';
import { ReactComponent as ExportIcon } from '../../assets/icons/export.svg';
import { ReactComponent as ArrowDropdown } from '../../assets/icons/arrow-dropdown.svg';
import { ReactComponent as ArrowDropUp } from '../../assets/icons/arrow-dropup.svg';
import { ReactComponent as TradingAccountArrows } from '../../assets/icons/trading-account-arrows.svg';
import { ReactComponent as CustodyAccountVault } from '../../assets/icons/custody-account-vault.svg';
import { ReactComponent as TradingAccountArrowsFilled } from '../../assets/icons/trading-account-arrows-filled.svg';
import { ReactComponent as CustodyAccountVaultFilled } from '../../assets/icons/custody-account-vault-filled.svg';
import { ReactComponent as HoldingsInfo } from '../../assets/icons/holdings-info.svg';
import { ReactComponent as Handshake } from '../../assets/icons/handshake.svg';
import { ReactComponent as Refresh } from '../../assets/icons/refresh.svg';
import { ReactComponent as CircleShieldCheck } from '../../assets/icons/circle-shield-check.svg';
import { ReactComponent as ChevronIconDropdown } from '../../assets/icons/chevron-icon-drop-down.svg';
import { ReactComponent as PencilLine } from '../../assets/icons/pencil-line.svg';
import { ReactComponent as ExternalCircle } from '../../assets/icons/external-circle.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as CheckSuccess } from '../../assets/icons/check-success.svg';
import { ReactComponent as StablehouseProducts } from '../../assets/icons/stablehouse-products.svg';
import { ReactComponent as ShieldPlus } from '../../assets/icons/shield-plus.svg';
import { ReactComponent as LogoStablehouse } from '../../assets/icons/logo-stablehouse.svg';
import { ReactComponent as CalendarCaret } from '../../assets/icons/calendar-caret.svg';
import { ReactComponent as Filter } from '../../assets/icons/filter.svg';
import { ReactComponent as NegativeCircleTransfer } from '../../assets/icons/negative-circle-transfer.svg';
import { ReactComponent as NegativeCircleVault } from '../../assets/icons/negative-circle-vault.svg';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import { ReactComponent as ArrowBack } from '../../assets/icons/arrow-back.svg';
import { ReactComponent as ArrowNorthEast } from '../../assets/icons/arrow-north-east.svg';
import { ReactComponent as ChevronGreyRight } from '../../assets/icons/chevron-grey-right.svg';
import { ReactComponent as VisibilityShow } from '../../assets/icons/visibility-show.svg';
import { ReactComponent as UserPlaceholder } from '../../assets/icons/user-placeholder.svg';
import { ReactComponent as MarketsGraph } from '../../assets/icons/markets-graph.svg';
import { ReactComponent as GraphLine } from '../../assets/icons/graph-line.svg';
import { ReactComponent as FriendReferral } from '../../assets/icons/friend_referral.svg';
import { ReactComponent as Approvals } from '../../assets/icons/approvals.svg';
import { ReactComponent as Statement } from '../../assets/icons/statement.svg';
import { ReactComponent as Invoice } from '../../assets/icons/invoice.svg';
import { ReactComponent as Settings } from '../../assets/icons/settings.svg';
import { ReactComponent as SignOut } from '../../assets/icons/sign-out.svg';
import { ReactComponent as Person } from '../../assets/icons/person.svg';
import { ReactComponent as StablehouseLogo } from '../../assets/icons/stablehouse-logo.svg';
import { ReactComponent as Candlestick } from '../../assets/icons/candlestick.svg';
import { ReactComponent as LifeBuoy } from '../../assets/icons/lifebuoy.svg';
import { ReactComponent as GraduationCap } from '../../assets/icons/graduation-cap.svg';
import { ReactComponent as CustodyAccountVaultFilledSolid } from '../../assets/icons/custody-account-vault-filled-solid.svg';
import { ReactComponent as StablehouseTelegramLogo } from '../../assets/icons/stablehouse-telegram-logo.svg';
import { ReactComponent as XBTOLogoIcon } from '../../assets/icons/logo-xbto-icon.svg';
import { ReactComponent as ArrowExternal } from '../../assets/icons/arrow-external.svg';
import { ReactComponent as ReOrder } from '../../assets/icons/re-order.svg';
import { ReactComponent as LogoXbtoHubBlack } from '../../assets/icons/logo-xbto-hub-black.svg';
import { ReactComponent as LogoXbtoHubWhite } from '../../assets/icons/logo-xbto-hub-white.svg';
import { ReactComponent as LogoXbtoPwrdByStablehouse } from '../../assets/icons/logo-xbto-powered-by-stablehouse.svg';
import { ReactComponent as LogoXbtoHubPwrdByStablehouseStacked } from '../../assets/icons/logo-xbtohub-powered-by-stablehouse-stacked.svg';
import { ReactComponent as LogoPwrdByStablehouseStacked } from '../../assets/icons/logo-powered-by-stablehouse-stacked.svg';
import { ReactComponent as BritishFlag } from '../../assets/icons/british-flag.svg';
import { ReactComponent as CheckInfo } from '../../assets/icons/check-info.svg';
import { ReactComponent as MarketTrends } from '../../assets/icons/market-trends.svg';
import { ReactComponent as RjAppLogoHeader } from '../../assets/icons/rj-app-logo-header.svg';
import { ReactComponent as RjAppLogoFooter } from '../../assets/icons/rj-app-logo-footer.svg';
import { ReactComponent as ApexAppLogoHeader } from '../../assets/icons/apex-app-logo-header.svg';
import { ReactComponent as Dots } from '../../assets/icons/dots.svg';
import { ReactComponent as InProgress } from '../../assets/icons/in-progress.svg';
import { ReactComponent as BlankIcon } from '../../assets/icons/blank-icon.svg';
import { ReactComponent as ChevronDown } from '../../assets/design/icons/chevron-down.svg';
import { ReactComponent as AssetwUSDM } from '../../assets/design/images/xbto-widget-wusdm.svg';
import { ReactComponent as LogoXbtoRound } from '../../assets/design/images/xbto-widget-logo.svg';
import { ReactComponent as ChevronRightAlt } from '../../assets/icons/chevron-right-alt.svg';
import { ReactComponent as LockClock } from '../../assets/icons/lock-clock.svg';
import { ReactComponent as GraphArea } from '../../assets/icons/graph-area.svg';
import { ReactComponent as GraphCandlestick } from '../../assets/icons/graph-candlestick.svg';
import { ReactComponent as ShieldSecurity } from '../../assets/icons/shield-security.svg';

import { AppIconType } from '.';

export const SvgIcon = (
  Component: SvgrComponent,
  size: number,
  height?: number
) => <Component width={size} height={height || size} />;

export const appIconMap: Record<
  AppIconType,
  (size: number, height?: number) => JSX.Element
> = {
  'arrow-dropup': size => <ArrowDropUp width={size} height={size} />,
  'arrow-dropdown': size => <ArrowDropdown width={size} height={size} />,
  'flag-outline': size => <FlagOutline width={size} height={size} />,
  'lock-2fa': size => <LockTwoFaIcon width={size} height={size} />,
  user: size => <UserIcon width={size} height={size} />,
  'hamburger-menu': size => <HamburgerMenuIcon width={size} height={size} />,
  'hamburger-menu-close': size => (
    <HamburgerCloseIcon width={size} height={size} />
  ),
  'sort-asc': size => <SortAscIcon width={size} height={size} />,
  'sort-desc': size => <SortDescIcon width={size} height={size} />,
  exclamation: size => <ExclamationIcon width={size} height={size} />,
  'exclamation-circle': size => (
    <ExclamationCircleIcon width={size} height={size} />
  ),
  'information-circle': size => <InformationIcon width={size} height={size} />,
  institutions: size => <InstitutionsIcon width={size} height={size} />,
  'check-circle': size => <CheckCircleIcon width={size} height={size} />,
  'chat-check': size => <ChatCheck width={size} height={size} />,
  'cross-circle': size => <CrossCircleIcon width={size} height={size} />,
  percent: size => <PercentIcon width={size} height={size} />,
  'credit-card-rounded': size => (
    <CardRoundedAccentIcon width={size} height={size} />
  ),
  'check-shield': size => <CheckShieldIcon width={size} height={size} />,
  'kyc-shield': size => <KycShieldIcon width={size} height={size} />,
  'check-shield-white': size => (
    <CheckShieldWhiteIcon width={size} height={size} />
  ),
  'cross-shield-white': size => (
    <CrossShieldWhiteIcon width={size} height={size} />
  ),
  pencil: size => <Pencil width={size} height={size} />,
  check: size => <CheckIcon width={size} height={size} />,
  copy: size => <CopyIcon width={size} height={size} />,
  'green-tick': size => <GreenTickIcon width={size} height={size} />,
  'circle-shield-cross': size => (
    <CircleShieldCross width={size} height={size} />
  ),
  'credit-card-amex': size => <Amex width={size} height={size} />,
  'credit-card-diners-club': size => <DinersClub width={size} height={size} />,
  'credit-card-jcb': size => <Jcb width={size} height={size} />,
  'credit-card-mastercard': size => <Mastercard width={size} height={size} />,
  'credit-card-visa': size => <Visa width={size} height={size} />,
  deposit: size => <DepositIcon width={size} height={size} />,
  withdraw: size => <WithdrawIcon width={size} height={size} />,
  convert: size => <ConvertIcon width={size} height={size} />,
  'percent-circle': size => <PercentInCircleIcon width={size} height={size} />,
  chat: size => <ChatIcon width={size} height={size} />,
  'dollar-circle': size => <DollarSignCircleIcon width={size} height={size} />,
  info: size => <Info width={size} height={size} />,
  'thumbs-up': size => <ThumbsUp width={size} height={size} />,
  pin: size => <Pin width={size} height={size} />,
  'fiat-currencies-group': size => (
    <FiatGroupIcons width={size} height={size} />
  ),
  'fiat-crypto-currencies-group': size => (
    <FiatCryptoGroupIcons width={size} height={size} />
  ),
  'heart-circle': size => <HeartInCircle width={size} height={size} />,
  'btc-circle': size => <BtcInCircle width={size} height={size} />,
  'sheid-circle': size => <ShieldInCircle width={size} height={size} />,
  'double-quote-accent': size => <PinkDoubleQuote width={size} height={size} />,
  arrow: size => <ArrowIcon width={size} height={size} />,
  referred: size => <ReferredIcon width={size} height={size} />,
  'check-simple': size => <CheckSimple width={size} height={size} />,
  'chevron-right': size => <ChevronRightIcon width={size} height={size} />,
  plus: size => <Plus width={size} height={size} />,
  'plus-accent': size => <PlusAccent width={size} height={size} />,
  'bank-account': size => <BankAccount width={size} height={size} />,
  'crypto-addresses': size => (
    <CryptoAddressesIcon width={size} height={size} />
  ),
  'mail-notifications': size => (
    <MailNotifications width={size} height={size} />
  ),
  verification: size => <VerficationIcon width={size} height={size} />,
  padlock: size => <PadlockIcon width={size} height={size} />,
  'padlock-unlock': size => <PadlockUnlockIcon width={size} height={size} />,
  calendar: size => <CalendarIcon width={size} height={size} />,
  interest: size => <InterestIcon width={size} height={size} />,
  csv: size => <CsvIcon width={size} height={size} />,
  airplane: size => <AirPlaneIcon width={size} height={size} />,
  'cross-with-circle': size => <CrossWithCircle width={size} height={size} />,
  mobile: size => <MobileIcon width={size} height={size} />,
  'question-dot': size => <QuestionDotIcon width={size} height={size} />,
  gift: size => <GiftIcon width={size} height={size} />,
  email: size => <EmailIcon width={size} height={size} />,
  redeem: size => <RedeemIcon width={size} height={size} />,
  card: size => <CardIcon width={size} height={size} />,
  'workflow-add-cash': size => <WorkflowAddCash width={size} height={size} />,
  'workflow-buy': size => <WorkflowBuy width={size} height={size} />,
  'workflow-sell': size => <WorkflowSell width={size} height={size} />,
  'workflow-receive': size => <WorkflowReceive width={size} height={size} />,
  'workflow-send': size => <WorkflowSend width={size} height={size} />,
  'workflow-withdraw-cash': size => (
    <WorkflowWithdrawCash width={size} height={size} />
  ),
  'workflow-transfer': size => <WorkflowTransfer width={size} height={size} />,
  'check-filled': size => <CheckFilled width={size} height={size} />,
  'chat-filled': size => <ChatFilled width={size} height={size} />,
  'negative-circle-vault': size => (
    <NegativeCircleVault width={size} height={size} />
  ),
  'negative-circle-transfer': size => (
    <NegativeCircleTransfer width={size} height={size} />
  ),
  'negative-check-shield': size => (
    <NegativeCheckShieldIcon width={size} height={size} />
  ),
  'negative-circle-flag': size => (
    <NegativeCircleFlag width={size} height={size} />
  ),
  'negative-circle-shield': size => (
    <NegativeCircleShield width={size} height={size} />
  ),
  'logo-circle': size => <LogoCircle width={size} height={size} />,
  'negative-chat-check': size => (
    <NegativeChatCheck width={size} height={size} />
  ),
  'negative-circle-shield-check': size => (
    <NegativeCircleShieldCheck width={size} height={size} />
  ),
  'chevron-down-dark': size => <ChevronDownDark width={size} height={size} />,
  'chevron-up-accent': size => <ChevronUpAccent width={size} height={size} />,
  'email-checked': size => <EmailChecked width={size} height={size} />,
  'email-checked-light': size => (
    <EmailCheckedLight width={size} height={size} />
  ),
  'id-card': size => <IdCard width={size} height={size} />,
  'stablehouse-lettermark': size => (
    <StablehouseLettermark width={size} height={size} />
  ),
  'social-twitter': size => <SocialTwitter width={size} height={size} />,
  'social-linkedin': size => <SocialLinkedIn width={size} height={size} />,
  'social-facebook': size => <SocialFacebook width={size} height={size} />,
  'social-telegram': size => <SocialTelegram width={size} height={size} />,
  send: size => <Send width={size} height={size} />,
  'negative-twitter': size => <NegativeTwitter width={size} height={size} />,
  'negative-pencil': size => <NegativePencil width={size} height={size} />,
  'negative-people-voices': size => (
    <NegativePeopleVoices width={size} height={size} />
  ),
  'negative-refer-a-friend': size => (
    <NegativeReferAFriend width={size} height={size} />
  ),
  envelope: size => <Envelope width={size} height={size} />,
  'manage-history': size => <ManageHistory width={size} height={size} />,
  telegram: size => <Telegram width={size} height={size} />,
  cross: size => <Cross width={size} height={size} />,
  'close-button': size => <CloseButtonIcon width={size} height={size} />,
  'pencil-edit': size => <PencilEdit width={size} height={size} />,
  'pencil-line': size => <PencilLine width={size} height={size} />,
  'calendar-date': size => <CalendarDate width={size} height={size} />,
  balance: size => <Balance width={size} height={size} />,
  'insights-pulse': size => <InsightsPulse width={size} height={size} />,
  'insights-update': size => <InsightsUpdate width={size} height={size} />,
  'twitter-gray': size => <TwitterGray width={size} height={size} />,
  'ios-app-store': (size, height) => (
    <IosAppStore width={size} height={height} />
  ),
  'google-app-store': (size, height) => (
    <GoogleAppStore width={size} height={height} />
  ),
  'stablehouse-footer-logo': (size, height) => (
    <StablehouseFooterLogo width={size} height={height} />
  ),
  'pof-black-shield-check': (size, height) => (
    <PofBlackShieldCheck width={size} height={height} />
  ),
  'pof-white-shield-check': (size, height) => (
    <PofWhiteShieldCheck width={size} height={height} />
  ),
  'pof-white-shield-cross': (size, height) => (
    <PofWhiteShieldCross width={size} height={height} />
  ),
  'triangle-down': (size, height) => (
    <TriangleDown width={size} height={height} />
  ),
  'solutions-custody': size => <SolutionsCustody width={size} height={size} />,
  'solutions-trading': size => <SolutionsTrading width={size} height={size} />,
  'solutions-white-label': size => (
    <SolutionsWhiteLabel width={size} height={size} />
  ),
  'client-building': size => <ClientBuilding width={size} height={size} />,
  'client-intermediaries': size => (
    <ClientIntermediaries width={size} height={size} />
  ),
  'client-investors': size => <ClientInvestors width={size} height={size} />,
  'stablehouse-lettermark-dark': size => (
    <StablehouseLettermarkDark width={size} height={size} />
  ),
  bma: size => <BmaLogo width={size} height={size} />,
  'search-input': size => <SearchInput width={size} height={size} />,
  'manage-search': size => <ManageSearch width={size} height={size} />,
  'search-off': size => <SearchOff width={size} height={size} />,
  'stacked-coins': size => <StackedCoins width={size} height={size} />,
  'key-facts-pencil': size => <KeyFactsPencil width={size} height={size} />,
  'why-relevant-pin': size => <WhyRelevantPin width={size} height={size} />,
  'chevron-back': size => <ChevronBack width={size} height={size} />,
  export: size => <ExportIcon width={size} height={size} />,
  'trading-account-arrows': size => (
    <TradingAccountArrows width={size} height={size} />
  ),
  'custody-account-vault': () => <CustodyAccountVault />,
  'holdings-info': size => <HoldingsInfo width={size} height={size} />,
  handshake: size => <Handshake width={size} height={size} />,
  refresh: size => <Refresh width={size} height={size} />,
  'circle-shield-check': size => (
    <CircleShieldCheck width={size} height={size} />
  ),
  'chevron-icon-drop-down': size => (
    <ChevronIconDropdown width={size} height={size} />
  ),
  'external-circle': size => <ExternalCircle width={size} height={size} />,
  'arrow-right': size => <ArrowRight width={size} height={size} />,
  'check-success': size => <CheckSuccess width={size} height={size} />,
  'trading-account-arrows-filled': size => (
    <TradingAccountArrowsFilled width={size} height={size} />
  ),
  'custody-account-vault-filled': size => (
    <CustodyAccountVaultFilled width={size} height={size} />
  ),
  'stablehouse-products': size => (
    <StablehouseProducts width={size} height={size} />
  ),
  'shield-plus': size => <ShieldPlus width={size} height={size} />,
  'logo-stablehouse': size => <LogoStablehouse width={size} height={size} />,
  'calendar-caret': size => <CalendarCaret width={size} height={size} />,
  filter: size => <Filter width={size} height={size} />,
  download: size => <Download width={size} height={size} />,
  'arrow-back': size => <ArrowBack width={size} height={size} />,
  'arrow-north-east': size => <ArrowNorthEast width={size} height={size} />,
  'chevron-grey-right': size => <ChevronGreyRight width={size} height={size} />,
  'visibility-show': size => <VisibilityShow width={size} height={size} />,
  'user-placeholder': (size, height) => (
    <UserPlaceholder width={size} height={height} />
  ),
  'markets-graph': (size, height) => (
    <MarketsGraph width={size} height={height} />
  ),
  'graph-line': (size, height) => <GraphLine width={size} height={height} />,
  statement: (size, height) => <Statement width={size} height={height} />,
  invoice: (size, height) => <Invoice width={size} height={height} />,
  settings: (size, height) => <Settings width={size} height={height} />,
  'friend-referral': (size, height) => (
    <FriendReferral width={size} height={height} />
  ),
  approvals: (size, height) => <Approvals width={size} height={height} />,
  'sign-out': (size, height) => <SignOut width={size} height={height} />,
  person: (size, height) => <Person width={size} height={height} />,
  'stablehouse-logo': (size, height) => (
    <StablehouseLogo width={size} height={height} />
  ),
  candlestick: (size, height) => <Candlestick width={size} height={height} />,
  lifebuoy: (size, height) => <LifeBuoy width={size} height={height} />,
  'graduation-cap': (size, height) => (
    <GraduationCap width={size} height={height} />
  ),
  'custody-account-vault-filled-solid': size => (
    <CustodyAccountVaultFilledSolid width={size} height={size} />
  ),
  'stablehouse-telegram-logo': size => (
    <StablehouseTelegramLogo width={size} height={size} />
  ),
  'xbto-logo-icon': size => <XBTOLogoIcon width={size} height={size} />,
  'arrow-external': size => <ArrowExternal width={size} height={size} />,
  're-order': size => <ReOrder width={size} height={size} />,
  'logo-xbtohub-black': size => <LogoXbtoHubBlack width={size} height={size} />,
  'logo-xbtohub-white': size => <LogoXbtoHubWhite width={size} height={size} />,
  'logo-xbto-powered-by-stablehouse': size => (
    <LogoXbtoPwrdByStablehouse width={size} height={size} />
  ),
  'logo-xbtohub-powered-by-stablehouse-stacked': size => (
    <LogoXbtoHubPwrdByStablehouseStacked width={size} height={size} />
  ),
  'logo-powered-by-stablehouse-stacked': size => (
    <LogoPwrdByStablehouseStacked width={size} height={size} />
  ),
  'british-flag': size => <BritishFlag width={size} height={size} />,
  'check-info': size => <CheckInfo width={size} height={size} />,
  'market-trends': size => <MarketTrends width={size} height={size} />,
  'rj-app-logo-header': size => <RjAppLogoHeader width={size} height={size} />,
  'rj-app-logo-footer': size => <RjAppLogoFooter width={size} height={size} />,
  'apex-app-logo-header': size => (
    <ApexAppLogoHeader width={size} height={size} />
  ),
  dots: size => <Dots width={size} height={size} />,
  'in-progress': size => <InProgress width={size} height={size} />,
  'blank-icon': size => <BlankIcon width={size} height={size} />,
  'chevron-down': size => <ChevronDown width={size} height={size} />,
  'asset-wUSDM': size => <AssetwUSDM width={size} height={size} />,
  'logo-xbto-round': size => <LogoXbtoRound width={size} height={size} />,
  'chevron-right-alt': size => <ChevronRightAlt width={size} height={size} />,
  'lock-clock': size => <LockClock width={size} height={size} />,
  'graph-area': size => <GraphArea width={size} height={size} />,
  'graph-candlestick': size => <GraphCandlestick width={size} height={size} />,
  'shield-security': size => <ShieldSecurity width={size} height={size} />,
};
