import { FC, ReactNode } from 'react';
import cx from 'classnames';
import { AppLoader } from '../../components/app-loader';
import { AppStore, DataStore } from '../../store';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import {
  AccountLevelSettings,
  AppTenant,
  DecodedToken,
  GlobalAppSettings,
  useComputedConfig,
  AppContainer,
  ClientUserType,
  SelectInput,
  BaseComponentProps,
} from 'common';

export enum SettingsMenuIds {
  DETAILS = 'details',
  SECURITY = 'security',
  NOTIFICATIONS = 'notifications',
  PAYMENT_METHODS = 'payment_methods',
  CRYPTO_ADDRESSES = 'crypto_addresses',
  REFERRALS = 'referrals',
  REWARDS = 'rewards',
  APIKEYS = 'apikeys',
  ACCOUNT_LABELS = 'account_labels',
}

interface MenuItemProps {
  active?: boolean;
  disabled?: boolean;
  id: string;
  title: string;
  uri: string;
  tenant?: AppTenant;
  condition?: (
    token: DecodedToken,
    globalSettings: GlobalAppSettings | null,
    accountLevelSettings: AccountLevelSettings | null,
    clientUserType?: ClientUserType | null
  ) => boolean;
}

export const SETTINGS_MENU: readonly MenuItemProps[] = [
  {
    id: SettingsMenuIds.DETAILS,
    title: 'My details',
    uri: '/settings/details',
  },
  {
    id: SettingsMenuIds.SECURITY,
    title: 'Security',
    uri: '/settings/security',
  },
  {
    id: SettingsMenuIds.NOTIFICATIONS,
    title: 'Email notifications',
    uri: '/settings/email-notifications',
  },
  {
    id: SettingsMenuIds.CRYPTO_ADDRESSES,
    title: 'Address book',
    uri: '/settings/crypto-addresses',
    condition: (
      _token,
      _globalAppSettings,
      _accountSettings,
      clientUserType
    ) => {
      // Note: do not show this menu for any Apex user!
      return clientUserType !== 'admin' && clientUserType !== 'fund-manager';
    },
  },
  {
    id: SettingsMenuIds.ACCOUNT_LABELS,
    title: 'Account labels',
    uri: '/settings/account-labels',
    condition: (
      _token,
      _globalAppSettings,
      _accountLevelSettings,
      clientUserType
    ) => {
      return clientUserType !== 'admin';
    },
  },
  {
    id: SettingsMenuIds.REWARDS,
    title: 'Rewards',
    uri: '/settings/rewards-history',
    condition: (_token, globalAppSettings) => {
      return !!globalAppSettings?.allowReferralRewards;
    },
  },
  {
    id: SettingsMenuIds.PAYMENT_METHODS,
    title: 'Bank accounts',
    uri: '/settings/bank-accounts',
    condition: (_token, _globalAppSettings, _accountSettings, clientUserType) =>
      clientUserType !== 'admin',
  },
  {
    id: SettingsMenuIds.APIKEYS,
    title: 'API Keys',
    uri: '/settings/api-keys',
    condition: (_token, globalAppSettings) => {
      return !!globalAppSettings?.allowApiKeys;
    },
  },
];

const MenuItem: FC<MenuItemProps> = ({
  active = false,
  disabled = false,
  title,
  uri,
}) => {
  const content = (
    <span
      className={cx(
        'px-4 py-3 rounded-4 cursor-pointer mb-1.5 text-md flex items-center justify-between border border-grey-bright',
        {
          'button-settings-menu-container-active': active && !disabled,
        },
        { 'bg-white hover:bg-black hover:text-white': !active && !disabled },
        { 'bg-white cursor-not-allowed opacity-50': disabled }
      )}
      style={{
        height: 60,
      }}
    >
      <span
        className={cx('items-center justify-center inline-block', {
          'button-settings-menu-inner-active': active && !disabled,
        })}
      >
        <span className="font-semibold">{title}</span>
      </span>
      <ArrowRightIcon
        className={cx('w-4 h-4 ml-4', {
          'opacity-0': !active,
        })}
      />
    </span>
  );

  if (disabled) {
    return content;
  }

  return <Link to={uri}>{content}</Link>;
};

const renderSelectorItem = (
  item: MenuItemProps,
  selected: boolean,
  active: boolean
) => {
  return (
    <div
      className={cx(
        'flex justify-between items-center px-3 py-1',
        { 'bg-grey-bright': selected },
        { 'hover-bg-grey-brighter': active }
      )}
    >
      {item.title}
    </div>
  );
};

interface LayoutProps extends BaseComponentProps {
  menuId: string;
  hasPaddings?: boolean;
  header?: string | ReactNode;
}

export const SettingsLayout: FC<LayoutProps> = ({
  children,
  menuId,
  hasPaddings = true,
  header,
}) => {
  /**
   * Store
   */
  const busy = DataStore.useStoreState(s => s.busy);
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);
  const globalAppSettings = DataStore.useStoreState(
    s => s.settings.globalAppSettings
  );
  const accountLevelSetttings = DataStore.useStoreState(
    s => s.portfolio.accountDetail?.settings || null
  );
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);
  const dashboardSelectedDialogType = AppStore.useStoreState(
    s => s.dashboardSelectedDialogType
  );

  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { tenant } = useComputedConfig();

  /**
   * DOM
   */
  return (
    <>
      {/* busy  */}
      {busy && !dashboardSelectedDialogType && (
        <AppLoader isFixed spinnerTop="104px" />
      )}

      {/* content  */}
      <AppContainer wrapperCls="p-6" cls="" fullHeight>
        {/* settings tabs  */}
        {/* content  */}
        <div className="">
          {/* page title  */}
          <p className="text-2xl font-bold text-primary mb-6">Settings</p>
          <div className="flex flex-col lg:flex-row">
            {/* left col  */}
            <div
              className="mb-6 lg:mb-0"
              style={{
                minWidth: 278, // Note: design requirement
              }}
            >
              <SelectInput
                cls="block md:hidden bg-black text-white dark"
                value={menuId}
                values={SETTINGS_MENU.filter(i =>
                  i.condition
                    ? i.condition(
                        decodedToken!,
                        globalAppSettings,
                        accountLevelSetttings,
                        clientUserType
                      )
                    : true
                )}
                getItemValue={(item: MenuItemProps) => item.id}
                getSelectedItemTemplate={(activeId: string) => {
                  const menuItem = SETTINGS_MENU.find(
                    ({ id }) => id === activeId
                  );

                  if (menuItem) {
                    return <p>{menuItem.title}</p>;
                  }

                  return null;
                }}
                getItemTemplate={renderSelectorItem}
                onChange={(newId: string) => {
                  const menuItem = SETTINGS_MENU.find(({ id }) => id === newId);

                  if (menuItem && menuItem.uri) {
                    console.log(menuItem.uri);
                    navigate(menuItem.uri, {
                      state: menuItem.title,
                    });
                  }
                }}
              />
              <div className="hidden md:block">
                {SETTINGS_MENU.filter(i =>
                  i.condition
                    ? i.condition(
                        decodedToken!,
                        globalAppSettings,
                        accountLevelSetttings,
                        clientUserType
                      )
                    : true
                ).map(item => (
                  <MenuItem
                    {...item}
                    key={item.id}
                    active={item.id === menuId}
                    tenant={tenant}
                  />
                ))}
              </div>
            </div>
            {/* right col  */}
            <div className="flex-1 h-fit bg-white border border-grey-bright rounded-4 lg:ml-6">
              {header && (
                <>
                  <div className="flex flex-col justify-center py-0.5 h-20">
                    <div className="font-bold typo-20 px-4 sm:px-8 py-4">
                      {header}
                    </div>
                  </div>
                  <hr className="bg-grey-bright" />
                </>
              )}
              <div
                className={cx({
                  'py-4 px-4 sm:px-8': hasPaddings,
                })}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </>
  );
};
