import { API } from 'api';
import { formatters } from './formatters';
import { EnrichedCurrencyInformation } from '../types';

export function getRateLabel(
  baseDisplayCode: string,
  rateValue: number,
  quoteCurrency: EnrichedCurrencyInformation
) {
  if (!quoteCurrency.code) {
    return '';
  }

  const quoteDisplayCode = quoteCurrency.displayCode ?? quoteCurrency.code;

  const formattedRateValue = formatters.getCurrency(
    rateValue,
    undefined,
    quoteDisplayCode,
    [],
    true
  );
  return `1 ${baseDisplayCode} = ${formattedRateValue}`;
}

export function getRateValue(
  rateCode: string,
  rateSymbol: string,
  rate: string,
  rateContra: string
) {
  // Ex:
  // - symbol = 'BTC/USD'
  // - rate = 20,000
  // - rateContra = 0.00005

  // If `rateCode = 'USD'` (very usual: "1 BTC  = $24,034")
  //  - We take `rate` if `symbol = 'BTC/USD'`
  //  - We take `rateContra` if `symbol = 'USD/BTC'`

  const [, symbolTo] = rateSymbol.split('/');

  return Number(rateCode === symbolTo ? rate : rateContra);
}

export function getTradeRateLabel(
  fxDetails: API.FxTradeDetails,
  currencies: EnrichedCurrencyInformation[]
) {
  const baseCurrency = currencies.find(c => c.code === fxDetails.baseCurrency);
  const quoteCurrency = currencies.find(
    c => c.code === fxDetails.quoteCurrency
  );

  if (!baseCurrency?.displayCode || !quoteCurrency) {
    return '';
  }

  return getRateLabel(
    baseCurrency.displayCode,
    Number(fxDetails.rate),
    quoteCurrency
  );
}

export function getSimulationRateLabel(
  simulation: API.TradeSimulation,
  quoteCode: string,
  currencies: EnrichedCurrencyInformation[]
) {
  if (!simulation.fxSymbol || !simulation.fxRate || !simulation.fxRateContra) {
    return '';
  }

  const rateValue = getRateValue(
    quoteCode,
    simulation.fxSymbol,
    simulation.fxRate,
    simulation.fxRateContra
  );

  const baseCode =
    quoteCode === simulation.currencyFrom
      ? simulation.currencyTo
      : simulation.currencyFrom;

  const baseCurrency = currencies.find(c => c.code === baseCode);
  const quoteCurrency = currencies.find(c => c.code === quoteCode);

  if (!baseCurrency?.displayCode || !quoteCurrency) {
    return '';
  }

  return getRateLabel(baseCurrency.displayCode, rateValue, quoteCurrency);
}
