import React, { FC } from 'react';
import cx from 'classnames';
import { Error } from '../../common/error';
import { BaseComponentProps } from 'common';

interface FormFooterProps extends BaseComponentProps {
  error?: string | null;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  submitCls?: string;
  cancelText?: string;
  submitText?: string;
  isSubmitDisabled?: boolean;
}

export const FormFooter: FC<FormFooterProps> = ({
  error = null,
  onCancel,
  onSubmit,
  submitCls = 'app-button-primary',
  cancelText = 'Cancel',
  submitText = 'Confirm',
  className,
  isSubmitDisabled = false,
  children,
}) => {
  /**
   * DOM
   */
  return (
    <div>
      {/* api error  */}
      <Error message={error} cls="mx-6 md:mx-10" />

      <div
        className={cx(
          'flex flex-col border-t border-grey-bright bg-grey-brighter rounded-b mt-8 py-6 px-6 md:px-10',
          className
        )}
      >
        {children}

        {/* preview conversion button  */}
        <div className="flex flex-col gap-y-4 gap-x-3 sm:flex-row">
          {/* cancel btn  */}
          {!!onCancel && (
            <button
              className="app-button-outline w-full flex-1"
              onClick={onCancel}
            >
              {cancelText}
            </button>
          )}
          {/* confirm btn */}
          {!!onSubmit && (
            <button
              data-testid="form-footer-submit"
              role="button"
              disabled={isSubmitDisabled}
              type="button"
              className={cx('w-full flex-1', submitCls)}
              onClick={onSubmit}
            >
              {submitText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
