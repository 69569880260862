import { API } from 'api';
import {
  Balances,
  EnrichedAccountDetail,
  EnrichedAccountDetailAsset,
} from '../../types';
import { formatAmountWithDecimals } from '../format-amount';
import { formatMoney } from '../format-money';
import { DEFAULTS } from '../../constants';

type BalancesData = {
  total: string;
  pendingSettlement: number;
  pendingIncoming: number;
  pendingReceive: number;
  pendingTransferIn: number;
  pendingOutgoing: number;
  pendingSend: number;
  pendingTransferOut: number;
  openOrders: number;
  tradable: number | undefined;
  withdrawable: string;
};

type CurrencyLike = {
  displayCode: string;
  decimals: number;
};

function createBalances(
  currency: CurrencyLike,
  hasPendingBalance: boolean,
  {
    total,
    pendingSettlement,
    pendingIncoming,
    pendingReceive,
    pendingTransferIn,
    pendingOutgoing,
    pendingSend,
    pendingTransferOut,
    openOrders,
    tradable,
    withdrawable,
  }: BalancesData
): Balances {
  const formattedTotal = formatAmountWithDecimals(total.toString());
  return {
    total,
    hasPendingBalance,
    formattedTotal,
    formattedTotalWithCurrencyCode: `${formattedTotal} ${currency.displayCode}`,
    //   formatMoney(
    //   total.toString(),
    //   currency.displayCode,
    //   currency.decimals
    // ),
    pendingSettlement,
    formattedPendingSettlement: `${
      pendingSettlement > 0 ? '-' : ''
    }${formatMoney(
      pendingSettlement.toString(),
      currency.displayCode,
      currency.decimals
    )}`,
    pendingIncoming,
    formattedPendingIncoming: `${pendingIncoming > 0 ? '-' : ''}${formatMoney(
      pendingIncoming.toString(),
      currency.displayCode,
      currency.decimals
    )}`,
    pendingReceive,
    formattedPendingReceive: formatMoney(
      pendingReceive.toString(),
      currency.displayCode,
      currency.decimals
    ),
    pendingTransferIn,
    formattedPendingTransferIn: formatMoney(
      pendingTransferIn.toString(),
      currency.displayCode,
      currency.decimals
    ),
    pendingOutgoing,
    formattedPendingOutgoing: `${pendingOutgoing > 0 ? '-' : ''}${formatMoney(
      pendingOutgoing.toString(),
      currency.displayCode,
      currency.decimals
    )}`,
    pendingSend,
    formattedPendingSend: formatMoney(
      pendingSend.toString(),
      currency.displayCode,
      currency.decimals
    ),
    pendingTransferOut,
    formattedPendingTransferOut: formatMoney(
      pendingTransferOut.toString(),
      currency.displayCode,
      currency.decimals
    ),
    openOrders,
    formattedOpenOrders: `${openOrders > 0 ? '-' : ''}${formatMoney(
      openOrders.toString(),
      currency.displayCode,
      currency.decimals
    )}`,
    tradable,
    formattedTradable:
      typeof tradable === 'number'
        ? `${formatAmountWithDecimals(tradable.toString())} ${currency.displayCode}`
        : undefined,
    withdrawable,
    formattedWithdrawable: `${formatAmountWithDecimals(withdrawable.toString())} ${currency.displayCode}`,
    // formatMoney(
    //   withdrawable.toString(),
    //   currency.displayCode,
    //   currency.decimals
    // ),
  };
}

export function createBalancesByAsset(
  asset: EnrichedAccountDetailAsset
): Balances {
  return createBalances(asset.currency, asset.hasPendingBalance, {
    total: asset.quantityIncludingPending ?? '0',
    pendingSettlement: Number(asset.pendingSettlementQuantity ?? '0'),
    pendingIncoming: Number(asset.pendingIncomingQuantity ?? '0'),
    pendingReceive: Number(asset.pendingReceiveQuantity ?? '0'),
    pendingTransferIn: Number(asset.pendingTransferInQuantity ?? '0'),
    pendingOutgoing: Number(asset.pendingOutgoingQuantity ?? '0'),
    pendingSend: Number(asset.pendingSendQuantity ?? '0'),
    pendingTransferOut: Number(asset.pendingTransferOutQuantity ?? '0'),
    openOrders: Number(asset.openOrdersQuantity ?? '0'),
    tradable: Number(asset.tradableQuantityDecimal ?? '0'),
    withdrawable: asset.withdrawableQuantity ?? '0',
  });
}

export function createBalancesByListAccounts(data: API.ListAccounts): Balances {
  return createBalances(
    { displayCode: 'USD', decimals: DEFAULTS.DECIMAL_SCALE },
    data.hasPendingBalance,
    {
      total: data.totalPortfolioBalanceUsd ?? '0',
      pendingSettlement: Number(data.pendingSettlementBalanceUsd ?? '0'),
      pendingIncoming: Number(data.pendingIncomingBalanceUsd ?? '0'),
      pendingReceive: Number(data.pendingReceiveBalanceUsd ?? '0'),
      pendingTransferIn: Number(data.pendingTransferInBalanceUsd ?? '0'),
      pendingOutgoing: Number(data.pendingOutgoingBalanceUsd ?? '0'),
      pendingSend: Number(data.pendingSendBalanceUsd ?? '0'),
      pendingTransferOut: Number(data.pendingTransferOutBalanceUsd ?? '0'),
      openOrders: Number(data.openOrdersBalanceUsd ?? '0'),
      tradable: Number(data.tradableBalanceUsd ?? '0'),
      withdrawable: data.withdrawableBalanceUsd ?? '0',
    }
  );
}

export function createBalancesByAccount(
  accountDetail: EnrichedAccountDetail
): Balances {
  return createBalances(
    { displayCode: 'USD', decimals: DEFAULTS.DECIMAL_SCALE },
    accountDetail.hasPendingBalance,
    {
      total: accountDetail.totalBalanceIncludingPendingUsd || '0',
      pendingSettlement: accountDetail.totalPendingSettlementUsdDecimal,
      pendingIncoming: accountDetail.totalPendingIncomingUsdDecimal,
      pendingReceive: accountDetail.totalPendingReceiveUsdDecimal,
      pendingTransferIn: accountDetail.totalPendingIncomingTransferInUsdDecimal,
      pendingOutgoing: accountDetail.totalPendingOutgoingUsdDecimal,
      pendingSend: accountDetail.totalPendingSendUsdDecimal,
      pendingTransferOut:
        accountDetail.totalPendingOutgoingTransferOutUsdDecimal,
      openOrders: accountDetail.totalOpenOrdersUsdDecimal,
      tradable: accountDetail.isAccountOfTypeVault
        ? undefined
        : accountDetail.totalTradableUsdDecimal,
      withdrawable: accountDetail.totalWithdrawableUsd || '0',
    }
  );
}
