import { MouseEvent, useCallback } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { AppIcon, TOAST_OPTIONS } from 'common';
import { DataStore } from '../../store';
import { saveBlobAsFile } from '~/utils/save-blob-as-file';
import { API } from 'api';
import { useParams } from 'react-router-dom';

interface Props {
  hasData: boolean;
  showRefresh?: boolean;
  handleFilters?: (value: boolean) => void;
  handleRefresh?: (e: React.MouseEvent) => void;
  account: API.AccountProperties | null | undefined;
}

export const ExportCsv = ({
  account,
  hasData,
  showRefresh = false,
  handleFilters,
  handleRefresh,
}: Props) => {
  if (!account) return null;
  /**
   * Store & data
   */
  const getActivitiesCsv = DataStore.useStoreActions(
    a => a.transactions.getActivitiesCsv
  );
  const filterCount = DataStore.useStoreState(a => a.transactions.filterCount);

  /**
   * Methods
   */

  const { id, fundAccountId } = useParams();
  const getStatement = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      const { isSuccessful, result, errorMessage } = await getActivitiesCsv({
        accountId: id || account.accountId,
        impersonatedAccountId: fundAccountId,
      });
      if (!isSuccessful || !result) {
        toast.warn(errorMessage, TOAST_OPTIONS as ToastOptions);
        return;
      }
      saveBlobAsFile(result.blob, result.filename || 'xbto-transactions.csv');
    },
    [id, account.accountId, fundAccountId]
  );

  /**
   * DOM
   */
  return (
    <div className="flex px-4 md:px-8 py-4 justify-between rounded-t-4 border-1 border-b-0 border-grey-bright bg-white items-center">
      <span>&nbsp;</span>
      <span className="flex flex-row justify-between w-full gap-6">
        {showRefresh && handleRefresh && (
          <button
            className="app-button-outline gap-2 flex flex-row items-center"
            onClick={handleRefresh}
          >
            <span className="font-bold text-sm hidden md:block">Refresh</span>{' '}
            <AppIcon icon="refresh" bg="transparent" size={16} />
          </button>
        )}
        <span className="flex flex-row justify-between sm:justify-end gap-6">
          {handleFilters && (
            <button
              type="button"
              className="app-button-outline flex gap-x-1.5"
              onClick={() => handleFilters(true)}
            >
              <span className="font-bold text-sm hidden md:block">Filter</span>
              {filterCount > 0 ? (
                <span className="w-7 p-1 font-bold text-sm bg-yellow-300 rounded-full">
                  {filterCount}
                </span>
              ) : (
                <AppIcon icon="filter" bg="transparent" size={16} />
              )}
            </button>
          )}
          <button
            type="button"
            disabled={!hasData}
            onClick={getStatement}
            className="app-button-outline flex gap-x-1.5"
          >
            <span className="font-bold md:text-sm text-sm hidden md:block">
              Export CSV
            </span>
            <AppIcon icon="export" bg="transparent" size={16} />
          </button>
        </span>
      </span>
    </div>
  );
};
