export const saveBlobAsFile = (data: Blob, fileName: string) => {
  const url = URL.createObjectURL(data);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  requestAnimationFrame(() => {
    a.remove();
    URL.revokeObjectURL(url);
  });
};
