import { FormHeader } from 'common';
import { AppTooltip } from '~/components/app-tooltip';

export const FeeInfoTooltip = ({
  title = '',
  tooltipWidth = 350,
  content = 'The fees listed incorporate both the network fee levied by the blockchain and the fee imposed by Stablehouse.',
  ...rest
}) => {
  return (
    <AppTooltip
      {...rest}
      effect="float"
      tooltipWidth={tooltipWidth}
      contentCls="px-3.5 py-2.5 text-gray-400"
      cls="flex ml-1 items-center justify-center"
      content={
        <>
          {!!title && (
            <FormHeader
              title={title}
              titleCls="text-sm text-primary mb-2"
              marginCls=""
            />
          )}
          <span className="text-sm text-grey-darker">{content}</span>
        </>
      }
    />
  );
};
