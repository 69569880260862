import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '../../../store';
import { WithdrawCashSteps } from './steps';
import { ConfirmCancel } from '../shared/confirm-cancel';

export const ConfirmCancelWithdrawCash: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  /* Store */
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const withdrawal = DataStore.useStoreState(s => s.withdrawCash.withdrawal);
  const cancelFiatWithdrawal = DataStore.useStoreActions(
    a => a.transactions.cancelFiatWithdrawal
  );

  if (!goToNamedStep) {
    return null;
  }

  /*
   * Handlers
   */
  const handleCancel = async () => {
    if (withdrawal?.canCancel && withdrawal?.id) {
      const response = await cancelFiatWithdrawal({
        request: {
          id: withdrawal.id,
        },
        accountId: accountDetail?.account?.accountId || '',
      });
      if (response.isSuccessful) {
        goToNamedStep(WithdrawCashSteps.Cancelled);
      }
    }
  };
  const handleNoActionClick = () => {
    goToNamedStep(WithdrawCashSteps.Summary);
  };

  /**
   * DOM
   */
  return (
    <ConfirmCancel
      noActionText="Continue"
      onNoActionClick={handleNoActionClick}
      onSubmitClick={handleCancel}
    />
  );
};
