import { FC, useEffect } from 'react';
import StepWizard from 'react-step-wizard';
import { Form } from './form';
import { Summary } from './summary';
import { defaultStepWizardProps } from '../../../constants';
import { DataStore } from '../../../store';
import { AddCryptoAddressSteps } from './steps';
import { AccountLocked } from '../shared/account-locked';
import { Validate2FA } from './validate-2fa';
import { Intro } from './intro';
import { Code } from './code';
import { ConfirmCancelAddCryptoAddress } from './confirm-cancel';
import { CancelledRequest } from './cancelled-request';

interface AddCryptoAddressFlowProps extends AddCryptoCurrencyCodeProps {
  onClose: () => void;
  showIntro?: boolean;
  onAddressAdded: () => void;
}

export interface AddCryptoCurrencyCodeProps {
  currencyCode?: string | null;
}

const widgetContent = ({
  onClose,
  showIntro,
  currencyCode,
  onAddressAdded,
}: AddCryptoAddressFlowProps) => {
  const baseNav = [
    <Form
      key={`Form`}
      onClose={onClose}
      currencyCode={currencyCode}
      stepName={AddCryptoAddressSteps.Form}
    />,
    <Validate2FA
      key={`Validate2FA`}
      stepName={AddCryptoAddressSteps.Validate2FA}
    />,
    <Code
      key={`Code`}
      onAddressAdded={onAddressAdded}
      stepName={AddCryptoAddressSteps.Code}
    />,
    <ConfirmCancelAddCryptoAddress
      key={`ConfirmCancelAddCryptoAddress`}
      stepName={AddCryptoAddressSteps.ConfirmCancellation}
    />,
    <CancelledRequest
      key={`CancelledRequest`}
      stepName={AddCryptoAddressSteps.Cancelled}
      onBack={onClose}
    />,
    <Summary
      key={`Summary`}
      onClose={onClose}
      stepName={AddCryptoAddressSteps.Summary}
    />,
    <AccountLocked
      key={`AccountLocked`}
      stepName={AddCryptoAddressSteps.AccountLocked}
    />,
  ];
  if (showIntro)
    baseNav.unshift(
      <Intro key={`Intro`} stepName={AddCryptoAddressSteps.Intro} />
    );

  return baseNav;
};

export const AddCryptoAddressFlow: FC<AddCryptoAddressFlowProps> = ({
  onClose,
  showIntro,
  currencyCode,
  onAddressAdded,
}) => {
  /**
   * Store
   */
  const setError = DataStore.useStoreActions(a => a.setError);
  const setFormValues = DataStore.useStoreActions(
    a => a.settings.cryptoAddresses.setFormValues
  );

  /**
   * Hooks
   */
  useEffect(() => {
    return () => {
      console.log(`Add Crypto Address dialog => reset`);
      setFormValues(null);
    };
  }, []);

  /**
   * DOM
   */
  return (
    <div className="relative text-primary h-full">
      {/* wizard  */}
      <StepWizard
        {...defaultStepWizardProps}
        className="h-full"
        onStepChange={() => setError(null)}
      >
        {widgetContent({
          onClose,
          showIntro,
          currencyCode,
          onAddressAdded,
        })}
      </StepWizard>
    </div>
  );
};
