import { API } from 'api';
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { API_HTTP_CODES, ERROR_MESSAGES } from '../constants';
import { EnrichedCryptoAddress, EnrichedCurrencyInformation } from '../types';
import {
  AddAddressFormikProps,
  getBlockchainNetworkName,
  getCurrencyInfoForCode,
  runApi,
} from '../utils';
import { ApiError } from './api-error';
import { DataModel } from './data-store';
import { AdditionalOptionsXHR, Injections, ApiThunk } from './types';
import { BaseModel, createBaseModel } from './base-store';

export type CreateWithdrawalAddressOperationPayload = {
  request: API.CreateWithdrawalAddressRequest;
} & AdditionalOptionsXHR;

export type DeleteWithdrawalAddressOperationPayload = {
  request: API.DeleteWithdrawalAddressRequest;
} & AdditionalOptionsXHR;

export type ValidateAddressResponseProps = API.ValidateAddressResponse & {
  variant: 'error' | 'warning' | 'success' | null;
  message: string;
};
export interface CryptoAddressesModel extends BaseModel {
  // state
  _withdrawalAddresses: API.WithdrawalAddress[];
  _withdrawalAddress: API.WithdrawalAddress | null;
  formValues: AddAddressFormikProps | null;
  currentAddress: API.WithdrawalAddress | null;
  validatedAddress: ValidateAddressResponseProps | null;

  // computed
  allowedCurrencies: Computed<
    CryptoAddressesModel,
    EnrichedCurrencyInformation[],
    DataModel
  >;
  verifiedWithdrawalAddresses: Computed<
    CryptoAddressesModel,
    EnrichedCryptoAddress[],
    DataModel
  >;
  withdrawalAddresses: Computed<
    CryptoAddressesModel,
    EnrichedCryptoAddress[],
    DataModel
  >;

  withdrawalAddress: Computed<
    CryptoAddressesModel,
    EnrichedCryptoAddress | null,
    DataModel
  >;

  // actions
  _setWithdrawalAddresses: Action<
    CryptoAddressesModel,
    API.WithdrawalAddress[]
  >;
  _setWithdrawalAddress: Action<
    CryptoAddressesModel,
    API.WithdrawalAddress | null
  >;
  setValidatedAddress: Action<
    CryptoAddressesModel,
    API.ValidateAddressResponse | null
  >;
  setFormValues: Action<CryptoAddressesModel, AddAddressFormikProps | null>;
  setCurrentAddress: Action<CryptoAddressesModel, API.WithdrawalAddress | null>;

  // thunk
  resetState: Thunk<CryptoAddressesModel, undefined, Injections, DataModel>;
  createWithdrawalAddress: ApiThunk<
    CryptoAddressesModel,
    string | undefined,
    API.WithdrawalAddress | API.CreateQuorumOperationApiResponse
  >;
  cancelWithdrawalAddress: ApiThunk<CryptoAddressesModel, string>;
  deleteWithdrawalAddress: ApiThunk<CryptoAddressesModel, string>;
  createWithdrawalAddressOperation: ApiThunk<
    CryptoAddressesModel,
    {
      accountId: string;
      impersonatedAccountId?: string;
    },
    API.CreateQuorumOperationApiResponse
  >;
  deleteWithdrawalAddressOperation: ApiThunk<
    CryptoAddressesModel,
    { id: string; accountId: string; impersonatedAccountId: string },
    unknown
  >;
  getWithdrawalAddresses: ApiThunk<
    CryptoAddressesModel,
    {
      accountId: string | undefined;
      impersonatedAccountId?: string;
    },
    API.WithdrawalAddress[]
  >;
  getWithdrawalAddress: ApiThunk<
    CryptoAddressesModel,
    string,
    API.WithdrawalAddress
  >;
  confirmCode: ApiThunk<CryptoAddressesModel, string>;
  resendCode: ApiThunk<CryptoAddressesModel, undefined>;
  validateAddress: ApiThunk<
    CryptoAddressesModel,
    API.ValidateAddressRequest & { silent?: boolean },
    API.ValidateAddressResponse
  >;
}

export const cryptoAddressesModel: CryptoAddressesModel = {
  ...createBaseModel(),

  // state
  _withdrawalAddresses: [],
  _withdrawalAddress: null,
  formValues: null,
  currentAddress: null,
  validatedAddress: null,
  // computed
  allowedCurrencies: computed(
    [(_state, storeState) => storeState.metaData.currencies],
    currencies => {
      if (!currencies || !currencies.length) {
        return [];
      }
      return currencies.filter(
        _currency =>
          _currency.withdrawalsAllowed &&
          !_currency.hidden &&
          !_currency.isAssetOfTypeFiat &&
          !_currency.isAssetOfTypeFund
      );
    }
  ),
  withdrawalAddresses: computed(
    [
      (_state, storeState) => storeState.metaData.currencies,
      state => state._withdrawalAddresses,
    ],
    (currencies, _withdrawalAddresses) => {
      return _withdrawalAddresses.map(_address => {
        const currency = getCurrencyInfoForCode(
          _address.currencyCode,
          currencies
        );
        return {
          ..._address,
          blockchain: currency?.blockchain || '',
          network: getBlockchainNetworkName(currency?.blockchain) || '',
        };
      });
    }
  ),
  verifiedWithdrawalAddresses: computed(
    [state => state.withdrawalAddresses],
    addresses => {
      return addresses.filter(a => !a.isVerifying);
    }
  ),

  withdrawalAddress: computed(
    [
      (_state, storeState) => storeState.metaData.currencies,
      state => state._withdrawalAddress,
    ],
    (currencies, address) => {
      if (!address) {
        return null;
      }
      const currency = getCurrencyInfoForCode(address.currencyCode, currencies);
      return {
        ...address,
        blockchain: currency?.blockchain || '',
        network: getBlockchainNetworkName(currency?.blockchain) || '',
      };
    }
  ),
  // actions
  setFormValues: action((state, payload) => {
    state.error = null;

    if (payload === null) {
      state.formValues = null;
    } else {
      state.formValues = {
        ...(state.formValues ?? {}),
        ...payload,
      };
    }
  }),
  _setWithdrawalAddresses: action((state, payload) => {
    state._withdrawalAddresses = payload;
  }),
  _setWithdrawalAddress: action((state, payload) => {
    state._withdrawalAddress = payload;
  }),
  setValidatedAddress: action((state, payload) => {
    if (!payload) {
      return;
    }

    let variant: 'error' | 'warning' | 'success' | null = null;
    let message = '';

    if (!payload.isValid && !payload.normalized) {
      variant = 'error';
      message = `Address entered is invalid`;
    }
    if (payload.isValid && !payload.normalized) {
      variant = 'success';
      message = `Address has a valid checksum`;
    }
    if (payload.isValid && payload.normalized) {
      variant = 'warning';
      message = `Address doesn’t have a checksum`;
    }

    state.validatedAddress = {
      ...payload,
      variant,
      message,
    };
  }),
  setCurrentAddress: action((state, payload) => {
    state.currentAddress = payload;
  }),
  // thunk
  resetState: thunk((actions, _payload, { getStoreActions }) => {
    const storeActions = getStoreActions();
    storeActions.setError(null);
    actions.setFormValues(null);
    actions.setCurrentAddress(null);
  }),
  createWithdrawalAddress: thunk(async (actions, code, helpers) => {
    const storeState = helpers.getStoreState();
    const { formValues } = helpers.getState();

    return runApi(
      actions,
      helpers,
      () => {
        if (
          !formValues?.label ||
          !formValues?.currency ||
          !formValues?.walletAddress
        ) {
          throw new ApiError(
            API_HTTP_CODES.UNPROCESSABLE_ENTITY,
            ERROR_MESSAGES.UNPROCESSABLE_ENTITY,
            -1
          );
        }

        const request: API.CreateWithdrawalAddressRequest = {
          withdrawalAddress: {
            label: formValues.label,
            address: formValues.walletAddress,
            currencyCode: formValues.currency.code,
          },
          appAuthenticatorCode: typeof code === 'string' ? code : null,
        };
        return helpers.injections.apiClient.createWithdrawalAddress(request);
      },
      result => {
        actions.setCurrentAddress(result);
      },
      {
        'x-account-id': storeState.user.decodedTokenAccountId,
      }
    );
  }),
  createWithdrawalAddressOperation: thunk(
    async (actions, { accountId, impersonatedAccountId }, helpers) => {
      return runApi<API.CreateQuorumOperationApiResponse>(
        actions,
        helpers,
        async () => {
          const storeActions = helpers.getStoreActions();
          storeActions.setBusy(true);
          storeActions.setError(null);

          const { formValues } = helpers.getState();
          if (
            !formValues?.label ||
            !formValues?.currency ||
            !formValues?.walletAddress
          ) {
            throw new ApiError(
              API_HTTP_CODES.UNPROCESSABLE_ENTITY,
              ERROR_MESSAGES.UNPROCESSABLE_ENTITY,
              -1
            );
          }

          const request: API.CreateWithdrawalAddressRequest = {
            withdrawalAddress: {
              label: formValues.label,
              address: formValues.walletAddress,
              currencyCode: formValues.currency.code,
            },
            appAuthenticatorCode: null,
          };
          const {
            timestamp,
            isSuccessful,
            errorMessage,
            errorCode,
            requestId,
          } =
            await helpers.injections.apiClient.createWithdrawalAddressOperation(
              request
            );

          if (errorCode) {
            storeActions.setError(errorMessage);
          }
          storeActions.setBusy(false);
          return {
            timestamp,
            isSuccessful,
            errorMessage,
            errorCode,
            requestId,
          };
        },
        result => {
          return result;
        },
        {
          'x-account-id': accountId,
          ...(!!impersonatedAccountId && {
            'x-impersonated-account-id': impersonatedAccountId,
          }),
        }
      );
    }
  ),
  cancelWithdrawalAddress: thunk(async (actions, id, helpers) => {
    const storeState = helpers.getStoreState();
    return runApi(
      actions,
      helpers,
      () => {
        // TODO: integrate with new api endpoint for canceling withdrawal address
        return helpers.injections.apiClient.deleteWithdrawalAddress({
          withdrawalAddressId: id,
        });
      },
      () => {
        actions._setWithdrawalAddress(null);
      },
      {
        'x-account-id': storeState.user.decodedTokenAccountId,
      }
    );
  }),
  deleteWithdrawalAddress: thunk(async (actions, id, helpers) => {
    const storeState = helpers.getStoreState();
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.deleteWithdrawalAddress({
          withdrawalAddressId: id,
        });
      },
      () => {
        actions._setWithdrawalAddress(null);
      },
      {
        'x-account-id': storeState.user.decodedTokenAccountId,
      }
    );
  }),
  deleteWithdrawalAddressOperation: thunk(
    async (actions, { id, accountId, impersonatedAccountId }, helpers) => {
      return runApi(
        actions,
        helpers,
        async () => {
          const storeActions = helpers.getStoreActions();
          storeActions.setBusy(true);
          storeActions.setError(null);

          const {
            timestamp,
            isSuccessful,
            errorMessage,
            errorCode,
            requestId,
          } =
            await helpers.injections.apiClient.deleteWithdrawalAddressOperation(
              {
                withdrawalAddressId: id,
              }
            );

          if (errorCode) {
            storeActions.setError(errorMessage);
          }
          storeActions.setBusy(false);

          return {
            timestamp,
            isSuccessful,
            errorMessage,
            errorCode,
            requestId,
          };
        },
        undefined,
        {
          'x-account-id': accountId,
          ...(!!impersonatedAccountId && {
            'x-impersonated-account-id': impersonatedAccountId,
          }),
        }
      );
    }
  ),
  getWithdrawalAddresses: thunk(
    async (actions, { accountId, impersonatedAccountId }, helpers) => {
      return runApi(
        actions,
        helpers,
        () => {
          return helpers.injections.apiClient.getWithdrawalAddresses(undefined);
        },
        result => {
          actions._setWithdrawalAddresses(result);
        },
        {
          'x-account-id':
            accountId || helpers.getStoreState().user.decodedTokenAccountId,
          ...(!!impersonatedAccountId && {
            'x-impersonated-account-id': impersonatedAccountId,
          }),
        }
      );
    }
  ),
  getWithdrawalAddress: thunk(async (actions, id, helpers) => {
    const storeState = helpers.getStoreState();
    return runApi(
      actions,
      helpers,
      () => {
        const { _withdrawalAddress } = helpers.getState();

        if (_withdrawalAddress?.id !== id) {
          actions._setWithdrawalAddress(null);
        }

        return helpers.injections.apiClient.getWithdrawalAddress({ id });
      },
      result => {
        actions._setWithdrawalAddress(result);
      },
      {
        'x-account-id': storeState.user.decodedTokenAccountId,
      }
    );
  }),
  confirmCode: thunk(async (actions, code, helpers) => {
    const storeState = helpers.getStoreState();
    return runApi(
      actions,
      helpers,
      () => {
        const { currentAddress } = helpers.getState();

        if (!currentAddress) {
          throw new ApiError(
            API_HTTP_CODES.UNPROCESSABLE_ENTITY,
            'Unprocessable Entity',
            -1
          );
        }
        return helpers.injections.apiClient.confirmWithdrawalAddress({
          withdrawalAddressId: currentAddress.id,
          code,
        });
      },
      undefined,
      {
        'x-account-id': storeState.user.decodedTokenAccountId,
      }
    );
  }),
  resendCode: thunk(async (actions, _payload, helpers) => {
    const storeState = helpers.getStoreState();

    return runApi(
      actions,
      helpers,
      () => {
        const { currentAddress } = helpers.getState();

        if (!currentAddress) {
          throw new ApiError(
            API_HTTP_CODES.UNPROCESSABLE_ENTITY,
            'Unprocessable Entity',
            -1
          );
        }

        return helpers.injections.apiClient.resendWithdrawalAddressVerificationCode(
          {
            withdrawalAddressId: currentAddress.id,
          }
        );
      },
      undefined,
      {
        'x-account-id': storeState.user.decodedTokenAccountId,
      }
    );
  }),
  validateAddress: thunk(async (actions, { silent, ...rest }, helpers) => {
    const storeState = helpers.getStoreState();

    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.validateAddress(rest);
      },
      result => {
        actions.setValidatedAddress(result);
      },
      {
        'x-account-id': storeState.user.decodedTokenAccountId,
      },
      silent
    );
  }),
};
