import { ERROR_MESSAGES } from '../../../constants';
import { AddCashFormikField, AddCashFormikProps, AddCashMethod } from './types';

const validate = ({ addCashMethod, toAsset }: AddCashFormikProps) => {
  const errors: Record<string, unknown> = {};

  if (addCashMethod === AddCashMethod.bank && !toAsset) {
    errors[AddCashFormikField.toAsset] = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  return errors;
};

export const validateAddCashFormik = validate;
