export const getValueOfKey = <T>(
  matchKey: string, // eg: `account-UUID` -> `argument: account-`
  obj: Record<string, unknown>,
  excludeKeys: string[] = [] // eg: `account-type-UUID` -> `['account-type-UUID']`
): T => {
  const objKeys = Object.keys(obj);
  const matchedKeys = objKeys.filter(key => {
    const excluded = excludeKeys.some(eK => {
      return key.includes(eK);
    });
    if (excluded) {
      return false;
    }
    return key.toLowerCase().includes(matchKey.toLowerCase());
  });
  return (matchedKeys.map(k => obj[k]) || []) as unknown as T;
};
