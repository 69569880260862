import { EnrichedCryptoAddress } from 'common';
import { FC, memo, useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { AppTableRows } from '../app-table/rows';
import {
  FundAddressesColumnColumn,
  getFundAddressesColumns,
} from '../dashboard/apex-quorum-tables/columns';
import { SortInfo } from '~/store/types';
import { AppTableHeadings } from '../app-table';
import { SearchEmpty } from '~/components/search-empty/search-empty';

interface Props {
  onClickAddress: (addressId: string) => void;
  addresses: EnrichedCryptoAddress[];
  showTableHeader?: boolean;
}

export const FundAddressesList: FC<Props> = memo(
  ({ addresses, showTableHeader = true }) => {
    /**
     *  State
     */
    const [sortInfo, setSortInfo] =
      useState<SortInfo<FundAddressesColumnColumn> | null>(null);

    const columnProps = {
      sort: sortInfo,
      isSettings: false,
      showStatus: showTableHeader,
      onSort: (value: SortInfo<FundAddressesColumnColumn>) =>
        setSortInfo(value),
    };
    const columns = getFundAddressesColumns(columnProps);
    return (
      <>
        {showTableHeader && (
          <AppTableHeadings<
            FundAddressesColumnColumn,
            EnrichedCryptoAddress | null
          >
            borderCls={cx('border-grey-bright bg-white border-b-0 border', {
              'border-b-0': addresses?.length,
            })}
            columns={columns}
          />
        )}
        <AppTableRows<FundAddressesColumnColumn, EnrichedCryptoAddress | null>
          as={Link}
          to={(row) => `${row?.id}`}
          columns={columns}
          rows={addresses || []}
          rowPadding="px-4 md:px-8 py-2.5 md:py-4"
          emptyDataText={<SearchEmpty title="There are no saved addresses" />}
        />
      </>
    );
  }
);
