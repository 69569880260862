import { ERROR_MESSAGES } from '../constants';
import { API } from 'api';
import { decode } from 'bip21';

import { ValidationResult } from './validations/types';

export interface DecodedAddress {
  amount: string | null;
  address: string;
  message: string | null;
  assetid: string | null;
  blockchainCode: string | null | undefined;
}

const validateTag = async (
  tag: string,
  apiClient: API.StablehouseClient,
  accountId: string
): Promise<API.ValidateTagResponse | null> => {
  try {
    apiClient.setAdditionalHeaders({ 'x-account-id': accountId });

    const { isSuccessful, result } = await apiClient.validateTag({
      tag,
    });

    if (!isSuccessful) {
      return null;
    }
    return result;
  } catch {
    return null;
  }
};

const validateAddress = async (
  address: string,
  blockchainCode: string,
  apiClient: API.StablehouseClient,
  accountId: string
): Promise<API.ValidateAddressResponse | null> => {
  try {
    apiClient.setAdditionalHeaders({ 'x-account-id': accountId });

    const { isSuccessful, result } = await apiClient.validateAddress({
      address,
      blockchainCode,
    });

    if (!isSuccessful) {
      return null;
    }
    return result;
  } catch {
    return null;
  }
};

const getBipScheme = (value: string) => {
  if (!value) {
    return undefined;
  }

  if (value.indexOf(':') <= -1) {
    return undefined;
  }

  const match = value.match(/[^:]*/i);
  if (!match || !match.length) {
    return undefined;
  }

  return match[0];
};

export const processCryptoAddress = async (
  value: string,
  blockchain: string,
  accountId: string,
  apiClient?: API.StablehouseClient
): Promise<DecodedAddress> => {
  // if has white space within the text
  if (/\s/g.test(value.trim())) {
    throw new Error(ERROR_MESSAGES.INVALID_DESTINATION);
  }
  const trimmedValue = value.trim();
  const scheme = getBipScheme(trimmedValue);
  const addressResponse = apiClient
    ? await validateAddress(trimmedValue, blockchain, apiClient, accountId)
    : null;
  if (!scheme) {
    return {
      amount: null,
      address: trimmedValue,
      message: null,
      assetid: null,
      blockchainCode: addressResponse?.blockchainCode,
    };
  }

  // eslint-disable-next-line
  try {
    const { address, options } = decode(trimmedValue, scheme);
    const { message = null, amount = null, assetid = null } = options;

    return {
      amount: amount ? amount.toString().trim() : null,
      address: address ? address.toString().trim() : null,
      message: message ? message.toString().trim() : null,
      assetid: assetid ? assetid.toString().trim() : null,
      blockchainCode: addressResponse?.blockchainCode,
    };
  } catch (error) {
    throw error;
  }
};

export const validateDestinationTag = async (
  value: string,
  accountId: string,
  apiClient: API.StablehouseClient
): Promise<ValidationResult> => {
  const res = await validateTag(value, apiClient, accountId);
  if (!res) {
    return {
      isValid: false,
    };
  }

  // valid tag, but does not exist
  if (res.valid && !res.exist && !res.isSelf) {
    return {
      isValid: false,
      message: ERROR_MESSAGES.TAG_NOT_EXIST,
    };
  }

  // valid tag & exists, but is self
  if (res.valid && res.exist && res.isSelf) {
    return {
      isValid: false,
      message: ERROR_MESSAGES.TAG_IS_SELF,
    };
  }

  // valid tag, but user cannot transfer
  if (res.valid && res.exist && !res.canTransfer) {
    return {
      isValid: false,
      message: ERROR_MESSAGES.TAG_NOT_EXIST,
    };
  }

  // default
  return {
    isValid: res.valid,
  };
};

export const validateDestinationAddress = async (
  address: string,
  blockchain: string,
  apiClient: API.StablehouseClient,
  accountId: string
): Promise<ValidationResult> => {
  try {
    const res = await validateAddress(
      address,
      blockchain,
      apiClient,
      accountId
    );
    if (!res) {
      return {
        isValid: false,
      };
    }

    return {
      isValid: !!res.isValid,
    };
  } catch {
    return {
      isValid: false,
    };
  }
};
