import {
  BlockchainNetworkName,
  EnrichedAccountDetailAsset,
  EnrichedCryptoAddress,
} from '../../../types';

export enum SendDestinationType {
  crypto = `Send to a new external address`,
  book = `Send to saved address`,
  transfer = `Transfer to a Stablehouse user`, // // Note: https://stablehouse.atlassian.net/browse/SH-5156
}

export enum SendFormikField {
  destinationType = 'destinationType',
  fromAsset = 'fromAsset',
  network = 'network',
  destination = 'destination',
  whitelistAddress = 'whitelistAddress',
  amount = 'amount',
  description = 'description',
}

export type SendFormikProps = {
  [SendFormikField.fromAsset]: EnrichedAccountDetailAsset | null;
  [SendFormikField.destinationType]: SendDestinationType;
  [SendFormikField.destination]: string;
  [SendFormikField.whitelistAddress]: EnrichedCryptoAddress | null;
  [SendFormikField.amount]: string | null;
  [SendFormikField.description]?: string;
  [SendFormikField.network]?: BlockchainNetworkName | null;
};
