import { FC } from 'react';
import { SettingsPaymentMethods } from '~/components/settings/payment-methods';
import { SettingsLayout, SettingsMenuIds } from './layout';

export const PaymentMethods: FC = () => {
  /**
   * DOM
   */
  return (
    <SettingsLayout
      hasPaddings={false}
      header=""
      menuId={SettingsMenuIds.PAYMENT_METHODS}
    >
      <SettingsPaymentMethods />
    </SettingsLayout>
  );
};
