import { ContentCardProps } from '../types';

export enum ContentCardsSections {
  activeTrader = 'activeTrader',
  investments = 'investments',
  funds = 'funds',
  discoverMore = 'discoverMore',
}

interface ContentCardsFilters {
  hasAccountOfTypeTrading: boolean;
  hasAccountOfTypeCustody: boolean;
  hasAccountOfTypeProTrading: boolean;
}

export function filterContentCards(
  cards: ContentCardProps[],
  filters: ContentCardsFilters
) {
  return cards.filter(({ id }) => {
    if (!filters.hasAccountOfTypeProTrading && id === 'activeTrader') {
      return false;
    }

    // SH-9027
    if (filters.hasAccountOfTypeProTrading && id === 'proTradingAccount') {
      return false;
    }

    // SH-9039
    if (
      !filters.hasAccountOfTypeProTrading &&
      !filters.hasAccountOfTypeCustody &&
      [
        'diamondHandsBtc',
        'diamondHandsEth',
        'portfolioDiversifier',
        'bermudAir',
      ].includes(id)
    ) {
      return false;
    }

    if (filters.hasAccountOfTypeCustody && id === 'vaultAccount') {
      return false;
    }

    return true;
  });
}

export const getWidgetCopyLink = (
  id: string,
  url?: string,
  baseUrl?: string,
  infoParam?: string
) => {
  const link = ['vaultAccount', 'proTradingAccount'].includes(id)
    ? `${baseUrl}${url}`
    : url;

  if (infoParam) {
    return `${link}${link?.includes('?') ? '&' : '?'}info=${infoParam}`;
  }
  return link;
};
