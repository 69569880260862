import { API } from 'api';

export const isCryptoMarketType = type => {
  return [
    API.MarketAssetType.Crypto,
    API.MarketAssetType.Stablecoin,
    API.MarketAssetType.Memecoin,
    API.MarketAssetType.Yield,
    API.MarketAssetType.Trending,
    API.MarketAssetType.Top,
  ].includes(type);
};
