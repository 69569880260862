import { FC, ReactElement, ReactNode } from 'react';
import {
  useField,
  FieldInputProps,
  FieldHelperProps,
  FieldMetaProps,
} from 'formik';
import { AppValidationWrapper } from 'common';
import { PasswordWrapper } from '../../utils/password-wrapper';
import cx from 'classnames';

export type FieldProps = {
  name: string;
  label?: string | ReactNode;
  validator?: 'password';
  validate?: (value: any) => undefined | string | Promise<any>;
  labelCls?: string;
};

type Props = FieldProps & {
  children: (
    field: FieldInputProps<string>,
    meta: FieldMetaProps<string>,
    helpers: FieldHelperProps<string>
  ) => ReactElement;
  cls?: string;
};

export const Field: FC<Props> = ({
  children: renderInput,
  label,
  name,
  validator,
  validate,
  cls = '',
  labelCls = 'text-gray-400 mt-2.5 mb-1',
}) => {
  /**
   * Hooks
   */
  const [field, meta, helpers] = useField({ name, validate });

  /**
   * DOM
   */
  const hasError = !!meta.error && !!meta.touched && !!meta.value;
  let content = (
    <AppValidationWrapper cls={cls} show={hasError} message={meta.error}>
      {renderInput(field, meta, helpers)}
    </AppValidationWrapper>
  );
  if (validator === 'password') {
    content = (
      <PasswordWrapper
        cls={cls}
        showError={hasError}
        hasValue={Boolean(meta.value)}
      >
        {renderInput(field, meta, helpers)}
      </PasswordWrapper>
    );
  }
  return (
    <>
      {Boolean(label) && <div className={cx('text-sm', labelCls)}>{label}</div>}
      {content}
    </>
  );
};
