import { BlockchainNetworkName } from '../types';

export const getBlockchainNetworkName = (
  blockchain: string | undefined | null
) => {
  if (!blockchain) {
    return null;
  }
  const key = blockchain as keyof typeof BlockchainNetworkName;
  return BlockchainNetworkName[key] || null;
};
