import { API } from 'api';
import { TransactionColumn } from '~/config';

export const convertColumnToSortColumn = (column: TransactionColumn) => {
  switch (column) {
    case TransactionColumn.TransactionType:
      return API.SortColumn.Type;
    case TransactionColumn.AmountCurrency:
      return null;
    case TransactionColumn.Status:
      return API.SortColumn.Status;
    case TransactionColumn.Date:
      return API.SortColumn.Date;
    default:
      return null;
  }
};
