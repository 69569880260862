import { Root } from './root';
import { Default } from './default';
import { AdminFundAccounts } from './fund';
import { AdminQuorumApproval } from './quorum-approval';
import { AdminQuorumPendingApprovals } from './quorum-pending-approvals';
import { AdminFundLayout } from '~/components/layouts/admin-fund-layout';

export const AdminPage = {
  Root: Root,
  Default,
  Fund: {
    AccountDetail: AdminFundLayout,
    Accounts: AdminFundAccounts,
  },
  QuorumApproval: AdminQuorumApproval,
  QuorumPendingApprovals: AdminQuorumPendingApprovals,
};
