import React, {
  createContext,
  FC,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import { BaseComponentProps } from '../../types';
import { AppDialog } from '../../components/app-dialog';
export interface ContextValues {
  showDialog: (dialog: ReactNode) => void;
  hideDialog: () => void;
}
export const AppDialogContext = createContext<ContextValues>({
  showDialog: () => undefined,
  hideDialog: () => undefined,
});
export const AppDialogProvider: FC<BaseComponentProps> = ({ children }) => {
  /**
   * State
   */
  const [dialog, setDialog] = useState<ReactNode | null>(null);

  /**
   * Methods
   */
  const showDialog = (dialog: ReactNode) => {
    setDialog(dialog);
  };
  const hideDialog = () => {
    setDialog(null);
  };
  useEffect(() => {
    const handlePopState = () => {
      if (dialog) {
        hideDialog();
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [dialog]);

  /**
   * DOM
   */
  const value = { showDialog, hideDialog } as ContextValues;
  return (
    <AppDialogContext.Provider value={value}>
      <AppDialog isOpen={!!dialog} onClose={hideDialog}>
        {!!dialog && dialog}
      </AppDialog>
      {children}
    </AppDialogContext.Provider>
  );
};
export const useAppDialog = () => useContext(AppDialogContext);
