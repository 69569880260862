import { FC } from 'react';
import { AppIcon } from 'common';

interface Props {
  onClose: () => void;
}

export const SummaryApproval: FC<Props> = ({ onClose }) => {
  return (
    <div className="flex flex-col text-primary">
      {/* header  */}
      <div className="flex flex-col items-center mt-12 mx-10">
        <AppIcon icon="check" size="lg" />
      </div>
      {/* content  */}
      <div className="flex flex-col text-primary">
        {/* header  */}
        <div className="flex flex-col items-center mx-10">
          <h3 className="my-8 font-bold">Request sent for approval!</h3>
        </div>

        {/* actions  */}
        <div className="flex border-t border-grey-bright bg-gray-100 rounded-b mt-14 py-6 px-6">
          <button
            role="button"
            type="button"
            className="app-button-outline w-full flex-1"
            onClick={e => {
              e.preventDefault();
              onClose();
            }}
          >
            Close window
          </button>
        </div>
      </div>
    </div>
  );
};
