import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

declare global {
  interface Window {
    Cypress?: any;
  }
}

const isDev = process.env.NODE_ENV === 'development';
const isE2e = !!window.Cypress;

type InitOptions = {
  applicationId: string;
  clientToken: string;
  env: string;
  version: string;
};

export function initializeDatadog({
  applicationId,
  clientToken,
  env,
  version,
}: InitOptions) {
  // https://docs.datadoghq.com/logs/log_collection/javascript/#initialization-parameters
  datadogLogs.init({
    clientToken,
    site: 'datadoghq.eu',
    env,
    service: 'webapp',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    telemetrySampleRate: 100,
    version,
  });

  if (isDev) {
    datadogLogs.logger.setHandler('console');
  } else if (isE2e) {
    datadogLogs.logger.setHandler('silent');
  }

  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.eu',
    service: 'webapp',
    sessionSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    env,
  });
  datadogRum.startSessionReplayRecording();
}
