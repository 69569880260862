import { DATE_FORMATS } from '../constants';
import { format } from 'date-fns';

export const dateToUtc = (date: Date) => {
  const offset = date.getTimezoneOffset();

  const newTime = date.getTime() + offset * 60 * 1000;

  return new Date(newTime);
};

export const verifyDateValidity = (
  day: number,
  monthIndex: number,
  year: number
) => {
  const date = new Date(year, monthIndex, day);

  const resultingDay = date.getDate();
  const resultingMonth = date.getMonth();
  const resultingYear = date.getFullYear();

  // if the date has shifted, it means it doesn't exist
  if (
    resultingDay !== day ||
    resultingMonth !== monthIndex ||
    resultingYear !== year
  ) {
    return false;
  } else {
    return true;
  }
};

export const getAgeInternal = (birthDate: Date, today: Date) => {
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const formatDateShort = (date: Date) => {
  return format(date, DATE_FORMATS.SHORTMONTH_D_YYYY);
};

export const daysDiff = (date: Date, now: Date) => {
  return Math.abs(
    Math.ceil((date.getTime() - now.getTime()) / (24 * 3600 * 1000))
  );
};

export const formatDatePp = (date: Date) => {
  return format(date, DATE_FORMATS.Pp);
};

export const formatReceiptDate = (date: Date) => {
  const offset = new Date().getTimezoneOffset() / 60;

  return `${format(date, DATE_FORMATS.RECEIPT)} GMT${offset < 0 ? '+' : '-'}${Math.abs(
    offset
  )}`;
};

export const getAge = (birthDate: Date) => {
  const today = new Date();
  return getAgeInternal(birthDate, today);
};
