import React, { FC } from 'react';

export interface Props {
  htmlFor?: string;
  title: string;
}

export const LabelForFormElement: FC<Props> = ({ htmlFor, title }) => {
  return (
    <label htmlFor={htmlFor} className="text-gray-400 text-sm mt-4 mb-1">
      {title}
    </label>
  );
};
