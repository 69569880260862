import {
  BlockchainNetworkName,
  EnrichedCurrencyInformation,
} from '../../../types';

export enum AddAddressFormikField {
  walletAddress = 'walletAddress',
  label = 'label',
  network = 'network',
  currency = 'currency',
}

export interface AddAddressFormikProps {
  [AddAddressFormikField.walletAddress]: string;
  [AddAddressFormikField.label]: string;
  [AddAddressFormikField.network]: BlockchainNetworkName | null;
  [AddAddressFormikField.currency]: EnrichedCurrencyInformation | null;
}
