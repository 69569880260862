import { useCallback, useMemo } from 'react';
import { handleNavigation } from '../shared/url-params/url-params';

export enum SEARCH_PARAMS {
  TAB_INDEX = 'tabIndex',
}

export const useAppTabs = (
  customParamOptions?: Record<string, string | number>
) => {
  /**
   * Hooks
   */
  const {
    applyParams,
    queryParams: { tabIndex },
  } = handleNavigation<'tabIndex'>();

  const handleOnTabClicked = useCallback(
    async (tab: number | string) => {
      applyParams({
        tabIndex: customParamOptions
          ? Object.values(customParamOptions)[tab]
          : tab,
      });
    },
    [applyParams, customParamOptions]
  );
  const tab = useMemo(() => {
    if (!tabIndex) {
      return 0;
    }
    if (!customParamOptions?.[tabIndex as string]) {
      return Number(tabIndex);
    }
    return Object.values(customParamOptions).indexOf(tabIndex as string);
  }, [customParamOptions, tabIndex]);

  /**
   * Return
   */
  return {
    tabIndex: tab,
    handleOnTabClicked,
  };
};
