import {
  Disable2FAField,
  Disable2FAFormikProps,
  Enable2FAField,
  Enable2FAFormikProps,
} from './types';
import { DEFAULTS, ERROR_MESSAGES } from '../../../constants';

export const validateDisable2FA = ({
  password,
  twoFaCode,
}: Disable2FAFormikProps) => {
  const errors: Record<string, unknown> = {};

  if (!password) {
    errors[Disable2FAField.password] = ERROR_MESSAGES.REQUIRED_VALUE;
  }
  if (!twoFaCode) {
    errors[Disable2FAField.twoFaCode] = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  return errors;
};

export const validateEnable2FA = ({ twoFaCode }: Enable2FAFormikProps) => {
  const errors: Record<string, unknown> = {};

  if (!twoFaCode) {
    errors[Enable2FAField.twoFaCode] = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  return errors;
};

export const is2FAValid = (code: string) => {
  return code.length === DEFAULTS.TFA_LENGTH;
};
