import { getMaskedUntilValue } from '~/utils/get-masked-until-value';
import { ActivityDate, ActivityDescription, ActivityHash } from '../shared';
import {
  CellActivityStatus,
  CellTransferAccount,
} from '~/components/transactions/cells';
import { Enriched } from 'common';

export const getWithdrawCashRecords = (
  data: Enriched.FiatWithdrawal,
  status: string,
  account: Enriched.ListAccountItem | undefined
): Record<string, JSX.Element | string> => {
  const accNum = getMaskedUntilValue(data.bankNumber);

  return {
    '': <CellActivityStatus {...data} status={status} />,
    Send: (
      <>
        {data.formatted.amountSentWithCurrencyCodeAndSign}
        <br />
        <CellTransferAccount data={account} />
      </>
    ),
    Fee: data.formatted.feeWithCurrencyCodeAndSign,
    Receive: (
      <>
        {data.formatted.amountReceivedWithCurrencyCodeAndSign}
        <div>
          <p className="text-primary font-bold text-sm">{data.bankName}</p>
          <p className="text-gray-darker font-normal text-sm">{accNum}</p>
        </div>
      </>
    ),
    'Reference code': <ActivityHash hash={data.humanReadableIdentifier} />,
    Date: <ActivityDate date={data.creationDate} />,
    Description: <ActivityDescription value={data?.description} />,
  };
};
