import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '../../../store';
import { SuccessfulOperationStep } from '../shared/successful-operation-step';

type CancelledRequestProps = Partial<StepWizardChildProps> & {
  onBack: () => void;
};

export const CancelledRequest: FC<CancelledRequestProps> = ({ onBack }) => {
  /**
   * Store
   */
  const resetState = DataStore.useStoreActions(
    a => a.settings.cryptoAddresses.resetState
  );

  const handleBack = () => {
    resetState();
    onBack();
  };

  /**
   * DOM
   */
  return (
    <SuccessfulOperationStep
      title="Your request has been canceled"
      description="This address will no longer show up in your address book and you will no longer be able to send to this address."
      onBack={handleBack}
    />
  );
};
