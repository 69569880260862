import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { StepWizardChildProps } from 'react-step-wizard';
import { APP_ROUTES } from '~/routes';
import { Widgetcard } from './widget-card';
import { WidgetStepsNavigation } from './widget-steps-navigation';

export const WidgetAdd2Fa: FC<Partial<StepWizardChildProps>> = memo(
  ({ ...rest }) => {
    /**
     * Hooks
     */
    const navigate = useNavigate();

    /**
     * DOM
     */
    return (
      <Widgetcard
        icon="circle-shield-check"
        title="Activate 2FA"
        subText="Protect your account with added security by enabling 2FA"
        ctaText="Activate 2FA"
        titleCls="text-xl"
        handleButtonClick={() =>
          navigate(`${APP_ROUTES.AUTH_SETTINGS}/security/2fa`)
        }
      >
        <WidgetStepsNavigation {...rest} />
      </Widgetcard>
    );
  }
);
