import { FC, InputHTMLAttributes } from 'react';
import {
  DebounceInput as VendorInput,
  DebounceInputProps as VendorProps,
} from 'react-debounce-input';

import { InputProps, getInputStyle } from 'common';

export type DebounceInputProps = VendorProps<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
> &
  Partial<Pick<HTMLInputElement, 'spellcheck'>>;

export const DebounceInput: FC<DebounceInputProps & InputProps> = ({
  errored = false,
  ...rest
}) => (
  <VendorInput
    id={rest.name}
    data-testid={rest.name}
    errored={errored}
    {...rest}
    className={getInputStyle(errored)}
  />
);
