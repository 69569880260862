import { ArrowRightIcon } from '@heroicons/react/outline';
import { FC, useState } from 'react';
import { Auth0 } from '~/utils/auth0';
import { apiClient } from '../../api/client';
import { AppIcon } from 'common';

export const SettingsSecurityAuth02faWizard: FC = () => {
  const [success, setSuccess] = useState(false);

  return (
    <>
      <div className="text-sm text-gray-400 mb-2">
        Two-factor authentication (2FA) is the industry standard for securing
        your online accounts. Once enabled, 2FA adds a second layer of
        protection to your Stablehouse account. When you sign in, you’ll be
        prompted to input your password and a verification code generated by
        your authenticator app.
      </div>
      <div className="text-sm text-gray-400">
        You can setup 2FA by using the{' '}
        <span className="text-primary">Google Authenticator</span> app,
        available on
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer mx-1 underline text-primary"
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
        >
          Android
        </a>
        and
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer mx-1 underline text-primary"
          href="https://itunes.apple.com/us/app/google-authenticator/id388497605"
        >
          iOS
        </a>
        mobile devices. Once enabled, you will only be able to log in to your
        Stablehouse account with a code generated by the app. This code
        regenerates every 30 seconds. We urge all Stablehouse account holders to
        enable 2FA on their accounts. Stablehouse is not responsible for any
        unauthorized users that may access your account.
        <div className="my-4">
          {!success ? (
            <button
              onClick={async e => {
                e.preventDefault();
                try {
                  await Auth0.triggerMfa();
                  await apiClient.disableAuth0Mfa({});

                  //Note: Now prompt the user to set his MFA again
                  await Auth0.forceSetMfa();
                  setSuccess(true);
                } catch (e) {
                  // Do not crash the app if the user closes the popup.
                  return;
                }
              }}
              data-testid="disable-2fa-button"
              className="app-button-accent flex items-center"
            >
              Reset 2FA
              <ArrowRightIcon className="w-5 h-5 ml-2" />
            </button>
          ) : (
            <div className="flex flex-col items-center px-10 text-center">
              <AppIcon
                icon="check-circle"
                bg="bg-success"
                size={48}
                cls="mt-10 mb-5 text-white"
              />
              <h4 className="text-primary font-bold px-10 mb-2">
                Your two-factor authentication has been successfuly reset.
              </h4>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
