import { FC, useState } from 'react';
import { apiClient } from '../../api/client';
import { AppDialog, EMAIL, useComputedConfig } from 'common';
import { DataStore } from '~/store';
import { SuccessfulOperationStep } from '../workflows/shared/successful-operation-step';

export const SettingsSecurityAuth02ChangePassword: FC = () => {
  const [success, setSuccess] = useState(false);
  const userEmail = DataStore.useStoreState(s => s.user.decodedToken?.email);
  const { tenant } = useComputedConfig();

  return (
    <>
      {/* dialogs: app version */}
      <AppDialog isOpen={success} onClose={() => setSuccess(false)}>
        <SuccessfulOperationStep
          titleCls=""
          containerCls="pt-10"
          descriptionCls=""
          title={`A reset password link has been sent to your email.`}
          description={`Please check your registered email address to find this link.`}
          onBack={() => setSuccess(false)}
        />
      </AppDialog>

      <div className="text-sm text-gray-400 mb-2">
        Do you want to change your password, click the “Change password” button
        below. If you have an issue with your account, contact support at{' '}
        <a href={`mailto:${EMAIL.SUPPORT}`}>
          <strong>{EMAIL.SUPPORT}</strong>
        </a>
      </div>
      <div className="text-sm text-gray-400">
        <div className="my-4">
          <button
            onClick={async e => {
              e.preventDefault();
              await apiClient.sendResetPasswordEmail({
                email: userEmail!,
                siteCode: tenant,
                tenant: null,
              });

              setSuccess(true);
            }}
            data-testid="disable-2fa-button"
            className="app-button-accent flex items-center"
          >
            Change password
          </button>
        </div>
      </div>
    </>
  );
};
