import { API } from 'api';
import { FC, useState } from 'react';
import { DataStore } from '../../store';
import { AppTableRows } from '../app-table/rows';
import {
  ApprovalsConfirmationColumn,
  getAprovalsConfirmationColumns,
} from '../dashboard/apex-quorum-tables/columns';
import { SortInfo } from '~/store/types';
import { SearchEmpty } from '~/components/search-empty/search-empty';
export interface BankAccountRowProps {
  accounts: API.BankAccount[] | null;
  onShow: (bankId: string) => void;
  onVerify: (bankId: string) => void;
}

export const BankAccountList: FC<BankAccountRowProps> = ({
  accounts,
  onShow,
  onVerify,
}) => {
  /**
   * Store
   */
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  /**
   *  State
   */
  const [sortInfo, setSortInfo] =
    useState<SortInfo<ApprovalsConfirmationColumn> | null>(null);

  const columnProps = {
    sort: sortInfo,
    onVerify: onVerify,
    isApexFundManager: clientUserType === 'fund-manager',
    onSort: (value: SortInfo<ApprovalsConfirmationColumn>) =>
      setSortInfo(value),
  };
  const columns = getAprovalsConfirmationColumns(columnProps);
  return (
    <AppTableRows<ApprovalsConfirmationColumn, API.BankAccount | null>
      columns={columns}
      rows={accounts || []}
      rowPadding="px-6 py-3"
      containerCls="rounded-b-4 grey-bright"
      emptyDataText={<SearchEmpty title="There are no saved bank accounts" />}
      onRowClick={(f: API.BankAccount | null) => {
        if (!f) return;
        onShow(f?.id);
      }}
    />
  );
};
