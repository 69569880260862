import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '../../../store';
import { TransferSteps } from './steps';
import { ConfirmCancel } from '../shared/confirm-cancel';

export const ConfirmCancelTransfer: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  if (!goToNamedStep) {
    return null;
  }

  /* Store */
  const isConfirmedCode = DataStore.useStoreState(
    a => a.transfer.isConfirmedCode
  );
  const cancel = DataStore.useStoreActions(a => a.transfer.cancel);

  /*
   * Handlers
   */
  const handleCancel = async () => {
    const response = await cancel();
    if (response.isSuccessful) {
      goToNamedStep(TransferSteps.Cancelled);
    }
  };
  const handleNoActionClick = () => {
    if (!isConfirmedCode) {
      goToNamedStep(TransferSteps.EmailCode);
      return;
    }
    goToNamedStep(TransferSteps.Summary);
  };

  /**
   * DOM
   */
  return (
    <ConfirmCancel
      noActionText={isConfirmedCode ? 'Continue' : undefined}
      submitText={isConfirmedCode ? 'Cancel request' : undefined}
      onNoActionClick={handleNoActionClick}
      onSubmitClick={handleCancel}
    />
  );
};
