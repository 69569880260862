import { useUrls } from 'common';

type Props = {
  width?: number;
  height?: number;
  countryCode: string;
};

export function FlagIcon({ countryCode, width = 30, height = 22.5 }: Props) {
  if (!countryCode) return null;
  const { getCountryFlagImageUrl } = useUrls();
  return (
    <img
      width={width}
      height={height}
      src={getCountryFlagImageUrl(countryCode)}
    />
  );
}
