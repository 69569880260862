import { API } from 'api';
import { useUrls } from 'common';
import { DataStore } from '../../store';
import { AppIcon } from 'common';
import { DASHBOARD_SELECTED_DIALOG_TYPE } from '~/store/types';

interface Props {
  onActionClick: (action: DASHBOARD_SELECTED_DIALOG_TYPE | null) => void;
}

export const ThanksForWaitingDialog: React.FC<Props> = ({ onActionClick }) => {
  /**
   * Store
   */
  const accountId = DataStore.useStoreState(
    _ => _.portfolio.accountDetail?.account?.accountId || ''
  );
  const updateYieldPromptAction = DataStore.useStoreActions(
    a => a.user.updateYieldPromptAction
  );

  /**
   * Hooks
   */
  const { termsUrl } = useUrls();

  /**
   * DOM
   */
  return (
    <div className="flex flex-col align-middle items-center p-10 pb-5">
      <AppIcon icon="thumbs-up" size="lg" bg={''} />
      <div className="text-2xl font-bold mt-5 text-center">
        Would you like to start earning yield on your deposit?
      </div>
      <div className="text-gray-400 mt-6 text-center mb-10">
        Your account is now eligible to earn weekly compounded interest. By
        clicking yes, you agree to our{' '}
        <a href={termsUrl} className="underline text-primary">
          T&amp;Cs
        </a>{' '}
        and will start earning yield.
      </div>
      <div className="flex flex-row gap-x-3">
        <button
          className="app-button-primary mb-5"
          style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
          onClick={async e => {
            e.preventDefault();
            await updateYieldPromptAction({
              accountId,
              request: { action: API.YieldPromptAction.Declined },
            });
            onActionClick(null);
          }}
        >
          No
        </button>
        <button
          className="app-button-accent mb-5"
          style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
          onClick={async e => {
            e.preventDefault();
            await updateYieldPromptAction({
              accountId,
              request: { action: API.YieldPromptAction.Accepted },
            });
            onActionClick(null);
          }}
        >
          Yes
        </button>
      </div>
    </div>
  );
};
