import { AppTenant, countries, getCountryFlagImageUrl } from 'common';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useAppFavicon } from '~/hooks/use-app-icon';

export const AppRootHelmet: FC<{ tenant: AppTenant; themeName: string }> = ({
  tenant,
  themeName,
}) => {
  /**
   * Hooks
   */
  const appFavIconUrl = useAppFavicon(themeName);

  /**
   * DOM
   */
  if (tenant === 'Apex') {
    return (
      <Helmet>
        <link rel="icon" href={appFavIconUrl} />
        <link
          rel="apple-touch-icon"
          href={`common/dist/theme/${themeName}/touchicon.png`}
        />
        <title>ApeXchange</title>
      </Helmet>
    );
  }
  const title = `Stablehouse - Institutional access to digital assets`;
  const description = `A secure custodial framework built for institutions: mitigating risk with security, bankruptcy protection and regulatory compliance.`;
  const ogImage = `https://stablehouse-assets.s3.eu-west-1.amazonaws.com/web/product-splash.png`;
  return (
    <Helmet>
      {countries?.map(({ code }) => (
        <link
          key={code}
          as="image"
          rel="preload"
          href={getCountryFlagImageUrl(code)}
        />
      ))}
      <link rel="icon" href={appFavIconUrl} />
      <link
        rel="apple-touch-icon"
        href={`common/dist/theme/${themeName}/touchicon.png`}
      />
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* og  */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      {/* twitter  */}
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={ogImage} />
    </Helmet>
  );
};
