import React from 'react';
import { FC, useEffect, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { TwoFaWidget } from '~/components/two-fa-widget';
import { DataStore } from '~/store';
import { FormFooter } from '../shared/form-footer';
import { createAddress } from './helper';
import { AddCryptoAddressSteps } from './steps';
import { FormHeader } from 'common';

type Validate2FAProps = Partial<StepWizardChildProps>;

export const Validate2FA: FC<Validate2FAProps> = ({ goToNamedStep }) => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.settings.cryptoAddresses.error);
  const setError = DataStore.useStoreActions(
    a => a.settings.cryptoAddresses.setError
  );
  const formValues = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.formValues
  );
  const createWithdrawalAddress = DataStore.useStoreActions(
    a => a.settings.cryptoAddresses.createWithdrawalAddress
  );
  const is2FaEnabled = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.is2FaEnabled
  );

  /**
   * State
   */
  const [twoFaCode, setTwoFaCode] = useState<string>('');

  /**
   * Hooks
   */
  useEffect(() => {
    setError(null);
  }, [twoFaCode]);

  if (!goToNamedStep) {
    return null;
  }

  /**
   * Methods
   */
  const onCancelPreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    goToNamedStep(AddCryptoAddressSteps.Form);
  };
  const onConfirmPreview = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await createAddress(
      formValues,
      twoFaCode,
      createWithdrawalAddress,
      step => {
        goToNamedStep(step);
      }
    );
  };

  /**
   * DOM
   */
  const withdrawDisabled = error !== null || (is2FaEnabled && !twoFaCode);

  return (
    <div className="flex flex-col">
      {/* header  */}

      <FormHeader accountInfo="" onBack={onCancelPreview} title="" />

      {/* content  */}
      <div className="mx-10 text-primary">
        {/* two fa widget  */}
        <TwoFaWidget
          askFor2FA={is2FaEnabled}
          isEnabled={is2FaEnabled}
          onChange={code => setTwoFaCode(code)}
          error={error}
        />
      </div>

      {/* actions  */}
      <FormFooter
        onSubmit={onConfirmPreview}
        onCancel={onCancelPreview}
        isSubmitDisabled={withdrawDisabled}
        submitCls="app-button-accent"
        submitText="Continue"
      />
    </div>
  );
};
