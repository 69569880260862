import React, { FC, useCallback, useMemo, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '../../../store';
import {
  AppIcon,
  assetContentTemplate,
  DashboardHeader,
  EnrichedCurrency,
  FormHeader,
  ReceiveMethod,
  SearchInput,
  TIMERS,
} from 'common';
import { getAccountInfo } from '~/utils/get-account-details';
import { RECEIVE_STEPS } from '~/components/workflows/receive/steps';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { AppLoader } from '~/components/app-loader';

export const SearchList: FC<
  Partial<StepWizardChildProps> & {
    accountId: string | undefined;
    onClose?: () => void;
  }
> = ({ goToNamedStep, accountId, onClose }) => {
  const [search, setSearch] = useState('');

  /**
   * Store
   */
  const formValues = DataStore.useStoreState(s => s.receive.formValues);
  const setFormValues = DataStore.useStoreActions(a => a.receive.setFormValues);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const allowedCurrencies = DataStore.useStoreState(
    s => s.receive.allowedCurrencies
  );
  const getDepositableCurrencies = DataStore.useStoreActions(
    a => a.receive.getDepositableCurrencies
  );
  const busy = DataStore.useStoreState(s => s.receive.busy);

  const debouncedGetDepositableCurrencies = useMemo(
    () => debounce(getDepositableCurrencies, TIMERS.INPUT_DEBOUNCE),
    []
  );

  const handleSearchChanged = useCallback(
    (value: string) => {
      setSearch(value);
      debouncedGetDepositableCurrencies({
        search: value,
        accountId,
      });
    },
    [accountId, setSearch, debouncedGetDepositableCurrencies]
  );

  const onCurrencyChanged = useCallback(
    (newValue: EnrichedCurrency | null) => {
      if (!newValue) {
        goToNamedStep?.(RECEIVE_STEPS.FORM);
        return;
      }
      const network = newValue.network;
      setFormValues({
        receiveMethod: formValues?.receiveMethod || ReceiveMethod.crypto,
        toAsset: newValue,
        network,
      });
      goToNamedStep?.(RECEIVE_STEPS.FORM);
    },
    [formValues, setFormValues, goToNamedStep]
  );

  /**
   * DOM
   */
  if (!accountDetail) {
    return null;
  }

  return (
    <div className="flex flex-col text-primary">
      <DashboardHeader asStickyHeader headerPadding="rounded-lg">
        <FormHeader
          cls="items-center"
          title="Choose an asset to receive"
          accountInfo={getAccountInfo(accountDetail.account, 'To')}
          onBack={() => {
            onCurrencyChanged(null);
          }}
          onClose={onClose}
        />
        <SearchInput
          autoFocus
          cls="flex relative items-center mx-10 pb-8"
          onChange={({ target }) => handleSearchChanged(target?.value)}
          handleClearInput={() => handleSearchChanged('')}
          value={search}
        />
      </DashboardHeader>
      <section className="relative rounded-b max-h-485 border-t overflow-y-scroll min-h-100">
        {busy ? (
          <AppLoader bgColor="bg-transparent" spinnerTop="50%" />
        ) : (
          <div className="rounded">
            {allowedCurrencies?.length ? (
              allowedCurrencies?.map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  className={cx(
                    'flex flex-row text-base font-bold truncate items-center cursor-pointer hover-bg-grey-brighter',
                    {
                      'border-b':
                        allowedCurrencies.length > 0 &&
                        itemIndex + 1 !== allowedCurrencies?.length,
                    }
                  )}
                  onClick={() => {
                    onCurrencyChanged(item);
                  }}
                >
                  {assetContentTemplate({
                    item,
                    filterAssetList: [],
                    onItemSelection: null,
                    multiSelection: false,
                  })}
                </div>
              ))
            ) : (
              <p className="text-grey-darker text-sm flex p-4 items-center justify-center">
                No matching values found
              </p>
            )}
          </div>
        )}
      </section>
    </div>
  );
};
