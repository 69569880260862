import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RequireAuth } from '~/components/auth/require-auth';
import { APP_ROUTES } from '~/routes';

export const JumpToPortfolio = () => {
  /**
   * Hooks
   */
  const navigate = useNavigate();
  useEffect(() => {
    navigate(APP_ROUTES.AUTH_PORTFOLIO);
  }, [navigate]);

  /**
   * DOM
   */
  return (
    <RequireAuth>
      <></>
    </RequireAuth>
  );
};
