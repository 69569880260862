import { FC, useEffect, useRef } from 'react';
import StepWizard from 'react-step-wizard';
import { Form } from './form';
import { Summary } from './summary';
import { defaultStepWizardProps } from '../../../constants';
import { Preview } from './preview';
import { WithdrawCashSteps } from './steps';
import { AddBank } from './add-bank';
import { AppStore, DataStore } from '../../../store';
import { AccountLocked } from '../shared/account-locked';
import { Validate2FA } from './validate-2fa';
import { ConfirmCancelWithdrawCash } from './confirm-cancel-withdraw';
import { CancelledWithdraw } from './cancelled-withdraw';
import { useAppDialogHelper } from 'common';

export const WorkflowWithdrawCash: FC = () => {
  /**
   * Vars
   */
  const ref = useRef<HTMLDivElement | null>(null);

  /**
   * Store
   */
  const setError = DataStore.useStoreActions(a => a.withdrawCash.setError);
  const resetState = DataStore.useStoreActions(a => a.withdrawCash.resetState);
  const setDashboardSelectedAsset = AppStore.useStoreActions(
    a => a.setDashboardSelectedAsset
  );

  /**
   * Hooks
   */
  const appDialogHelper = useAppDialogHelper();
  useEffect(() => {
    appDialogHelper.init(ref.current);

    return () => {
      setDashboardSelectedAsset(null);
      resetState();
      appDialogHelper.dispose();
    };
  }, []);

  /**
   * DOM
   */
  return (
    <div className="relative text-primary h-full" ref={ref}>
      <StepWizard
        {...defaultStepWizardProps}
        className="h-full"
        onStepChange={({ activeStep }) => {
          setError(null);

          const closeBtnHiddenSteps = [4, 6];
          if (!closeBtnHiddenSteps.includes(activeStep)) {
            appDialogHelper.closeButton.show();
            return;
          }
          appDialogHelper.closeButton.hide();
        }}
      >
        {/* step: 1 */}
        <Form stepName={WithdrawCashSteps.Form} />
        {/* step: 2 */}
        <AddBank stepName={WithdrawCashSteps.AddBank} />
        {/* step: 3 */}
        <Preview stepName={WithdrawCashSteps.Preview} />
        {/* step: 4 */}
        <Validate2FA stepName={WithdrawCashSteps.Validate2FA} />
        {/* step: 5 */}
        <Summary stepName={WithdrawCashSteps.Summary} />
        {/* step: 6 */}
        <ConfirmCancelWithdrawCash
          stepName={WithdrawCashSteps.ConfirmCancellation}
        />
        {/* step: 7 */}
        <CancelledWithdraw stepName={WithdrawCashSteps.Cancelled} />
        {/* step: 8 */}
        <AccountLocked stepName={WithdrawCashSteps.AccountLocked} />
      </StepWizard>
    </div>
  );
};
