import { API } from 'api';
import { action, Action, computed, Computed, thunk } from 'easy-peasy';
import { factories, runApi } from '../../utils';
import { DataModel } from '../data-store';
import { ApiThunk } from '../types';
import { PaginatedMarketList } from '../../types';
import { BaseModel, createBaseModel } from '../base-store';
import { MarketListAssetsRequest } from 'api/dist/api';

export interface MarketModel extends BaseModel {
  assetsListFilters: API.MarketListAssetsRequest | undefined;
  setAssetsListTab: Action<
    MarketModel,
    | API.MarketAssetType.Crypto
    | API.MarketAssetType.Fund
    | API.MarketAssetType.Bond
    | API.MarketAssetType.Fiat
  >;
  setAssetsListFilters: Action<MarketModel, API.MarketListAssetsRequest | void>;

  // >> `<api>/market/list`
  _assetsList: {
    result: API.SimplifiedListAsset[] | null;
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  } | null;
  _setAssetsList: Action<
    MarketModel,
    API.SimplifiedListAssetArrayPaginatedApiResponse | null
  >;
  assetsList: Computed<MarketModel, PaginatedMarketList | null, DataModel>;
  getAssetsList: ApiThunk<
    MarketModel,
    API.MarketListAssetsRequest | undefined,
    unknown
  >;
}

export const EmptyMarketFilters = {
  page: null,
  pageSize: null,
  search: null,
  types: [API.MarketAssetType.Crypto],
  marketAssetsOnly: true,
} as MarketListAssetsRequest;

export const marketModel: MarketModel = {
  ...createBaseModel(),

  assetsListFilters: { ...EmptyMarketFilters },
  setAssetsListTab: action((state, payload) => {
    state.assetsListFilters = { ...EmptyMarketFilters, types: [payload] };
    if (state._assetsList !== null) {
      state._assetsList = {
        ...state._assetsList,
        result: [],
      };
    }
  }),
  setAssetsListFilters: action((state, payload) => {
    state._assetsList = null;
    state.assetsListFilters = payload || undefined;
  }),

  _assetsList: null,
  _setAssetsList: action((state, payload) => {
    state._assetsList = payload;
  }),
  assetsList: computed([s => s._assetsList], assetsList => {
    if (assetsList) {
      return {
        data: factories.enrichSimplifiedAssetList(assetsList.result ?? []),
        pagination: {
          page: assetsList.page,
          pageSize: assetsList.pageSize,
          totalCount: assetsList.totalCount,
          totalPages: assetsList.totalPages,
        },
      };
    }

    return null;
  }),
  getAssetsList: thunk(async (actions, _payload, helpers) => {
    return runApi(
      actions,
      helpers,
      async () => {
        actions.setBusy(true);
        const res = await helpers.injections.apiClient.listAssets(
          _payload || EmptyMarketFilters
        );
        if (res.errorCode) {
          actions.setError(res.errorMessage);
        }
        actions._setAssetsList(res);
        actions.setBusy(false);

        return res;
      },
      undefined
    );
  }),
};
