import { FC, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { CheckIcon } from '@heroicons/react/outline';
import { DataStore } from '../store';
import {
  Note,
  useMediaQuery,
  PasswordProgress,
  BaseComponentProps,
} from 'common';
import { IconContent } from '../components/icon-content';

interface Props extends BaseComponentProps {
  showError: boolean;
  hasValue: boolean;
  cls?: string;
}

export const PasswordWrapper: FC<Props> = ({
  showError: hasError,
  hasValue,
  children,
  cls = '',
}) => {
  const entropy = DataStore.useStoreState(a => a.user.passwordEntropy);

  const { SM, LG } = useMediaQuery();
  const hasTooltip = SM || LG;

  const [errorVisible, setErrorVisible] = useState(hasError);

  const showError = useCallback(() => {
    setErrorVisible(true);
  }, []);

  const hideError = useCallback(() => {
    setErrorVisible(false);
  }, []);

  /**
   * Hooks
   */
  useEffect(() => {
    setErrorVisible(hasError);
  }, [hasError]);

  const showProgress = entropy && hasValue;
  const showToolTip = entropy && !entropy.message;
  const showErrorNote = entropy && entropy.message && showProgress;

  // DOM
  const errorContent = (
    <div className="bg-white rounded-4 border p-2 shadow-md">
      <div className="text-sm mb-2 md:text-base sm:m-5 text-gray-400">
        <p className="mb-2">We suggest your password contains:</p>
        <ul>
          {entropy?.requirementResults?.map(line => (
            <PasswordLineCheck
              key={line.label}
              value={line.label || ''}
              checked={line.isMet}
            />
          ))}
        </ul>
      </div>
    </div>
  );

  if (!hasTooltip) {
    return (
      <>
        <div
          className="bg-white rounded-4"
          onFocus={showError}
          onBlur={hideError}
        >
          {children}
        </div>
        {showProgress && <PasswordProgress entropy={entropy} />}
        {showErrorNote && (
          <Note>
            <IconContent
              textSize="xs"
              iconColor="black"
              contentCls="self-center"
              mt="mt-0"
              content={<p className="text-primary">{entropy.message}</p>}
            />
          </Note>
        )}
        {errorVisible && hasValue && (
          <div className="app-tooltip mt-1">{errorContent}</div>
        )}
      </>
    );
  }

  return (
    <>
      <div data-tip data-for="passwordTip">
        {children}
      </div>
      {showToolTip && (
        <ReactTooltip
          id="passwordTip"
          className="app-tooltip mt-1"
          effect="solid"
          place="right"
          offset={{ right: 3 }}
          event="focusin"
          eventOff="focusout"
        >
          {errorContent}
        </ReactTooltip>
      )}
      {showProgress && <PasswordProgress cls={cls} entropy={entropy} />}
    </>
  );
};

interface PwdProps {
  value: string;
  checked: boolean;
}

const PasswordLineCheck: FC<PwdProps> = ({ value, checked }) => {
  const color = checked ? 'text-success' : 'text-gray-200';
  return (
    <li className="flex items-center">
      <span className={cx('mb-1', color)}>
        <CheckIcon className="inline-block w-4 h-4 mr-2" />
      </span>
      <span>{value}</span>
    </li>
  );
};
