import { AppIcon } from 'common';
import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { FormFooter } from '../shared/form-footer';
import { AddCryptoAddressSteps } from './steps';

export const Intro: FC<Partial<StepWizardChildProps>> = ({ goToNamedStep }) => {
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Handlers
   */
  const onNextStep = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    goToNamedStep(AddCryptoAddressSteps.Form);
  };

  /**
   * DOM
   */
  return (
    <div className="pt-12">
      <div className="flex flex-col items-center px-10">
        <AppIcon icon="arrow-north-east" size="lg" />
        <h4 className="text-center px-10 pt-8 font-semibold">
          Adding an address allows you to send assets quicker and easier.
        </h4>
        <p className="mt-2 mb-10 text-center text-gray-400">
          For ease and convenience, add any address(es) you may frequently use.
          Please ensure that external crypto wallet address details are correct
          - incorrect details could result in loss of assets.
        </p>
      </div>
      <FormFooter submitText="Continue" onSubmit={onNextStep} />
    </div>
  );
};
