import { SelectField } from '../../forms/select-field';

interface Props {
  fieldName: string;
  label: string;
}

export const PermissionSelector: React.FC<Props> = ({ fieldName, label }) => {
  return (
    <SelectField
      name={fieldName}
      label={label}
      values={[true, false]}
      getSelectedItemTemplate={i => <div>{i ? 'Allowed' : 'Blocked'}</div>}
      getItemTemplate={i => (
        <div className="py-2 px-5">{i ? 'Allowed' : 'Blocked'}</div>
      )}
      getSelectedItem={i => i}
    />
  );
};
