import { ActivityDate } from '../shared/activity-date';
import {
  CellActivityStatus,
  CellAmountCurrency,
} from '~/components/transactions/cells';
import { Enriched } from 'common';

export const getAddCashRecords = (activity: Enriched.ActivityTransaction) => {

  return {
    '': (
      <CellActivityStatus {...activity} status={activity.statusFilter || ''} />
    ),
    Amount: <CellAmountCurrency data={activity} showMeta />,
    From: ` ${activity?.currency1?.displayCode} Bank Transfer`,
    // 'Reference code': <ActivityHash hash={transaction?. || ''} />, // Note: cannot be done for now as activity does not have this info
    Date: <ActivityDate date={activity?.creationDate} />,
  };
};
