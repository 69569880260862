import { FC, useCallback } from 'react';
import { DataStore } from '~/store';
import { FormikProps } from 'formik';
import { FilterAsset } from './filter-asset';
import { StepWizardChildProps } from 'react-step-wizard';
import { TransferFilterFormikField, TransferFilterFormikProps } from 'common';

export const FilterTransactionAsset: FC<
  Partial<StepWizardChildProps & FormikProps<TransferFilterFormikProps>>
> = ({ previousStep }) => {
  if (!previousStep) {
    return null;
  }

  /**
   * Store
   */
  const preFormValues = DataStore.useStoreState(
    a => a.transactions.preFormValues
  );
  const formValues = DataStore.useStoreState(a => a.transactions.formValues);
  const setPreFormValues = DataStore.useStoreActions(
    a => a.transactions.setPreFormValues
  );

  const handleApplyFilters = useCallback(
    arg => {
      setPreFormValues({
        ...preFormValues,
        [TransferFilterFormikField.filterAssetList]: arg,
      });
      previousStep();
    },
    [preFormValues]
  );

  return (
    <FilterAsset
      onClose={handleApplyFilters}
      initialValues={
        preFormValues?.filterAssetList ?? formValues?.filterAssetList
      }
    />
  );
};
