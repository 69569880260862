import { FC } from 'react';
import { AppHeader } from '../app-header';
import { AppIcon, BaseComponentProps } from 'common';
import { AppIconType } from 'common';
import { AppContainer } from '../app-container';

type Props = {
  bg?: string;
} & BaseComponentProps;

export const PageLayout: FC<Props> = ({ children, bg = 'white' }) => {
  /**
   * DOM
   */
  return (
    <div className={`flex flex-col min-h-screen bg-${bg}`}>
      {/* header  */}
      <AppHeader />

      {/* content  */}
      <AppContainer as="main" cls="text-primary pt-6 sm:pt-12">
        {children}
      </AppContainer>
    </div>
  );
};

type PageTitleProps = {
  icon: AppIconType;
  lead?: string;
} & BaseComponentProps;

export const PageTitle: FC<PageTitleProps> = ({ children, icon, lead }) => {
  return (
    <div className="flex flex-col items-center mb-10">
      <AppIcon icon={icon} size="lg" cls="mb-5" />
      <p className="text-3xl font-bold text-center mb-5">{children}</p>
      {Boolean(lead) && (
        <p className="text-gray-400 text-lg text-center">{lead}</p>
      )}
    </div>
  );
};
