export const getPdfAsBlob = async (
  url: string,
  callback: (blob: Blob | null, error: unknown | null) => void
) => {
  /**
   * Note, could not override/ use fetch for this, so fallback to XHR's
   */
  const httpClient = new XMLHttpRequest();

  httpClient.open('get', url, true);
  httpClient.responseType = 'blob';

  httpClient.onload = () => {
    const blob = new Blob([httpClient.response], { type: 'application/pdf' });
    callback(blob, null);
  };
  httpClient.onerror = err => callback(null, err);

  httpClient.send();
};
