import { SegmentProps, useSystemConfig } from 'common';
import { useEffect, useState } from 'react';
import { Analytics, AnalyticsBrowser, Context } from '@segment/analytics-next';

export const useSegment = () => {
  /**
   * State
   */
  const { segmentKey } = useSystemConfig();

  const [segment, setSegment] = useState<Analytics | undefined>(undefined);

  useEffect(() => {
    if (!segmentKey) {
      // segment is disabled during e2e tests
      return;
    }
    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({ writeKey: segmentKey });
      setSegment(response);
    };
    loadAnalytics();
  }, [segmentKey]);

  return segment;
};
