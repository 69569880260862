import { API } from 'api';
import React, { FC } from 'react';
import cx from 'classnames';

type BannerProps = Pick<API.WhiteLabelLiteSettings, 'text' | 'logoUrl'> & {
  isTextRight: boolean;
};

const Banner = ({ isTextRight, text, logoUrl }: BannerProps) => {
  if (!text || !logoUrl) {
    return null;
  }
  return (
    <div
      className={cx(
        'flex flex-row items-center justify-center bg-white text-primary py-4 px-10 gap-x-4',
        { 'flex-row-reverse': isTextRight }
      )}
    >
      <span className="text-xs text-grey-darker">{text}</span>
      {logoUrl && <img alt="icon" className="w-20 h-12" src={logoUrl} />}
    </div>
  );
};

interface Props {
  settings?: API.WhiteLabelLiteSettings | null;
}

export const WhitelabelBanner: FC<Props> = ({ settings }) => {
  /**
   * DOM
   */
  if (!settings || !settings.isWhiteLabelLite) {
    // For testing purposes
    // return (
    //   <Banner
    //     logoUrl={'https://prod-stablehouse-assets.s3.eu-west-1.amazonaws.com/whitelabel/logo-rj-wll.png'}
    //     text={'In partnership with'}
    //     isTextRight={false}
    //   />
    // )
    return null;
  }
  const { text, textLocation, logoUrl } = settings;
  const isTextRight =
    textLocation === API.WhiteLabelLiteSettingsTextLocation.Right;
  return <Banner logoUrl={logoUrl} text={text} isTextRight={isTextRight} />;
};
