import { FC, memo, useCallback, useMemo } from 'react';
import { shallowEqualObjects } from 'shallow-equal';
import { StepWizardChildProps } from 'react-step-wizard';
import { Form as FormikForm, Formik } from 'formik';
import parseNumber from 'multi-number-parse';
import { useEffectOnce } from 'react-use';
import {
  CurrencyIcon,
  decimalNumberValidation,
  DEFAULTS,
  Enriched,
  EnrichedAccountDetailAsset,
  formatAmount,
  FormHeader,
  Note,
  NUMBER_FORMAT,
  TIMERS,
  TransferFormikField,
  TransferFormikProps,
  useCurrencyInfoContext,
  validateTransferFormik,
} from 'common';
import {
  accountItemTemplate,
  accountSelectedItemTemplate,
  singleWithdrawableItemTemplate,
  singleWithdrawableSelectedItemTemplate,
} from '../../app-selector/templates';
import { TransferSteps } from './steps';
import { AppStore, DataStore } from '../../../store';
import { SelectField } from '../../forms/select-field';
import { NumberField } from '../../forms/number-field';
import debounce from 'lodash.debounce';
import { FormFooter } from '../shared/form-footer';
import { API } from 'api';
import { NotAllowed } from '../shared/not-allowed';
import { TextAreaField } from '~/components/forms/textarea-field';
import { getAccountType } from '~/utils/get-account-details';
import { AssetBalanceBreakdown } from '../shared/asset-balance-breakdown';
import { FeeNote } from '~/components/fee-note';

export const Form: FC<Partial<StepWizardChildProps>> = memo(
  ({ goToNamedStep }) => {
    /**
     * Store
     */
    const allowedAssets = DataStore.useStoreState(
      s => s.transfer.sourceAccountAllowedAssets
    );
    const assetBalances = DataStore.useStoreState(
      s => s.transfer.assetBalances
    );
    const currencies = DataStore.useStoreState(s => s.metaData.currencies);
    const isLoading = DataStore.useStoreState(s => s.transfer.busy);
    const transferError = DataStore.useStoreState(s => s.transfer.error);
    const sourceAccountDetail = DataStore.useStoreState(
      s => s.transfer.sourceAccountDetail
    );
    const destinationAccountDetail = DataStore.useStoreState(
      s => s.transfer.destinationAccountDetail
    );
    const destinationAccountAssetBalance = DataStore.useStoreState(
      s => s.transfer.destinationAccountAssetBalance
    );
    const transfer = DataStore.useStoreState(s => s.transfer.transfer);
    const formValues = DataStore.useStoreState(s => s.transfer.formValues);
    const sourceAccountId = DataStore.useStoreState(
      s => s.transfer.sourceAccountId
    );

    const setFormValues = DataStore.useStoreActions(
      a => a.transfer.setFormValues
    );
    const simulate = DataStore.useStoreActions(a => a.transfer.simulate);
    const getSourceAccountDetail = DataStore.useStoreActions(
      a => a.transfer.getSourceAccountDetail
    );
    const setError = DataStore.useStoreActions(a => a.transfer.setError);
    const getDestinationAccountDetail = DataStore.useStoreActions(
      a => a.transfer.getDestinationAccountDetail
    );
    const accountDetail = DataStore.useStoreState(
      s => s.portfolio.accountDetail
    );
    const dashboardSelectedAsset = AppStore.useStoreState(
      s => s.dashboardSelectedAsset
    );
    const setSelectedDialogType = AppStore.useStoreActions(
      a => a.setDashboardSelectedDialogType
    );
    const transferSourceAccounts = DataStore.useStoreState(
      s => s.transfer.allowedCanTransferAccounts
    );
    const transferDestinationAccounts = DataStore.useStoreState(
      s => s.transfer.destinationAccounts
    );
    const withdrawableAmount = DataStore.useStoreState(
      s => s.transfer.assetBalances?.withdrawable ?? 0
    );

    /**
     * Hooks
     */
    const debouncedSimulate = useMemo(
      () => debounce(simulate, TIMERS.SIMULATE_DEBOUNCE),
      []
    );

    /**
     * Methods
     */
    const onFormValidate = (values: TransferFormikProps) => {
      setFormValues(values);

      if (!currencies) {
        return;
      }
      setError(null);
      const errors = validateTransferFormik(
        values,
        currencies,
        sourceAccountDetail || undefined,
        destinationAccountDetail || undefined
      );
      if (shallowEqualObjects(formValues, values)) {
        return errors;
      }

      if (Object.keys(errors).length === 0) {
        debouncedSimulate();
      } else {
        debouncedSimulate.cancel();
      }

      return errors;
    };
    const onFormSubmit = async () => {
      setError(null);
      if (!goToNamedStep) {
        return;
      }
      goToNamedStep(TransferSteps.Preview);
    };

    /**
     * DOM
     */
    if (!allowedAssets || allowedAssets.length <= 0) {
      return (
        <NotAllowed
          message={`There are no assets in your account ${getAccountType(
            accountDetail?.account
          )} ${
            accountDetail?.account?.accountNumber
          } to perform this operation`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }
    const initialCurrencyCode = dashboardSelectedAsset?.currency.code || null;
    if (
      initialCurrencyCode &&
      !allowedAssets.find(a => a.currency.code === initialCurrencyCode)
    ) {
      // chosen asset is not in account!
      return (
        <NotAllowed
          message={`There is no ${
            dashboardSelectedAsset?.currency.displayCode ||
            dashboardSelectedAsset?.currency.code ||
            'asset'
          } in your account ${getAccountType(accountDetail?.account)} ${
            accountDetail?.account?.accountNumber
          } to perform this operation`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }
    const initialValues: TransferFormikProps = {
      currencyCode: initialCurrencyCode,
      amount: formValues?.amount ? formatAmount(formValues?.amount) : null,
      sourceAccountId: formValues?.sourceAccountId || sourceAccountId || null,
      destinationAccountId: formValues?.destinationAccountId || null,
      description: formValues?.description || '',
    };
    return (
      <div className="flex flex-col">
        {/* form  */}
        <Formik<TransferFormikProps>
          initialValues={initialValues}
          validate={onFormValidate}
          validateOnBlur={false}
          validateOnMount={false}
          validateOnChange={false}
          onSubmit={onFormSubmit}
        >
          {({
            values,
            errors,
            setFieldValue,
            setFieldTouched,
            submitForm,
            isValidating,
            setValues,
          }) => {
            const hasErrors = Object.values(errors).length > 0;

            /**
             * Hooks
             */
            useEffectOnce(() => {
              (async () => {
                await getSourceAccountDetail({
                  accountId: values.sourceAccountId || '',
                });
              })();
            });
            const selectedFund = useMemo(
              () =>
                allowedAssets?.find(
                  f => f.currency.code === values.currencyCode
                ),
              [allowedAssets, values.currencyCode]
            );

            /**
             * Handle Methods
             */
            const handleOnStartingAccountChanged = async (
              newValue: Enriched.ListAccountItem
            ) => {
              const accountId = newValue.account?.accountId || '';
              await setValues(
                {
                  currencyCode: null,
                  amount: '',
                  sourceAccountId: accountId,
                  destinationAccountId: null,
                  description: '',
                },
                true
              );
              await getSourceAccountDetail({
                accountId,
              });
            };

            const handleOnDestinationAccountChanged = async (
              newValue: Enriched.ListAccountItem
            ) => {
              const accountId = newValue.account?.accountId || '';
              await getDestinationAccountDetail({
                accountId,
              });
              await setFieldValue(
                TransferFormikField.destinationAccountId,
                accountId,
                true
              );
            };

            const handleOnFundChanged = useCallback(
              async (newValue: EnrichedAccountDetailAsset) => {
                await setFieldValue(TransferFormikField.amount, '', false);
                await setFieldValue(
                  TransferFormikField.currencyCode,
                  newValue.currency.code,
                  true
                );
              },
              []
            );

            const handleOnMaxButtonClicked = async () => {
              if (isValidating) {
                return;
              }
              const formattedBalance = formatAmount(
                selectedFund?.withdrawableQuantity,
                DEFAULTS.MAX_DECIMALS
              );
              await setFieldValue(
                TransferFormikField.amount,
                formattedBalance,
                true
              );
            };

            const handleOnCancelButtonClicked = useCallback(
              (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                setSelectedDialogType(null);
              },
              [setSelectedDialogType]
            );

            const handleOnNextButtonClicked = (
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              e.preventDefault();
              submitForm();
            };

            const handleOnAmountChanged = async (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              const amount = decimalNumberValidation(e.target.value);
              await setFieldTouched(e.target.name, true, false);
              await setFieldValue(TransferFormikField.amount, amount, true);
            };

            const handleOnDescriptionChanged = async (
              e: React.ChangeEvent<HTMLTextAreaElement>
            ) => {
              await setFieldTouched(e.target.name, true, false);
              await setFieldValue(e.target.name, e.target.value, false);
            };

            const debounceOnDescriptionChanged = debounce(
              handleOnDescriptionChanged,
              TIMERS.FORMIK_DEBOUNCE
            );

            /**
             * Form DOM
             */
            const currency = useCurrencyInfoContext(values.currencyCode);
            const hasAllFieldValues =
              values.amount &&
              values.currencyCode &&
              values.sourceAccountId &&
              values.destinationAccountId;
            const submitIsDisabled =
              isLoading ||
              isValidating ||
              parseNumber(values.amount || '0') <= 0 ||
              !hasAllFieldValues ||
              transferError !== null ||
              hasErrors ||
              !transfer;

            const feeNote =
              !!transfer && !isValidating && !hasErrors && !submitIsDisabled ? (
                transfer.feeMode ===
                API.WithdrawalFeeChargeMode.ChargedImmediately ? (
                  <FeeNote
                    feeLabel={
                      transfer.fee ? `${transfer.formattedFee}` : 'FREE'
                    }
                    extraLine={
                      <>
                        <span>You send:</span>{' '}
                        <span className="font-bold text-primary">
                          {transfer.formattedSendingAmount}
                        </span>{' '}
                        <span className="text-primary">
                          ({transfer.formattedSendingAmountUsd})
                        </span>
                      </>
                    }
                  />
                ) : (
                  <FeeNote
                    feeLabel={transfer.formattedFeeUsd}
                    feeFirst={false}
                    extraLine={
                      <>
                        Value:{' '}
                        <b className="text-primary">
                          {transfer.formattedAmountUsd}
                        </b>
                      </>
                    }
                  />
                )
              ) : null;

            return (
              <FormikForm>
                {/* header  */}
                <FormHeader
                  cls="mb-4"
                  title={`Transfer ${
                    currency ? currency?.displayCode?.toLocaleUpperCase() : ''
                  }`}
                />

                <div className="px-5 md:px-10">
                  {/* starting account  */}
                  <div className="flex flex-col flex-1 ">
                    <SelectField
                      name={TransferFormikField.sourceAccountId}
                      label="Starting account"
                      values={transferSourceAccounts}
                      disableHelpers
                      getSelectedItemTemplate={accountId =>
                        accountSelectedItemTemplate(
                          transferSourceAccounts.find(
                            acc => acc.account?.accountId === accountId
                          )
                        )
                      }
                      disabled={transferSourceAccounts?.length === 1}
                      getItemTemplate={accountItemTemplate}
                      onChange={handleOnStartingAccountChanged}
                    />
                  </div>

                  {/* fund  */}
                  <div className="flex flex-col flex-1 mt-4">
                    <SelectField
                      name={TransferFormikField.currencyCode}
                      label="Choose the currency you want to transfer"
                      values={allowedAssets}
                      disableHelpers
                      getSelectedItemTemplate={code =>
                        singleWithdrawableSelectedItemTemplate(
                          allowedAssets.find(f => f.currency.code === code)
                        )
                      }
                      getItemTemplate={(
                        fund,
                        selected: boolean,
                        active: boolean
                      ) =>
                        singleWithdrawableItemTemplate(fund, selected, active)
                      }
                      onChange={handleOnFundChanged}
                    />

                    <AssetBalanceBreakdown
                      availableToTitle={'Available to transfer now'}
                      balances={assetBalances}
                    />
                  </div>

                  {/* destination account  */}
                  <div className="flex flex-col flex-1 mt-4">
                    <SelectField
                      name={TransferFormikField.destinationAccountId}
                      label="Destination account"
                      values={transferDestinationAccounts}
                      disableHelpers
                      getSelectedItemTemplate={accountId =>
                        accountSelectedItemTemplate(
                          transferDestinationAccounts.find(
                            acc => acc.account?.accountId === accountId
                          )
                        )
                      }
                      getItemTemplate={accountItemTemplate}
                      onChange={handleOnDestinationAccountChanged}
                    />

                    {Boolean(selectedFund) &&
                      !isValidating &&
                      !!values.destinationAccountId &&
                      !!destinationAccountAssetBalance && (
                        <Note
                          cls="flex justify-between justify-center"
                          textSize="sm"
                          textColor="text-grey-darker"
                        >
                          <span>
                            Existing balance:{' '}
                            <b className="text-primary">
                              {destinationAccountAssetBalance}
                            </b>
                          </span>
                        </Note>
                      )}
                  </div>
                  {/* amount  */}
                  <div className="flex flex-col flex-1 mt-4">
                    <NumberField
                      name={TransferFormikField.amount}
                      label="Amount"
                      placeholder="Enter amount"
                      allowNegative={false}
                      disabled={!selectedFund || !selectedFund.hasBalance}
                      decimalScale={
                        selectedFund?.currency?.decimals ||
                        DEFAULTS.DECIMAL_SCALE
                      }
                      autoComplete="off"
                      thousandSeparator={NUMBER_FORMAT.THOUSANDS_SEPARATOR}
                      leftAddon={
                        values.currencyCode ? (
                          <div className="px-0 py-2 h-full w-1/4">
                            <CurrencyIcon currencyCode={values.currencyCode} />
                          </div>
                        ) : undefined
                      }
                      rightAddon={
                        selectedFund &&
                        withdrawableAmount &&
                        Number(withdrawableAmount) > 0 ? (
                          <button
                            type="button"
                            className="text-sm font-bold underline focus:outline-none relative mb-1"
                            onClick={handleOnMaxButtonClicked}
                          >
                            Max
                          </button>
                        ) : undefined
                      }
                      onChange={handleOnAmountChanged}
                    />
                  </div>
                  {feeNote}

                  {/* description */}
                  <div className="flex flex-col mt-4">
                    <TextAreaField
                      label="Description"
                      placeholder={'Add description'}
                      maxLength={1024}
                      name={TransferFormikField.description}
                      onChange={debounceOnDescriptionChanged}
                    />
                  </div>
                </div>

                {/* actions  */}
                <FormFooter
                  error={transferError}
                  onSubmit={handleOnNextButtonClicked}
                  onCancel={handleOnCancelButtonClicked}
                  isSubmitDisabled={submitIsDisabled}
                  submitText="Preview"
                />
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    );
  }
);
