import { FC } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { nameof } from '../../utils/typescript-helpers';
import { AppIcon, DEFAULTS, YUP_VALIDATIONS } from 'common';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { CharacterInputField } from '../forms/character-input-field';

interface FormProps {
  code: string;
}

const formSchema = Yup.object().shape({
  [nameof<FormProps>('code')]: YUP_VALIDATIONS.TWO_FA_CODE,
});

interface Props {
  isEnabled: boolean;
  askFor2FA: boolean;
  error?: string | null;
  onChange: (code: string) => void;
}

export const TwoFaWidget: FC<Props> = ({
  isEnabled,
  askFor2FA,
  onChange,
  error,
}) => {
  /**
   * Methods
   */
  const onFormSubmit = async (values: FormProps) => {
    onChange(values.code);
  };

  const onFormValidate = (values: FormProps) => {
    if (
      !formSchema.isValidSync({
        [nameof<FormProps>('code')]: values.code,
      })
    ) {
      onFormSubmit({
        [nameof<FormProps>('code')]: '',
      });
      return;
    }
    onFormSubmit(values);
  };

  /**
   * DOM
   */
  if (!askFor2FA) {
    return null;
  }
  if (!isEnabled && askFor2FA) {
    return (
      <div className="flex flex-col items-center p-8 pt-7">
        <AppIcon icon="circle-shield-check" size={48} />
        <h4 className="flex items-center text-center mb-3 flex-row">
          <span className="font-bold">2FA protects your account.</span>
        </h4>
        <p className="text-gray-400 text-center text-lg mb-5">
          Please set up 2FA to proceed.
        </p>
        <Link
          className="app-button-accent typo-b2 xl:typo-b1 no-underline"
          role="button"
          to={`${APP_ROUTES.AUTH_SETTINGS}/security/2fa`}
        >
          Setup 2FA
        </Link>
      </div>
    );
  }
  const initialValues: FormProps = {
    code: '',
  };
  return (
    <Formik<FormProps>
      initialValues={initialValues}
      validate={onFormValidate}
      validationSchema={formSchema}
      validateOnBlur={false}
      validateOnMount={false}
      validateOnChange
      onSubmit={onFormSubmit}
    >
      {() => {
        // DOM
        return (
          <Form>
            {/* content  */}
            <div className="flex flex-col items-center py-8 pt-0">
              <AppIcon icon="circle-shield-check" size={48} />
              <h4 className="flex mt-1 text-xl items-center mb-3">
                <span className="font-bold">
                  Enter your 2FA authentication code
                </span>
              </h4>

              <p className="text-gray-400 text-center mb-5">
                You’ll find the verification code generated <br />
                on your authenticator app.
              </p>
              <CharacterInputField
                name="code"
                aria-label="two-factor authentication code"
                length={DEFAULTS.MAX_TWO_FA_LENGTH}
                hasError={!!error}
              />
              {error && (
                <div className="text-center">
                  <p className="text-xs text-failure my-1 font-bold">{error}</p>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
