export const excludePropInObj = <T>(obj: T, prop: string) => {
  if (!obj) {
    return obj;
  }
  return Object.keys(obj).reduce((out, key) => {
    if (key === prop) {
      return out;
    }
    out[key] = obj[key];
    return out;
  }, {} as T);
};
