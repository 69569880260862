import { useFormikContext } from 'formik';
import { FC } from 'react';
import { AppStore } from '../../store';

interface Props {
  showValues?: boolean;
  showErrors?: boolean;
  showTouched?: boolean;
}

export const FormikDebug: FC<Props> = ({
  showErrors = true,
  showValues = false,
  showTouched = false,
}) => {
  /**
   * Store
   */
  const devtoolsOptions = AppStore.useStoreState(s => s.devtoolsOptions);

  /**
   * Hooks
   */
  const form = useFormikContext();

  /**
   * DOM
   */
  if (!devtoolsOptions.showFormikDebug) {
    return null;
  }
  return (
    <>
      {showValues && (
        <pre className="bg-gray-50 p-5 m-5 border-1 border-black rounded-md">
          Values:
          {JSON.stringify(form.values, undefined, 2)}
        </pre>
      )}
      {showTouched && (
        <pre className="bg-gray-50 p-5 m-5 border-1 border-black rounded-md">
          Touched:
          {JSON.stringify(form.touched, undefined, 2)}
        </pre>
      )}
      {showErrors && (
        <pre className="bg-gray-50 p-5 m-5 border-1 border-black rounded-md">
          Errors:
          {JSON.stringify(form.errors, undefined, 2)}
        </pre>
      )}
    </>
  );
};
