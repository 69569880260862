/* eslint-disable */
import { API } from 'api';
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { DEFAULTS } from '../constants';
import {
  EnrichedActivityType,
  filterIgnoredActivityTypes,
  getActivityTypes,
} from '../utils/get-activity-types';
import { DataModel } from './data-store';
import {
  ClearError,
  Injections,
  AdditionalOptionsXHR,
  ThunkResult,
  ApiThunk,
  ApiResponse,
} from './types';
import {
  createEnrichedInternalTransfer,
  createEnrichedInvoice,
  factories,
  getApiErrorMessage,
  runApi,
  TransferFilterFormikProps,
} from '../utils';
import { runThunk } from '../utils/run-thunk';
import { Enriched, EnrichedInternalTransfer, EnrichedInvoice } from '../types';
import { BaseModel, createBaseModel } from './base-store';
import { enrichTransaction } from '../utils/factories/enrich-transaction';

export const isTransfer = (
  activity?: API.ActivityTransaction | null
): boolean => {
  if (!activity) return false;
  return [
    API.ActivityType.Transfer,
    API.ActivityType.TransferIn,
    API.ActivityType.TransferOut,
  ].includes(activity.type);
};
export const isInternalTransfer = (
  activity?: API.ActivityTransaction | null
): boolean => {
  if (!activity) return false;
  return [
    API.ActivityType.InternalTransfer,
    API.ActivityType.InternalTransferIn,
    API.ActivityType.InternalTransferOut,
  ].includes(activity.type);
};

export type Transaction =
  | Enriched.Withdrawal
  | API.FiatWithdrawalWithTimeline
  | Enriched.Transfer
  | API.Payment
  | API.Deposit
  | Enriched.Trade
  | Enriched.FiatWithdrawal
  | EnrichedInternalTransfer
  | EnrichedInvoice
  | Enriched.FiatDeposit;

export type GetTransactionsPayload = {
  request: Partial<API.GetActivityTransactionsRequest>;
} & AdditionalOptionsXHR & {
    accountId: string;
    applyLoading?: boolean;
    impersonatedAccountId?: string;
  };

type GetActivityTransactionReq = {
  activity: API.ActivityTransaction;
  accountId: string;
  impersonatedAccountId?: string;
  silent?: boolean;
};

export interface TransactionsModel extends BaseModel {
  // state
  activityTypes: Computed<
    TransactionsModel,
    EnrichedActivityType[] | null,
    DataModel
  >;
  activitiesById: ActivityRecord;
  activityIds: string[];
  paginatedActivitiesFilters: Partial<API.GetActivityTransactionsRequest>;
  paginatedActivities: Enriched.ActivityTransactionArrayPaginatedApiResponse | null;
  activity: Enriched.ActivityTransaction | null;
  transaction: Transaction | null;
  pendingActivityCount: number | null;
  formValues: TransferFilterFormikProps;
  preFormValues: TransferFilterFormikProps;
  filterCount: number;
  isPaginationEnd: boolean;

  // computed
  activities: Computed<TransactionsModel, Enriched.ActivityTransaction[]>;
  // actions
  resetPaginatedActivitiesFilters: Action<TransactionsModel>;
  setPaginatedActivitiesFilters: Action<
    TransactionsModel,
    Partial<API.GetActivityTransactionsRequest>
  >;
  _setPaginatedActivities: Action<
    TransactionsModel,
    Enriched.ActivityTransactionArrayPaginatedApiResponse | null
  >;
  setPaginatedActivities: Thunk<
    TransactionsModel,
    API.ActivityTransactionArrayPaginatedApiResponse | null,
    Injections,
    DataModel
  >;
  upsertActivities: Action<TransactionsModel, ActivityRecord>;
  addActivityIds: Action<TransactionsModel, string[]>;
  setActivityIds: Action<TransactionsModel, string[]>;
  _setActivity: Action<TransactionsModel, Enriched.ActivityTransaction | null>;
  setActivity: Thunk<
    TransactionsModel,
    API.ActivityTransaction | null,
    Injections,
    DataModel
  >;
  _setTransaction: Action<TransactionsModel, Transaction | null>;
  setPendingActivityCount: Action<TransactionsModel, number | null>;
  setFormValues: Action<TransactionsModel, TransferFilterFormikProps>;
  setPreFormValues: Action<TransactionsModel, TransferFilterFormikProps>;
  setFilterCount: Action<TransactionsModel, number>;
  resetInfiniteScrollActivityIds: Action<TransactionsModel, void>;

  // thunk
  findActivity: Thunk<
    TransactionsModel,
    string,
    Injections,
    DataModel,
    Enriched.ActivityTransaction | null
  >;
  setActivityTransaction: Thunk<
    TransactionsModel,
    | API.Withdrawal
    | API.FiatWithdrawalWithTimeline
    | API.Transfer
    | API.Payment
    | API.Deposit
    | API.Trade
    | API.InternalTransfer
    | API.Invoice
    | null,
    Injections,
    DataModel
  >;
  getPaginatedActivities: Thunk<
    TransactionsModel,
    GetTransactionsPayload &
      ClearError & {
        accountId: string;
      },
    Injections,
    DataModel
  >;
  getInfiniteScrollActivities: Thunk<
    TransactionsModel,
    {
      request: Partial<API.GetActivityTransactionsRequest>;
      accountId: string;
    } & AdditionalOptionsXHR,
    Injections,
    DataModel,
    Promise<void>
  >;
  resetInfiniteScrollActivities: Thunk<
    TransactionsModel,
    string,
    Injections,
    DataModel,
    Promise<void>
  >;
  getActivityTransaction: ApiThunk<
    TransactionsModel,
    GetActivityTransactionReq,
    | API.Withdrawal
    | API.FiatWithdrawalWithTimeline
    | API.Transfer
    | API.Payment
    | API.Deposit
    | API.Trade
    | API.InternalTransfer
    | API.Invoice
    | null
  >;
  getActivitiesCsv: Thunk<
    TransactionsModel,
    { accountId: string; impersonatedAccountId?: string },
    Injections,
    DataModel,
    Promise<ThunkResult<{ filename: string | undefined; blob: Blob }>>
  >;
  cancelWithdrawal: Thunk<
    TransactionsModel,
    { request: API.CancelWithdrawalRequest; accountId: string },
    Injections,
    DataModel,
    Promise<ThunkResult<null>>
  >;
  cancelFiatWithdrawal: Thunk<
    TransactionsModel,
    { request: API.CancelFiatWithdrawalRequest; accountId: string },
    Injections,
    DataModel,
    Promise<ThunkResult<null>>
  >;
  cancelTransfer: Thunk<
    TransactionsModel,
    { request: API.CancelTransferRequest; accountId: string },
    Injections,
    DataModel,
    Promise<ThunkResult<null>>
  >;
}

type ActivityRecord = Record<string, Enriched.ActivityTransaction>;

function normalizeActivities(
  result: Enriched.ActivityTransaction[]
): [ActivityRecord, string[]] {
  const ids: string[] = [];
  const byId: ActivityRecord = {};

  result.forEach(activity => {
    ids.push(activity.id);
    byId[activity.id] = activity;
  });

  return [byId, ids];
}

const fetchActivityTransaction = (
  apiClient: API.StablehouseClient,
  type: API.ActivityType,
  id: string
): Promise<
  ApiResponse<
    | API.Withdrawal
    | API.FiatWithdrawalWithTimeline
    | API.Transfer
    | API.Payment
    | API.Deposit
    | API.Trade
    | API.InternalTransfer
    | API.Invoice
    | null
  >
> => {
  switch (type) {
    case API.ActivityType.Withdrawal:
      return apiClient.getWithdrawal({ id, clientId: null });
    case API.ActivityType.FiatWithdrawal:
      return apiClient.getFiatWithdrawal({ id });
    case API.ActivityType.Transfer:
    case API.ActivityType.TransferIn:
    case API.ActivityType.TransferOut:
      return apiClient.getTransfer({ id });
    case API.ActivityType.InternalTransfer:
    case API.ActivityType.InternalTransferIn:
    case API.ActivityType.InternalTransferOut:
      return apiClient.getInternalTransfer({ id });
    case API.ActivityType.Payment:
      return apiClient.getPayment({ id, clientId: null });
    case API.ActivityType.FiatDeposit:
      return Promise.resolve({ isSuccessful: true, result: null });
    case API.ActivityType.Deposit:
      return apiClient.getSingleDeposit({ depositId: id });
    case API.ActivityType.Trade:
      return apiClient.getTrade({ id });
    case API.ActivityType.InvoicePayment:
      return apiClient.getInvoiceDetails({ id });
    default:
      return Promise.resolve({ isSuccessful: true, result: null });
  }
};

const initialPaginatedActivitiesFilters = {
  page: DEFAULTS.PAGE,
  pageSize: DEFAULTS.PAGE_SIZE,
  dateStart: null,
  dateEnd: null,
  currencyCodeFilter: null,
  statusFilter: null,
};

const defaultActivityHistoryRequest: API.GetActivityTransactionsRequest = {
  pageSize: DEFAULTS.PAGE_SIZE,
  page: DEFAULTS.PAGE,
  currencyCodeFilter: null,
  dateStart: null,
  dateEnd: null,
  sortColumn: null,
  sortDirection: null,
  statusFilter: null,
  activityLabels: null,
};

export const transactionsModel: TransactionsModel = {
  ...createBaseModel(),

  // state
  activityTypes: computed([], () => {
    const activityTypes = getActivityTypes();
    return activityTypes;
  }),
  activitiesById: {},
  activityIds: [],
  paginatedActivitiesFilters: initialPaginatedActivitiesFilters,
  paginatedActivities: null,
  activity: null,
  transaction: null,
  pendingActivityCount: null,
  formValues: {
    filterAssetList: [],
    filterDateRange: {
      startDate: null,
      endDate: null,
    },
    filterStartDate: null,
    filterEndDate: null,
    filterDatePeriod: null,
    filterType: [],
    filterStatus: [],
  },
  preFormValues: {
    filterAssetList: [],
    filterDateRange: {
      startDate: null,
      endDate: null,
    },
    filterStartDate: null,
    filterEndDate: null,
    filterDatePeriod: null,
    filterType: [],
    filterStatus: [],
  },
  filterCount: 0,
  isPaginationEnd: false,

  // computed
  activities: computed(
    [state => state.activityIds, state => state.activitiesById],
    (ids, byId) => {
      return ids.map(id => byId[id]);
    }
  ),
  // actions
  resetPaginatedActivitiesFilters: action(state => {
    state.paginatedActivitiesFilters = initialPaginatedActivitiesFilters;
  }),
  resetInfiniteScrollActivityIds: action(state => {
    state.activityIds = [];
  }),
  setPaginatedActivitiesFilters: action((state, payload) => {
    state.paginatedActivitiesFilters = payload;
  }),
  _setPaginatedActivities: action((state, payload) => {
    state.paginatedActivities = payload;

    if (payload?.result) {
      state.isPaginationEnd = !payload.result.length;
    }
  }),
  setPaginatedActivities: thunk(async (actions, payload, { getStoreState }) => {
    if (!payload?.result) {
      return;
    }
    const storeState = getStoreState();

    actions._setPaginatedActivities(
      factories.enrichActivitiesPaginatedResponse(
        payload,
        storeState.metaData.fiatCurrencyCodes
      )
    );
  }),
  upsertActivities: action((state, activitiesById) => {
    state.activitiesById = {
      ...state.activitiesById,
      ...activitiesById,
    };

    state.isPaginationEnd = !Object.keys(activitiesById).length;
  }),
  addActivityIds: action((state, ids) => {
    // Push unique IDs for when backend returns overlapping results
    ids.forEach(id => {
      if (!state.activityIds.includes(id)) {
        state.activityIds.push(id);
      }
    });
  }),
  setActivityIds: action((state, ids) => {
    state.activityIds = ids;
  }),
  _setActivity: action((state, payload) => {
    state.activity = payload;
  }),
  setActivity: thunk(async (actions, payload, { getStoreState }) => {
    if (payload) {
      const storeState = getStoreState();

      actions._setActivity(
        enrichTransaction(payload, storeState.metaData.fiatCurrencyCodes)
      );
    } else {
      actions._setActivity(null);
    }
  }),
  _setTransaction: action((state, payload) => {
    state.transaction = payload;
  }),
  setPendingActivityCount: action((state, payload) => {
    state.pendingActivityCount = payload;
  }),
  setFormValues: action((state, payload) => {
    state.formValues = payload;
  }),
  setPreFormValues: action((state, payload) => {
    state.preFormValues = payload;
  }),
  setFilterCount: action((state, payload) => {
    state.filterCount = payload;
  }),

  // thunk
  findActivity: thunk((_actions, id, { getState }) => {
    const state = getState();

    return state.activitiesById[id] ?? null;
  }),
  setActivityTransaction: thunk(
    async (actions, payload, { getState, getStoreState }) => {
      if (!payload) {
        return;
      }
      const state = getState();
      const storeState = getStoreState();

      actions._setTransaction(
        state.activity?.type === API.ActivityType.InvoicePayment
          ? createEnrichedInvoice(
              payload as API.Invoice,
              storeState.metaData.currencies
            )
          : isInternalTransfer(state.activity)
          ? createEnrichedInternalTransfer(
              payload as API.InternalTransferWithTimeline,
              storeState.metaData.currencies,
              storeState.metaData.fiatCurrencyCodes
            )
          : state.activity?.type === API.ActivityType.Trade
          ? factories.enrichTrade(
              payload as API.Trade,
              storeState.metaData.fiatCurrencyCodes
            )
          : isTransfer(state.activity)
          ? factories.enrichTransfer(
              payload as API.Transfer,
              storeState.metaData.currencies,
              storeState.metaData.fiatCurrencyCodes
            )
          : state.activity?.type === API.ActivityType.Withdrawal
          ? factories.enrichWithdrawal(
              payload as API.Withdrawal,
              storeState.metaData.currencies,
              storeState.metaData.fiatCurrencyCodes
            )
          : state.activity?.type === API.ActivityType.FiatWithdrawal
          ? factories.enrichFiatWithdrawal(
              payload as API.FiatWithdrawal,
              storeState.metaData.fiatCurrencyCodes
            )
          : state.activity?.type === API.ActivityType.FiatDeposit
          ? factories.enrichFiatDeposit(
              payload as API.FiatDeposit,
              storeState.metaData.fiatCurrencyCodes
            )
          : (payload as Transaction)
      );
    }
  ),
  getPaginatedActivities: thunk(async (actions, payload, helpers) => {
    runThunk<API.ActivityTransactionArrayPaginatedApiResponse>(
      helpers,
      {
        execute: async () => {
          const additionalHeaders = {
            'x-account-id': payload.accountId,
            ...(!!payload.impersonatedAccountId && {
              'x-impersonated-account-id': payload.impersonatedAccountId,
            }),
          };
          if (payload.applyLoading) {
            actions.setBusy(true);
          }
          helpers.injections.apiClient.setAdditionalHeaders(additionalHeaders);

          actions.setPaginatedActivitiesFilters(payload.request);
          const curatedPayload = filterIgnoredActivityTypes(payload.request);

          const request = {
            ...defaultActivityHistoryRequest,
            ...curatedPayload,
          };
          return await helpers.injections.apiClient.getActivityTransactions(
            request
          );
        },
        onSucccess: response => {
          actions.setPaginatedActivities(response);
          if (payload?.applyLoading) {
            actions.setBusy(false);
          }
        },
        onError: message => {
          if (payload.throwOnError) {
            throw new Error('Error on getPaginatedActivities: ' + message);
          }
        },
      },
      !payload.isBackgroundXHR,
      payload.clearError,
      null
    );
  }),
  getInfiniteScrollActivities: thunk(
    async (
      actions,
      payload,
      { injections, getStoreActions, getStoreState }
    ) => {
      const storeActions = getStoreActions();
      const storeState = getStoreState();
      // Used in `mobile`, `web` has normal pagination while `mobile` has infinite scroll pagination

      try {
        if (!payload.isBackgroundXHR) {
          actions.setBusy(true);
        }

        const request = {
          ...defaultActivityHistoryRequest,
          ...payload.request,
        };

        if (payload.request.activityLabels) {
          Object.assign(request, filterIgnoredActivityTypes(payload.request));
        }

        actions.setPaginatedActivitiesFilters(request);

        injections.apiClient.setAdditionalHeaders({
          'x-account-id': payload.accountId,
        });

        const res = await injections.apiClient.getActivityTransactions(request);

        if (res.isSuccessful && res.result) {
          const [byId, ids] = normalizeActivities(
            factories.enrichActivities(
              res.result,
              storeState.metaData.fiatCurrencyCodes
            )
          );

          actions.upsertActivities(byId);

          if (request.page === 1) {
            actions.setActivityIds(ids);
          } else {
            actions.addActivityIds(ids);
          }
        } else if (res.errorMessage) {
          if (!payload.isBackgroundXHR) {
            storeActions.setError(res.errorMessage);
          }
        }
      } catch (err) {
        if (!payload.isBackgroundXHR) {
          storeActions.setError(getApiErrorMessage(err));
        }
      } finally {
        actions.setBusy(false);
      }
    }
  ),
  resetInfiniteScrollActivities: thunk(async (actions, accountId) => {
    actions.resetPaginatedActivitiesFilters();

    await actions.getInfiniteScrollActivities({
      request: {
        page: 1,
        sortColumn: API.SortColumn.Date,
        sortDirection: API.SortDirection.Descending,
      },
      accountId,
    });
  }),
  getActivityTransaction: thunk(
    async (
      actions,
      { accountId, activity, impersonatedAccountId, silent = false },
      helpers
    ) => {
      actions.setActivity(activity);

      const additionalHeaders = Object.assign(
        { 'x-account-id': accountId },
        impersonatedAccountId
          ? { 'x-impersonated-account-id': impersonatedAccountId }
          : undefined
      );

      return runApi(
        actions,
        helpers,
        async () => {
          if (
            activity.type === API.ActivityType.YieldPayout ||
            activity.type === API.ActivityType.Reward ||
            activity.type === API.ActivityType.FiatDeposit
          ) {
            actions.setActivityTransaction(null);

            return {
              isSuccessful: true,
            };
          }

          const state = helpers.getState();

          const previousActivityId = state.transaction?.id ?? null;

          if (previousActivityId && activity.id !== previousActivityId) {
            actions.setActivityTransaction(null);
          }

          return fetchActivityTransaction(
            helpers.injections.apiClient,
            activity.type,
            activity.id
          );
        },
        result => {
          actions.setActivityTransaction(result);
        },
        additionalHeaders,
        silent
      );
    }
  ),
  getActivitiesCsv: thunk(
    async (_actions, payload, { injections, getStoreActions, getState }) => {
      const storeActions = getStoreActions();

      try {
        storeActions.setBusy(true);
        storeActions.setError(null);

        const filters = getState().paginatedActivitiesFilters;
        if (!filters) {
          return {
            isSuccessful: false,
            errorMessage: 'Filters are not set',
          };
        }

        const curatedPayload = filterIgnoredActivityTypes({
          ...filters,
          page: 1,
          pageSize: DEFAULTS.MAX_EXPORT_RECORDS,
        });
        const request = {
          ...defaultActivityHistoryRequest,
          ...curatedPayload,
        };

        injections.apiClient.setAdditionalHeaders({
          'x-account-id': payload.accountId,
          ...(!!payload.impersonatedAccountId && {
            'x-impersonated-account-id': payload.impersonatedAccountId,
          }),
        });

        const response = await injections.apiClient.getActivityTransactionsCsv(
          request
        );

        storeActions.setBusy(false);
        return {
          isSuccessful: true,
          result: response as unknown as {
            filename: string | undefined;
            blob: Blob;
          },
        };
      } catch (error) {
        const errorMessage = getApiErrorMessage(error);
        storeActions.setError(errorMessage);
        storeActions.setBusy(false);
        return {
          isSuccessful: false,
          errorMessage,
        };
      }
    }
  ),
  cancelWithdrawal: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        actions.setBusy(true);

        storeActions.setBusy(true);
        storeActions.setError(null);

        injections.apiClient.setAdditionalHeaders({
          'x-account-id': payload.accountId,
        });

        const response = await injections.apiClient.cancelWithdrawal(
          payload.request
        );
        const { isSuccessful, errorMessage = null } = response || {};
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);

          return {
            isSuccessful: false,
          };
        }
        storeActions.setBusy(false);
        return {
          isSuccessful: true,
        };
      } catch (error) {
        const errorMessage = getApiErrorMessage(error);
        storeActions.setError(errorMessage);
        storeActions.setBusy(false);
        return {
          isSuccessful: false,
        };
      } finally {
        actions.setBusy(false);
      }
    }
  ),
  cancelFiatWithdrawal: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        actions.setBusy(true);

        storeActions.setBusy(true);
        storeActions.setError(null);

        injections.apiClient.setAdditionalHeaders({
          'x-account-id': payload.accountId,
        });

        const response = await injections.apiClient.cancelFiatWithdrawal(
          payload.request
        );
        const { isSuccessful, errorMessage = null } = response || {};
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return {
            isSuccessful: false,
          };
        }
        storeActions.setBusy(false);
        return {
          isSuccessful: true,
        };
      } catch (error) {
        const errorMessage = getApiErrorMessage(error);
        storeActions.setError(errorMessage);
        storeActions.setBusy(false);
        return {
          isSuccessful: false,
        };
      } finally {
        actions.setBusy(false);
      }
    }
  ),
  cancelTransfer: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        actions.setBusy(true);

        storeActions.setBusy(true);
        storeActions.setError(null);

        injections.apiClient.setAdditionalHeaders({
          'x-account-id': payload.accountId,
        });

        const response = await injections.apiClient.cancelTransfer(
          payload.request
        );
        const { isSuccessful, errorMessage = null } = response || {};
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return {
            isSuccessful: false,
          };
        }
        storeActions.setBusy(false);
        return {
          isSuccessful: true,
        };
      } catch (error) {
        const errorMessage = getApiErrorMessage(error);
        storeActions.setError(errorMessage);
        storeActions.setBusy(false);
        return {
          isSuccessful: false,
        };
      } finally {
        actions.setBusy(false);
      }
    }
  ),
};
