import { FC } from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

interface Props {
  isFixed?: boolean;
  bgColor?: string;
  spinnerTop?: string;
  unsetZindex?: boolean;
}

export const AppLoader: FC<Props> = ({
  isFixed = false,
  bgColor = 'bg-black-70',
  spinnerTop = '50%',
  unsetZindex = false,
}) => {
  /**
   * DOM
   */
  const compStyle = unsetZindex ? { zIndex: 'unset' } : undefined;
  return (
    <div
      className={cx(
        styles.component,
        bgColor,
        { absolute: !isFixed },
        { fixed: isFixed }
      )}
      style={compStyle}
    >
      <div
        className={cx(styles.spinnerBg, 'shadow-2xl bg-accent')}
        style={{ top: spinnerTop }}
      ></div>
      <div className={styles.spinner} style={{ top: spinnerTop }}></div>
    </div>
  );
};

export const AppInlineLoader: FC<Props> = ({
  bgColor = 'bg-black-70',
  unsetZindex = false,
}) => {
  /**
   * DOM
   */
  const compStyle = unsetZindex ? { zIndex: 'unset' } : undefined;
  return (
    <div className={cx(styles.inlineComponent, bgColor)} style={compStyle}>
      <div className={cx(styles.spinnerBg, 'shadow-2xl bg-accent')}></div>
      <div className={styles.spinner}></div>
    </div>
  );
};
