import { API } from 'api';
import { action, Action, Computed, computed, thunk, Thunk } from 'easy-peasy';
import { getApiErrorMessage } from '../utils';
import { DataModel } from './data-store';
import { Injections, ThunkResult } from './types';

/**
 * This is used by the fund admin - API.UserType.ClientAdminReadWrite (eg: Apex Fund Admin)
 */
export interface AdminModel {
  // funds -> as of date
  _fundsAsOfDate: Date | null;
  setFundsAsOfDate: Action<AdminModel, Date | null>;

  // fund accounts - as seem by admin
  fundAccounts: API.ApexFundArrayPaginatedApiResponse | null;
  setFundAccounts: Action<
    AdminModel,
    API.ApexFundArrayPaginatedApiResponse | null
  >;
  getFundAccounts: Thunk<
    AdminModel,
    API.GetApexFundsRequest,
    Injections,
    DataModel
  >;
  fundAccountByName: Computed<AdminModel, API.ApexFund | null, DataModel>;

  // export fund account as CSV
  getFundPortfolioCsv: Thunk<
    AdminModel,
    undefined,
    Injections,
    DataModel,
    Promise<ThunkResult<Blob>>
  >;

  // fund account
  selectedFundAccountId: string | undefined;
  setSelectedFundAccountId: Action<AdminModel, string | undefined>;
}

export const adminModel: AdminModel = {
  // funds -> as of date
  _fundsAsOfDate: null,
  setFundsAsOfDate: action((state, payload) => {
    state._fundsAsOfDate = payload;
  }),

  // fund accounts - as seem by admin
  fundAccounts: null,
  setFundAccounts: action((state, payload) => {
    state.fundAccounts = payload;
  }),
  getFundAccounts: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);
        const response = await injections.apiClient.getAllApexFunds(payload);
        if (!response.isSuccessful) {
          storeActions.setError(response.errorMessage);
          return;
        }
        if (!response.result) {
          storeActions.setBusy(false);
          return;
        }
        actions.setFundAccounts(response);
        storeActions.setBusy(false);
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
      }
    }
  ),
  fundAccountByName: computed(
    [state => state.fundAccounts, state => state.selectedFundAccountId],
    (accounts, id) =>
      accounts?.result?.find(fund => fund.accountId === id) || null
  ),

  // export as CSV
  getFundPortfolioCsv: thunk(
    async (
      _actions,
      _payload,
      { getStoreActions, getStoreState, getState, injections }
    ) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);
        storeActions.setError(null);

        const headers = getStoreState().additionalHeaders;
        injections.apiClient.setAdditionalHeaders(headers);

        const { _fundsAsOfDate } = getState();
        const response = _fundsAsOfDate
          ? await injections.apiClient.getFundsCsvAsOf({
              asOf: _fundsAsOfDate,
            })
          : await injections.apiClient.getFundsCsv();

        storeActions.setBusy(false);
        return {
          isSuccessful: true,
          result: response as unknown as Blob,
          errorMessage: null,
        };
      } catch (error) {
        const errorMessage = getApiErrorMessage(error);
        storeActions.setError(errorMessage);
        storeActions.setBusy(false);
        return {
          isSuccessful: false,
          errorMessage,
        };
      }
    }
  ),

  // fund account
  selectedFundAccountId: undefined,
  setSelectedFundAccountId: action((state, payload) => {
    state.selectedFundAccountId = payload;
  }),
};
