import { FieldHelperProps } from 'formik';
import { FC } from 'react';
import { Field, FieldProps } from './field';
import { TextAreaInput, TextAreaProps } from './textarea-input';
import { InputProps } from 'common';

type Props = FieldProps &
  TextAreaProps & {
    disableHelpers?: boolean;
  };

export const TextAreaField: FC<Props> = ({
  label,
  name,
  onChange,
  disableHelpers = false,
  ...rest
}) => {
  const handleChange =
    (helpers: FieldHelperProps<string>) =>
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(e);
      }

      if (!disableHelpers) {
        helpers.setValue(e.target.value);
        helpers.setTouched(true);
      }
    };
  return (
    <Field name={name} label={label}>
      {(field, meta, helpers) => (
        <TextAreaInput
          errored={Boolean(meta.error && meta.touched)}
          {...(rest as TextAreaProps & InputProps)}
          {...field}
          onChange={handleChange(helpers)}
        />
      )}
    </Field>
  );
};
