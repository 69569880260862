import { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import ReactCountdownClock from 'react-countdown-clock';
import { useMountedState, useUnmount } from 'react-use';

interface Props {
  preContent?: JSX.Element | string;
  durationInSeconds?: number;
  cls?: string;
  content: JSX.Element | string;
  onClick: () => void;
}

export const TimedButton: FC<Props> = ({
  durationInSeconds = 30,
  cls,
  preContent,
  content,
  onClick,
}) => {
  /**
   * vars
   */
  let timeout: any;

  /**
   * State
   */
  const [disabled, setDisabled] = useState<boolean>(false);

  /**
   * Methods
   */
  const reset = () => {
    if (!timeout || !isMounted()) {
      return;
    }
    setDisabled(false);
    clearTimeout(timeout);
  };
  const onButtonClicked = () => {
    setDisabled(true);
    timeout = setTimeout(reset, durationInSeconds * 1000);
    if (onClick) {
      onClick();
    }
  };

  /**
   * Hooks
   */
  const isMounted = useMountedState();
  useUnmount(() => reset());
  useEffect(() => reset(), [timeout]);

  /**
   * DOM
   */
  return (
    <div className={cx('flex items-center text-gray-400', cls)}>
      {preContent && <>{preContent}</>}
      <button
        type={'button'}
        className={cx('text-primary mx-2 hover:underline font-bold', {
          'text-gray-300 cursor-not-allowed': disabled,
        })}
        disabled={disabled}
        onClick={e => {
          e.preventDefault();
          onButtonClicked();
        }}
      >
        {content}
      </button>
      {disabled && (
        <ReactCountdownClock
          seconds={durationInSeconds}
          color="#cdcdcd"
          size={28}
          weight={3}
          fontSize={14}
          showMilliseconds={false}
          onComplete={() => {
            reset();
          }}
        />
      )}
    </div>
  );
};
