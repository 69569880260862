import { ERROR_MESSAGES } from '../constants';
import { EnrichedAccountDetailAsset, EnrichedInvoice } from '../types';

export function getInvoiceBalanceError(
  invoice: EnrichedInvoice | null,
  asset: EnrichedAccountDetailAsset | null
) {
  if (!invoice?.isPayable) {
    // Not payable: No balance error
    return null;
  }

  const _balance = asset?.balance ?? 0;

  if (_balance < invoice.amount) {
    // Insufficient balance
    return ERROR_MESSAGES.INSUFFICIENT_BALANCE;
  }

  // Enough balance
  return null;
}
