import { AppIcon } from 'common';
import { FC, PropsWithChildren, useCallback, MouseEvent } from 'react';

interface Props extends PropsWithChildren {
  label: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
}

export const ImitationSelectField: FC<Props> = ({
  onClick,
  children,
  label,
  disabled = false,
}) => {
  /**
   * Methods
   */
  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!onClick) {
        return;
      }
      onClick();
    },
    [onClick]
  );

  /**
   * DOM
   */
  return (
    <div className="flex flex-col gap-y-1">
      {Boolean(label) && <div className={'text-sm text-gray-400'}>{label}</div>}
      <button
        className="flex flex-row gap-x-2 justify-between items-center w-full full app-input-select border border-grey-bright rounded pointer-cursor"
        onClick={handleOnClick}
        disabled={disabled}
      >
        <div className="truncate text-secondary font-bold w-full flex flex-row justify-between items-center">
          {children}
        </div>
        <AppIcon icon="chevron-down-dark" size={25} bg="bg-transparent" />
      </button>
    </div>
  );
};
