import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

export const applySchemaErrors = <T, K extends ObjectShape>(
  schema: Yup.ObjectSchema<K>,
  values: T,
  errors: Record<string, unknown>
) => {
  try {
    schema.validateSync(values, { abortEarly: false });
  } catch (e) {
    const yupError = e as Yup.ValidationError;
    if (yupError.inner) {
      yupError.inner.forEach(({ path, message }) => {
        errors[path!] = message;
      });
    }
  }
};
