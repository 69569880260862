export const hasSensibleValue = (v: string | null) => {
  if (!v) {
    return false;
  }
  /**
   * Note:
   * cater to edge case -> replace any space characters <carriage return / newline/ tab> with <space> in case given string has newline symbols
   */
  const value = v
    .replace(/[\r\n\t]+/g, ' ')
    .trim()
    .toLowerCase();
  if (value.length <= 0 || value === 'n/a') {
    return false;
  }
  return true;
};
