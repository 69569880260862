import { AppIcon, EMAIL } from 'common';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '~/routes';

export const WidgetPortfolioContactUs: FC = () => {
  /**
   * DOM
   */
  return (
    <div className="flex flex-col lg:flex-row items-start gap-3 border-1 grey-bright rounded-4 py-4 px-4 md:px-8   justify-between bg-white">
      <div className="flex flex-row lg:flex-row">
        <AppIcon icon="stablehouse-products" cls="mr-4" size={48} />
        <div className="flex flex-col xl:flex-row">
          <div className="flex flex-col gap-2">
            <span className="text-base md:typo-20 font-bold text-primary">
              Interested in learning more about our products?
            </span>
            <span className="text-sm text-grey-darker text-primary">
              If you&#39;re interested in applying for another trading or vault
              account, don’t hesitate to contact us directly.
            </span>
          </div>
        </div>
      </div>
      <Link
        to={APP_ROUTES.NON_AUTH_CONTACT_US}
        className="text-primary app-button-accent w-129 ml-16 lg:ml-8"
      >
        Let&#39;s chat
      </Link>
    </div>
  );
};
