import is from 'is';
import { API } from 'api';
import { ApiError } from '../store/api-error';

export const getApiErrorCode = (
  error: string | null | API.ApiResponse | ApiError | unknown
): number => {
  if (error instanceof ApiError) {
    return error.code;
  }

  if (!error) {
    return -1;
  }
  if (
    is.object(error) &&
    // eslint-disable-next-line
    (error as API.ApiResponse).hasOwnProperty('errorCode')
  ) {
    return ((error as API.ApiResponse).errorCode || -1) as number;
  }
  return -1;
};
