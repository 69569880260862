import { FC, useMemo } from 'react';
import cx from 'classnames';
import {
  AppIcon,
  AllowedWorkflowType,
  getWorkflowName,
  useMediaQuery,
  useComputedConfig,
} from 'common';
import { Menu } from '@headlessui/react';
import { AppRoundButton } from '../app-round-button';

interface HeroButtonsProps {
  items: JSX.Element[];
}

const HeroButtons: FC<HeroButtonsProps> = ({ items }) => {
  /**
   * DOM
   */
  const containerCls = 'relative flex flex-row gap-x-4 w-72 sm:w-96 md:w-auto';
  if (items.length < 3) {
    return <div className={containerCls}>{items}</div>;
  }
  const { XS, SM, LG } = useMediaQuery();

  const handleButtonList = useMemo(() => {
    const maxDisplayValue = XS ? 2 : SM ? 4 : LG ? 5 : items?.length;
    const displayButtons = items.slice(0, maxDisplayValue);
    const hiddenButtons = items.slice(maxDisplayValue, items?.length);

    return {
      displayButtons,
      hiddenButtons,
    };
  }, [items, XS, SM, LG]);

  return (
    <div>
      {/* lg*/}
      <div className={cx('hidden lg:flex', containerCls)}>
        {items.map((r, i) => (
          <div className="py-2.5" key={`workflow-hero-btn-${i}`}>
            {r}
          </div>
        ))}
      </div>

      {/* sm & md */}
      <div className={cx('sm:flex lg:hidden', containerCls)}>
        <span className="flex flex-row gap-x-3">
          {handleButtonList?.displayButtons.length > 0 &&
            handleButtonList.displayButtons.map(button => (
              <span key={button.key}>{button}</span>
            ))}
        </span>
        {handleButtonList?.hiddenButtons.length > 0 && (
          <Menu>
            {() => (
              <>
                <Menu.Button data-testid="more-workflows-btn">
                  <AppIcon
                    size={25.5}
                    icon="dots"
                    bg="bg-accent bg-accent-hover"
                    cls="cursor-pointer circle-btn btn-current-color-default"
                  />
                </Menu.Button>
                <Menu.Items className="absolute top-8 -right-8 sm:top-auto sm:bottom-8 sm:-right-48 origin-bottom sm:w-56 bg-white rounded-md shadow-3xl focus:outline-none z-20 shadow-2xl border">
                  {handleButtonList?.hiddenButtons.map((r, i) => {
                    return (
                      <div
                        className="py-2.5 px-3.5"
                        key={`workflow-hero-btn-${i}`}
                      >
                        {r}
                      </div>
                    );
                  })}
                </Menu.Items>
              </>
            )}
          </Menu>
        )}
      </div>

      {/* sm */}
      <div className={cx('hidden', containerCls)}>
        {items && items.length > 0 && (
          <Menu>
            {() => (
              <>
                <Menu.Button data-testid="more-workflows-btn">
                  <AppIcon
                    size={25.5}
                    icon="dots"
                    bg="bg-accent bg-accent-hover btn-current-color-default"
                    cls="cursor-pointer circle-btn"
                  />
                </Menu.Button>
                <Menu.Items className="absolute top-8 -right-8 sm:top-auto sm:bottom-8 sm:-right-48 origin-bottom sm:w-56 bg-white rounded-md shadow-3xl focus:outline-none z-20">
                  {items.map((r, i) => {
                    return (
                      <div
                        className="py-2.5 px-3.5"
                        key={`workflow-hero-btn-${i}`}
                      >
                        {r}
                      </div>
                    );
                  })}
                </Menu.Items>
              </>
            )}
          </Menu>
        )}
      </div>
    </div>
  );
};
interface Props {
  onSelected: (type: AllowedWorkflowType) => void;
  canBuy?: boolean;
  canSell?: boolean;
  canSend?: boolean;
  canReceive?: boolean;
  canAddCash?: boolean;
  canWithdrawCash?: boolean;
  canTransfer?: boolean;
}

export const WorkflowHeroButtons: FC<Props> = ({
  onSelected,
  canBuy = true,
  canSell = true,
  canSend = true,
  canReceive = true,
  canAddCash = true,
  canWithdrawCash = true,
  canTransfer = true,
}) => {
  /**
   * Hooks
   */
  const { tenant } = useComputedConfig();

  /**
   * DOM
   */
  const btnCls = 'group btn-current-color-default';
  const iconsProps = {
    size: 25,
    cls: 'bg-transparent cursor-pointer circle-btn',
  };
  const getBtnProps = (type: AllowedWorkflowType) => {
    return {
      text: getWorkflowName(type),
      icon: (
        <AppIcon
          icon={type}
          {...iconsProps}
          // TODO(Hadrien): How to handle group hover when we remove Tailwind ?
          bg={
            tenant === 'Apex'
              ? 'bg-transparent'
              : 'bg-accent bg-accent-hover group-hover:bg-figma-secondary'
          }
        />
      ),
      onClick: () => onSelected(type),
      cls: btnCls,
      iconCls: 'bg-transparent',
      iconInteractive: tenant !== 'Apex',
    };
  };

  const btns: JSX.Element[] = [];
  if (canBuy) {
    btns.push(
      <AppRoundButton {...getBtnProps('workflow-buy')} key="workflow-buy" />
    );
  }
  if (canSell) {
    btns.push(
      <AppRoundButton {...getBtnProps('workflow-sell')} key="workflow-sell" />
    );
  }
  if (canSend) {
    btns.push(
      <AppRoundButton {...getBtnProps('workflow-send')} key="workflow-send" />
    );
  }
  if (canReceive) {
    btns.push(
      <AppRoundButton
        {...getBtnProps('workflow-receive')}
        key="workflow-receive"
      />
    );
  }
  if (canAddCash) {
    btns.push(
      <AppRoundButton
        {...getBtnProps('workflow-add-cash')}
        key="workflow-add-cash"
      />
    );
  }
  if (canWithdrawCash) {
    btns.push(
      <AppRoundButton
        {...getBtnProps('workflow-withdraw-cash')}
        key="workflow-withdraw-cash"
      />
    );
  }
  if (canTransfer) {
    btns.push(
      <AppRoundButton
        {...getBtnProps('workflow-transfer')}
        key="workflow-transfer"
      />
    );
  }
  return <HeroButtons items={btns} />;
};
