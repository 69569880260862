import { Enriched, humanize } from 'common';
import { ActivityDate } from '../shared/activity-date';
import { ActivityAmount, ActivityDescription } from '../shared';
import { CellActivityStatus } from '~/components/transactions/cells';
import { CellTransferAccount } from '~/components/transactions/cells/cell-transfer-account';

export const getChargedFeeRecords = (
  activity: Enriched.ActivityTransaction,
  account: Enriched.ListAccountItem | undefined
) => {
  return {
    '': <CellActivityStatus status={activity?.statusFilter || ''} />,
    Amount: (
      <ActivityAmount
        formatted
        amount={activity.formatted.amount1WithCurrencyCodeAndSign}
      />
    ),
    From: <CellTransferAccount data={account} />,
    Date: <ActivityDate date={activity?.creationDate} />,
    Description: (
      <ActivityDescription value={humanize(activity?.feesChargedType || '')} />
    ),
  };
};
