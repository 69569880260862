import { API } from 'api';
import { QuorumOperationKindValue, QuorumToggleStatusValue } from 'common';

export const getAccountAndUserToggleStatus = (
  data: API.QuorumOperationKind,
  state: boolean
) => {
  const isAccountToggleStatus =
    data === API.QuorumOperationKind.ToggleAccountStatus;
  const isUserToggleStatus = data === API.QuorumOperationKind.ToggleUserStatus;

  if (!isAccountToggleStatus && !isUserToggleStatus)
    return QuorumOperationKindValue[data];
  if (isAccountToggleStatus) {
    if (state) {
      return QuorumToggleStatusValue.EnableAccount;
    } else {
      return QuorumToggleStatusValue.DisableAccount;
    }
  }
  if (isUserToggleStatus) {
    if (state) {
      return QuorumToggleStatusValue.EnableUser;
    } else {
      return QuorumToggleStatusValue.DisableUser;
    }
  }
};
