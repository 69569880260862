import { useEffect, useMemo, useRef } from 'react';
import { loadRecaptcha } from '../utils/javascript-loader';

declare global {
  interface Window {
    envs?: any;
    [functionName: string]: any;
  }
}

export const useRecaptcha = ({ key }: { key: string }) => {
  const uniqueId = useMemo(() => Math.floor(Math.random() * 10000000), []);

  const data = useRef({
    id: 'recaptchaDiv' + uniqueId,
    functionName: 'recaptchaCallback' + uniqueId,
    eventHandlers: {},
    callback: (() => {
      return;
    }) as (result: string) => void,
    closed: () => {
      return;
    },
    widget: null,
    observer: null as MutationObserver | null,
  });

  const mount = () => {
    if (document === undefined || window === undefined) {
      return;
    }
    const div = document.createElement('div');
    div.id = String(data.current.id);
    div.setAttribute('data-sitekey', key!);
    div.setAttribute('data-callback', data.current.functionName);
    div.setAttribute('data-size', 'invisible');

    window[data.current.functionName] = (result: string) =>
      data.current.callback(result);

    document.body.appendChild(div);

    loadRecaptcha().then(() => {
      const widget = window.grecaptcha.render(div, {
        sitekey: key,
      });
      data.current.widget = widget;
    });
  };

  const unMount = () => {
    if (document === undefined || window === undefined) {
      return;
    }
    const node = document.getElementById(data.current.id);
    if (node && node.parentElement) {
      node.parentElement.removeChild(node);
    }
    if (window[data.current.functionName]) {
      delete window[data.current.functionName];
    }
    if (data.current.observer) {
      data.current.observer.disconnect();
    }
  };

  const getRecaptchaToken: () => Promise<string> = () => {
    return new Promise((resolve, reject) => {
      if (document === undefined || window === undefined) {
        return;
      }
      data.current.closed = function () {
        reject(new Error('Closed'));
      };

      data.current.callback = function (result: any) {
        console.log('Recaptcha callback', result);
        resolve(result);
        window.grecaptcha.reset(data.current.widget);
      };

      try {
        console.log('Executing recaptcha', data.current.id);
        window.grecaptcha.execute(data.current.widget);

        // Unfortunately, recaptcha doesn't provide a "closed handler"
        // See https://stackoverflow.com/a/44984658
        const recaptchaWindows = Array.prototype.filter.call(
          document.getElementsByTagName('iframe'),
          x => x.src.includes('google.com/recaptcha/api2/bframe')
        );
        const recaptchaWindow =
          recaptchaWindows.length > 0
            ? recaptchaWindows[recaptchaWindows.length - 1]?.parentNode
                ?.parentNode
            : undefined;
        if (recaptchaWindow) {
          data.current.observer = new MutationObserver(() => {
            return (
              parseInt(recaptchaWindow.style.opacity) === 0 &&
              data.current.closed()
            );
          });
          data.current.observer.observe(recaptchaWindow, {
            attributes: true,
            attributeFilter: ['style'],
          });
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  useEffect(() => {
    mount();
    return () => unMount();
  }, [uniqueId]);

  return {
    getRecaptchaToken: getRecaptchaToken,
  };
};
