import { RowLabelContent, SelectInput } from 'common';
import { FC, useCallback } from 'react';
const isLocalhostUrl = require('is-localhost-url');

interface Props {
  value: string;
}

export const ChooseTenant: FC<Props> = ({ value }) => {
  /**
   * Methods
   */
  const onTenantChanged = useCallback(value => {
    const url = new URL(window.location.href);
    url.searchParams.set('tenant', value || 'Stablehouse');
    window.location.href = url.href;
  }, []);

  /**
   * DOM
   */
  if (!isLocalhostUrl(window.location.href)) {
    return null;
  }
  return (
    <RowLabelContent
      label={`Tenant`}
      borderCls=""
      content={
        <SelectInput
          value={value}
          contentCls="w-44"
          cls="font-semibold"
          onChange={onTenantChanged}
          values={['Stablehouse', 'Apex', 'XBTO']}
        />
      }
    />
  );
};
