import cx from 'classnames';

import { API } from 'api';
import { getStorageKeys, useBanners, useEnvironmentConfig } from 'common';
import { Navigate, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { DataStore } from '../../store';
import { storageHelper } from '../../utils/storage-helper';
import { AppFooter } from '../app-footer';
import { AppHeader } from '../app-header';
import { Auth0 } from '~/utils/auth0';
import { useLeftNav } from '~/hooks/use-left-nav';
import { memo, useCallback, useRef } from 'react';
import StepWizard from 'react-step-wizard';
import { defaultStepWizardProps } from '~/constants';
import { DismissibleBanner, SplashBanner } from '../splash-banner';

interface Props {
  children: JSX.Element;
}

export const RequireAuth = memo(({ children }: Props) => {
  /**
   * Store
   */
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);
  const isEmailVerified = DataStore.useStoreState(s => s.user.isEmailVerified);
  const display2FaAfterLoginShowPage = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.display2FaAfterLogin?.showPage
  );
  const canUserBypass2FALogin = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.display2FaAfterLogin?.bypass
  );
  const showProofOfAddressAlert = DataStore.useStoreState(
    s =>
      s.settings.globalAppSettings?.proofOfAddressStatus ===
      API.ProofOfAddressStatus.RequiresPrompt
  );
  const busy = DataStore.useStoreState(s => s.busy);

  const acceptMessage = DataStore.useStoreActions(s => s.alerts.acceptMessage);

  const dismissMessage = DataStore.useStoreActions(
    s => s.alerts.dismissMessage
  );
  const { banner } = useBanners(DataStore);
  const splashScreens = DataStore.useStoreState(s => s.alerts.splashScreens);

  /**
   * Hooks
   */
  const { REACT_APP_BUILD_ENV_NAME } = useEnvironmentConfig();
  const location = useLocation();
  const isAuthenticatedWithAuth0 = Auth0.useIsAuthenticated();

  const handleAccept = useCallback(
    async (userMessageId: string, arg?: () => void) => {
      const result = await acceptMessage({ userMessageId });
      if (result.isSuccessful) {
        arg?.();
      }
    },
    [acceptMessage]
  );

  const handleSplashDismiss = useCallback(
    async (userMessageId: string, arg?: () => void) => {
      const result = await dismissMessage({ userMessageId });
      if (result.isSuccessful) {
        arg?.();
      }
    },
    [dismissMessage]
  );

  /**
   * Vars
   */
  const userHasBypassedEnforce2FA = Boolean(
    localStorage.getItem(
      getStorageKeys('web', REACT_APP_BUILD_ENV_NAME)?.user2FABypassed
    )
  );
  const userHasBypassedVerifyAddress = Boolean(storageHelper.alerts.get());
  const showVerifyPage =
    showProofOfAddressAlert && !userHasBypassedVerifyAddress;
  const showEnforce2FAPage =
    display2FaAfterLoginShowPage &&
    ((canUserBypass2FALogin && !userHasBypassedEnforce2FA) ||
      !canUserBypass2FALogin);

  const { leftNavCls, Nav } = useLeftNav();
  const ref = useRef<HTMLDivElement | null>(null);

  /**
   * DOM
   */
  if (!busy && isAuthenticated && !isEmailVerified) {
    return (
      <Navigate
        to={{
          pathname: APP_ROUTES.NON_AUTH_EMAIL_VERIFICATION_PENDING,
        }}
        state={{
          from: location,
        }}
      />
    );
  }
  if (!busy && !isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: APP_ROUTES.NON_AUTH_HOME,
        }}
        state={{
          from: location,
        }}
      />
    );
  }

  if (splashScreens.length) {
    return (
      <StepWizard
        className="flex flex-col h-screen flex-1"
        {...defaultStepWizardProps}
      >
        {splashScreens.map(i => (
          <SplashBanner
            {...i}
            key={i.id}
            onAccept={handleAccept}
            onDismiss={handleSplashDismiss}
          />
        ))}
      </StepWizard>
    );
  }

  return (
    <div className={'flex flex-col h-screen flex-1'}>
      <span className={leftNavCls}>
        <AppHeader />
      </span>
      {!!banner && (
        <StepWizard
          className={cx(leftNavCls, 'transition-all')}
          {...defaultStepWizardProps}
        >
          <DismissibleBanner />
        </StepWizard>
      )}
      <div className="flex-1 flex" ref={ref}>
        {Nav}
        <div
          className={cx(
            'flex flex-col w-full relative h-full text-primary',
            leftNavCls
          )}
        >
          {children}
          <AppFooter />
        </div>
      </div>
    </div>
  );
});
