import { FC } from 'react';
import cx from 'classnames';

interface CurrencyTransactionProps {
  cls?: string;
  amount: string;
  positive?: boolean;
}

export const CurrencyTransaction: FC<CurrencyTransactionProps> = ({
  amount,
  positive = true,
  cls = 'text-base font-bold',
}) => {
  return (
    <div className={cx('text-right', cls)}>
      {positive ? (
        <span className="text-success">+</span>
      ) : (
        <span className="text-failure">-</span>
      )}
      <span>{amount}</span>
    </div>
  );
};
