import { SORT_ASSETS_BY, SORT_ASSETS_FIELDS, SORT_DIR } from '../config';
import { EnrichedCurrencyInformation } from '../types';
import { orderBy } from './order-by';

export function orderCurrenciesBy24hChange(
  currencies: EnrichedCurrencyInformation[]
) {
  return orderBy(
    currencies.filter(
      ({ hidden, isAssetOfTypeFiat, isStablecoin }) =>
        !hidden && !isAssetOfTypeFiat && !isStablecoin
    ),
    SORT_DIR.DESC,
    SORT_ASSETS_FIELDS[SORT_ASSETS_BY.PERCENTAGE_CHANGE_1_DAY]
  );
}

export function getTop24hChangeCurrencies(
  currenciesBy24hChange: EnrichedCurrencyInformation[]
) {
  return currenciesBy24hChange
    .filter(
      ({ fxRate, showInTopGainersOrLosers }) =>
        showInTopGainersOrLosers &&
        fxRate.rate24HoursAgoPercentageChange !== null &&
        fxRate.rate24HoursAgoPercentageChange > 0
    )
    .slice(0, 3);
}

export function getFlop24hChangeCurrencies(
  currenciesBy24hChange: EnrichedCurrencyInformation[]
) {
  return currenciesBy24hChange
    .filter(
      ({ fxRate, showInTopGainersOrLosers }) =>
        showInTopGainersOrLosers &&
        fxRate.rate24HoursAgoPercentageChange !== null &&
        fxRate.rate24HoursAgoPercentageChange < 0
    )
    .slice(-3)
    .reverse();
}
