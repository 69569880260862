import { FC } from 'react';
import { StatusNote } from 'common';
import { DataStore } from '../../store';

export const SecurityVerificationDetails: FC = () => {
  /**
   * Store
   */
  const isKycVerified = DataStore.useStoreState(s => s.user.isKycVerified);

  /**
   * DOM
   */
  return (
    <div className="text-base">
      {/* content  */}
      {isKycVerified ? (
        <StatusNote level={'success'} className="font-semibold">
          Verified!
        </StatusNote>
      ) : (
        <StatusNote level={'failure'} className="font-semibold">
          Your identity is not verified
        </StatusNote>
      )}
    </div>
  );
};
