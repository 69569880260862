import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { AppErrorBoundary } from '../components/app-error-boundary';
import { AnalyticsContextProvider } from '../context/analytics-context';
import { AppTenant, getThemeForTenant, useComputedConfig } from 'common';
import { PusherProvider } from '~/context/pusher-context';
import { AppRootHelmet } from './helmet';
import { AppRouter } from './router';

export const AppRoot: FC = () => {
  /**
   * Hooks
   */
  const { tenant } = useComputedConfig();

  /**
   * DOM
   */
  const themeName = getThemeForTenant(tenant as AppTenant);
  return (
    <ErrorBoundary FallbackComponent={AppErrorBoundary}>
      <AppRootHelmet tenant={tenant} themeName={themeName} />
      <AnalyticsContextProvider>
        <PusherProvider>
          <AppRouter />
        </PusherProvider>
      </AnalyticsContextProvider>
    </ErrorBoundary>
  );
};
