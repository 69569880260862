import cx from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { BaseForm } from '../definitions/base-form';
import { DataStore } from '../store';
import { handleApiException } from '../utils/handle-errors';
import { nameof } from '../utils/typescript-helpers';
import { ExternalSplashLayout } from '../components/layouts/external-splash';
import { AlreadyHaveAccount } from '../components/common/already-have-account';
import { Error } from '../components/common/error';
import { APP_ROUTES } from '../routes';
import { Link } from 'react-router-dom';
import { TextField } from '../components/forms/text-field';
import { useComputedConfig, ERROR_MESSAGES } from 'common';

enum FormField {
  email = 'email',
}

interface FormProps extends BaseForm {
  [FormField.email]: string;
}

const formSchema = Yup.object().shape({
  [FormField.email]: Yup.string()
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .required(ERROR_MESSAGES.REQUIRED_VALUE),
});

export const ForgotPassword: FC = () => {
  /**
   * Store
   */
  const sendResetPasswordEmail = DataStore.useStoreActions(
    a => a.user.sendResetPasswordEmail
  );
  const { tenant } = useComputedConfig();

  /**
   * State
   */
  const [message, setMessage] = useState('');

  /**
   * Methods
   */
  const onFormSubmit = async (
    values: FormProps,
    helper: FormikHelpers<FormProps>
  ) => {
    try {
      setMessage('');
      helper.setFieldValue(nameof<BaseForm>('errorMessage'), '');
      const error = await sendResetPasswordEmail({
        email: values.email,
        tenant: null,
        siteCode: tenant,
      });
      if (!error) {
        setMessage(
          'If an account already exists for this email, a link will be sent to you to reset your password.'
        );
      } else {
        helper.setFieldValue(nameof<BaseForm>('errorMessage'), error);
      }
    } catch (exception) {
      handleApiException(exception, helper);
    }
  };

  /**
   * DOM
   */
  const initialValues = {
    email: '',
  };
  return (
    <ExternalSplashLayout title="Forgot password">
      {/* form  */}
      <div>
        {/* message  */}
        {message ? (
          <>
            <div className="my-5 flex flex-col sm:block">
              {/* message  */}
              <p>{message}</p>

              <Link
                to={APP_ROUTES.NON_AUTH_HOME}
                className={cx(
                  { 'app-button-accent': tenant === 'Apex' },
                  { 'app-button-outline': tenant === 'Stablehouse' },
                  'inline-block mt-5 text-center'
                )}
              >
                Back to sign in
              </Link>
            </div>
          </>
        ) : (
          <Formik<FormProps>
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={onFormSubmit}
          >
            {({ errors, values, isSubmitting }) => (
              <Form className="mt-4">
                {/* api error  */}
                {values.errorMessage && (
                  <Error
                    testid="authentication-failed"
                    message={values.errorMessage}
                  />
                )}

                <div className="sm:max-w-md">
                  {/* email address  */}
                  <div className="flex flex-col mt-4">
                    <TextField
                      type="email"
                      name={FormField.email}
                      label="Email"
                      autoComplete="email"
                      data-testid="email-address-input"
                    />
                  </div>
                </div>

                {/* TODO: formik isSubmitting should be a wired up to busy state  */}
                {/* actions  */}
                <div className="flex flex-col mt-8">
                  <button
                    type="submit"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    className={cx('app-button-accent')}
                  >
                    Reset my password
                  </button>

                  {/* already know password  */}
                  <AlreadyHaveAccount
                    tag="Already know your password?"
                    cls="mt-3"
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </ExternalSplashLayout>
  );
};
