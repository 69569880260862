import React, { Fragment } from 'react';
import cx from 'classnames';
import { AppIcon } from '../app-icon';
import startCase from 'lodash/startCase';
import { ASSET_FILTERS, FILTER_ASSETS_BY } from '../../config';
import { CurrencyIcon } from '../currency-icon';

type AssetFilterProps = {
  filterBy?: FILTER_ASSETS_BY;
  onClick?: (arg?: FILTER_ASSETS_BY) => void;
};

export const assetTemplate = ({
  onClick,
  item: asset,
  filterOption,
  filterAssetList,
  onItemSelection,
}) => {
  return (
    <Fragment>
      <div className="flex whitespace-nowrap overflow-auto scrollbar-hide pl-1 pr-3 pb-5 gap-4 ml-8">
        {ASSET_FILTERS.map(({ name, value }) => {
          if (!name) return null;
          return (
            <button
              key={value}
              type="button"
              className={cx(
                {
                  'app-button-outline': filterOption.filterBy
                    ? value !== filterOption.filterBy
                    : value !== FILTER_ASSETS_BY.ALL,
                  'app-button-primary': filterOption.filterBy
                    ? filterOption.filterBy === value
                    : value === FILTER_ASSETS_BY.ALL,
                },
                'button-xs text-xs md:text-sm snap-center'
              )}
              onClick={() => {
                if (!onClick) return;
                onClick(value);
              }}
            >
              {startCase(name)}
            </button>
          );
        })}
      </div>
      <section className="rounded-b max-h-485 border-t overflow-y-scroll">
        <div
          key={asset.code}
          onClick={() => {
            if (!onItemSelection) return;
            onItemSelection(asset.code);
          }}
          className={cx(
            { 'bg-grey-brighter': filterAssetList?.includes(asset.code) },
            'flex flex-row justify-between px-5 py-2.5 border-b items-center cursor-pointer hover-bg-grey-brighter'
          )}
        >
          <span className="flex flex-row gap-x-2.5 items-center">
            <CurrencyIcon
              showLabel
              size={20}
              currencyCode={asset.code}
              currencyName={asset.name}
            />
          </span>
          <label className="app-input-checkbox-wrap">
            <input
              type="checkbox"
              onChange={() => {
                if (!onItemSelection) return;
                onItemSelection(asset.code);
              }}
              checked={filterAssetList?.includes(asset.code)}
            />
          </label>
        </div>
      </section>
    </Fragment>
  );
};

export const assetHeaderTemplate = ({
  onClick,
  filterBy,
}: AssetFilterProps) => (
  <div className="flex whitespace-nowrap overflow-auto scrollbar-hide pl-1 pr-3 pb-5 gap-4 ml-8">
    {ASSET_FILTERS.map(({ name, value }) => {
      if (!name) return null;
      return (
        <button
          key={value}
          type="button"
          className={cx(
            {
              'app-button-outline': filterBy
                ? value !== filterBy
                : value !== FILTER_ASSETS_BY.ALL,
              'app-button-primary': filterBy
                ? filterBy === value
                : value === FILTER_ASSETS_BY.ALL,
            },
            'button-xs text-xs md:text-sm snap-center'
          )}
          onClick={() => {
            if (!onClick) return;
            onClick(value);
          }}
        >
          {startCase(name)}
        </button>
      );
    })}
  </div>
);

export const assetContentTemplate = ({
  item: asset,
  filterAssetList,
  onItemSelection,
  multiSelection = true,
}) => {
  return (
    <span
      className={cx('flex flex-row w-full justify-between items-center', {
        'bg-grey-brighter': filterAssetList?.includes(asset.code),
      })}
      onClick={() => {
        if (!onItemSelection) return;
        onItemSelection(asset.code);
      }}
    >
      <span className="flex flex-1 gap-x-2.5 pl-5 py-2.5 items-center">
        <CurrencyIcon
          showLabel
          size={20}
          currencyCode={asset.code}
          currencyName={asset.name}
        />
      </span>
      {multiSelection && (
        <label className="app-input-checkbox-wrap pr-5">
          <input
            type="checkbox"
            onChange={() => {
              if (!onItemSelection) return;
              onItemSelection(asset.code);
            }}
            checked={filterAssetList?.includes(asset.code)}
          />
        </label>
      )}
    </span>
  );
};

export const addressBookContentTemplate = ({ item }) => {
  return (
    <div
      className={cx(
        'flex flex-row w-full items-center gap-x-2.5 justify-between px-4 md:px-8 py-2.5 items-center cursor-pointer hover-bg-grey-brighter'
      )}
    >
      <div>
        <AppIcon icon="external-circle" bg="transparent" size={30} />
      </div>
      <span className="flex flex-col w-3/4 sm:w-4/5">
        <span className="text-base font-bold truncate">{item?.label}</span>
        <span className="text-base truncate">{item?.address}</span>
      </span>
      <button className="app-button-outline button-xs typo-b5">
        {item?.currencyCode}
      </button>
    </div>
  );
};

export const defaultFilterContentTemplate = content => {
  return (
    <p
      className={cx('w-full flex px-8 py-2.5', {
        'bg-grey-bright': content?.item === content?.value,
      })}
    >
      {content?.item}
    </p>
  );
};