import { useEffectOnce } from 'react-use';
import cx from 'classnames';

import { AppContainer, CurrencyIcon } from 'common';
import { DashboardHeader } from '~/components/dashboard/dashboard-header';
import { DataStore } from '~/store';
import { memo, PropsWithChildren } from 'react';

/**
 * Text components (typography)
 */
type TextColorVariant = 'em' | 'muted' | 'default';

const textColorClassNames: Record<TextColorVariant, string> = {
  em: 'text-primary',
  muted: 'text-grey-dark',
  default: 'text-grey-darker',
};

// Private text props
type _TextProps = PropsWithChildren<{
  color?: TextColorVariant;
  font?: 'bold';
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  leading?: boolean;
}>;

function _Text({
  color = 'default',
  font,
  leading = true,
  size = 'md',
  ...rest
}: _TextProps) {
  return (
    <div
      {...rest}
      className={cx(textColorClassNames[color], {
        'leading-none': !leading,
        'typo-b5': size === 'xxs',
        [`font-${font}`]: !!font,
        [`text-${size}`]: size !== 'xxs',
      })}
    />
  );
}

// Public text props (used by "text components")
type TextProps = Omit<_TextProps, 'color' | 'font' | 'size'>;

function TextSubheading(props: TextProps) {
  return <_Text {...props} size="sm" color="em" font="bold" />;
}

function TextBody(props: TextProps) {
  return <_Text {...props} size="xs" />;
}

function TextCaption(props: TextProps) {
  return <_Text color="muted" {...props} font="bold" size="xs" />;
}

/**
 * Table components
 */
type TableProps = PropsWithChildren;

function Table(props: TableProps) {
  return <div {...props} className="flex flex-col border-1 border-b-0" />;
}

type TableRowProps = PropsWithChildren;

function TableRow(props: TableRowProps) {
  return <div {...props} className="flex flex-row" />;
}

const TableCell = memo((props: PropsWithChildren) => {
  return (
    <div
      {...props}
      className="flex flex-row items-center flex-1 py-3 px-4 border-b-1"
    />
  );
});

type TableHeaderCellProps = {
  title: string;
};

const TableHeaderCell = memo(({ title }: TableHeaderCellProps) => {
  return (
    <TableCell>
      <TextCaption leading={false}>{title}</TextCaption>
    </TableCell>
  );
});

type TableCellAssetProps = {
  code: string;
  displayCode: string;
  name: string;
};

const TableCellAsset = memo(
  ({ code, displayCode, name }: TableCellAssetProps) => {
    return (
      <TableCell>
        <div className="pr-3">
          <CurrencyIcon currencyCode={code} size={24} showLabel={false} />
        </div>
        <div className="flex flex-col">
          <div className="mb-2">
            <TextSubheading leading={false}>{displayCode}</TextSubheading>
          </div>
          <TextBody leading={false}>{name}</TextBody>
        </div>
      </TableCell>
    );
  }
);

export const ListOfAssets = () => {
  /**
   * Store
   */
  const getAssetsList = DataStore.useStoreActions(a => a.market.getAssetsList);
  const assets = DataStore.useStoreState(a => a.market.assetsList?.data ?? []);

  /**
   * Effects
   */
  useEffectOnce(() => {
    getAssetsList();
  });

  /**
   * DOM
   */
  return (
    <AppContainer fullHeight containerWidth="md">
      <DashboardHeader withBorder title="Markets" />
      <Table>
        <TableRow>
          <TableHeaderCell title="Asset" />
          <TableHeaderCell title="Column 2" />
        </TableRow>
        {assets.map(asset => (
          <TableRow key={asset.code}>
            <TableCellAsset
              code={asset.code}
              displayCode={asset.displayCode}
              name={asset.name}
            />
            <TableCell>
              <TextBody>Column 2 content</TextBody>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </AppContainer>
  );
};
