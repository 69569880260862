import { memo, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { useAppTabs } from './use-app-tabs';

interface Tabs {
  panels: JSX.Element[];
  tabs: JSX.Element[];
  defaultIndex?: number;
  headerContainerCls?: string;
  headerItemCls?: string;
  panelContainerCls?: string;
  marginCls?: string;
  borderCls?: string;
  footer?: JSX.Element;
  fontCls?: string;
  spacingCls?: string;
  applyParams?: boolean;
}

export const AppTabs: React.FC<Tabs> = memo(
  ({
    defaultIndex = 0,
    tabs,
    panels,
    headerContainerCls,
    headerItemCls,
    panelContainerCls,
    marginCls = 'mt-5',
    borderCls = 'border-b-1',
    footer,
    fontCls = 'font-bold',
    spacingCls = 'gap-x-3.5 md:gap-x-5',
    applyParams = false,
  }) => {
    /**
     * Hooks
     * */
    const tabsContent = useMemo(() => {
      return tabs
        ?.map(item => item?.props?.children)
        .reduce((acc, current) => {
          acc[current] = current;
          return acc;
        }, {} as const);
    }, [tabs]);
    const { tabIndex, handleOnTabClicked } = useAppTabs(tabsContent);

    /**
     * State
     */
    const [selectedIndex, setSelectedIndex] = useState(
      defaultIndex || tabIndex
    );

    const selectedItem = useMemo(() => {
      return tabs.at(selectedIndex);
    }, [tabs, selectedIndex]);

    const handleChange = useCallback(
      (key: number) => {
        setSelectedIndex(key);
        if (!applyParams) {
          return;
        }
        return handleOnTabClicked(key);
      },
      [applyParams, setSelectedIndex, handleOnTabClicked]
    );

    return (
      <div>
        <div
          className={cx(
            'flex text-sm',
            headerContainerCls,
            borderCls,
            spacingCls
          )}
        >
          {tabs.map((t, index) => {
            return (
              <button
                key={index}
                type="button"
                tabIndex={0}
                onClick={() => handleChange(index)}
                title={t.key as string}
                className={cx('cursor-pointer pt-2', headerItemCls, fontCls, {
                  'bg-tab-selected border-accent text-primary':
                    t.key === selectedItem?.key,
                  'text-grey-darker': t.key !== selectedItem?.key,
                })}
              >
                {t}
              </button>
            );
          })}
        </div>
        <div className={cx(marginCls, panelContainerCls)}>
          <>{panels.at(selectedIndex)}</>
          <>{footer}</>
        </div>
      </div>
    );
  }
);
