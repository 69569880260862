import { StepWizardChildProps } from 'react-step-wizard';
import { AppIcon } from 'common';

type Props = Partial<StepWizardChildProps> & {
  onClose: () => void;
};

export const Canceled = ({ goToNamedStep, onClose }: Props) => {
  if (!goToNamedStep) {
    return null;
  }

  /**
   * DOM
   */
  return (
    <div className="flex flex-col">
      {/* header  */}
      <div className="flex flex-col items-center px-10 mb-12 gap-y-1">
        <AppIcon icon="check-success" size="lg" cls="mt-10" />
        <div className="flex flex-col gap-y-2">
          <h4 className="text-center font-bold text-lg sm:text-xl px-10">
            Your transaction has been successfully canceled
          </h4>
          <p className="flex justify-center text-grey-darker text-center px-0 sm:px-8">
            Your request will no longer be processed.
          </p>
        </div>
      </div>

      {/* actions  */}
      <div className="flex border-t border-grey-bright bg-gray-100 rounded-b mt-4 py-6 px-6">
        {/* close button  */}
        <button
          role="button"
          type="button"
          className="w-full flex-1 app-button-primary"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};
