export class ApiError extends Error {
  public status: number;
  public code: number;

  constructor(status: number, message: string, code: number) {
    super(message);

    this.code = code;
    this.status = status;
  }
}
