import { FC, memo } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { Widgetcard } from '~/components/widgets/widget-card';
import { WidgetStepsNavigation } from '~/components/widgets/widget-steps-navigation';

export const WidgetStablehouseTelegram: FC<Partial<StepWizardChildProps>> =
  memo(({ ...rest }) => {
    /**
     * DOM
     */
    const telegramUrl = `https://t.me/TheDailyStable`;

    return (
      <Widgetcard
        iconSize={52}
        titleCls="text-xl"
        icon="social-telegram"
        iconBg="bg-transparent"
        iconCls="mt-2 mr-1 fill-red"
        fill="fill-current-color-gradient"
        title="The Daily Stable on Telegram"
        subText={`The simple way to cut through the chaos. Your daily staple in curated news and insights.`}
        ctaText={
          <a
            target="_blank"
            rel="noreferrer"
            href={telegramUrl}
            className="app-button-outline text-sm w-336 pt-4"
          >
            Join for free
          </a>
        }
      >
        <WidgetStepsNavigation {...rest} />
      </Widgetcard>
    );
  });
