import { FC } from 'react';
import cx from 'classnames';
import { Enriched } from 'common';

interface Props {
  cls?: string;
  subTextCls?: string;
  data: Enriched.ListAccountItem | undefined;
}

export const CellTransferAccount: FC<Props> = ({
  data,
  subTextCls = 'text-xs font-normal text-grey-darker',
  cls = 'text-sm',
}) => {
  /**
   * DOM
   */
  if (!data) {
    return null;
  }

  return (
    <div className={cx('flex flex-col justify-center items-end', cls)}>
      <span className="text-primary font-bold">{data.account?.label}</span>
      <span className={cx('text-gray-darker text-wrap', subTextCls)}>
        {data.account?.accountNumber}
      </span>
    </div>
  );
};
