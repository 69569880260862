export enum BankAccountFormikField {
  currencyCode = 'currencyCode',
  accountNumber = 'accountNumber',
  bankIdentifier = 'bankIdentifier',
  bankCountry = 'bankCountry',
  recipientAddress1 = 'recipientAddress1',
  recipientAddress2 = 'recipientAddress2',
  recipientCity = 'recipientCity',
  recipientCountry = 'recipientCountry',
  recipientState = 'recipientState',
  recipientPostalCode = 'recipientPostalCode',
  addIntermediaryBank = 'addIntermediaryBank',
  intermediaryBankIdentifier = 'intermediaryBankIdentifier',
  intermediaryAccountNumber = 'intermediaryAccountNumber',
  bankName = 'bankName',
  accountHolderName = 'accountHolderName',
}

export interface BankAccountFormikProps {
  [BankAccountFormikField.currencyCode]: string;
  [BankAccountFormikField.accountNumber]: string;
  [BankAccountFormikField.bankIdentifier]: string;
  [BankAccountFormikField.bankCountry]: string;
  [BankAccountFormikField.recipientAddress1]: string;
  [BankAccountFormikField.recipientAddress2]: string;
  [BankAccountFormikField.recipientCity]: string;
  [BankAccountFormikField.recipientCountry]: string;
  [BankAccountFormikField.recipientState]: string;
  [BankAccountFormikField.recipientPostalCode]: string;
  [BankAccountFormikField.intermediaryBankIdentifier]: string;
  [BankAccountFormikField.intermediaryAccountNumber]: string;
  [BankAccountFormikField.addIntermediaryBank]: boolean;
  [BankAccountFormikField.bankName]: string;
  [BankAccountFormikField.accountHolderName]: string;
}
