import { FC, memo, useCallback, useState } from 'react';
import { FilterMain } from './filter-main';
import { dateRanges, FilterField, FilterProps } from 'common';

export interface FiltersProp {
  filterDateRange: string;
  filterStartDate: Date | null;
  filterEndDate: Date | null;
  filterAccountId: string;
}
interface Props {
  filters: FiltersProp | null;
  handleFiltersChange: (f: FilterProps) => void;
}

export const FilterNavigation: FC<Props> = memo(
  ({ filters, handleFiltersChange }) => {
    const [accountId, setAccountId] = useState(filters?.filterAccountId || '');
    const [dateRange, setDateRange] = useState(filters?.filterDateRange || '');

    /**
     * Methods
     */
    const submitFilterValues = useCallback(() => {
      const selectedTime = Object.values(dateRanges).find(
        ({ text }) => text === dateRange
      );
      const updatedFilter: FilterProps = withFallbacks({
        filterDateRange: selectedTime?.text,
        filterEndDate: selectedTime?.endDate,
        filterAccountId: accountId,
        filterStartDate: selectedTime?.startDate,
      });
      handleFiltersChange(updatedFilter);
    }, [handleFiltersChange, dateRange, accountId]);

    const handleSelection = useCallback((key?: FilterField, val?: string) => {
      if (key === FilterField.filterAccountId) {
        setAccountId(val || '');
      }
      if (key === FilterField.filterDateRange) {
        setDateRange(val || '');
      }
    }, []);

    const hasValues = !!accountId || !!dateRange;

    return (
      <div className="relative text-primary h-full">
        <div>
          <FilterMain
            filterAccountId={accountId}
            filterDateRange={dateRange}
            filterEndDate={null}
            filterStartDate={null}
            handleSelection={handleSelection}
          />
          <div className="flex flex-col sm:flex-row gap-4 border-t border-grey-bright bg-grey-brightest rounded-b p-6">
            {/* cancel button  */}
            <button
              type="button"
              disabled={!hasValues}
              className="app-button-outline button-lg sm:button-xl text-sm w-full sm:mr-3 flex-1"
              onClick={() => {
                handleFiltersChange(withFallbacks());
              }}
            >
              Clear
            </button>
            <button
              role="button"
              type="button"
              disabled={!hasValues}
              className="app-button-primary button-lg sm:button-xl text-sm w-full flex-1"
              onClick={submitFilterValues}
            >
              Apply filters
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export const initialSortInfo = {
  filterAccountId: '',
  filterStartDate: null,
  filterEndDate: null,
  filterDateRange: '',
};

const withFallbacks = (filters?: Partial<FilterProps> | null) => {
  return {
    filterDateRange: filters?.filterDateRange || '',
    filterStartDate: filters?.filterStartDate || null,
    filterEndDate: filters?.filterEndDate || null,
    filterAccountId: filters?.filterAccountId || '',
  };
};
