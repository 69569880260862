import assetsAbout from '../data/generated/assets-description.json';

export interface AssetAbout {
  description: string;
  usecase: string | null;
  procon: string | null;
  ctaLink?: string | null;
  ctaTitle?: string | null;
  headerImgWeb?: string | null;
  headerImgMobile?: string | null;
}

export const useAssets = () => {
  return {
    assetsAbout: assetsAbout as Record<string, AssetAbout>,
  };
};

export function useAssetInfo(currencyCode: string | null | undefined) {
  const { assetsAbout } = useAssets();

  if (!currencyCode) {
    return null;
  }

  return assetsAbout[currencyCode] ?? null;
}
