import React, { FC } from 'react';
import cx from 'classnames';
import { AppIcon } from 'common';

type NewAddressInfoCardProps = {
  cls?: string;
  onClick?: () => void;
};
export const NewAddressInfoCard: FC<NewAddressInfoCardProps> = ({
  cls,
  onClick,
}) => (
  <div
    className={cx(
      'flex flex-col gap-5 border border-gray-120 rounded-4 bg-white text-center items-center m-10 px-10 py-5',
      cls
    )}
  >
    <AppIcon icon="exclamation" />
    <span className="items-center justify-center">
      We are upgrading our technology to better secure your funds. As a result,
      your deposit address for your assets has changed. While your previous
      address still works, we will be deactivating it soon.
    </span>
    <button
      type="button"
      className="app-button-primary flex-1 flex items-center justify-center"
      onClick={onClick}
    >
      See my new address
    </button>
  </div>
);
