import { API_ERROR_CODES, REGEX } from 'common';
import { FC, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '../../../store';
import { VerifyCode } from '../../verify-code';
import { AddCryptoAddressSteps } from './steps';

type CodeProps = Partial<StepWizardChildProps> & {
  onAddressAdded: () => void;
};

export const Code: FC<CodeProps> = ({ goToNamedStep, onAddressAdded }) => {
  if (!goToNamedStep) {
    return null;
  }
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.settings.cryptoAddresses.error);
  const currentAddress = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.currentAddress
  );
  const confirmCode = DataStore.useStoreActions(
    a => a.settings.cryptoAddresses.confirmCode
  );
  const resendCode = DataStore.useStoreActions(
    a => a.settings.cryptoAddresses.resendCode
  );

  /**
   * State
   */
  const [cancelled, setCancelled] = useState(false);

  /**
   * Handlers
   */
  const handleOnClose = async () => {
    goToNamedStep(AddCryptoAddressSteps.ConfirmCancellation);
  };

  const onSubmit = async (code: string) => {
    const { isSuccessful, errorCode } = await confirmCode(code);
    if (!isSuccessful) {
      if (errorCode) {
        if (
          errorCode === API_ERROR_CODES.ACCOUNT_LOCKED_FAILED_CONFIRM_ATTEMPTS
        ) {
          goToNamedStep(AddCryptoAddressSteps.AccountLocked);
        }
      }
      return;
    }
    onAddressAdded();
    goToNamedStep(AddCryptoAddressSteps.Summary);
  };

  const handleOnCancelled = () => {
    setCancelled(true);
  };

  /**
   * DOM
   */
  if (!currentAddress) {
    return null;
  }
  return (
    <>
      <VerifyCode
        acceptedCharacters={REGEX.ALPHANUMERIC}
        titleMessage="Check email for verification number"
        submitButtonText="Add address"
        error={error}
        onClose={handleOnClose}
        onSubmit={onSubmit}
        onResend={resendCode}
        cancelled={cancelled}
        onCancelled={handleOnCancelled}
      />
    </>
  );
};
