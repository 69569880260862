import { AppTenant } from './app-tenant';

export const getThemeForTenant = (tenant: AppTenant) => {
  if (tenant === 'Apex') {
    return `apex`;
  }
  if (tenant === 'Rj') {
    return `rj`;
  }
  return `xbto`;
};
