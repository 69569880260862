export interface TransferFormikProps {
  currencyCode: string | null;
  amount: string | null;
  sourceAccountId: string | null;
  destinationAccountId: string | null;
  description: string | null;
}

export enum TransferFormikField {
  currencyCode = 'currencyCode',
  amount = 'amount',
  sourceAccountId = 'sourceAccountId',
  destinationAccountId = 'destinationAccountId',
  description = 'description',
}
