import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '../../../store';
import { WithdrawCashSteps } from './steps';
import { FormFooter } from '../shared/form-footer';
import { createFiatWithdrawalHelper } from './helper';
import { WithdrawalInfo } from './shared/withdrawal-info';
import { getAccountInfo, getAccountType } from '~/utils/get-account-details';
import { Auth0 } from '~/utils/auth0';
import { TransactionPeriodNotice } from '../shared/transaction-period-notice';
import { FormHeader } from 'common';

export const Preview: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.withdrawCash.error);
  const ask2FAForWithdraw = DataStore.useStoreState(
    s => !!s.portfolio.accountDetail?.settings?.ask2FaForFiatWithdrawals
  );
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const formValues = DataStore.useStoreState(s => s.withdrawCash.formValues);
  const simulation = DataStore.useStoreState(s => s.withdrawCash.simulation);

  const createFiatWithdrawal = DataStore.useStoreActions(
    a => a.withdrawCash.create
  );

  /**
   * Variables
   */
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Methods
   */
  const onCancelPreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    goToNamedStep(WithdrawCashSteps.Form);
  };
  const onNextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isAuth0 = await Auth0.isAuthenticated();
    if (ask2FAForWithdraw) {
      if (isAuth0) {
        const token = await Auth0.triggerMfa();
        if (!token) {
          return;
        }
      } else {
        goToNamedStep(WithdrawCashSteps.Validate2FA);
        return;
      }
    }

    createFiatWithdrawalHelper(
      formValues,
      createFiatWithdrawal,
      null,
      goToNamedStep
    );
  };

  /**
   * DOM
   */
  if (!accountDetail || !simulation) {
    return null;
  }
  return (
    <div className="flex flex-col">
      {/* header  */}

      <FormHeader
        onBack={onCancelPreview}
        title={`Withdraw ${formValues?.fromAsset?.currency.displayCode || ''}`}
        accountInfo={getAccountInfo(accountDetail.account, 'From')}
      />

      {/* content  */}
      <WithdrawalInfo
        amountSent={simulation?.formatted.amountSentWithCurrencyCodeAndSign}
        accountName={accountDetail?.account?.label || ''}
        accountType={getAccountType(accountDetail?.account)}
        accountNumber={accountDetail?.account?.accountNumber || ''}
        fees={simulation?.formatted.feeWithCurrencyCodeAndSign}
        amountReceive={
          simulation?.formatted.amountReceivedWithCurrencyCodeAndSign
        }
        bankAccount={formValues?.bankAccount}
        description={formValues?.description}
      />
      <TransactionPeriodNotice
        workflow={'workflow-withdraw-cash'}
        accountType={accountDetail?.account?.accountType}
      />
      {/* actions  */}
      <FormFooter
        error={error}
        onSubmit={onNextStep}
        onCancel={onCancelPreview}
        submitCls="app-button-accent"
        submitText="Confirm"
      />
    </div>
  );
};
