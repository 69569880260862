import {
  BlockchainNetworkName,
  EnrichedCurrency,
} from '../../../types';

export enum ReceiveMethod {
  crypto = `Receive from a crypto address`,
  transfer = 'Receive from stablehouse user', // Note: https://stablehouse.atlassian.net/browse/SH-5156
}

export enum ReceiveFormikField {
  receiveMethod = 'receiveMethod',
  toAsset = 'toAsset',
  network = 'network',
}

export interface ReceiveFormikProps {
  [ReceiveFormikField.receiveMethod]: ReceiveMethod | null;
  [ReceiveFormikField.toAsset]: EnrichedCurrency | null;
  [ReceiveFormikField.network]: BlockchainNetworkName | null;
}
