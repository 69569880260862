import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppStore, DataStore } from '../../../store';
import { SuccessfulOperationStep } from '../shared/successful-operation-step';

export const CancelledTransfer: FC<Partial<StepWizardChildProps>> = () => {
  /**
   * Store
   */
  const setDashboardSelectedAsset = AppStore.useStoreActions(
    a => a.setDashboardSelectedAsset
  );
  const setSelectedDialogType = AppStore.useStoreActions(
    s => s.setDashboardSelectedDialogType
  );
  const resetState = DataStore.useStoreActions(a => a.transfer.reset);

  const handleBack = () => {
    resetState();
    setSelectedDialogType(null);
    setDashboardSelectedAsset(null);
  };

  /**
   * DOM
   */
  return (
    <SuccessfulOperationStep
      title="Your transfer request has been successfully canceled"
      description="Your transfer request will no longer be processed and your funds will be returned to your account."
      onBack={handleBack}
    />
  );
};
