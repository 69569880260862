import { API } from 'api';
import memoizee from 'memoizee';
import { SORT_ASSETS_BY } from '../config';
import { EnrichedAccountDetailAsset } from '../types';
import { allowedTradeFundsSelector } from '../utils/selectors';
import { sortFunds } from './sort-funds';

const _getAllowedTradeFunds = (
  assets: EnrichedAccountDetailAsset[],
  selectedFromCurrencyCode: string | null = null,
  selectedToCurrencyCode: string | null = null,
  tradeSide = API.TradeSide.From
) => {
  const sortedFunds = sortFunds(assets, SORT_ASSETS_BY.BALANCE_USD);
  if (!sortedFunds) {
    return [];
  }

  const selectedFromFund = sortedFunds.find(
    f => f.currency.code === selectedFromCurrencyCode
  );
  const selectedToFund = sortedFunds.find(
    f => f.currency.code === selectedToCurrencyCode
  );
  const allowedTradeFunds = sortedFunds.filter(f =>
    allowedTradeFundsSelector(f, selectedFromFund, selectedToFund, tradeSide)
  );

  return allowedTradeFunds;
};

export const getAllowedTradeFunds = memoizee(_getAllowedTradeFunds);
