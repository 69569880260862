import { enrichCurrencyInformation } from './enrich-currency-information';
import { enrichTrade } from './enrich-trade';
import { enrichFiatWithdrawal } from './enrich-fiat-withdrawal';
import { enrichFiatWithdrawaSimulation } from './enrich-fiat-withdrawal-simulation';
import { enrichTransfer } from './enrich-transfer';
import { enrichTradeSimulation } from './enrich-trade-simulation';
import { enrichTransferSimulation } from './enrich-transfer-simulation';
import { enrichWithdrawal } from './enrich-withdrawal';
import { enrichWithdrawalSimulation } from './enrich-withdrawal-simulation';
import { enrichListAccountItem } from './enrich-list-account-item';
import { mapTradableCurrenciesToEnrichedAccountDetailAssets } from './enrich-trade-currency-to-account-detail-asset';
import { enrichAccountDetail } from './enriched-account-detail';
import { enrichAssetHoldings } from './enrich-asset-holdings';
import { enrichAllHoldings } from './enrich-all-holdings';
import { enrichFiatDeposit } from './enrich-fiat-deposit';
import { enrichTransaction } from './enrich-transaction';
import {
  enrichActivities,
  enrichActivitiesPaginatedResponse,
} from './enrich-activities';
import { enrichCurrency } from './enrich-currency';
import { enrichSimplifiedAssetList } from './enrich-simplified-asset-list';

export * from './balance-change';
export * from './balances';
export * from './enriched-internal-transfer-factory';
export * from './enriched-invoice-factory';

export const factories = {
  enrichCurrency,
  enrichCurrencyInformation,
  enrichAccountDetail,
  enrichTrade,
  mapTradableCurrenciesToEnrichedAccountDetailAssets,
  enrichTradeSimulation,
  enrichFiatWithdrawal,
  enrichFiatWithdrawaSimulation,
  enrichWithdrawal,
  enrichWithdrawalSimulation,
  enrichTransfer,
  enrichTransferSimulation,
  enrichListAccountItem,
  enrichAssetHoldings,
  enrichAllHoldings,
  enrichFiatDeposit,
  enrichTransaction,
  enrichActivities,
  enrichActivitiesPaginatedResponse,
  enrichSimplifiedAssetList,
};
