import { API } from 'api';
import { ERROR_MESSAGES } from '../constants';

export enum ContactUsTopic {
	/**
	 * Note: ordered alphabetically per product request
	 */
	CLOSE_ACCOUNT = `Close account`,
	GENERAL_ENQUIRY = `General enquiry`,
	PRODUCT_SUPPORT = `Product support`,
	WEBSITE_FEEDBACK = `Website feedback`,
	MOBILE_FEEDBACK = `Mobile feedback`,
	API_DEVELOPMENT = `API Development`,
	DEPOSIT_RELATED = `Deposit related`,
	TRADE_RELATED = `Trade related`,
	WITHDRAWAL_RELATED = `Withdrawal related`,
	XBTO_FUNDS = `XBTO funds`,
	INVESTMENT_OPORTUNITIES = `Investment opportunities`,
	INVESTMENT_STRATEGIES = `Investment strategies`,
	TRADING_SERVICES = `Trading services`,
	ADVISORY_SERVICES = `Advisory services`,
	MARKET_MAKING_SERVICES = `Market making services`,
	PRESS = `Press`,
	OTHER = `Other`,
}

export enum SendContactFormInterests {
  Trading = 'Trading',
  Custody = 'Custody',
  Staking = 'Staking',
  WhiteLabel = 'WhiteLabel',
  Investments = 'Investments',
  Other = 'Other',
}

export interface SendContactForm {
  email: string | null;
  content: string | null;
  name: string | null;
  topic: ContactUsTopic;
  recaptchaToken: string | null;
  company: string | null;
  country: string | null;
  website: string | null;
}

export interface SendContactFormNew {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  company: string;
  country: string | null;
  interests: SendContactFormInterests;
  howDidYouHearAboutUs: string | null;
  recaptchaToken: string | null;
}

/**
 * Note:
 * The below helper is to interact with the API directly without access to `apiClient`
 */
const handleFetchPost = async (
  url: string,
  payload: SendContactForm | SendContactFormNew
) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
  const json = await response.json();
  return json as API.ApiResponse;
};

const getDefaultErrorResponse = () => {
  return {
    isSuccessful: false,
    errorMessage: ERROR_MESSAGES.DEFAULT,
    errorCode: null,
    requestId: null,
    timestamp: Date.now(),
  };
};

export const sendApiV1Contact = async (
  apiUrl: string,
  payload: SendContactForm
): Promise<API.ApiResponse> => {
  try {
    const body: SendContactForm = {
      name: payload.name,
      email: payload.email,
      content: payload.content,
      recaptchaToken: payload.recaptchaToken,
      topic: payload.topic,
      company: payload.company,
      country: payload.country,
      website: payload.website,
    };
    const result = await handleFetchPost(`${apiUrl}/v1/contact`, body);
    return result;
  } catch (error) {
    return getDefaultErrorResponse();
  }
};

export const sendApiV1ContactNew = async (
  apiUrl: string,
  payload: SendContactFormNew
): Promise<API.ApiResponse> => {
  try {
    const result = await handleFetchPost(`${apiUrl}/v1/contact/new`, payload);
    return result;
  } catch (error) {
    return getDefaultErrorResponse();
  }
};
