import { API } from 'api';
import { Enriched, EnrichedCurrencyInformation } from '../../types';
import { formatters } from '../formatters';
import { DEFAULTS } from '../../constants';
import { StablehousePusherChannel } from '../subscribe-pusher';
import { createSendDestination } from './create-send-destination';

export const enrichWithdrawal = (
  withdrawal: API.Withdrawal | null,
  currencies: EnrichedCurrencyInformation[],
  fiatCurrencyCodes: string[]
): Enriched.Withdrawal | null => {
  if (!withdrawal) {
    return null;
  }

  const currency =
    currencies.find(({ code }) => code === withdrawal.currencyCode) ?? null;

  if (!currency) {
    return null;
  }

  const amountWithCurrencyCode = `${withdrawal.amount} ${currency.displayCode}`;
  // formatters.getCurrency(
  //   withdrawal.amount,
  //   currency.decimals,
  //   currency.displayCode,
  //   fiatCurrencyCodes
  // );

  const amountWithCurrencyCodeAndSign = formatters.forceSign(
    amountWithCurrencyCode,
    '-'
  );

  const amountSentWithCurrencyCode = `${withdrawal.amountSent} ${currency.displayCode}`;
  //   formatters.getCurrency(
  //   withdrawal.amountSent,
  //   currency.decimals,
  //   currency.displayCode,
  //   fiatCurrencyCodes
  // );

  const amountSentUsdWithCurrencyCode = formatters.getCurrency(
    withdrawal.amountSentUsd,
    DEFAULTS.DECIMAL_SCALE,
    'USD',
    fiatCurrencyCodes
  );

  const feesWithCurrencyCode = formatters.getCurrency(
    withdrawal.fees,
    currency.decimals,
    currency.displayCode,
    fiatCurrencyCodes
  );

  const invoicedFeeWithCurrencyCode = formatters.getCurrency(
    withdrawal.invoicedFee,
    currency.decimals,
    currency.displayCode,
    fiatCurrencyCodes
  );

  const invoicedFeeUsdWithCurrencyCode = formatters.getCurrency(
    withdrawal.invoicedFeeUsd,
    DEFAULTS.DECIMAL_SCALE,
    'USD',
    fiatCurrencyCodes
  );

  return {
    ...withdrawal,
    channelName: StablehousePusherChannel.WITHDRAWAL_STATUS,
    timeline: (withdrawal as API.WithdrawalWithTimeline).timeline ?? null,
    formatted: {
      amountWithCurrencyCode,
      amountWithCurrencyCodeAndSign,
      amountSentWithCurrencyCode,
      amountSentUsdWithCurrencyCode,
      destination: createSendDestination(withdrawal, currency),
      feesWithCurrencyCode,
      invoicedFeeWithCurrencyCode,
      invoicedFeeUsdWithCurrencyCode,
    },
  };
};
