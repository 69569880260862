import { FC, memo, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { StepWizardChildProps } from 'react-step-wizard';
import { Form as FormikForm, FormikProps } from 'formik';
import {
  addressItemTemplate,
  addressSelectedItemTemplate,
  singleWithdrawableItemTemplate,
  singleWithdrawableSelectedItemTemplate,
} from '../../app-selector/templates';
import {
  AppIcon,
  BlockchainNetworkName,
  CurrencyIcon,
  decimalNumberValidation,
  DEFAULTS,
  EnrichedAccountDetailAsset,
  EnrichedCryptoAddress,
  FormHeader,
  getSendFees,
  Note,
  NUMBER_FORMAT,
  SegmentTrackType,
  SendDestinationType,
  SendFormikField,
  SendFormikProps,
  TIMERS,
  useSegmentTrack,
} from 'common';
import { SendSteps } from './steps';
import { AppStore, DataStore } from '../../../store';
import { Error } from '../../common/error';
import { FormikDebug } from '../../common/formik-debug';
import { DebounceField } from '../../forms/debounce-field';
import { SelectField } from '../../forms/select-field';
import { NumberField } from '../../forms/number-field';
import { NotAllowed } from '../shared/not-allowed';
import { TextAreaField } from '~/components/forms/textarea-field';
import { getAccountInfo, getAccountType } from '~/utils/get-account-details';
import { API } from 'api';
import { AssetBalanceBreakdown } from '../shared/asset-balance-breakdown';
import { ImitationSelectField } from '~/components/lib';
import { FeeNote } from '~/components/fee-note';

interface AddAddressProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const AddAddress: FC<AddAddressProps> = ({ onClick }) => (
  <div className="flex flex-row items-center justify-between mt-2.5">
    <span>Address</span>
    <button
      type="button"
      onClick={onClick}
      className="flex flex-row items-center gap-1"
    >
      <AppIcon icon="plus" size={24} bg="bg-transparent" />
      <span className="text-primary font-bold text-base">Add new address</span>
    </button>
  </div>
);

export const Form: FC<
  Partial<StepWizardChildProps> & FormikProps<SendFormikProps>
> = memo(
  ({
    values,
    errors,
    handleBlur,
    submitForm,
    isValidating,
    goToNamedStep,
    setFieldValue,
    setFieldTouched,
  }) => {
    if (!goToNamedStep) {
      return null;
    }

    /**
     * Store
     */
    const error = DataStore.useStoreState(s => s.send.error);
    const busy = DataStore.useStoreState(s => s.send.busy);
    const accountDetail = DataStore.useStoreState(
      s => s.portfolio.accountDetail
    );
    const assetBalances = DataStore.useStoreState(s => s.send.assetBalances);
    const formValues = DataStore.useStoreState(s => s.send.formValues);
    const allowedAssets = DataStore.useStoreState(s => s.send.allowedAssets);
    const simulation = DataStore.useStoreState(s => s.send.simulation);
    const setSimulation = DataStore.useStoreActions(a => a.send.setSimulation);

    const getBankAccounts = DataStore.useStoreActions(
      a => a.settings.getBankAccounts
    );
    const verifiedWithdrawalAddresses = DataStore.useStoreState(
      a => a.settings.cryptoAddresses.verifiedWithdrawalAddresses
    );
    const getWithdrawalAddresses = DataStore.useStoreActions(
      a => a.settings.cryptoAddresses.getWithdrawalAddresses
    );
    const setFormValues = DataStore.useStoreActions(a => a.send.setFormValues);
    const setSendMethod = DataStore.useStoreActions(a => a.send.setSendMethod);
    const setSelectedWithdrawalAddress = AppStore.useStoreActions(
      a => a.setSelectedWithdrawalAddress
    );
    const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);
    const dashboardSelectedAsset = AppStore.useStoreState(
      s => s.dashboardSelectedAsset
    );
    const setSelectedDialogType = AppStore.useStoreActions(
      a => a.setDashboardSelectedDialogType
    );

    /**
     * Hooks
     */
    useEffect(() => {
      getBankAccounts();
      getWithdrawalAddresses({
        accountId: accountDetail?.account?.accountId || '',
      });
    }, []);
    useSegmentTrack(SegmentTrackType.StartWithdrawalStep1, simulation);

    /**
     * DOM
     */
    if (!accountDetail) {
      return null;
    }
    const fromAsset = formValues?.fromAsset || dashboardSelectedAsset || null;
    if (!allowedAssets || allowedAssets.length <= 0) {
      return (
        <NotAllowed
          message={`There are no assets in your account ${getAccountType(
            accountDetail?.account
          )} ${
            accountDetail?.account?.accountNumber
          } to perform this operation`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }
    if (fromAsset && !!fromAsset.currency.isAssetOfTypeFiat) {
      return (
        <NotAllowed
          message={`Send operation is not allowed for ${fromAsset.currency.displayCode}`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }
    if (
      fromAsset &&
      !allowedAssets.find(a => a.currency.code === fromAsset.currency.code)
    ) {
      // chosen asset is not in account!
      return (
        <NotAllowed
          message={`There is no ${
            fromAsset.currency.displayCode
          } in your account ${getAccountType(accountDetail?.account)} ${
            accountDetail?.account?.accountNumber
          } to perform this operation`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }

    const initAddressesList = (code?: string) =>
      verifiedWithdrawalAddresses.filter(
        ({ currencyCode, isVerifying }) => currencyCode === code && !isVerifying
      );

    const handleSendDestinationType = (code: string | undefined) =>
      initAddressesList(code).length > 0
        ? SendDestinationType.book
        : SendDestinationType.crypto;

    /**
     * Methods
     */
    const handleOnAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = decimalNumberValidation(e.target.value);
      setFieldValue(SendFormikField.amount, val, true);
      setFieldTouched(SendFormikField.amount, true, false);
    };
    const handleOnFundChanged = async (
      newValue: EnrichedAccountDetailAsset
    ) => {
      if (isValidating) {
        return;
      }
      await setFieldValue(
        SendFormikField.network,
        newValue.currency.network,
        false
      );
      await setFieldValue(
        SendFormikField.destinationType,
        handleSendDestinationType(newValue?.currency.code),
        false
      );
      await setFieldValue(SendFormikField.amount, '', false);
      await setFieldValue(SendFormikField.whitelistAddress, null, false);

      // IMPORTANT:
      // Trigger validation on last field
      // setFieldValue(<name>, <value>, shouldValidate: true)
      await setFieldValue(SendFormikField.destination, '', true);

      setSelectedWithdrawalAddress(null);
    };
    const handleOnSendMethodChanged = async (value: SendDestinationType) => {
      await setFieldValue(SendFormikField.destination, '', true);
      setSelectedWithdrawalAddress(null);
      setSendMethod(value);
    };
    const handleChangeAddress = async (value: EnrichedCryptoAddress) => {
      await setFieldValue(SendFormikField.whitelistAddress, value, true);
    };
    const handleChangeDestination = async (value: string) => {
      await setFieldValue(SendFormikField.destination, value, true);
    };
    const handleChangeDescription = async (
      e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      await setFieldValue(SendFormikField.description, e.target.value, true);
    };

    const handleMaxBtnClick = async () => {
      if (isValidating) {
        return;
      }
      setSimulation(null);
      await setFieldValue(
        SendFormikField.amount,
        values.fromAsset?.withdrawableQuantity,
        true
      );
    };
    const handleNextBtnClick = async (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.preventDefault();
      await submitForm();
      goToNamedStep(SendSteps.Preview);
    };
    const handleCancelBtnClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.preventDefault();
      setSelectedDialogType(null);
    };

    const handleAddAddress = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.preventDefault();
      setFormValues(values);
      goToNamedStep('add-crypto-address');
    };

    /**
     * Form DOM
     */
    const hasErrors = Object.values(errors).length > 0;
    const addressesList = initAddressesList(values.fromAsset?.currency.code);
    const isDestinationDisabled =
      !values.fromAsset || values.fromAsset?.balance <= 0;
    const cantGoNextStep =
      isValidating ||
      error !== null ||
      !formValues ||
      busy ||
      Object.keys(errors).length > 0 ||
      (values.destinationType === SendDestinationType.crypto && !simulation);

    const supportedWithdrawMethods = useMemo(() => {
      const methods = [SendDestinationType.crypto];
      if (
        !!accountDetail?.canStabletagTransfer ||
        !!values.fromAsset?.canStabletagTransfer
      ) {
        methods.push(SendDestinationType.transfer);
      }
      if (addressesList.length > 0) {
        methods.push(SendDestinationType.book);
      }
      return methods;
    }, [
      accountDetail?.canStabletagTransfer,
      values.fromAsset?.canStabletagTransfer,
      addressesList.length,
    ]);
    const feeLabel = getSendFees(
      formValues?.fromAsset?.currency.displayCode,
      formValues,
      simulation
    );

    const feeNote =
      formValues?.amount && simulation && !isValidating && !hasErrors ? (
        formValues?.destinationType === SendDestinationType.transfer ? null : (
          <>
            {simulation.feeMode ===
            API.WithdrawalFeeChargeMode.ChargedImmediately ? (
              <FeeNote
                feeLabel={feeLabel}
                extraLine={
                  <>
                    <span>You send:</span>{' '}
                    <span className="font-bold text-primary">
                      {simulation.formatted.sendingAmountWithCurrencyCode}
                    </span>{' '}
                    <span className="text-primary">
                      ({simulation.formatted.sendingAmountUsdWithCurrencyCode})
                    </span>
                  </>
                }
              />
            ) : (
              <FeeNote
                feeLabel={feeLabel}
                feeFirst={false}
                extraLine={
                  <div className="text-grey-darker">
                    Value:{' '}
                    <b className="text-primary">
                      {simulation.formatted.amountUsdWithCurrencyCode}
                    </b>
                  </div>
                }
              />
            )}
          </>
        )
      ) : null;
    return (
      <div data-testid="send-form" className="flex flex-col">
        <FormikForm>
          {/* header  */}
          <FormHeader
            title={`Send ${values.fromAsset?.currency.displayCode || ''}`}
            accountInfo={getAccountInfo(accountDetail.account, 'From')}
          />
          <div className="px-6 md:px-10">
            {/* fromAsset */}
            <div className="flex flex-col gap-y-1">
              <SelectField
                name={SendFormikField.fromAsset}
                label="Asset"
                values={allowedAssets}
                getSelectedItemTemplate={fund =>
                  singleWithdrawableSelectedItemTemplate(fund)
                }
                getItemTemplate={(fund, selected: boolean, active: boolean) =>
                  singleWithdrawableItemTemplate(fund, selected, active)
                }
                onChange={handleOnFundChanged}
              />
              {values.fromAsset?.currency ===
                formValues?.fromAsset?.currency && (
                <AssetBalanceBreakdown
                  availableToTitle="Available to send"
                  balances={assetBalances}
                  breakdownType="pendingOutgoing"
                />
              )}
            </div>

            {/* withdraw method  */}
            {accountDetail.canWithdrawToNonWhitelistedAddress && (
              <div className="flex flex-col">
                <SelectField
                  name={SendFormikField.destinationType}
                  label="Send method"
                  disabled={
                    !values.fromAsset && supportedWithdrawMethods?.length < 2
                  }
                  onChange={handleOnSendMethodChanged}
                  values={supportedWithdrawMethods}
                />
              </div>
            )}

            {/* method = crypto  */}
            <div
              className={cx('flex flex-col', {
                hidden:
                  values.destinationType !== SendDestinationType.crypto ||
                  !values.fromAsset ||
                  !values.network,
              })}
            >
              {/* network dropdown  */}
              <SelectField
                name={SendFormikField.network}
                label="Network"
                disabled
                values={Object.values(BlockchainNetworkName)}
              />
            </div>

            {/* method = crypto or tag  */}
            {/* destination address/ tag /book */}
            <div className="flex flex-col">
              {!accountDetail.canWithdrawToNonWhitelistedAddress && (
                <>
                  <SelectField
                    name={SendFormikField.whitelistAddress}
                    label={<AddAddress onClick={handleAddAddress} />}
                    disabled={!addressesList.length}
                    values={addressesList}
                    getItemTemplate={(
                      item: EnrichedCryptoAddress,
                      selected: boolean,
                      active: boolean
                    ) => addressItemTemplate(item, selected, active)}
                    getSelectedItemTemplate={(
                      item: EnrichedCryptoAddress | null
                    ) => addressSelectedItemTemplate(item)}
                    disableHelpers
                    onChange={handleChangeAddress}
                  />
                  {!addressesList.length && (
                    <Note cls="px-2 mt-4 font-semibold">
                      {` There are no whitelisted addresses configured${
                        clientUserType !== 'none'
                          ? `, Contact your Apex representative.`
                          : '.'
                      }`}
                    </Note>
                  )}
                </>
              )}
              {accountDetail.canWithdrawToNonWhitelistedAddress && (
                <div className="flex flex-col">
                  {values.destinationType === SendDestinationType.book ? (
                    <ImitationSelectField
                      label={<AddAddress onClick={handleAddAddress} />}
                      onClick={async () => {
                        setFormValues(values);
                        goToNamedStep(SendSteps.AddressBook);
                      }}
                    >
                      {values.destination || (
                        <span className="text-grey">Please select</span>
                      )}
                    </ImitationSelectField>
                  ) : (
                    <DebounceField
                      name={SendFormikField.destination}
                      label={
                        <>
                          {values.destinationType ===
                          SendDestinationType.transfer ? (
                            'Enter Stablehouse user'
                          ) : (
                            <AddAddress onClick={handleAddAddress} />
                          )}
                        </>
                      }
                      spellCheck={false}
                      placeholder={
                        values.destinationType === SendDestinationType.transfer
                          ? ' '
                          : 'e.g. 36dexYmEWM4ShowE9he9hstoPwa1oPhYkt'
                      }
                      debounceTimeout={TIMERS.INPUT_DEBOUNCE}
                      disabled={isDestinationDisabled}
                      autoComplete="off"
                      onBlur={handleBlur}
                      disableHelpers
                      onChange={handleChangeDestination}
                    />
                  )}

                  {values.destinationType === SendDestinationType.crypto &&
                    !!values.destination &&
                    !errors?.destination && (
                      <Note cls="text-sm flex flex-col gap-2 px-4 py-3">
                        <span className="font-bold text-primary">
                          Please verify the address before proceeding
                        </span>
                        <span className="text-grey-darker">
                          This address isn&#39;t stored in your address book.
                          Ensure its accuracy before continuing with your
                          transaction.
                        </span>
                      </Note>
                    )}
                </div>
              )}
            </div>

            {/* amount  */}
            <div className="flex flex-col">
              <NumberField
                name={SendFormikField.amount}
                label="Amount"
                placeholder="Enter amount"
                allowNegative={false}
                decimalScale={
                  values.fromAsset?.currency?.decimals || DEFAULTS.DECIMAL_SCALE
                }
                disabled={!values.fromAsset?.hasBalance}
                autoComplete="off"
                thousandSeparator={NUMBER_FORMAT.THOUSANDS_SEPARATOR}
                leftAddon={
                  values.fromAsset ? (
                    <CurrencyIcon
                      currencyCode={values?.fromAsset?.currency.code}
                      apy={values?.fromAsset.currency.apy}
                    />
                  ) : undefined
                }
                rightAddon={
                  values.fromAsset ? (
                    <button
                      type="button"
                      className="text-sm font-bold underline focus:outline-none relative mb-1"
                      onClick={handleMaxBtnClick}
                    >
                      Max
                    </button>
                  ) : undefined
                }
                onChange={handleOnAmountChange}
              />

              {feeNote}
            </div>

            {/* description  */}
            <TextAreaField
              label="Description"
              placeholder={'Add description'}
              maxLength={1024}
              name={SendFormikField.description}
              onChange={handleChangeDescription}
            />
          </div>

          <FormikDebug />

          {/* api errors  */}
          <Error message={error} cls="mx-6 md:mx-10" />

          {/* actions  */}
          <div className="flex flex-col gap-y-4 gap-x-3 sm:flex-row border-t border-grey-bright bg-gray-100 rounded-b py-6 px-6 md:px-10 mt-12">
            {/* cancel button  */}
            <button
              className="app-button-outline w-full flex-1"
              onClick={handleCancelBtnClick}
            >
              Cancel
            </button>
            {/* preview  */}
            <button
              role="button"
              disabled={cantGoNextStep}
              type="button"
              className="app-button-primary w-full flex-1"
              onClick={handleNextBtnClick}
            >
              Preview
            </button>
          </div>
        </FormikForm>
      </div>
    );
  }
);
