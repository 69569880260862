import { EnrichedAccountDetailAsset, EnrichedAssetHolding } from 'common';
import { DASHBOARD_SELECTED_DIALOG_TYPE } from '~/store/types';

export type DashboardRowSelect = (
  action: DASHBOARD_SELECTED_DIALOG_TYPE,
  selectedFund: EnrichedAccountDetailAsset | EnrichedAssetHolding | null
) => void;

export const DASHBOARD_PARAMS = {
  FIRST_DEPOSIT: 'first-deposit',
  WELCOME_BONUS: 'welcome-bonus',
  CHECKOUT_STATUS: `checkout-status`,
};
