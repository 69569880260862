import { FC } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { DataStore } from '../../store';
import { Unrestricted404 } from './unrestricted-404';

export const FourOFour: FC<RouteProps> = () => {
  /**
   * Store
   */
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);

  /**
   * DOM
   */
  if (isAuthenticated) {
    return <Navigate to={{ pathname: APP_ROUTES.AUTH_404 }} />;
  }
// TODO(Hadrien): @jey is this page still accessible somehow ?
//  I cant find a way to make it display, I always endup on the other 404
  return <Unrestricted404 />;
};
