import { API } from 'api';
import {
  validateDestinationAddress,
  validateDestinationTag,
} from '../get-recipient-details';
import memoize from 'memoizee';
import { ValidationResult } from './types';
import { SendDestinationType } from '../formik/send/types';

const validate = async (
  destination: string,
  blockchainCode: string,
  apiClient: API.StablehouseClient,
  accountId: string,
  destinationType: SendDestinationType
): Promise<ValidationResult> => {
  try {
    if (destinationType === SendDestinationType.transfer) {
      return validateDestinationTag(destination, accountId, apiClient);
    }

    // SendDestinationType.crypto
    // SendDestinationType.book
    return validateDestinationAddress(
      destination,
      blockchainCode,
      apiClient,
      accountId
    );
  } catch {
    return Promise.resolve({ isValid: false });
  }
};

export const validateDestination = memoize(validate);
