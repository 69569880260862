import { FC } from 'react';
import { Field, FieldProps } from './field';
import { SelectInput, SelectInputProps } from 'common';

type Props = FieldProps &
  Omit<SelectInputProps, 'value' | 'onChange'> & {
    value?: any;
    onChange?: (newValue: any) => void;
    disableHelpers?: boolean;
  };

export const SelectField: FC<Props> = ({
  label,
  name,
  labelCls,
  onChange: handleChange,
  disableHelpers = false,
  ...rest
}) => {
  /**
   * DOM
   */
  return (
    <Field name={name} label={label} labelCls={labelCls}>
      {(field, meta, helpers) => {
        const showError = Boolean(meta.error && meta.touched && !!meta.value);
        /**
         * DOM
         */
        return (
          <SelectInput
            errored={showError}
            {...field}
            onChange={(newValue: any) => {
              if (handleChange) {
                handleChange(newValue);
              }
              if (!disableHelpers) {
                helpers.setValue(newValue);
                helpers.setTouched(true);
              }
            }}
            {...rest}
          />
        );
      }}
    </Field>
  );
};
