import { useContext, useEffect } from 'react';
import { useLocation } from 'react-use';
import { LocationSensorState } from 'react-use/lib/useLocation';
import { AnalyticsContext, useSystemConfig } from 'common';
import { DataStore } from '../store';

export const useSegmentPage = () => {
  /**
   * Context
   */
  const analyticsContext = useContext(AnalyticsContext);
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);
  const { envName } = useSystemConfig();

  /**
   * Hooks
   */
  const location = useLocation();

  useEffect(() => {
    if (!analyticsContext || !location || !isAuthenticated) {
      return;
    }

    const value: LocationSensorState & {
      environment: string;
      title: string;
    } = {
      ...location,
      environment: envName,
      title: document.title || '',
    };
    analyticsContext?.page(value as any);
    analyticsContext?.braze?.logCustomEvent('pageview', value as any);
  }, [location && location.pathname]);
};
