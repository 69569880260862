import { useMemo } from 'react';
import { DataStoreType } from '../store';

export function useAddress(DataStore: DataStoreType, id?: string) {
  const withdrawalAddresses = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.withdrawalAddresses
  );

  const address = useMemo(() => {
    return withdrawalAddresses.find(_address => _address.id === id);
  }, [id, withdrawalAddresses]);

  return address;
}
