import { FC } from 'react';
import cx from 'classnames';
import { nanoid } from 'nanoid';
import ReactTooltip, { Effect, Place } from 'react-tooltip';
import { AppIcon } from 'common';

interface Props {
  content: JSX.Element | string;
  id?: string;
  cls?: string;
  tooltipCls?: string;
  contentCls?: string;
  tooltipWidth?: number | null;
  icon?: JSX.Element;
  effect?: Effect | undefined;
  event?: string | undefined;
  place?: Place | undefined;
}

export const AppTooltip: FC<Props> = ({
  content,
  id = nanoid(6),
  cls,
  tooltipCls,
  tooltipWidth = 280,
  icon = <AppIcon icon="information-circle" bg="bg-transparent" size={20} />,
  effect = 'solid',
  event = undefined,
  place = 'top',
  contentCls = 'px-3.5 py-1.5 text-gray-400',
}) => {
  /**
   * DOM
   */
  return (
    <span className={cx('inline-flex', cls)}>
      <div data-tip data-for={id} className="text-gray-300 cursor-pointer">
        {icon}
      </div>
      <ReactTooltip
        id={id}
        className={cx('app-tooltip', tooltipCls)}
        effect={effect}
        event={event}
        place={place}
        globalEventOff={'click'}
        isCapture
      >
        <div
          className={contentCls}
          style={
            tooltipWidth
              ? {
                  width: '100%',
                  maxWidth: tooltipWidth,
                }
              : {}
          }
        >
          {content}
        </div>
      </ReactTooltip>
    </span>
  );
};
