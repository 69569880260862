import { FC, useEffect } from 'react';
import { FundShowDepositDetails, useSegmentTrack } from 'common';
import { SegmentTrackType } from 'common';
import StepWizard from 'react-step-wizard';
import { defaultStepWizardProps } from '../../../constants';
import { AppStore, DataStore } from '../../../store';
import { Form } from './form';
import { Attention } from './attention';
import { useParams } from 'react-router-dom';
import { SearchList } from '~/components/workflows/receive/search-list';
import { RECEIVE_STEPS } from '~/components/workflows/receive/steps';

export type ReceiveProps = {
  chooseCurrency?: JSX.Element;
  chooseMethod?: JSX.Element;
  chooseNetwork?: JSX.Element;
};

export const ReceiveWorkflow: FC<FundShowDepositDetails> = props => {
  /**
   * Store
   */
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const decodedTokenAccountId = DataStore.useStoreState(
    s => s.user.decodedTokenAccountId
  );
  const resetState = DataStore.useStoreActions(a => a.receive.resetState);
  const setDashboardSelectedAsset = AppStore.useStoreActions(
    a => a.setDashboardSelectedAsset
  );

  /**
   * Hooks
   */
  const { fundAccountId } = useParams();
  useSegmentTrack(SegmentTrackType.StartDeposit);
  useEffect(() => {
    // clean up
    () => {
      setDashboardSelectedAsset(null);
      resetState();
    };
  }, []);

  /**
   * DOM
   */
  const accountId =
    clientUserType === 'admin'
      ? decodedTokenAccountId
      : accountDetail?.account?.accountId;
  const impersonatedAccountId =
    clientUserType === 'admin' ? fundAccountId : undefined;
  return (
    <div className="relative text-primary h-full">
      <StepWizard {...defaultStepWizardProps} className="h-full">
        <Form
          {...props}
          stepName={RECEIVE_STEPS.FORM}
          accountId={accountId}
          impersonatedAccountId={impersonatedAccountId}
          onClose={props.onClose}
        />
        <SearchList
          stepName={RECEIVE_STEPS.CHOOSE_ASSET}
          accountId={accountId}
          onClose={props.onClose}
        />
        <Attention stepName={RECEIVE_STEPS.ATTENTION} />
      </StepWizard>
    </div>
  );
};
