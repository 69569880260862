import { FC } from 'react';
import { SettingsApiKeys as SettingsApiKeysInner } from '../../components/settings/api-keys';
import { SettingsLayout, SettingsMenuIds } from './layout';

export const ApiKeys: FC = () => {
  /**
   * DOM
   */
  return (
    <SettingsLayout menuId={SettingsMenuIds.APIKEYS}>
      <SettingsApiKeysInner />
    </SettingsLayout>
  );
};
