import { API } from 'api';
import { EnrichedAccountDetailAsset } from '../../types';

export const allowedTradeFundsSelector = (
  f: EnrichedAccountDetailAsset,
  fromFund?: EnrichedAccountDetailAsset | null,
  toFund?: EnrichedAccountDetailAsset | null,
  tradeSide = API.TradeSide.From
) => {
  // filter out if the fund is already selected either on the from or to side
  if (
    f.currency.code === fromFund?.currency.code ||
    f.currency.code === toFund?.currency.code
  ) {
    return false;
  }
  if (tradeSide === API.TradeSide.From && !f.hasBalance) {
    return false;
  }
  return !!f?.canTrade;
};
