import { Transaction, Enriched } from 'common';
import StepWizard from 'react-step-wizard';
import { defaultStepWizardProps } from '~/constants';
import { Records } from './records';
import { TransactionSummarySteps } from './steps';
import { ConfirmCancelation } from './confirm-cancelation';
import { Canceled } from './canceled';

type TransactionSummaryProps = {
  activity: Enriched.ActivityTransaction | null;
  transaction: Transaction | null;
  onClose: () => void;
};

export const TransactionSummary = ({
  activity,
  transaction,
  onClose,
}: TransactionSummaryProps) => {
  /**
   * DOM
   */
  if (!activity) {
    return null;
  }
  return (
    <StepWizard {...defaultStepWizardProps} className="h-full">
      {/* step 1  */}
      <Records
        activity={activity}
        transaction={transaction}
        stepName={TransactionSummarySteps.Records}
      />
      {/* step 2 - confirm cancel */}
      <ConfirmCancelation
        activity={activity}
        stepName={TransactionSummarySteps.ConfirmCancellation}
      />

      {/* step 3 - show confirmation of cancellation  */}
      <Canceled
        stepName={TransactionSummarySteps.Cancelled}
        onClose={onClose}
      />
    </StepWizard>
  );
};
