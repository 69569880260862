import React from 'react'
import cx from 'classnames'

type Props = { href?: string, label: string, onClick?: () => void, cls?: string, ctaCls?: string, target?: string }
export const FallbackCta = ({ href, label, onClick, cls, ctaCls, target }: Props) => <a
	className={cx('flex flex-row rounded py-4 px-6 items-center justify-center font-bold text-sm', ctaCls || 'bg-secondary text-white', cls)}
	target={href && !target ? '_blank' : target}
	rel="noreferrer"
	href={href}
	onClick={onClick}
>
	{label}
</a>
