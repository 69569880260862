import { FC } from 'react';
import cx from 'classnames';
import { dataTestIds, AppIcon } from 'common';

interface Props {
  testid?: string;
  icon?: JSX.Element;
  content: string | JSX.Element;
  cls?: string;
  contentCls?: string;
  iconColor?: 'gray-400' | 'black' | 'blue-darkest';
  textSize?: 'xs' | 'sm' | 'base';
  mt?: string;
}

export const IconContent: FC<Props> = ({
  icon = <AppIcon bg="bg-transparent" icon="information-circle" size={15} />,
  content,
  cls,
  iconColor = 'gray-400',
  testid = dataTestIds.components.iconContent.root,
  textSize = 'sm',
  contentCls = '',
  mt = 'mt-1',
}) => {
  return (
    <div
      data-testid={testid}
      className={cx(
        `flex flex-row items-center text-gray-400 text-${textSize}`,
        cls
      )}
    >
      <div className={`self-start mr-3 ${mt} fill-${iconColor}`}>{icon}</div>
      <div className={cx(contentCls)}>{content}</div>
    </div>
  );
};
