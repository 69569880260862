import { FC, useEffect, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { Error } from '../../common/error';
import { AppStore, DataStore } from '../../../store';
import { FormHeader, SendDestinationType, useSegmentTrack } from 'common';
import { SegmentTrackType } from 'common';
import { TwoFaWidget } from '../../two-fa-widget';
import { SendSteps } from './steps';
import { createSendHelper } from './helper';

export const PreviewTwoFaWidget: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Store
   */
  const is2FaEnabled = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.is2FaEnabled
  );
  const error = DataStore.useStoreState(s => s.send.error);
  const simulation = DataStore.useStoreState(s => s.send.simulation);
  const formValues = DataStore.useStoreState(s => s.send.formValues);
  const createSend = DataStore.useStoreActions(a => a.send.create);
  const setError = DataStore.useStoreActions(a => a.send.setError);
  const setSelectedDialogType = AppStore.useStoreActions(
    a => a.setDashboardSelectedDialogType
  );

  /**
   * State
   */
  const [twoFaCode, setTwoFaCode] = useState<string>('');

  /**
   * Hooks
   */
  useSegmentTrack(SegmentTrackType.StartWithdrawalStep2, simulation, {
    onlyIf: !!simulation,
  });
  useEffect(() => {
    setError(null);
  }, [twoFaCode]);

  /**
   * State
   */
  const [cancelled, setCancelled] = useState(false);

  /**
   * Methods
   */
  const onCancelPreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    goToNamedStep(SendSteps.Preview);
  };
  const onCloseButtonClicked = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSelectedDialogType(null);
  };
  const onRequestWithdrawal = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    await createSendHelper(
      formValues,
      twoFaCode || null,
      createSend,
      step => goToNamedStep(step),
      () => setCancelled(true)
    );
  };

  /**
   * DOM
   */
  const isTransfer =
    formValues?.destinationType === SendDestinationType.transfer;
  const isDigital =
    !formValues?.fromAsset?.currency.isAssetOfTypeFiat &&
    !formValues?.fromAsset?.currency.isAssetOfTypeFund;
  const submitDisabled =
    (!isTransfer && isDigital && !simulation) || error !== null || !twoFaCode;
  return (
    <div data-testid="send-preview" className="flex flex-col">
      {/* header  */}
      <FormHeader onBack={onCancelPreview} />

      {/* content  */}
      <div className="mx-10 text-primary">
        {/* two fa widget  */}
        <TwoFaWidget
          askFor2FA
          isEnabled={is2FaEnabled}
          onChange={code => {
            setTwoFaCode(code);
          }}
        />
      </div>

      {/* api errors  */}
      <Error message={error} cls="mx-6 md:mx-10" />

      {/* actions  */}
      <div className="flex border-t border-grey-bright bg-gray-100 rounded-b mt-4 py-6 px-6">
        {cancelled && (
          <>
            <button
              className="app-button-outline w-full mr-3 flex-1"
              onClick={onCloseButtonClicked}
            >
              Go to Dashboard
            </button>
          </>
        )}
        {!cancelled && (
          <>
            {/* cancel button  */}
            <button
              className="app-button-outline w-full mr-3 flex-1"
              onClick={onCancelPreview}
            >
              Cancel
            </button>
            {/* withdraw button  */}
            <button
              disabled={submitDisabled}
              type="button"
              className="app-button-accent flex-1 flex items-center justify-center"
              onClick={onRequestWithdrawal}
            >
              Confirm
            </button>
          </>
        )}
      </div>
    </div>
  );
};
