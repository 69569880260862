import { FC } from 'react';
import { CurrencyIcon, EnrichedCryptoAddress, Note } from 'common';
import { DataStore } from '../../store';
import { ShowWithdrawalAddressDetails } from './show-details';

interface Props {
  address: EnrichedCryptoAddress | undefined;
  onClose: () => void;
  onDelete: (bankId: string) => void;
}

export const ShowCryptoAddress: FC<Props> = ({
  address,
  onClose,
  onDelete,
}) => {
  /**
   * Store
   */
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  /**
   * DOM
   */
  if (!address) {
    return null;
  }
  return (
    <div>
      <div className="flex flex-col items-center px-10">
        {/* header  */}
        <div className="mt-12 mb-5">
          <CurrencyIcon
            size={42}
            currencyCode={address?.currencyCode}
            showLabel={false}
          />
        </div>
        <h4 className="text-3xl font-bold text-center mb-6">
          {address?.label}
        </h4>
        <Note
          bgColor={'bg-success-light'}
          border="grey-bright"
          cls="w-full mb-8 flex flex-col items-center text-sm mb-4"
        >
          <div className="flex flex-row items-center">
            <span className="text-primary font-bold text-base">Approved</span>
            <span className="rounded-full w-4 h-4 ml-2 inline-block bg-success"></span>
          </div>
        </Note>
        <ShowWithdrawalAddressDetails withdrawalAddress={address} />
        {/* remove button  */}
        {clientUserType !== 'fund-manager' && (
          <button
            type="button"
            className="app-button-outline failure hover-bg-grey-brighter mb-6"
            style={{ width: '100%' }}
            onClick={() => onDelete(address.id)}
          >
            Remove this address
          </button>
        )}
      </div>
      {/* actions   */}
      <div className="flex border-t border-grey-bright bg-gray-100 rounded-b py-6 px-10">
        {/* cancel button  */}
        <button
          className="app-button-outline w-full mr-3 flex-1"
          onClick={onClose}
        >
          Close window
        </button>
      </div>
    </div>
  );
};
