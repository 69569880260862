/**
 * Note: This utility method is written only to sovle for a content-disposition that looks like below
 * `attachment; filename="2023-07-05-Statement-11-97378.csv"`
 *
 * Using a REGEX breaks chrome/ safari on older IOS - due to JS engine not supporting Regex lookahead/lookbehind
 *
 * @param value
 * @returns string
 */
export const parseContentDispositionFilename = (
  value: string
): undefined | string => {
  if (!value || !value.length) {
    return;
  }

  const parts = value.split(';').map(v => v.trim());
  if (!parts || !parts.length) {
    return;
  }

  const filenamePart = parts.find(p => p.includes('filename='));
  if (!filenamePart) {
    return;
  }

  // replace filename key and double quotes
  return filenamePart.replace('filename=', '').trim().replace(/"/g, '').trim();
};
