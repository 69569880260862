import React, { FC } from 'react';
import cx from 'classnames';
import { BaseComponentProps } from '../../types';

type StatusNoteProps = {
  className?: string;
  level: 'success' | 'warning' | 'failure';
  borderCls?: string;
} & BaseComponentProps;

export const StatusNote: FC<StatusNoteProps> = ({
  children,
  className,
  level,
  borderCls = 'rounded',
}) => {
  /**
   * DOM
   */
  return (
    <div
      className={cx(
        'flex flex-row justify-center items-center px-2 py-1 rounded-4',
        borderCls,
        {
          'bg-success-light': level === 'success',
          'bg-warning-light': level === 'warning',
          'bg-failure-light': level === 'failure',
        },
        {
          'text-success': level === 'success',
          'text-warning': level === 'warning',
          'text-failure': level === 'failure',
        },
        className
      )}
    >
      {children}
    </div>
  );
};
