import { FC } from 'react';
import { DATE_FORMATS, EnrichedInternalTransfer } from 'common';
import { RowLabelContent } from 'common';

import { API } from 'api';
import { format } from 'date-fns';
import { CopyableText } from '~/components/copyable-text';
import { CurrencyTransaction } from './currency-transaction';
import { RowSection } from './row-section';

type AccountDetail = {
  label: string;
  type: string;
  number: string;
  amount: string;
};

type Props = {
  originAccount: AccountDetail;
  destinationAccount: AccountDetail;
  network: string;
  fees: {
    amount: string;
    amountUSD: string;
  };
  showReferenceCode?: boolean;
  showTransactionHash?: boolean;
  showDateOfTransaction?: boolean;
} & Partial<API.Transfer | API.Withdrawal | EnrichedInternalTransfer>;

export const CryptoReceipt: FC<Props> = ({
  originAccount,
  destinationAccount,
  network,
  fees,
  description = null,
  showReferenceCode = false,
  showTransactionHash = false,
  showDateOfTransaction = false,
  ...rest
}) => {
  /**
   * DOM
   */
  return (
    <div>
      {/* send  */}
      <RowLabelContent
        showColon={false}
        cls="border-none"
        label={`Send`}
        content={
          <div className="flex flex-col text-primary items-end gap-y-1">
            <CurrencyTransaction
              positive={false}
              amount={originAccount.amount}
            />
            <RowSection
              title={originAccount.label || ''}
              description={`${originAccount.type || ''} ${originAccount.number || ''}`}
            />
          </div>
        }
      />
      {/* network  */}

      <RowLabelContent
        showColon={false}
        label={`Network`}
        content={network || ''}
      />

      {/* fees  */}

      <RowLabelContent
        showColon={false}
        label={`Fee`}
        content={
          <div className="flex flex-col items-end">
            <span>{fees.amount}</span>
            {!!fees.amountUSD && (
              <span className="text-sm text-grey-darker font-normal">
                {fees.amountUSD}
              </span>
            )}
          </div>
        }
      />
      {/* amount withdrawn  */}

      <RowLabelContent
        showColon={false}
        cls="mb-3"
        label={`Receive`}
        content={
          <div className="flex flex-col text-primary items-end gap-y-1">
            <CurrencyTransaction amount={destinationAccount.amount} />
            <RowSection
              title={destinationAccount.label || ''}
              description={`${destinationAccount.type || ''} ${
                destinationAccount.number || ''
              }`}
            />
          </div>
        }
      />

      {/* reference code  */}
      {showReferenceCode && (
        <RowLabelContent
          showColon={false}
          label={`Reference code`}
          content={
            <CopyableText
              showCopyText={false}
              text={rest.humanReadableIdentifier}
              textCls="font-bold uppercase text-sm sm:text-base"
            />
          }
        />
      )}

      {/* transaction hash  */}
      {showTransactionHash && (
        <RowLabelContent
          showColon={false}
          label={`Transaction hash`}
          content={
            <CopyableText
              showCopyText={false}
              text={rest.humanReadableIdentifier}
              textCls="font-bold uppercase text-sm sm:text-base"
            />
          }
        />
      )}

      {/* date of transaction   */}
      {showDateOfTransaction && rest?.creationDate && (
        <RowLabelContent
          showColon={false}
          label={`Date of transaction `}
          content={
            <>
              {format(rest?.creationDate, DATE_FORMATS.D_LLL_Y)}{' '}
              {format(rest?.creationDate, DATE_FORMATS.H_MM_AAA_Z)}
            </>
          }
        />
      )}

      {/* description  */}
      {description && (
        <RowLabelContent
          label={`Description`}
          showColon={false}
          content={<p>{description}</p>}
        />
      )}
    </div>
  );
};
