import { memo } from 'react';
import { CopyDynamicTextParts } from 'common';
import cx from 'classnames';

type Props = {
  parts: CopyDynamicTextParts;
};

export const DynamicText = memo<Props>(({ parts }) => {
  /**
   * DOM
   */
  if (typeof parts === 'string') {
    return null;
  }
  return (
    <span>
      {parts.map(p => {
        return (
          <span
            key={p.value}
            className={cx({
              'font-bold': p.type === 'bold',
            })}
          >
            {p.value}&nbsp;
          </span>
        );
      })}
    </span>
  );
});
