import { FC } from 'react';
import { type Balances as BalancesType, useUrls } from 'common';
import cx from 'classnames';

export type BalanceDetailProps = BalancesType & {
  showPendingSettlement?: boolean;
  availableToTitle: string | null;
  cls?: string;
};

export type BalanceBreakdownDetailProps = {
  showPendingSettlement?: boolean;
  availableToTitle?: string | null;
  cls?: string;
};

export const BalanceDetail: FC<BalanceDetailProps> = ({
  availableToTitle,
  formattedOpenOrders,
  formattedPendingIncoming,
  formattedPendingOutgoing,
  formattedPendingReceive,
  formattedPendingSend,
  formattedPendingSettlement,
  formattedPendingTransferIn,
  formattedPendingTransferOut,
  formattedTradable,
  formattedTotalWithCurrencyCode,
  formattedWithdrawable,
  showPendingSettlement,
  cls = 'text-sm',
}) => {
  /**
   * Hooks
   */
  const { faqUrl } = useUrls();

  /**
   * DOM
   */
  const availableToBalance = showPendingSettlement
    ? formattedWithdrawable
    : formattedTradable;

  const assetValue = (value: string) => (
    <span className={cx('font-normal font-semibold', cls)}>{value}</span>
  );

  return (
    <>
      <p
        className={cx(
          'flex flex-row justify-between py-1.5 font-bold bg-grey-brighter w-full rounded text-primary px-3',
          cls
        )}
      >
        <span>Total balance</span>
        <span
          className={cx(
            'flex flex-row justify-end items-end text-right font-normal font-semibold',
            cls
          )}
        >
          {formattedTotalWithCurrencyCode}
        </span>
      </p>
      <div className="w-full flex flex-col gap-2 px-3">
        {/**pending incoming */}
        <>
          <p>
            <span
              className={cx(
                'flex flex-row justify-between text-primary gap-4 items-center',
                cls
              )}
            >
              <span className="flex flex-col">
                <span>Pending incoming</span>
                <span className="text-gray-darker text-xs">
                  Waiting for network confirmations
                </span>
              </span>

              <span
                className={cx(
                  'flex flex-row justify-end items-end text-right font-normal font-semibold items-center',
                  cls
                )}
              >
                {formattedPendingIncoming}
              </span>
            </span>
            {/* <span className="text-neutral-600 text-xs">
              Short description for pending incoming
            </span> */}
          </p>
          <p className="flex flex-row justify-between text-grey-darker text-sm">
            <span>Pending receive</span>
            <span
              className={cx(
                'flex flex-row justify-end items-end text-right font-normal font-semibold items-center',
                cls
              )}
            >
              {formattedPendingReceive}
            </span>
          </p>
          <p className="flex flex-row justify-between text-grey-darker text-sm items-center pb-3 border-b">
            <span>Pending transfer in</span>
            <span
              className={cx(
                'flex flex-row justify-end items-end text-right font-normal font-semibold items-center',
                cls
              )}
            >
              {formattedPendingTransferIn}
            </span>
          </p>
        </>
        {/**pending outgoing */}
        <>
          <p>
            <span
              className={cx(
                'flex flex-row justify-between text-primary gap-4 items-center',
                cls
              )}
            >
              <span className="flex flex-col">
                <span>Pending outgoing</span>
                <span className="text-gray-darker text-xs">
                  Processed within one business day
                </span>
              </span>
              <span className="flex flex-row justify-end items-end text-right">
                {assetValue(formattedPendingOutgoing)}
              </span>
            </span>
            {/* <span className="text-neutral-600 text-xs">
              Short description for pending outgoing
            </span> */}
          </p>
          <p className="flex flex-row justify-between text-grey-darker text-sm">
            <span>Pending send</span>
            <span className="flex flex-row justify-end items-end text-right">
              {assetValue(formattedPendingSend)}
            </span>
          </p>
          <p className="flex flex-row justify-between text-grey-darker text-sm items-center pb-3 border-b">
            <span>Pending transfer out</span>
            <span className="flex flex-row justify-end items-end text-right">
              {assetValue(formattedPendingTransferOut)}
            </span>
          </p>
        </>
        {showPendingSettlement && (
          <p className="flex flex-row justify-between text-primary text-sm border-b pb-3 gap-4">
            <span>Pending settlement</span>
            <span className="flex flex-row justify-end items-end text-right">
              {assetValue(formattedPendingSettlement)}
            </span>
          </p>
        )}
        <p
          className={cx(
            'flex flex-row justify-between text-primary gap-4 items-center',
            cls
          )}
        >
          <span>Open orders</span>
          <span className="flex flex-row justify-end items-end text-right">
            {assetValue(formattedOpenOrders)}
          </span>
        </p>
      </div>
      {!!availableToBalance && (
        <p
          className={cx(
            'flex flex-row justify-between font-bold bg-grey-brighter p-3 w-full rounded text-primary',
            cls
          )}
        >
          <span>{availableToTitle}</span>
          <span className="flex flex-row justify-end items-end text-right">
            {assetValue(availableToBalance)}
          </span>
        </p>
      )}
      {/* <div className="text-grey-darker text-sm flex flex-wrap pt-4 gap-x-1.5">
        <p>
          <span>Find out more about pending balances in our</span>
          <a
            href={faqUrl}
            target="_blank"
            rel="help noreferrer"
            className="hover:underline font-bold text-primary ml-1"
          >
            FAQs
          </a>
        </p>
      </div> */}
    </>
  );
};
