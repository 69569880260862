import { API } from 'api';
import { AddAddressFormikProps, API_ERROR_CODES, ThunkResult } from 'common';
import { ThunkCreator } from 'easy-peasy';
import { AddCryptoAddressSteps } from './steps';

export const createAddress = async (
  formValues: AddAddressFormikProps | null,
  twoFaCode: string | undefined,
  create: ThunkCreator<
    string | undefined,
    Promise<
      ThunkResult<API.WithdrawalAddress | API.CreateQuorumOperationApiResponse>
    >
  >,
  onNext: (step: AddCryptoAddressSteps) => void
) => {
  if (!formValues) {
    return;
  }
  const { isSuccessful, errorCode } = await create(twoFaCode);

  if (!isSuccessful) {
    if (errorCode && errorCode === API_ERROR_CODES.USER_ACOUNT_LOCKED) {
      onNext(AddCryptoAddressSteps.AccountLocked);
    }
    return;
  }
  onNext(AddCryptoAddressSteps.Code);
};
