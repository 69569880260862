import createBreakpoint from 'react-use/lib/factory/createBreakpoint';

enum BREAKPOINTS {
  XXL = 'XXL',
  XL = 'XL',
  LG = 'LG',
  MD = 'MD',
  SM = 'SM',
  XS = 'XS',
}

export interface Breakpoints {
  XXL: boolean;
  XL: boolean;
  LG: boolean;
  MD: boolean;
  SM: boolean;
  XS: boolean;
}

// https://v2.tailwindcss.com/docs/responsive-design
const useBreakpoint = createBreakpoint({
  [BREAKPOINTS.XXL]: 1440,
  [BREAKPOINTS.XL]: 1280,
  [BREAKPOINTS.LG]: 1024,
  [BREAKPOINTS.MD]: 768,
  [BREAKPOINTS.SM]: 640,
  [BREAKPOINTS.XS]: 100,
});

export const useMediaQuery: () => Breakpoints = () => {
  /**
   * Hooks
   */
  const breakpoint = useBreakpoint();

  /**
   * Return
   */
  return {
    [BREAKPOINTS.XXL]: breakpoint === BREAKPOINTS.XXL,
    [BREAKPOINTS.XL]: breakpoint === BREAKPOINTS.XL,
    [BREAKPOINTS.LG]: breakpoint === BREAKPOINTS.LG,
    [BREAKPOINTS.MD]: breakpoint === BREAKPOINTS.MD,
    [BREAKPOINTS.SM]: breakpoint === BREAKPOINTS.SM,
    [BREAKPOINTS.XS]: breakpoint === BREAKPOINTS.XS,
  };
};
