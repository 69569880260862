import { FC } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';

interface Props {
  tag?: string;
  actionText?: string;
  cls?: string;
}

export const AlreadyHaveAccount: FC<Props> = ({
  tag = `Already have an account?`,
  actionText = `Sign in here`,
  cls,
}) => {
  return (
    <div className={cx('typo-b2 text-gray-300', cls)}>
      {tag}
      <Link
        to={APP_ROUTES.NON_AUTH_HOME}
        className="text-primary underline ml-2"
      >
        {actionText}
      </Link>
    </div>
  );
};
