import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppStore, DataStore } from '../../../store';
import { AppIcon } from 'common';
import { APP_ROUTES } from '../../../routes';
import { Link } from 'react-router-dom';
import { useLogout } from '../../../hooks/use-authentication-hooks';

export const AccountLocked: FC<Partial<StepWizardChildProps>> = ({
  goToNamedStep,
}) => {
  if (!goToNamedStep) {
    return null;
  }
  /**
   * Store
   */
  const logout = useLogout();
  const setSelectedDialogType = AppStore.useStoreActions(
    s => s.setDashboardSelectedDialogType
  );
  const resetState = DataStore.useStoreActions(a => a.send.resetState);

  /**
   * DOM
   */
  return (
    <div className="flex flex-col">
      {/* header  */}
      <div className="flex flex-col items-center text-center px-14 pt-20">
        <AppIcon icon="negative-circle-shield" size="lg" />
        <h3 className="text-center mb-10 mt-4">
          Your account has been locked.
        </h3>
        <div>
          You have entered the wrong code more than 3 times.
          <br />
          You will need to reset your password, please{' '}
          <strong>check your email</strong> for instructions to unlock your
          account.
          <br />
          <br />
          If you need further assistance please{' '}
          <Link to={APP_ROUTES.NON_AUTH_CONTACT_US}>
            <strong>contact support.</strong>
          </Link>
        </div>
      </div>

      {/* actions  */}
      <div className="flex border-t border-grey-bright bg-gray-100 rounded-b mt-12 py-6 px-6">
        <button
          type="button"
          className="app-button-primary w-full flex-1"
          onClick={e => {
            e.preventDefault();
            resetState();
            setSelectedDialogType(null);
            logout();
          }}
        >
          Back to sign in
        </button>
      </div>
    </div>
  );
};
