import { FC, useEffect, useState } from 'react';
import { DataStore } from '../store';
import { AppLoader } from '../components/app-loader';
import { useSegmentPage } from '../hooks/use-segment-page';
import { useSegmentIdentify } from '../hooks/use-segment-identify';
import { useBrowserDatadog } from '../hooks/use-datadog';
import { storageHelper } from '../utils/storage-helper';
import { ApiError, BaseComponentProps, useComputedConfig } from 'common';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../routes';
import { useLocalStorageUpdates } from '../hooks/use-localstorage-updates';
import { useLogout } from '~/hooks/use-authentication-hooks';
import { handleNavigation } from '~/components/shared/url-params/url-params';

export const AppStartup: FC<BaseComponentProps> = props => {
  /**
   * Store
   */
  const booted = DataStore.useStoreState(s => s.booted);
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);
  const error = DataStore.useStoreState(s => s.error);
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);
  const isEmailVerified = DataStore.useStoreState(
    s => !!s.user.isEmailVerified
  );

  const { getData, setJwt, resetUserStore } = DataStore.useStoreActions(_ => ({
    getData: _.getData,
    setJwt: _.user.setJwt,
    resetUserStore: _.user.resetStore,
  }));
  const logout = useLogout();
  const { tenant } = useComputedConfig();

  const [authenticationIsReady, setAuthenticationReady] = useState(false);

  /**
   * Hooks
   */
  const navigate = useNavigate();
  useEffect(() => {
    const jwt = storageHelper.jwt.get();
    if (jwt) {
      setJwt(jwt);
    }

    setAuthenticationReady(true);
  }, []);
  useSegmentIdentify();
  useSegmentPage();
  useBrowserDatadog();
  useLocalStorageUpdates(jwt => {
    console.log('Received token from another tab', jwt);
    if (jwt) {
      setJwt(jwt);
    } else {
      logout();
    }
  });
  const {
    queryParams: { accountId = '' },
  } = handleNavigation<'accountId'>() as {
    queryParams: { accountId: string };
  };

  useEffect(() => {
    if (!decodedToken) {
      return;
    }
    if (!isEmailVerified) {
      navigate(APP_ROUTES.NON_AUTH_EMAIL_VERIFICATION_PENDING);
      return;
    }
    const timer = setTimeout(() => {
      // TODO: tech-debt
      if (window.location.href.includes(APP_ROUTES.AUTH_AUTH0_CALLBACK)) {
        // We should not be authenticated at this stage.
        console.log('Clearing existing authentication data');
        resetUserStore();
        return;
      }
      // TODO: tech-debt
      getData({
        client: 'web',
        tenant: tenant,
        throwOnError: true,
        queryParams: { accountId },
      }).catch(e => {
        if (e instanceof ApiError && (e as ApiError).status === 401) {
          document.location.href = APP_ROUTES.NON_AUTH_LOGGED_OUT;
        } else {
          document.location.href = APP_ROUTES.NON_AUTH_500x;
        }
      });
    });
    return () => clearTimeout(timer);
  }, [decodedToken, isEmailVerified]);

  useEffect(() => {
    if (booted === false && error) {
      navigate(APP_ROUTES.NON_AUTH_500x);
    }
  }, []);

  /**
   * DOM
   */
  if (
    isAuthenticated &&
    isEmailVerified &&
    !window.location.href.includes(
      APP_ROUTES.NON_AUTH_EMAIL_VERIFICATION_PENDING
    ) &&
    !booted
  ) {
    return <AppLoader spinnerTop="104px" />;
  }

  if (!authenticationIsReady) {
    return <AppLoader spinnerTop="104px" />;
  }

  return (
    <>
      {/* content  */}
      <div className="bg-grey-brightest h-full">{props.children}</div>
    </>
  );
};
