import { AnalyticsContext, useSystemConfig } from 'common';
import { useContext, useEffect } from 'react';
import { BrazeApi } from '../context/braze-context';
import { DataStore } from '../store';

export const useSegmentIdentify = () => {
  /**
   * Context
   */
  const analyticsContext = useContext(AnalyticsContext);

  /**
   * Store
   */
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);
  const { envName } = useSystemConfig();

  /**
   * Hooks
   */
  useEffect(() => {
    if (!isAuthenticated || !decodedToken) {
      return;
    }

    const userProperties = {
      accounts: decodedToken?.accounts,
      email: decodedToken?.email,
      firstName: decodedToken.firstName,
      lastName: decodedToken.lastName,
      environment: envName,
    };

    analyticsContext?.identify(decodedToken.id, userProperties);

    if (window.heap) {
      window.heap.identify(decodedToken.id);
      window.heap.addUserProperties(userProperties);
    }

    const brazeApi = analyticsContext?.braze as BrazeApi;
    brazeApi?.changeUser(decodedToken.id);
    const brazeUser = brazeApi?.getUser();
    if (brazeUser) {
      brazeUser.setEmail(decodedToken?.email);
      brazeUser.setFirstName(decodedToken?.firstName);
      brazeUser.setLastName(decodedToken?.lastName);
      brazeUser.setCustomUserAttribute('environment', envName);
    }
  }, [isAuthenticated, decodedToken]);
};
