import { EnrichedCurrency } from '../../../types';

export enum AddCashMethod {
  bank = `Add cash from a bank account`,
}

export enum AddCashFormikField {
  addCashMethod = 'addCashMethod',
  toAsset = 'toAsset',
  note = 'note',
  amount = 'amount',
  cvv = 'cvv',
  simulationResult = 'simulationResult',
}

export interface AddCashFormikProps {
  [AddCashFormikField.addCashMethod]: AddCashMethod;
  [AddCashFormikField.toAsset]: EnrichedCurrency | null;
  [AddCashFormikField.amount]: string | null;
  [AddCashFormikField.note]: string | null;
  [AddCashFormikField.cvv]: string | null;
}
