import { string } from 'is';
import cx from 'classnames';
import { AppDialog, AppIcon, FormHeader } from 'common';
import { FC, useCallback, useMemo, useState } from 'react';
import { AppTabs } from '~/components/app-tabs/app-tabs';
import {
  BalanceDetail,
  BalanceDetailProps,
} from '~/pages/portfolio/balance-detail';

enum BALANCE_VIEW_TABS {
  AVAILABLE_TO_TRADE = 'Available to trade',
  AVAILABLE_TO_WITHDRAW = 'Available to withdraw',
}

export type BalanceBreakdownProps = Omit<
  BalanceDetailProps,
  'availableToTitle'
> & {
  title?: string;
  account?: string;
  availableToTitle?: string;
  content?: string | JSX.Element;
  breakdownType?: 'pendingIncoming' | 'pendingOutgoing' | 'pendingAll';
  cls?: string;
};

export const BalanceBreakdown: FC<BalanceBreakdownProps> = ({
  content = 'Balances',
  title = 'Holdings',
  breakdownType = 'pendingAll',
  account,
  availableToTitle,
  ...props
}) => {
  /**
   * State
   */
  const [viewBalanceInfoDialog, setViewBalanceInfoDialog] = useState(false);

  /**
   * Hooks
   */
  const handleViewBalanceInfoDialog = useCallback((arg: boolean) => {
    setViewBalanceInfoDialog(arg);
  }, []);

  const updatedTabView = useMemo(() => {
    const tabData = {
      tabs: [
        <div key={BALANCE_VIEW_TABS.AVAILABLE_TO_WITHDRAW}>
          {BALANCE_VIEW_TABS.AVAILABLE_TO_WITHDRAW}
        </div>,
      ],
      panels: [
        <div
          key={BALANCE_VIEW_TABS.AVAILABLE_TO_WITHDRAW}
          className="flex flex-col justify-center items-center gap-y-1.5"
        >
          <BalanceDetail
            {...props}
            showPendingSettlement
            availableToTitle={availableToTitle || 'Available to withdraw'}
          />
        </div>,
      ],
    };
    if (typeof props.tradable === 'undefined') {
      return tabData;
    }
    tabData.tabs.unshift(
      <div key={BALANCE_VIEW_TABS.AVAILABLE_TO_TRADE}>
        {BALANCE_VIEW_TABS.AVAILABLE_TO_TRADE}
      </div>
    );
    tabData.panels.unshift(
      <div
        key={BALANCE_VIEW_TABS.AVAILABLE_TO_TRADE}
        className="flex flex-col justify-center items-center gap-y-1.5"
      >
        <BalanceDetail
          {...props}
          availableToTitle={availableToTitle || 'Available to trade'}
        />
      </div>
    );
    return tabData;
  }, [props.tradable]);

  if (breakdownType === 'pendingIncoming') {
    return (
      <div className="flex flex-col text-primary py-2 gap-3 z-50 z-100">
        <FormHeader
          accountInfo={account}
          title={title}
          marginCls={props?.cls || 'px-0.5 pt-1 pb-1.5'}
        />
        <BalanceDetail
          {...props}
          availableToTitle={availableToTitle || 'Available to trade'}
        />
      </div>
    );
  }

  if (breakdownType === 'pendingOutgoing') {
    return (
      <div className="flex flex-col text-primary py-2 gap-3 z-100">
        <FormHeader
          titleCls="text-base"
          subTitleCls="text-sm"
          accountInfo={account}
          title={title}
          marginCls={props?.cls || 'px-0.5 pt-1 pb-1.5'}
        />
        <BalanceDetail
          {...props}
          showPendingSettlement
          availableToTitle={availableToTitle || 'Available to withdraw'}
        />
      </div>
    );
  }

  return (
    <div className={cx('text-primary', props.cls)}>
      {/* Create new card dialog  */}
      <AppDialog
        buttonCls="top-8"
        isOpen={viewBalanceInfoDialog}
        onClose={() => handleViewBalanceInfoDialog(false)}
      >
        <>
          <FormHeader title={title} />
          <div className="px-4 sm:px-10 pb-8">
            <AppTabs
              headerItemCls="border-primary p-1"
              borderCls=""
              tabs={updatedTabView.tabs}
              panels={updatedTabView.panels}
            />
          </div>
        </>
      </AppDialog>
      <p
        className={cx(
          { 'mt-4': string(content) },
          'flex flex-wrap items-center gap-x-2 text-sm'
        )}
      >
        {!!content && (
          <button
            onClick={() => handleViewBalanceInfoDialog(true)}
            className="flex flex-row items-center cursor-pointer gap-x-2 bg-grey-brighter rounded-lg pl-3 pr-2 py-2"
          >
            <span className="text-sm font-bold">{content}</span>
            <AppIcon
              size={16}
              cls="mt-0.5"
              bg="bg-transparent"
              icon="chevron-right-alt"
              fill="fill-current-color-dark"
            />
          </button>
        )}
        {props?.hasPendingBalance && (
          <span className="bg-warning-fair text-warning-dark text-sm font-bold px-3 py-2 rounded-lg">
            Pending
          </span>
        )}
      </p>
    </div>
  );
};
