import { useEffect, useState } from 'react';

export const useAppFavicon = (themeName: string) => {
  /**
   * State
   */
  const [appFavIconUrl, setAppFavIconUrl] = useState('');

  /**
   * Hooks
   */
  useEffect(() => {
    (async () => {
      if (appFavIconUrl.length > 0) {
        return;
      }
      if (!themeName) {
        return;
      }
      const mod = await import(`common/dist/theme/${themeName}/favicon.ico`);
      const url = mod.default;
      if (url === appFavIconUrl) {
        return;
      }
      setAppFavIconUrl(url);
    })();
  }, [themeName]);

  /***
   * return
   */
  return appFavIconUrl;
};
