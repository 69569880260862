import { Navigate } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { DataStore } from '../../store';

export const RequireAuthVerifyEmail = ({
  children,
}: {
  children: JSX.Element;
}) => {
  /**
   * Store
   */
  const isKycVerified =
    DataStore.useStoreState(s => s.user.isKycVerified) || false;
  const isAuthenticated =
    DataStore.useStoreState(s => s.user.isAuthenticated) || false;
  const isEmailVerified =
    DataStore.useStoreState(s => s.user.isEmailVerified) || false;

  /**
   * DOM
   */
  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: APP_ROUTES.NON_AUTH_HOME,
        }}
        state={{
          from: location,
        }}
      />
    );
  }
  if (isEmailVerified && isKycVerified) {
    return (
      <Navigate
        to={{
          pathname: APP_ROUTES.AUTH_PORTFOLIO,
        }}
        state={{
          from: location,
        }}
      />
    );
  }
  if (isEmailVerified && !isKycVerified) {
    return (
      <Navigate
        to={{
          pathname: APP_ROUTES.AUTH_KYC_INDEX,
        }}
        state={{
          from: location,
        }}
      />
    );
  }
  return children;
};
