export enum RegisterFormField {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  password = 'password',
  repeatPassword = 'repeatPassword',
  referralCode = 'referralCode',
  recaptchaToken = 'recaptchaToken',
  receiveMarketingUpdates = 'receiveMarketingUpdates',
  acceptTerms = 'acceptTerms',
}

export interface RegisterFormikProps {
  [RegisterFormField.firstName]: string;
  [RegisterFormField.lastName]: string;
  [RegisterFormField.recaptchaToken]: string;
  [RegisterFormField.email]: string;
  [RegisterFormField.password]: string;
  [RegisterFormField.repeatPassword]?: string;
  [RegisterFormField.referralCode]: string | undefined;
  [RegisterFormField.receiveMarketingUpdates]: boolean;
  [RegisterFormField.acceptTerms]: boolean;
}
