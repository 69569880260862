import { createContext, FC, useEffect } from 'react';
import { AppStore, DataStore } from '../store';
import { BaseComponentProps, useSystemConfig } from 'common';

export const PusherContext = createContext<null>(null);

export const PusherProvider: FC<BaseComponentProps> = ({ children }) => {
  const { envName } = useSystemConfig();

  /**
   * Store
   */
  const accountIds = DataStore.useStoreState(s => s.portfolio.accountIds);
  const orgId = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.organizationId
  );
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);

  const { connectToStreamingUpdates } = DataStore.useStoreActions(_ => ({
    connectToStreamingUpdates: _.metaData.connectToStreamingUpdates,
  }));
  const pusher = AppStore.useStoreState(s => s.pusher);

  /**
   * Hooks
   */
  useEffect(() => {
    if (!connectToStreamingUpdates) {
      return;
    }
    if (!pusher) {
      return;
    }
    if (!accountIds || !accountIds.length) {
      return;
    }
    if (!orgId) {
      return;
    }
    if (!decodedToken) {
      return;
    }
    connectToStreamingUpdates({
      pusher,
      env: envName,
      accountIds,
      orgId,
      userId: decodedToken.id,
    });
  }, [connectToStreamingUpdates, pusher, orgId, accountIds]);

  /**
   * DOM
   */
  return (
    <PusherContext.Provider value={null}>{children}</PusherContext.Provider>
  );
};
