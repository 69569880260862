import { FC } from 'react';
import { CellActivityDate } from '~/components/transactions/cells';

type Props = {
  date?: Date | null;
};

export const ActivityDate: FC<Props> = ({ date }) => {
  if (!date) {
    return <></>;
  }
  return <CellActivityDate date={date} cls="items-end" />;
};
