import { useContext, useEffect } from 'react';
import { SegmentTrackType } from './events';
import { AnalyticsContext } from '../../contexts/analytics-context';

export const useTrackEvent = () => {
  const analyticsContext = useContext(AnalyticsContext);

  return (type: SegmentTrackType, data?: any) => {
    if (process.env.NODE_ENV === 'development') {
      console.info('Sending event ', type, data);
    }

    if (analyticsContext) {
      analyticsContext.track(type, data);
    }
  };
};

export const useSegmentTrack = (
  type: SegmentTrackType,
  data?: any,
  options: { onlyIf: boolean } = { onlyIf: true },
  dependencies: any[] = []
) => {
  /**
   * Context
   */
  const trackEvent = useTrackEvent();

  /**
   * Hooks
   */
  useEffect(() => {
    if (!options.onlyIf) {
      return;
    }
    trackEvent(type, data);
  }, [options.onlyIf, ...dependencies]);
};
