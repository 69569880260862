import { Props as ColumnHeaderProps, SortProps } from '../../app-table/cell';
import { API } from 'api';
import { format } from 'date-fns';
import {
  CurrencyIcon,
  DATE_FORMATS,
  EnrichedCryptoAddress,
  getActivityStatus,
  getBlockchainNetworkName,
  getValuePlaceholder,
  maskHash,
  QuorumOperationKindValue,
  StatusIndicator,
  useMediaQuery,
} from 'common';
import { BankStatus } from '../../bank-accounts/bank-status';

export enum ApexFundColumn {
  FundMangerName = `FundManagerName`,
  FundName = `FundName`,
  DateAdded = `DateAdded`,
}

export enum PendingApprovalsColumn {
  PendingApprovalActionType = `PendingApprovalActionType`,
  PendingApprovalFundName = `PendingApprovalFundName`,
  PendingApprovalDate = `PendingApprovalDate`,
  PendingApprovalRequester = `PendingApprovalRequester`,
  PendingApprovalApprovalStatus = `PendingApprovalApprovalStatus`,
}

export enum ApprovalsConfirmationColumn {
  CurrencyAndName = `CurrencyAndName`,
  BankDetails = `BankDetails`,
  Status = `Status`,
}

export enum ApprovalsDescirptionColumn {
  Name = `Name`,
  DateActioned = `DateActioned`,
  Status = `Status`,
}

export enum FundAddressesColumnColumn {
  Asset = `Asset`,
  Status = `Status`,
}

const headings = {
  [ApexFundColumn.FundMangerName]: `Fund`,
  [ApexFundColumn.FundName]: `Fund name (LLP)`,
  [ApexFundColumn.DateAdded]: `Date added`,
  [PendingApprovalsColumn.PendingApprovalActionType]: `Action type`,
  [PendingApprovalsColumn.PendingApprovalFundName]: `Fund`,
  [PendingApprovalsColumn.PendingApprovalDate]: `Date`,
  [PendingApprovalsColumn.PendingApprovalRequester]: `Requester`,
  [PendingApprovalsColumn.PendingApprovalApprovalStatus]: `Approval Status`,
};

interface QuorumBankVerify {
  isApexFundManager?: boolean;
  onVerify: (bankId: string) => void;
}

interface QuorumAddressVerify {
  isSettings?: boolean;
  showTableHeader?: boolean;
}

interface QuorumApprovalOperationStatus {
  status: API.QuorumOperationStatus;
}

const getFundMangerColumn = <R,>(
  props: SortProps<ApexFundColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '80%',
    showSort: false,
    cls: 'overflow-hidden',
    justify: 'justify-start',
    name: ApexFundColumn.FundMangerName,
    cell: (data: API.ApexFund | null) => {
      if (!data) {
        return null;
      }
      return (
        <span className="flex flex-col">
          <span className="text-sm font-semibold truncate">
            {data.accountName}
          </span>
          <span className="text-xs font-medium text-gray-400">
            Fund manager: {data?.fundManagerName}
          </span>
        </span>
      );
    },
  };
};

const getFundNameColumn = <R,>(
  props: SortProps<ApexFundColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '40%',
    showSort: false,
    cls: 'overflow-hidden',
    justify: 'justify-start',
    name: ApexFundColumn.FundName,
    hideShowHeaderCls: 'hidden lg:inline-flex overflow-hidden',
    cell: (data: API.ApexFund | null) => {
      if (!data) {
        return null;
      }
      return <span className="text-sm truncate">{data.accountName}</span>;
    },
  };
};

const getDateAddedColumn = <R,>(
  props: SortProps<ApexFundColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '20%',
    showSort: false,
    justify: 'justify-end',
    name: ApexFundColumn.DateAdded,
    cell: (data: API.ApexFund | null) => {
      if (!data) {
        return null;
      }

      return (
        <span className="text-xs truncate">
          {format(data.creationDate, DATE_FORMATS.D_SHORTMONTH_YYYY)}
        </span>
      );
    },
  };
};

const getPendingApprovalActionTypeColumn = <R,>(
  props: SortProps<PendingApprovalsColumn>,
  heading?: string | JSX.Element | undefined
) => {
  return {
    ...props,
    name: PendingApprovalsColumn.PendingApprovalActionType,
    heading,
    width: '100%',
    showSort: false,
    cell: (data: API.QuorumOperation | null) => {
      if (!data) {
        return null;
      }

      return QuorumOperationKindValue[data.kind];
    },
  };
};

const getPendingApprovalFundNameColumn = <R,>(
  props: SortProps<PendingApprovalsColumn>,
  heading?: string | JSX.Element | undefined
) => {
  return {
    ...props,
    name: PendingApprovalsColumn.PendingApprovalFundName,
    heading,
    width: '100%',
    showSort: false,
    justify: 'justify-center md:justify-start',
    cell: (data: API.QuorumOperation | null) => {
      if (!data) {
        return null;
      }
      return data.parameters?.accountName || getValuePlaceholder();
    },
  };
};

const getPendingApprovalDateColumn = <R,>(
  props: SortProps<PendingApprovalsColumn>,
  heading?: string | JSX.Element | undefined
) => {
  return {
    ...props,
    name: PendingApprovalsColumn.PendingApprovalDate,
    heading,
    width: '100%',
    showSort: false,
    cell: (data: API.QuorumOperation | null) => {
      if (!data) {
        return null;
      }
      return format(data.creationDate, DATE_FORMATS.DD_MMM_YYYY);
    },
  };
};

const getPendingApprovalRequesterNameColumn = <R,>(
  props: SortProps<PendingApprovalsColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    name: PendingApprovalsColumn.PendingApprovalRequester,
    heading,
    width: '100%',
    showSort: false,
    cell: (data: API.QuorumOperation | null) => {
      if (!data) {
        return null;
      }
      return data.submittedByUserName;
    },
  };
};

const getPendingApprovalStatusColumn = <R,>(
  props: SortProps<PendingApprovalsColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    name: PendingApprovalsColumn.PendingApprovalApprovalStatus,
    heading,
    width: '50%',
    showSort: false,
    justify: 'justify-end',
    cell: (data: API.QuorumOperation | null) => {
      if (!data) {
        return null;
      }
      const status = getActivityStatus(data.status);

      return (
        <StatusIndicator
          value={getActivityStatus(data.status)}
          title={status}
        />
      );
    },
  };
};
const getApprovalConfirmationCurrencyAndNameColumn = <R,>(
  props: SortProps<ApprovalsConfirmationColumn>
) => {
  return {
    ...props,
    name: ApprovalsConfirmationColumn.CurrencyAndName,
    heading: 'Currency & Name',
    width: '50%',
    showSort: false,
    cell: (data: API.BankAccount | null) => {
      if (!data) return null;
      return (
        <CurrencyIcon
          labelTextSize="xs"
          currencyCode={data.currency?.code}
          customLabel={data.bankAccountDetails?.bankName}
          currencyName={data.bankAccountDetails?.accountNumber}
        />
      );
    },
  };
};

const getApprovalConfirmationStatusColumn = <R,>(
  props: SortProps<ApprovalsConfirmationColumn> & QuorumBankVerify
) => {
  return {
    ...props,
    name: ApprovalsConfirmationColumn.Status,
    width: '50%',
    heading: 'Status',
    showSort: false,
    justify: 'justify-end',
    cell: (data: API.BankAccount | null) => {
      if (!data) return null;
      return (
        <div className="flex flex-row items-center justify-end text-base">
          {!props.isApexFundManager && (
            <BankStatus bankId={data.id} status={data.status} />
          )}
        </div>
      );
    },
  };
};

const getFundAddressesAssetColumn = <R,>(
  props: SortProps<FundAddressesColumnColumn>
) => {
  const { XS } = useMediaQuery();
  return {
    ...props,
    heading: FundAddressesColumnColumn.Asset,
    name: FundAddressesColumnColumn.Asset,
    width: '100%',
    showSort: false,
    cell: (data: EnrichedCryptoAddress | null) => {
      if (!data) return null;
      return (
        <div className="flex flex-row gap-2 md:gap-4 py-2 md:py-0 w-full">
          <CurrencyIcon
            labelCls="text-xs"
            showLabel={false}
            currencyCode={data.currencyCode}
          />
          <div className="flex flex-col gap-1.5">
            <span className="text-base text-primary font-bold">
              {maskHash(data.label || '')}
            </span>
            <p>
              <span className="break-all md:break-normal">{`${data.currencyCode} ${data.address}`}</span>
              <span className="border-1 rounded-md bg-white ml-2.5 py-0.5 px-1 md:px-2 text-primary sm:justify-center sm:items-center break-all text-xs font-bold">
                {getBlockchainNetworkName(data.blockchain)}
              </span>
            </p>
            <div className="flex md:hidden">
              {data.isVerifying && (
                <>
                  <span className="font-bold text-sm">Pending</span>
                  <span className="block w-3 h-3 bg-black ml-2 rounded-full bg-warning"></span>
                </>
              )}
            </div>
          </div>
        </div>
      );
    },
  };
};

const getFundAddressesStatusColumn = <R,>(
  props: SortProps<FundAddressesColumnColumn> & QuorumAddressVerify
) => {
  return {
    ...props,
    heading: FundAddressesColumnColumn.Status,
    name: FundAddressesColumnColumn.Status,
    width: props?.showTableHeader ? '30%' : 'auto',
    showSort: false,
    justify: 'justify-end',
    cls: `hidden md:flex`,
    cell: (data: EnrichedCryptoAddress | null) => {
      if (!data) return null;
      return (
        <div className="flex flex-row items-center justify-end text-base mr-4">
          {data.isVerifying && (
            <>
              <span className="font-bold text-sm">Pending</span>
              <span className="block w-3 h-3 bg-black ml-2 rounded-full bg-warning"></span>
            </>
          )}
        </div>
      );
    },
  };
};

const getAprovalsDescriptionNameColumn = <R,>(
  props: SortProps<ApprovalsDescirptionColumn>
) => {
  return {
    ...props,
    name: ApprovalsDescirptionColumn.Name,
    width: '45%',
    showSort: false,
    cell: (data: API.QuorumOperationApproval | null) => {
      if (!data) return null;
      const fullName = `${data.firstname} ${data.lastname}`;
      return <span className="text-base break-all mr-3">{fullName}</span>;
    },
  };
};
const getAprovalsDescriptionDateColumn = <R,>(
  props: SortProps<ApprovalsDescirptionColumn>
) => {
  return {
    ...props,
    name: ApprovalsDescirptionColumn.DateActioned,
    width: '40%',
    showSort: false,
    cell: (data: API.QuorumOperationApproval | null) => {
      if (!data) return null;
      return (
        <span className="text-base font-semibold">
          {format(data.approvalDate, DATE_FORMATS.DD_MMM_YYYY)}
        </span>
      );
    },
  };
};
const getAprovalsDescriptionStatusColumn = <R,>(
  props: SortProps<ApprovalsDescirptionColumn> & QuorumApprovalOperationStatus
) => {
  return {
    ...props,
    name: ApprovalsDescirptionColumn.Status,
    width: '15%',
    showSort: false,
    cell: (data: API.QuorumOperationApproval | null) => {
      if (!data) return null;
      const status = getActivityStatus(props.status);
      return <StatusIndicator value={status} title={status} />;
    },
  };
};

export const getApexFundColumns = (
  columnProps: SortProps<ApexFundColumn>
): ColumnHeaderProps<ApexFundColumn, API.ApexFund | null>[] => {
  return [
    getFundMangerColumn(columnProps, headings[ApexFundColumn.FundMangerName]),
    // getFundNameColumn(columnProps, headings[ApexFundColumn.FundName]),
    getDateAddedColumn(columnProps, headings[ApexFundColumn.DateAdded]),
  ];
};

export const getPendingAprovalsColumns = (
  columnProps: SortProps<PendingApprovalsColumn>
): ColumnHeaderProps<PendingApprovalsColumn, API.QuorumOperation | null>[] => {
  return [
    getPendingApprovalActionTypeColumn(
      columnProps,
      headings[PendingApprovalsColumn.PendingApprovalActionType]
    ),
    getPendingApprovalFundNameColumn(
      columnProps,
      headings[PendingApprovalsColumn.PendingApprovalFundName]
    ),
    getPendingApprovalDateColumn(
      columnProps,
      headings[PendingApprovalsColumn.PendingApprovalDate]
    ),
    getPendingApprovalRequesterNameColumn(
      columnProps,
      headings[PendingApprovalsColumn.PendingApprovalRequester]
    ),
    getPendingApprovalStatusColumn(
      columnProps,
      headings[PendingApprovalsColumn.PendingApprovalApprovalStatus]
    ),
  ];
};

export const getAprovalsConfirmationColumns = (
  columnProps: SortProps<ApprovalsConfirmationColumn> & QuorumBankVerify
): ColumnHeaderProps<ApprovalsConfirmationColumn, API.BankAccount | null>[] => {
  return [
    getApprovalConfirmationCurrencyAndNameColumn(columnProps),
    getApprovalConfirmationStatusColumn(columnProps),
  ];
};

export const getFundAddressesColumns = (
  columnProps: SortProps<FundAddressesColumnColumn> & QuorumAddressVerify
): ColumnHeaderProps<
  FundAddressesColumnColumn,
  EnrichedCryptoAddress | null
>[] => {
  return [
    getFundAddressesAssetColumn(columnProps),
    getFundAddressesStatusColumn(columnProps),
  ];
};

export const getAprovalsDescriptionColumns = (
  columnProps: SortProps<ApprovalsDescirptionColumn> &
    QuorumApprovalOperationStatus
): ColumnHeaderProps<
  ApprovalsDescirptionColumn,
  API.QuorumOperationApproval | null
>[] => {
  return [
    getAprovalsDescriptionNameColumn(columnProps),
    getAprovalsDescriptionDateColumn(columnProps),
    getAprovalsDescriptionStatusColumn(columnProps),
  ];
};
