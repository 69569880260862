import { FC } from 'react';
import { SettingsSecurityOverview } from './settings-security-overview';

export enum SettingsSecuritySteps {
  OVERVIEW = 1,
  TWO_FA_ENABLED = 2,
  TWO_FA_DISABLED = 3,
}

export const SettingsSecurity: FC = () => {
  /**
   * DOM
   */
  return (
    <div>
      <SettingsSecurityOverview />
    </div>
  );
};
