import { ERROR_MESSAGES } from 'common';
import { FormField, FormProps as CreateApiKeyProps } from './add';

export const validate = ({
  name,
  passphrase,
  ipAddresses,
  canTrade,
  canWithdraw,
  canCreateApiKeys,
}: CreateApiKeyProps) => {
  const errors: Record<string, unknown> = {};

  if (!name) {
    errors[FormField.name] = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  return errors;
};
