import { FC, memo } from 'react';
import { Widgetcard, WidgetCardProps } from './widget-card';
import { StepWizardChildProps } from 'react-step-wizard';
import { WidgetStepsNavigation } from './widget-steps-navigation';
import { AppIconType, IconSize } from 'common';

interface Props {
  icon?: AppIconType;
  iconSize?: IconSize;
  iconCls?: string;
  titleCls?: string;
  iconBg?: string;
  title?: string;
  subText?: string | JSX.Element;
  fill?: string;
  ctaText?: JSX.Element | string;
  handleButtonClick?: () => void;
}

export const CustomWidget: FC<
  Partial<StepWizardChildProps> &
    Props &
    Pick<WidgetCardProps, 'ctaCls' | 'iconHeight'>
> = memo(
  ({
    fill,
    iconSize,
    iconCls,
    icon = 'info',
    titleCls = 'text-xl',
    iconBg = 'bg-transparent',
    title = 'Rate your experience',
    subText = `We value your feedback, please help us improve Stablehouse for you and others.`,
    ctaText = 'Send us your feedback',
    handleButtonClick,
    ctaCls,
    iconHeight,
    ...rest
  }) => {
    /**
     * DOM
     */
    return (
      <Widgetcard
        iconSize={iconSize}
        iconCls={iconCls}
        icon={icon}
        titleCls={titleCls}
        iconBg={iconBg}
        title={title}
        subText={subText}
        ctaText={ctaText}
        fill={fill}
        handleButtonClick={handleButtonClick}
        ctaCls={ctaCls}
        iconHeight={iconHeight}
      >
        <WidgetStepsNavigation {...rest} />
      </Widgetcard>
    );
  }
);
