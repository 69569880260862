import { API } from 'api';
import {
  Enriched,
  EnrichedCurrencyInformation,
  getCurrencyInfoForCode,
} from 'common';
import {
  ActivityAmountFuture,
  ActivityDate,
  ActivityDescription,
  ActivityHash,
  ActivityNetwork,
} from '../shared';
import {
  CellActivityStatus,
  CellTransferAccount,
} from '~/components/transactions/cells';
import { CopyableText } from '~/components/copyable-text';

export const getSendRecords = (
  data:
    | Enriched.Transfer
    | Enriched.Withdrawal
    | API.InternalTransferWithTimeline,
  status: string,
  currencies: EnrichedCurrencyInformation[],
  account: Enriched.ListAccountItem | undefined,
  isStableTag = false
): Record<string, JSX.Element | string> => {
  // stabletag
  if (isStableTag) {
    const value = data as Enriched.Transfer;

    return {
      '': <CellActivityStatus {...data} status={status} />,
      Amount: (
        <ActivityAmountFuture
          amount={value.formatted.amountWithCurrencyCodeAndSign}
          meta={<CellTransferAccount data={account} />}
        />
      ),
      ...(value.kind === API.TransferKind.Incoming &&
        value.senderTag && { 'Transfer from': value.senderTag }),
      ...(value.kind === API.TransferKind.Outgoing &&
        value.destinationTag && { 'Transfer to': value.destinationTag }),
      Date: <ActivityDate date={data.creationDate} />,
    };
  }

  // withdrawal
  const value = data as Enriched.Withdrawal;

  const ccyInfo = getCurrencyInfoForCode(value.currencyCode, currencies);
  const fees =
    value.feeMode === API.WithdrawalFeeChargeMode.ChargedImmediately
      ? value.formatted.feesWithCurrencyCode
      : value.formatted.invoicedFeeUsdWithCurrencyCode;

  const destinationAddress = value.formatted.destination.address;
  const destinationLabel = value.formatted.destination.label;

  return {
    '': <CellActivityStatus {...data} status={status} />,
    Send: (
      <ActivityAmountFuture
        amount={value.formatted.amountWithCurrencyCodeAndSign}
        meta={<CellTransferAccount data={account} />}
      />
    ),
    Network: <ActivityNetwork ccyInfo={ccyInfo} />,
    Fee: fees,
    Receive: (
      <ActivityAmountFuture
        amount={value.formatted.amountSentWithCurrencyCode}
        meta={
          <>
            <span className="text-primary font-bold">{destinationLabel}</span>
            <CopyableText
              cls="mt-1.5"
              text={destinationAddress}
              showCopyText={false}
              textCls="flex-row items-center text-grey-darker font-normal text-right break-all"
            />
          </>
        }
      />
    ),
    'Reference code': <ActivityHash hash={value.humanReadableIdentifier} />,
    'Transaction hash': (
      <CopyableText
        text={value.txId}
        showTextAfterCopy={false}
        showCopyText={false}
      />
    ),
    Date: <ActivityDate date={value.creationDate} />,
    Description: <ActivityDescription value={value.description} />,
  };
};
