import { FC, useCallback, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  ActionId,
  AppContainer,
  ContentCardsSections,
  Enriched,
  EnrichedAccountDetailAsset,
  EnrichedAllHolding,
  HoldingsTableColumnId,
  useAppDialog,
  useComputedConfig,
  useContentCards,
  useUrls,
} from 'common';
import { APP_ROUTES } from '../../routes';
import { AppStore, DataStore } from '../../store';
import {
  PortfolioAccountsList,
  PortfolioHero,
  sortAndFilterHoldingsData,
} from '../../components/portfolio';
import { Widgets } from '~/components/widgets';
import { AppLoader } from '~/components/app-loader';
import { initialFormState } from '~/components/transactions/transactions-filters/use-filter-transactions';
import {
  AppTableHeadings,
  AppTableRows,
  useAppTableSort,
} from '~/components/app-table';
import { SortDirection } from '~/config';
import { AppTabs } from '~/components/app-tabs/app-tabs';
import cx from 'classnames';
import { BalanceBreakdown } from '~/components/portfolio/balance-breakdown';
import { DASHBOARD_SELECTED_DIALOG_TYPE } from '~/store/types';
import { AccountSelector } from '~/components/workflows/account-selector';
import { WorkflowHeroButtons } from '~/components/workflows/hero-buttons';
import { getHoldingsColumns } from '~/utils/get-holdings-columns';
import { handleNavigation } from '~/components/shared/url-params/url-params';
import { SEARCH_PARAMS } from '~/components/app-tabs/use-app-tabs';

enum PORTFOLIO_TAB {
  ACCOUNTS = 'Accounts',
  HOLDINGS = 'Holdings',
}

enum SORT_PARAMS {
  COLUMN = 'column',
  DIRECTION = 'direction',
}

export const ListOfAccounts: FC = () => {
  /**
   * Store
   */
  const busy = DataStore.useStoreState(s => s.busy);
  const booted = DataStore.useStoreState(s => s.booted);
  const error = DataStore.useStoreState(s => s.error);
  const balances = DataStore.useStoreState(s => s.portfolio.portfolioBalances);
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const allHoldings = DataStore.useStoreState(s => s.portfolio.allHoldings);
  const totalBalanceUsd = DataStore.useStoreState(
    s => s.portfolio.totalBalanceUsd
  );
  const balanceChange = DataStore.useStoreState(s => s.portfolio.balanceChange);
  const decodedTokenAccountId = DataStore.useStoreState(
    s => s.user.decodedTokenAccountId
  );
  const tradingDeskContactName = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.tradingDeskContactName
  );
  const tradingDeskContactPhone = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.tradingDeskContactPhone
  );
  const tradingDeskContactPhoneLink = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.tradingDeskContactPhoneLink
  );
  const relationshipManagerName = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.relationshipManagerName
  );
  const relationshipManagerEmail = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.relationshipManagerEmail
  );
  const relationshipManagerEmailLink = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.relationshipManagerEmailLink
  );

  const hasAccountOfTypeProTrading = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeProTrading
  );
  const showRelationshipManagerInfo = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.showRelationshipManagerInfo
  );
  const allowedPortfolioActions = DataStore.useStoreState(
    s => s.portfolio.allowedActions
  );
  const portfolio = DataStore.useStoreActions(a => a.portfolio);
  const transactions = DataStore.useStoreActions(a => a.transactions);
  const getAllHoldings = DataStore.useStoreActions(
    a => a.portfolio.getAllHoldings
  );
  const getAccountDetail = DataStore.useStoreActions(
    a => a.portfolio.getAccountDetail
  );
  const dashboardSelectedDialogType = AppStore.useStoreState(
    s => s.dashboardSelectedDialogType
  );

  const setDashboardSelectedAsset = AppStore.useStoreActions(
    _ => _.setDashboardSelectedAsset
  );
  const setDashboardSelectedDialogType = AppStore.useStoreActions(
    _ => _.setDashboardSelectedDialogType
  );

  /**
   * Hooks
   */

  const {
    applyParams,
    queryParams: { column, direction },
  } = handleNavigation<SORT_PARAMS | SEARCH_PARAMS>({
    [SEARCH_PARAMS.TAB_INDEX]: PORTFOLIO_TAB.ACCOUNTS,
  });
  const { tenant } = useComputedConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const { showDialog, hideDialog } = useAppDialog();
  const { columnProps, sortInfo } = useAppTableSort<HoldingsTableColumnId>({
    column:
      HoldingsTableColumnId[column as HoldingsTableColumnId] ||
      HoldingsTableColumnId.Value,
    direction: SortDirection[direction as SortDirection] || SortDirection.ASC,
  });
  useEffect(() => {
    if (booted === false && error) {
      navigate(APP_ROUTES.NON_AUTH_500x);
    }
  }, [booted, error]);
  useEffect(() => {
    portfolio.getPortfolio({
      accountId: location?.state?.accountId || decodedTokenAccountId || '',
      impersonatedAccountId: location.state?.accountId,
      /**@TODO - investigate why this cause re-renders */
      // isBackgroundXHR: false
      isBackgroundXHR: true,
    });
    transactions.setFormValues(initialFormState);
    transactions.setPreFormValues(initialFormState);
    transactions.resetPaginatedActivitiesFilters();
    transactions.setFilterCount(0);
    portfolio.setAccountDetail(null);
  }, []);

  useEffect(() => {
    (async () => {
      await getAllHoldings();
      return;
    })();
  }, []);

  const { proTradingUrl } = useUrls();
  const activeTraderSection = useContentCards(
    DataStore,
    ContentCardsSections.activeTrader
  );

  /**
   * Methods
   */
  const toHandler = useCallback(
    (row: Enriched.ListAccountItem | null) =>
      `${APP_ROUTES.AUTH_PORTFOLIO}/${row?.account?.accountId}`,
    []
  );
  const handleSortInfoChange = useCallback(
    arg => {
      applyParams({
        [SORT_PARAMS.COLUMN]: arg?.column,
        [SORT_PARAMS.DIRECTION]: arg?.direction,
      });
    },
    [applyParams]
  );

  const beginWorkflow = useCallback(
    async (
      dialogType: DASHBOARD_SELECTED_DIALOG_TYPE,
      accountId: string | null = null
    ) => {
      if (!accountId) {
        return;
      }
      const { isSuccessful, result } = await getAccountDetail({
        accountId,
        isBackgroundXHR: true,
      });
      if (!isSuccessful) {
        return;
      }

      setDashboardSelectedDialogType(dialogType);
    },
    [getAccountDetail, setDashboardSelectedDialogType]
  );

  const onWorkflowSelected = useCallback(
    (dialogType: DASHBOARD_SELECTED_DIALOG_TYPE) => {
      showDialog(
        <AccountSelector
          workflowType={dialogType}
          onClose={hideDialog}
          onNext={async account => {
            hideDialog();
            beginWorkflow(dialogType, account.account?.accountId);
          }}
        />
      );
    },
    [showDialog, hideDialog, beginWorkflow]
  );

  /**
   * DOM
   */
  const hasData = Boolean(allHoldings?.assets?.length);
  const columns = getHoldingsColumns(
    allHoldings?.holdingsTable.columns,
    columnProps,
    (asset, action) => {
      if (!action && asset) {
        navigate(`${APP_ROUTES.AUTH_ASSET}/${asset.currency.code}`);
      }
      if (action && setDashboardSelectedAsset) {
        setDashboardSelectedAsset(asset);
      }
      if (action && setDashboardSelectedDialogType) {
        setDashboardSelectedDialogType(action);
      }
      return;
    }
  );
  const rows = allHoldings?.assets
    ? sortAndFilterHoldingsData(
        (allHoldings.assets ||
          []) as EnrichedAllHolding[] as EnrichedAccountDetailAsset[],
        sortInfo
      )
    : null;

  const discoverMoreContentCard = useContentCards(
    DataStore,
    ContentCardsSections.discoverMore
  );
  const fundsContentCard = useContentCards(
    DataStore,
    ContentCardsSections.funds
  );
  const investmentsContentCard = useContentCards(
    DataStore,
    ContentCardsSections.investments
  );

  /**
   * DOM
   */
  if (busy && !dashboardSelectedDialogType) {
    return <AppLoader isFixed spinnerTop="104px" />;
  }

  return (
    <>
      <AppContainer containerWidth="lg" wrapperCls="p-6" cls="">
        {/* balance hero  */}
        <PortfolioHero
          balance={totalBalanceUsd}
          balanceChange={balanceChange ?? undefined}
          additionalContent={
            <>
              {!!balances && (
                <BalanceBreakdown title="Portfolio breakdown" {...balances} />
              )}
              <div className="flex flex-row text-primary mt-6 px-0.5">
                <WorkflowHeroButtons
                  onSelected={onWorkflowSelected}
                  canBuy={allowedPortfolioActions?.includes(ActionId.Buy)}
                  canSell={allowedPortfolioActions?.includes(ActionId.Sell)}
                  canSend={allowedPortfolioActions?.includes(ActionId.Send)}
                  canReceive={allowedPortfolioActions?.includes(
                    ActionId.Receive
                  )}
                  canAddCash={allowedPortfolioActions?.includes(
                    ActionId.AddCash
                  )}
                  canTransfer={allowedPortfolioActions?.includes(
                    ActionId.Transfer
                  )}
                  canWithdrawCash={allowedPortfolioActions?.includes(
                    ActionId.WithdrawCash
                  )}
                />
              </div>
            </>
          }
        />
        {/* list of accounts  */}
        <AppTabs
          applyParams
          marginCls="mt-4"
          headerContainerCls="mt-6"
          headerItemCls="border-primary"
          borderCls=""
          tabs={[
            <div key={PORTFOLIO_TAB.ACCOUNTS} className="tab-header">
              {PORTFOLIO_TAB.ACCOUNTS}
            </div>,
            <div key={PORTFOLIO_TAB.HOLDINGS} className="tab-header">
              {PORTFOLIO_TAB.HOLDINGS}
            </div>,
          ]}
          panels={[
            <div key={PORTFOLIO_TAB.ACCOUNTS}>
              {/* list of accounts  */}
              <AppContainer
                cls="pb-8"
                containerWidth="lg"
                rightAddOn={
                  <div className="flex flex-col gap-6">
                    {/**active trader */}
                    {hasAccountOfTypeProTrading && (
                      <>
                        {activeTraderSection?.content?.map(activeTrader => (
                          <Widgets.WidgetDefault
                            key={activeTrader.id}
                            ctaSpacingCls="pt-5"
                            titleCls="text-base"
                            ctaUrl={proTradingUrl}
                            title={activeTrader?.title}
                            cta={'Go to Active Trader'}
                            titleSpacingCls="pt-5 pb-3"
                            image={activeTrader?.imageWeb}
                            ctaCls="app-button-primary text"
                            content={
                              <ul className="text-left list-disc list-inside ml-1.5">
                                {(activeTrader?.content as string[])?.map(
                                  item => (
                                    <li key={item}>{item}</li>
                                  )
                                )}
                              </ul>
                            }
                          />
                        ))}
                      </>
                    )}
                    {/**trading desk */}
                    {showRelationshipManagerInfo && (
                      <>
                        <Widgets.DashboardContactCard
                          title={`Trade via Trading desk`}
                          relationshipManagerNameTitle="Name"
                          relationshipManagerName={tradingDeskContactName}
                          relationshipManagerContactNumberTitle={`Recorded line`}
                          relationshipManagerContactNumber={
                            <a href={tradingDeskContactPhoneLink}>
                              {tradingDeskContactPhone}
                            </a>
                          }
                        />
                        {/* contact widget */}
                        <Widgets.DashboardContactCard
                          title={`Your relationship manager`}
                          relationshipManagerNameTitle="Name"
                          relationshipManagerName={relationshipManagerName}
                          relationshipManagerEmailTitle="Email"
                          relationshipManagerEmail={
                            <a href={relationshipManagerEmailLink}>
                              {relationshipManagerEmail}
                            </a>
                          }
                        />
                      </>
                    )}
                    {/*markets widget*/}
                    <Widgets.ExploreMarket />
                    {tenant !== 'Apex' && (
                      <Widgets.WidgetPortfolioUserActions />
                    )}
                  </div>
                }
              >
                <PortfolioAccountsList accounts={accounts} to={toHandler} />
                <div className="flex flex-col gap-y-6">
                  {/** discover more section */}
                  <Widgets.WidgetPortfolioHome
                    showAccountTypeInfo
                    title={discoverMoreContentCard.title}
                    card={discoverMoreContentCard.content}
                  />
                  {/** active funds section */}
                  <Widgets.WidgetPortfolioHome
                    showAccountTypeInfo
                    title={fundsContentCard.title}
                    card={fundsContentCard.content}
                  />
                  {/** investments section */}
                  <Widgets.WidgetPortfolioHome
                    showAccountTypeInfo
                    title={investmentsContentCard.title}
                    card={investmentsContentCard.content}
                  />
                  <Widgets.WidgetPortfolioHome title="Help" showHelpInfo />
                </div>
              </AppContainer>
            </div>,
            <div key={PORTFOLIO_TAB.HOLDINGS}>
              <div className="flex px-7 lg:px-8 py-4 justify-between rounded-t-4 border-1 border-b-0 border-grey-bright bg-white items-center">
                <span className="text-base font-bold text-primary">
                  Holdings
                </span>
              </div>
              <div className="snap-x snap-mandatory min-w-7xl max-w-7xl overflow-x-auto overflow-scroll">
                <div className="min-w-780">
                  <AppTableHeadings<
                    HoldingsTableColumnId,
                    EnrichedAccountDetailAsset
                  >
                    columns={columns}
                    borderCls={cx('border-grey-bright bg-white border-1', {
                      'border-b-0': hasData,
                    })}
                    handleSort={handleSortInfoChange}
                  />
                  <AppTableRows<
                    HoldingsTableColumnId,
                    EnrichedAccountDetailAsset
                  >
                    as={Link}
                    to={row => `${APP_ROUTES.AUTH_ASSET}/${row.currency.code}`}
                    rows={rows}
                    columns={columns}
                    rowPadding="px-4 md:px-8 py-3"
                    emptyDataText="No holdings found"
                    containerCls="border-1 border-grey-bright"
                    cellItemCls="text-sm md:text-base truncate"
                    cls="cursor-pointer hover-bg-grey-brighter py-0"
                  />
                </div>
              </div>
            </div>,
          ]}
        />
      </AppContainer>
    </>
  );
};
