import React, { memo } from 'react';
import { AppIcon, useCopy } from 'common';

// TODO(Hadrien): Same component for mobile. Regroup into common/design system
type SearchEmptyProps = { title?: string; subtitle?: string };
export const SearchEmpty = memo(({ title, subtitle }: SearchEmptyProps) => {
  const {
    widgets: { assetSearchNoResult },
  } = useCopy();

  return (
    <div className="flex flex-col flex-grow justify-center items-center text-secondary py-12">
      <AppIcon
        icon="manage-search"
        size={36}
        bg="bg-accent-active"
        cls="fill-current-color p-2 justify-center items-center"
      />
      <p className="text-sm font-bold text-secondary mt-2">
        {title || assetSearchNoResult.title}
      </p>
      <p className="text-xs text-grey-darker mt-2">
        {subtitle || assetSearchNoResult.subtitle}
      </p>
    </div>
  );
});
