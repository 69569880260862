import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  label: string;
}

export const FieldWithLabel: FC<Props> = ({ label, children }) => {
  return (
    <div className="flex flex-col gap-y-1">
      {Boolean(label) && <div className="text-sm text-gray-400">{label}</div>}
      {children}
    </div>
  );
};
