import { API } from 'api';
import parseNumber from 'multi-number-parse';
import { EnrichedFxRate } from '../types';
import { getPercentageChange } from './get-percentage-change';
import { DEFAULTS } from '../constants';
import { formatters } from './formatters';
import { formatAmount } from './format-amount';

function formatPercentage(value: number) {
  return `${value < 0 ? '-' : '+'}${formatAmount(Math.abs(value), 1)}%`;
}

function getFiatFormattedValue(
  amount: string | number | null,
  decimals: number
) {
  return formatters.getCurrency(amount, decimals, 'USD', ['USD']);
}

export const getFxRate = (
  ccyCode: string,
  fxRates: API.FxRate[],
  decimals: number
): EnrichedFxRate => {
  const fallbackResult: EnrichedFxRate = {
    _rate: '1',
    rate: 1,
    rateFormatted: formatAmount(1, DEFAULTS.DECIMAL_SCALE),
    rateWithCurrencyCodeFormatted: getFiatFormattedValue(
      1,
      DEFAULTS.DECIMAL_SCALE
    ),

    rate24HoursAgo: 1,
    rate24HoursAgoFormatted: formatAmount(1, DEFAULTS.DECIMAL_SCALE),
    rate24HoursAgoFormattedUsd: getFiatFormattedValue(
      1,
      DEFAULTS.DECIMAL_SCALE
    ),
    rate24HoursAgoValueChange: 0,
    rate24HoursAgoValueChangeFormatted: `+${formatAmount(0, decimals)}`,
    rate24HoursAgoValueChangeFormattedUsd: `+${getFiatFormattedValue(
      0,
      decimals
    )}`,
    rate24HoursAgoPercentageChange: getPercentageChange(1, 1),
    rate24HoursAgoPercentageChangeFormatted: formatPercentage(0),

    rate7DaysAgo: 1,
    rate7DaysAgoFormatted: formatAmount(1, DEFAULTS.DECIMAL_SCALE),
    rate7DaysAgoFormattedUsd: getFiatFormattedValue(1, DEFAULTS.DECIMAL_SCALE),
    rate7DaysAgoValueChange: 0,
    rate7DaysAgoPercentageChange: getPercentageChange(1, 1),

    rate1MonthAgo: 1,
    rate1MonthAgoFormatted: formatAmount(1, DEFAULTS.DECIMAL_SCALE),
    rate1MonthAgoFormattedUsd: getFiatFormattedValue(1, DEFAULTS.DECIMAL_SCALE),
    rate1MonthAgoValueChange: 0,
    rate1MonthAgoPercentageChange: getPercentageChange(1, 1),
  };

  if (ccyCode === 'USD') {
    return fallbackResult;
  }

  // Note: All rates are quoted in USD
  const match = fxRates.find(({ base }) => {
    return base === ccyCode;
  });

  if (!match) {
    return fallbackResult;
  }

  try {
    const rate = parseNumber(match.rate || 1);
    const rate24HoursAgo = match.rate24HoursAgo
      ? parseNumber(match.rate24HoursAgo)
      : null;
    const rate7DaysAgo = match.rate7DaysAgo
      ? parseNumber(match.rate7DaysAgo)
      : null;
    const rate1MonthAgo = match.rate1MonthAgo
      ? parseNumber(match.rate1MonthAgo)
      : null;

    const rate24HoursAgoPercentageChange = rate24HoursAgo
      ? getPercentageChange(rate24HoursAgo, rate)
      : null;

    const rate24HoursAgoValueChange = rate24HoursAgo
      ? rate - rate24HoursAgo
      : null;
    const rate24HoursAgoValueChangeSign =
      (rate24HoursAgoValueChange ?? 0) < 0 ? '-' : '+';

    return {
      _rate: match.rate || '1',
      rate,
      rateFormatted: formatAmount(rate, decimals),
      rateWithCurrencyCodeFormatted: getFiatFormattedValue(rate, decimals),

      rate24HoursAgo,
      rate24HoursAgoFormatted: formatAmount(rate24HoursAgo, decimals),
      rate24HoursAgoFormattedUsd: getFiatFormattedValue(
        rate24HoursAgo,
        decimals
      ),
      rate24HoursAgoValueChange,
      rate24HoursAgoValueChangeFormatted: `${rate24HoursAgoValueChangeSign}${formatAmount(
        Math.abs(rate24HoursAgoValueChange ?? 0),
        decimals
      )}`,
      rate24HoursAgoValueChangeFormattedUsd: `${rate24HoursAgoValueChangeSign}${getFiatFormattedValue(
        Math.abs(rate24HoursAgoValueChange ?? 0),
        decimals
      )}`,
      rate24HoursAgoPercentageChange,
      rate24HoursAgoPercentageChangeFormatted: formatPercentage(
        rate24HoursAgoPercentageChange ?? 0
      ),

      rate7DaysAgo,
      rate7DaysAgoFormatted: formatAmount(rate7DaysAgo, decimals),
      rate7DaysAgoFormattedUsd: getFiatFormattedValue(rate7DaysAgo, decimals),
      rate7DaysAgoValueChange: rate7DaysAgo ? rate - rate7DaysAgo : null,
      rate7DaysAgoPercentageChange: rate7DaysAgo
        ? getPercentageChange(rate7DaysAgo, rate)
        : null,

      rate1MonthAgo,
      rate1MonthAgoFormatted: formatAmount(rate1MonthAgo, decimals),
      rate1MonthAgoFormattedUsd: getFiatFormattedValue(rate1MonthAgo, decimals),
      rate1MonthAgoValueChange: rate1MonthAgo ? rate - rate1MonthAgo : null,
      rate1MonthAgoPercentageChange: rate1MonthAgo
        ? getPercentageChange(rate1MonthAgo, rate)
        : null,
    };
  } catch (error) {
    console.error(`Error: getFxRate > error with fraction conversion`);

    return fallbackResult;
  }
};
