import {
  AppIcon,
  Enriched,
  EnrichedAccountDetailAsset,
  EnrichedAssetHolding,
} from 'common';
import { FC, useState } from 'react';
import { DataStore } from '../../store';
import { AppRoundButton } from '../app-round-button';
import cx from 'classnames';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { DashboardRowSelect } from '../dashboard/types';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';

interface Props {
  asset: EnrichedAccountDetailAsset | EnrichedAssetHolding;
  onActionClick: DashboardRowSelect;
  menuItemCls?: string;
}

const getButtons = (
  asset: EnrichedAccountDetailAsset | EnrichedAssetHolding,
  accounts: Enriched.ListAccountItem[],
  onActionClick: DashboardRowSelect,
  isClient: boolean
) => {
  const buttons: JSX.Element[] = [];
  const defaultsButtonProps = {
    iconCls: 'bg-transparent circle-btn',
    cls: 'py-2.5 px-3.5 hover-bg-grey-brighter',
    iconInteractive: false,
  };
  const iconProps = {
    size: 25,
    cls: 'bg-transparent cursor-pointer',
  };

  if (!asset.currency.isAssetOfTypeFund) {
    buttons.push(
      <AppRoundButton
        {...defaultsButtonProps}
        icon={
          <AppIcon
            icon="visibility-show"
            {...iconProps}
            size={19}
            bg="transparent"
          />
        }
        text="View"
        key="view-account"
        onClick={() => {
          onActionClick(null, asset);
        }}
      />
    );
  }

  if (asset.canTrade) {
    buttons.push(
      <AppRoundButton
        {...defaultsButtonProps}
        icon={
          <AppIcon
            icon="workflow-buy"
            {...iconProps}
            bg="transparent"
            size="md"
          />
        }
        text="Buy"
        key="buy"
        onClick={() => {
          onActionClick('workflow-buy', asset);
        }}
      />
    );
    buttons.push(
      <AppRoundButton
        {...defaultsButtonProps}
        icon={
          <AppIcon
            icon="workflow-sell"
            {...iconProps}
            bg="transparent"
            size="md"
          />
        }
        text="Sell"
        key="sell"
        onClick={() => {
          onActionClick('workflow-sell', asset);
        }}
      />
    );
  }

  if (
    (asset.canSendCrypto || asset.canStabletagTransfer) &&
    asset.hasBalance &&
    !asset.currency.isAssetOfTypeFiat &&
    !asset.currency.isAssetOfTypeFund
  ) {
    buttons.push(
      <AppRoundButton
        {...defaultsButtonProps}
        icon={
          <AppIcon
            icon="workflow-send"
            {...iconProps}
            bg="transparent"
            size="md"
          />
        }
        text="Send"
        key="send"
        onClick={() => onActionClick('workflow-send', asset)}
      />
    );
  }

  if (
    asset.canReceiveCrypto &&
    !isClient &&
    !asset.currency.isAssetOfTypeFiat &&
    !asset.currency.isAssetOfTypeFund
  ) {
    buttons.push(
      <AppRoundButton
        {...defaultsButtonProps}
        icon={
          <AppIcon
            icon="workflow-receive"
            {...iconProps}
            bg="transparent"
            size="md"
          />
        }
        text="Receive"
        key="receive"
        onClick={() => {
          onActionClick('workflow-receive', asset);
        }}
      />
    );
  }

  if (asset.canReceiveCash && !!asset.currency.isAssetOfTypeFiat) {
    buttons.push(
      <AppRoundButton
        {...defaultsButtonProps}
        icon={
          <AppIcon
            icon="workflow-add-cash"
            {...iconProps}
            bg="transparent"
            size="md"
          />
        }
        text="Add cash"
        key="add cash"
        onClick={() => {
          onActionClick('workflow-add-cash', asset);
        }}
      />
    );
  }

  if (
    asset.canSendCash &&
    asset.hasBalance &&
    !!asset.currency.isAssetOfTypeFiat
  ) {
    buttons.push(
      <AppRoundButton
        {...defaultsButtonProps}
        icon={
          <AppIcon
            icon="workflow-withdraw-cash"
            {...iconProps}
            bg="transparent"
            size="md"
          />
        }
        text="Withdraw cash"
        key="withdraw cash"
        onClick={() => onActionClick('workflow-withdraw-cash', asset)}
      />
    );
  }

  if (asset.canSendAccountTransfer && !!(accounts?.length > 1)) {
    buttons.push(
      <AppRoundButton
        {...defaultsButtonProps}
        icon={
          <AppIcon
            icon="workflow-transfer"
            {...iconProps}
            bg="transparent"
            size="md"
          />
        }
        text="Transfer"
        key="transfer"
        onClick={() => onActionClick('workflow-transfer', asset)}
      />
    );
  }

  if (asset.currency.isAssetOfTypeFund) {
    buttons.push(
      <AppRoundButton
        {...defaultsButtonProps}
        icon={
          <AppIcon icon="download" {...iconProps} size={19} bg="transparent" />
        }
        text="Download latest statement"
        key="DOWNLOAD_LATEST_STATEMENT"
        onClick={() => {
          onActionClick('DOWNLOAD_LATEST_STATEMENT', asset);
        }}
      />
    );
  }
  if (asset.currency.isAssetOfTypeFund) {
    buttons.push(
      <AppRoundButton
        {...defaultsButtonProps}
        icon={
          <AppIcon
            icon="visibility-show"
            {...iconProps}
            size={19}
            bg="transparent"
          />
        }
        text="View all statements"
        key="VIEW_STATEMENTS"
        onClick={() => {
          onActionClick('VIEW_STATEMENTS', asset);
        }}
      />
    );
  }

  return buttons;
};

export const WorkflowRowButtons: FC<Props> = ({
  asset,
  onActionClick,
  menuItemCls = '',
}) => {
  /**
   * Store
   */
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const isClient = DataStore.useStoreState(s => s.user.isClient);

  /**
   * State
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Hooks
   */
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        mainAxis: 5,
        crossAxis: asset.currency.isAssetOfTypeFund ? -110 : -70,
      }),
      flip(),
    ],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  /**
   * DOM
   */
  const buttons = getButtons(asset, accounts, onActionClick, !!isClient);
  if (!buttons || !buttons.length) {
    return null;
  }
  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        className="rounded-full p-1"
      >
        <DotsVerticalIcon
          className={cx('w-5 h-5', {
            'text-primary': isOpen,
            'text-gray-400': !isOpen,
          })}
        />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={cx(
              asset.currency.isAssetOfTypeFund ? 'w-64' : 'w-44',
              'bg-white rounded-md shadow-2xl focus:outline-none border z-20 pointer-events-auto overflow-hidden',
              menuItemCls
            )}
            {...getFloatingProps()}
          >
            {buttons}
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};
