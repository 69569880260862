import {
  BankAccountFormikField,
  BankAccountFormikProps,
  Country,
  countries,
} from 'common';
import { TextField } from '../../forms/text-field';
import { SelectField } from '../../forms/select-field';
import {
  countrySelectedItemTemplate,
  countryItemTemplate,
} from '../../app-selector/templates';
import { useFormikContext } from 'formik';

export const BeneficiaryInfo = () => {
  /**
   * Hooks
   */
  const { setFieldValue } = useFormikContext<BankAccountFormikProps>();
  /**
   * Handlers
   */
  const getSelectedCountry = (value: string) => {
    return (
      countries.find(country => country.code === value) || {
        code: '',
        name: '',
      }
    );
  };
  const handleChangeBankCountry = (country: Country) => {
    setFieldValue(BankAccountFormikField.recipientCountry, country.code);
  };

  /**
   * DOM
   */
  return (
    <div className="flex flex-col gap-5 mb-5">
      <span className="flex flex-col text-sm text-primary font-bold mt-2.5">
        Account holder&apos;s details:
      </span>
      {/* account holder name  */}
      <div className="flex flex-col">
        <TextField
          spellCheck={false}
          name={BankAccountFormikField.accountHolderName}
          label="Account holder name"
        />
      </div>

      {/* Personal address  */}
      <div className="flex flex-col gap-2">
        <TextField
          name={BankAccountFormikField.recipientAddress1}
          label="Street address"
        />
        <TextField name={BankAccountFormikField.recipientAddress2} />
      </div>

      {/* city  */}
      <div className="flex flex-col">
        <TextField name={BankAccountFormikField.recipientCity} label="City" />
      </div>

      {/* state  */}
      <div className="flex flex-col">
        <TextField
          name={BankAccountFormikField.recipientState}
          label="State (Optional)"
        />
      </div>

      <div>
        <TextField
          name={BankAccountFormikField.recipientPostalCode}
          label="Postal or zip code"
        />
      </div>
      <div>
        <SelectField
          name={BankAccountFormikField.recipientCountry}
          label="Country"
          values={countries}
          disableHelpers
          getSelectedItemTemplate={countrySelectedItemTemplate}
          getItemTemplate={countryItemTemplate}
          getSelectedItem={getSelectedCountry}
          onChange={handleChangeBankCountry}
        />
      </div>
    </div>
  );
};
