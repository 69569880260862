export const configuration = {
  envName: 'test-regtest',

  publicUrl: 'http://localhost:8000',

  pusherKey: '5c19ab9170d3c7ad6274',
  pusherCluster: 'eu',

  segmentKey: 'NbIV8OPzXWBXyjKq7BCK0Yl6P4IIR1Dq',
  datadogKey: 'puba66ba5a1370d90e028b4b69e86fd9611',
  datadogApplication: '921830c8-f31b-4911-935f-e16647c28313',
  brazeKey: 'c010ded5-b89f-49d3-af51-2a9a87ab7e28',
  brazeEndpoint: 'sdk.iad-05.braze.com',
  heapKey: '1002406992',
  recaptchaMobilePublicKey: '6LcYbfYZAAAAAGKHZxAzeD2GuQ93cF5wZpZ6P1at',
  recaptchaWebappPublicKey: '6LcYbfYZAAAAAGKHZxAzeD2GuQ93cF5wZpZ6P1at',
};
