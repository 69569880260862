export enum Enable2FAField {
  twoFaCode = 'twoFaCode',
}

export interface Enable2FAFormikProps {
  [Enable2FAField.twoFaCode]: string | undefined;
}

export enum Disable2FAField {
  password = 'password',
  twoFaCode = 'twoFaCode',
}

export interface Disable2FAFormikProps {
  [Disable2FAField.password]: string;
  [Disable2FAField.twoFaCode]: string | undefined;
}
