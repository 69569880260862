import { EnrichedAccountDetailAsset } from '../types';

export const getFundFromCurrencyCode = (
  currencyCode: string | null | undefined,
  funds: EnrichedAccountDetailAsset[] | null
) => {
  if (!currencyCode || !funds) {
    return;
  }
  const f = funds.find(f => f?.currency?.code === currencyCode);
  return f;
};
