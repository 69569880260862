import React, { FC } from 'react';
import cx from 'classnames';
import { BaseComponentProps } from '../../types';
import { AppIcon, AppIconType, IconSize } from '../app-icon';

type Props = {
  cls?: string;
  iconBg?: string;
  ctaCls?: string;
  titleCls?: string;
  headerCls?: string;
  subTextCls?: string;
  contentCls?: string;
  icon?: AppIconType;
  iconSize?: IconSize;
  iconHeight?: IconSize;
  title?: JSX.Element | string;
  subText?: JSX.Element | string;
  ctaText?: JSX.Element | string;
  handleButtonClick?: () => void;
} & BaseComponentProps;

export const Widgetcard: FC<Props> = ({
  icon,
  title,
  iconBg,
  ctaCls,
  subText,
  ctaText,
  children,
  iconHeight,
  subTextCls,
  contentCls,
  iconSize = 'lg',
  handleButtonClick,
  cls = 'flex flex-col px-6 pt-6 pb-8 bg-white border rounded-lg',
  titleCls = 'text-2xl text-center text-primary font-bold py-2',
}) => {
  /**
   * DOM
   */

  return (
    <div className={cx(cls)}>
      <div className={cx(contentCls)}>
        {icon && (
          <AppIcon
            bg={iconBg}
            icon={icon}
            size={iconSize}
            height={iconHeight || iconSize}
          />
        )}
        {title && React.isValidElement(title) ? (
          title
        ) : (
          <p className={cx(titleCls)}>{title}</p>
        )}

        {subText && React.isValidElement(subText) ? (
          subText
        ) : (
          <p className={cx(subTextCls)}>{subText}</p>
        )}
      </div>

      {ctaText && React.isValidElement(ctaText) ? (
        ctaText
      ) : (
        <button onClick={handleButtonClick} className={ctaCls}>
          {ctaText}
        </button>
      )}
      {children}
    </div>
  );
};
