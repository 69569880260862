import { getDataStore } from 'common';
import { createContextStore } from 'easy-peasy';
import { apiClient } from '../api/client';
import { storageHelper } from '../utils/storage-helper';
import { appModel } from './app-store';

export const DataStore = getDataStore(apiClient, storageHelper);
export const AppStore = createContextStore(appModel, {
  name: 'App Store',
});
