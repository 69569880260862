import { FILTER_ASSETS_BY } from '../config';
import { EnrichedCurrencyInformation } from '../types';

function searchString(value: string, search: string) {
  return value.toLowerCase().includes(search.toLowerCase());
}

function searchCurrency(
  currency: EnrichedCurrencyInformation,
  search: string,
  propertiesToSearch: string[]
) {
  return propertiesToSearch.some(propName => {
    let propValue = currency[propName];

    if (typeof propValue === 'number') {
      propValue = propValue.toString();
    }

    if (typeof propValue === 'string') {
      return searchString(propValue, search);
    }

    return false;
  });
}

export function searchStringOrCurrency(
  value: string | EnrichedCurrencyInformation,
  search: string,
  propertiesToSearch: string[]
) {
  if (typeof value === 'string') {
    return searchString(value, search);
  }

  return searchCurrency(value, search, propertiesToSearch);
}

export function filterAssetBy(
  filterBy: FILTER_ASSETS_BY,
  currency: EnrichedCurrencyInformation
) {
  return (
    filterBy === FILTER_ASSETS_BY.ALL ||
    (filterBy === FILTER_ASSETS_BY.CASH && currency.isAssetOfTypeFiat) ||
    (filterBy === FILTER_ASSETS_BY.CRYPTO && !currency.isAssetOfTypeFiat) ||
    (filterBy === FILTER_ASSETS_BY.STABLECOIN && currency.isStablecoin)
  );
}

export const filterCurrencies = (
  currencies: EnrichedCurrencyInformation[],
  filterBy: FILTER_ASSETS_BY,
  search?: string
) => {
  return currencies.filter(currency => {
    const ok = filterAssetBy(filterBy, currency);

    if (ok && search) {
      return searchCurrency(currency, search, ['code', 'name']);
    }

    return ok;
  });
};
