import { Navigate } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { DataStore } from '../../store';

export const RequireAuthExternal = ({
  children,
}: {
  children: JSX.Element;
}) => {
  /**
   * Store
   */
  const isUserKycVerified =
    DataStore.useStoreState(s => s.user.isKycVerified) || false;
  const isAuthenticated =
    DataStore.useStoreState(s => s.user.isAuthenticated) || false;
  const isEmailVerified =
    DataStore.useStoreState(s => s.user.isEmailVerified) || false;
  const redirectAfterLogin = DataStore.useStoreState(
    s => s.user.redirectAfterLogin
  );

  /**
   * DOM
   */
  if (isAuthenticated && isEmailVerified && isUserKycVerified) {
    console.log('User is now authenticated. Starting redirect.');
    if (redirectAfterLogin) {
      return (
        <Navigate
          to={{
            pathname: redirectAfterLogin,
          }}
        />
      );
    } else {
      return (
        <Navigate
          to={{
            pathname: APP_ROUTES.AUTH_PORTFOLIO,
          }}
        />
      );
    }
  }
  if (isAuthenticated && !isEmailVerified) {
    return (
      <Navigate
        to={{
          pathname: APP_ROUTES.NON_AUTH_EMAIL_VERIFICATION_PENDING,
        }}
        state={{
          from: location,
        }}
      />
    );
  }
  if (isAuthenticated && !isUserKycVerified) {
    return (
      <Navigate
        to={{
          pathname: APP_ROUTES.AUTH_KYC_INDEX,
        }}
        state={{
          from: location,
        }}
      />
    );
  }
  return children;
};
