import { FC, useEffect } from 'react';
import { APP_ROUTES } from '~/routes';
import { AppContainer } from 'common';
import { PortfolioAccountHoldings, PortfolioHero } from '../portfolio';
import { AppTabs } from '../app-tabs/app-tabs';
import { DataStore } from '../../store';
import { useParams } from 'react-router-dom';
import { BreadCrumbsNavigation } from '../dashboard/bread-crumbs-navigation';
import { TransactionsTable } from '../transactions';

export enum ADMIN_TAB {
  HOLDINGS = `Holdings`,
  TRANSACTIONS = `Transactions`,
}

export const AdminFundLayout: FC = () => {
  const { fundId, fundAccountId } = useParams();
  /**
   * Store
   */
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const decodedTokenAccountId = DataStore.useStoreState(
    s => s.user.decodedTokenAccountId
  );
  const selectedFund = DataStore.useStoreState(s => s.admin.fundAccountByName);

  const { getAccountDetail } = DataStore.useStoreActions(_ => ({
    getAccountDetail: _.portfolio.getAccountDetail,
  }));

  /**
   * Methods
   */
  /**
   * Note: the `get-account-details` endpoint does not support `asOfDate`. BE is aware of this and working on it, but for the phase1a release we are removing this
   */
  // const handleDateChange = useCallback(
  //   async (date: Date) => {
  //     const payloadDate = new Date(date);
  //     payloadDate.setUTCHours(0, 0, 0, 0);
  //     setFundsAsOfDate(payloadDate);
  //     await getAccountDetail({
  //       accountId: selectedFund?.accountId || '',
  //     });
  //     setOpenDateModal(false);
  //     setDisplayDate(date);
  //   },
  //   [getAccountDetail, setOpenDateModal, selectedFund]
  // );
  // const handleDateRefresh = useCallback(async () => {
  //   setFundsAsOfDate(null);
  //   // if (!isSuccessful) {
  //   //   toast.warn(errorMessage || ERROR_MESSAGES.DEFAULT, TOAST_OPTIONS as ToastOptions);
  //   //   return;
  //   // }
  //   setDisplayDate(new Date());
  // }, [getAccountDetail, setOpenDateModal]);

  /**
   * Hooks
   */
  useEffect(() => {
    (async () => {
      if (!fundAccountId) return;

      if (accountDetail?.account?.accountId === fundAccountId) {
        // do not - refetch data - if already present!
        return;
      }
      await getAccountDetail({
        accountId: decodedTokenAccountId,
        impersonatedAccountId: fundAccountId,
      });
    })();
  }, [fundAccountId, decodedTokenAccountId, accountDetail?.account?.accountId]);

  return (
    <>
      <BreadCrumbsNavigation
        title="Portfolio"
        customBasePath={`${APP_ROUTES.AUTH_ADMIN_FUND_ACCOUNT_OVERVIEW}/${fundId}/accounts`}
      />

      <AppContainer cls="py-10 px-4 xl:px-0" containerWidth="lg">
        {/* balance hero  */}
        <PortfolioHero
          title={
            <div className="flex flex-col gap-0.25">
              <div className="text-2xl font-semibold text-primary">
                {selectedFund?.accountName}
              </div>
              <p className="text-sm font-medium text-gray-400">
                Fund manager: {selectedFund?.fundManagerName}
              </p>
            </div>
          }
          balance={Number(accountDetail?.totalBalanceUsd)}
        />

        <AppTabs
          applyParams
          marginCls="mt-6"
          headerContainerCls="mt-6"
          headerItemCls="border-primary"
          fontCls="font-bold capitalize text-primary"
          tabs={[
            <div key={ADMIN_TAB.HOLDINGS} className="tab-header">
              {ADMIN_TAB.HOLDINGS}
            </div>,
            <div key={ADMIN_TAB.TRANSACTIONS} className="tab-header">
              {ADMIN_TAB.TRANSACTIONS}
            </div>,
          ]}
          panels={[
            <div key={ADMIN_TAB.HOLDINGS}>
              <div className="rounded-4 bg-white flex-1">
                <div className="flex flex-row border border-b-0 rounded-t justify-between items-center py-7 px-4 md:px-8">
                  <p className="font-bold text-primary">Account holdings</p>
                </div>
                <PortfolioAccountHoldings accountDetail={accountDetail} />
              </div>
            </div>,
            <div key={ADMIN_TAB.TRANSACTIONS}>
              <TransactionsTable />
            </div>,
          ]}
        />
      </AppContainer>

      {/* as of filter */}
      {/* <span className="text-gray-400">Holdings and Prices as of:</span>
      <div
        className="flex flex-none justify-between items-center"
        onClick={() => setOpenDateModal(true)}
      >
        <span className="mx-2 font-semibold">
          {format(new Date(displayDate), DATE_FORMATS.D_SHORTMONTH_YYYY)}
        </span>
        <AppIcon icon="pencil-edit" bg="bg-transparent" cls="cursor-pointer" size={12} />
      </div>
      <div className="flex items-center">
        <button
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            handleDateRefresh();
          }}
        >
          <RefreshIcon className="w-4 h-4 stroke-current-color ml-2" />
        </button>
      </div> */}

      {/* <AppDialog
        isOpen={openDateModal}
        onClose={() => {
          setOpenDateModal(false);
        }}
      >
        <TransactionsDateFilter
          date={displayDate}
          onCancel={() => setOpenDateModal(false)}
          onChange={handleDateChange}
        />
      </AppDialog> */}
    </>
  );
};
