import { AccountDetail } from './account-detail';
import { Default } from './default';
import { ListOfAccounts } from './list-of-accounts';
import { Root } from './root';

export const PortfolioPage = {
  Root,
  Default,
  ListOfAccounts,
  AccountDetail,
};
