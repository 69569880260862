export const getAssetPricingHtml = () => {
  return `
    <!DOCTYPE html>
    <html>
    
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <!-- Note: See UNPKG CDN - https://github.com/tradingview/lightweight-charts#cdn failed to load arbitarily, so moved the script to our s3 bucket -->
      <script type="text/javascript"
        src="https://stablehouse-assets.s3.eu-west-1.amazonaws.com/third-party/trading-view/lightweight-charts-v4.0.1.js"></script>
    </head>
    
    <body>
      <script type="text/javascript">
        let chart, series = null;
    
        document.addEventListener("DOMContentLoaded", function () {
          if (!LightweightCharts) {
            return
          }

          const chartOptions = {
            autoSize: true,

            crosshair: {
              vertLine: {
                color: '#b8b8b8',
                width: 1,
                style: 0, // solid
                labelVisible: true,
                labelBackgroundColor: '#b8b8b8',
              },
              horzLine: {
                color: '#b8b8b8',
                width: 1,
                style: 2, // dashed
                labelVisible: true,
                labelBackgroundColor: '#ffd3b3',
              }
            },

            grid: {
              vertLines: {
                visible: false,
              },
              horzLines: {
                visible: false,
              },
            },

             
            handleScale: false,
            handleScroll: false,
            height: 320,

            layout: {
              textColor: '#000',
              background: {
                type: 'solid',
                color: '#fff'
              }
            },

            localization: {
              timeFormatter: businessDayOrTimestamp => {
                return new Date(new Date(businessDayOrTimestamp * 1000)).toUTCString();
              },
            },

            rightPriceScale: {
              borderVisible: false,
              textColor: '#fff', // Note: hiding text white on white as disabling this will not show crosshair interacted tooltip value
            },

            timeScale: {
              fixLeftEdge: true,
              fixRightEdge: true,
              borderVisible: false,
              timeVisible: true,
            }
          };
    
          chart = LightweightCharts.createChart(
            document.body,
            chartOptions
          );

          const seriesOptions = {
            // line chart specific options
            color: '#ff6d00',

            crosshairMarkerVisible: true,
            crosshairMarkerRadius: 6,
            crosshairMarkerBorderColor: '#fff',
            crosshairMarkerBackgroundColor: '#000',
            crosshairMarkerBorderWidth: 2,

            lastPriceAnimation: 2, // on data updated
            lineStyle: 0, // solid
            lineWidth: 4,
            lineType: 0, // simple
            

            // series specific options
            lastValueVisible: true,
            priceLineVisible: false,
            baseLineVisible: false,

          };
          series = chart.addLineSeries(seriesOptions);
        });
    
        window.addEventListener('message', function (event) {
          const payload = JSON.parse(event.data);
          const dataPoints = payload.dataPoints || null;
          if (!dataPoints || !series || !chart) {
            return
          }
          series.setData(dataPoints)
          chart.timeScale().fitContent();
        }, false);
      </script>
    </body>
    </html>`;
};
