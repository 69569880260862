import { FC } from 'react';
import { API } from 'api';
import { dataTestIds } from 'common';

interface Props {
  data: API.ActivityTransaction;
}

export const CellActivityType: FC<Props> = ({ data }) => {
  /**
   * DOM
   */
  return (
    <div
      data-testid={dataTestIds.components.transactions.cellActivityType.root}
      className="flex flex-col gap-1 truncate"
    >
      <span className="text-sm font-bold">{data.title}</span>
      <span className="text-grey-darker text-xs truncate">
        {data.description}
      </span>
    </div>
  );
};
