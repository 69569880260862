import React, { FC } from 'react';
import { AppSelector, SelectorProps } from '../app-selector';
import cx from 'classnames';
import { getSelectInputStyle } from '../form/helpers';

export type SelectInputProps = SelectorProps & {
  asAddon?: boolean;
  isActive?: boolean;
  errored?: boolean;
};

export const SelectInput: FC<SelectInputProps> = ({
  cls,
  errored = false,
  asAddon = false,
  isActive = false,
  ...rest
}) => {
  const className = getSelectInputStyle(errored, asAddon, isActive);

  return <AppSelector {...rest} cls={cx(className, cls)} asAddon={asAddon} />;
};
