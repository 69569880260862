import { FC } from 'react';
import { OnboardingAppHeader } from '../app-header/onboarding';
import { AppFooterNonAuth } from '../app-footer/non-auth';
import { BaseComponentProps } from 'common';

interface Props extends BaseComponentProps {
  testid?: string;
  title?: string | JSX.Element;
  headingContent?: JSX.Element;
  userIcon?: boolean;
}

export const OnboardingLayout: FC<Props> = ({
  title,
  children,
  headingContent,
  userIcon = true,
}) => {
  return (
    <div className="w-screen overflow-auto bg-primary">
      <div className="container flex flex-col sm:mx-auto px-2 sm:px-0 h-screen">
        <div className="p-2 md:p-0 flex flex-col text-white items-center my-auto">
          {/* title  */}
          {title && (
            <h2 className="flex items-center justify-center mb-6 text-center text-3xl md:text-5xl">
              {title}
            </h2>
          )}
          {/* header   */}
          <OnboardingAppHeader userIcon={userIcon} />

          {/* headingContent  */}
          {headingContent ? (
            <div className="w-full md:w-45 mb-6">{headingContent}</div>
          ) : (
            <div className="mb-6" />
          )}

          {/* content  */}
          <>{children}</>
        </div>

        {/* footer  */}
        {/**Might not need */}
        {/* <AppFooterNonAuth cls="text-white opacity-90 h-32" linkCls="hover-text-accent" /> */}
      </div>
    </div>
  );
};
