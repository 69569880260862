import { FC } from 'react';
import cx from 'classnames';
import { DataStore } from '~/store';
import { AppLogo } from '../app-logo';
import {
  AllowedWorkflowType,
  AppIcon,
  BaseComponentProps,
  CurrencyIcon,
} from 'common';
import {
  AccountBalanceWalletRounded,
  AccountBalanceRounded,
} from '@mui/icons-material';

const Wrapper: FC<BaseComponentProps> = ({ children }) => {
  return (
    <div
      className={'flex flex-row items-center justify-center py-6 text-white'}
    >
      {children}
    </div>
  );
};

export type ReceiptType =
  | AllowedWorkflowType
  | 'yield-payout'
  | 'earned-fee'
  | 'reward'
  | 'charged-fee'
  | 'invoice-payment';

const getWorkflowIcon = (type: ReceiptType | null) => {
  if (!type) {
    return null;
  }

  if (type === 'workflow-buy') {
    return (
      <AppIcon
        icon="workflow-buy"
        cls="bg-primary text-white fill-current-color"
        size={32}
      />
    );
  }
  if (type === 'workflow-sell') {
    return (
      <AppIcon
        icon="workflow-sell"
        cls="bg-primary text-white fill-current-color"
        size={32}
      />
    );
  }
  if (type === 'workflow-send') {
    return (
      <AppIcon
        icon="workflow-send"
        cls="bg-primary text-white fill-current-color"
        size={32}
      />
    );
  }
  if (type === 'workflow-transfer') {
    return (
      <AppIcon
        icon="custody-account-vault"
        cls="bg-primary text-white fill-current-color p-1.5"
        size={32}
      />
    );
  }
  if (type === 'workflow-receive') {
    return (
      <AppIcon
        icon="workflow-receive"
        cls="bg-primary text-white fill-current-color"
        size={32}
      />
    );
  }
  if (type === 'workflow-add-cash') {
    return (
      <AppIcon
        size={32}
        bg="bg-primary"
        icon={
          <AccountBalanceWalletRounded
            color="inherit"
            style={{
              width: 18,
            }}
          />
        }
      />
    );
  }
  if (type === 'workflow-withdraw-cash') {
    return (
      <AppIcon
        size={32}
        bg="bg-primary"
        icon={
          <AccountBalanceRounded
            color="inherit"
            style={{
              width: 20,
            }}
          />
        }
      />
    );
  }
  if (type === 'yield-payout' || type === 'earned-fee') {
    return (
      <AppIcon
        icon="percent"
        cls="bg-primary text-white fill-current-color"
        size="md"
      />
    );
  }
  if (type === 'reward') {
    return (
      <AppIcon
        icon="gift"
        cls="bg-primary text-white fill-current-color"
        size="md"
      />
    );
  }

  if (type === 'charged-fee' || type === 'invoice-payment') {
    return (
      <AppIcon
        bg=""
        size={33.5}
        icon="xbto-logo-icon"
        fill="fill-current-color-dark"
      />
    );
  }
  return null;
};

interface ReceiptInfoProps {
  type: ReceiptType | null;
  ccyCodes: (string | undefined | null)[];
}

const ReceiptInfo = ({ ccyCodes, type }: ReceiptInfoProps) => {
  /**
   * DOM
   */
  const arrowIcon = (
    <span className="flex flex-row items-center">
      <AppIcon size={32} icon="arrow-right" bg="bg-transparent" />
    </span>
  );
  const [ccy1Code, ccy2Code] = ccyCodes;
  const currencyIcon1 = (
    <CurrencyIcon currencyCode={ccy1Code} showLabel={false} size={32} />
  );

  if (type === 'workflow-buy' || type === 'workflow-sell') {
    return (
      <Wrapper>
        {currencyIcon1}
        {arrowIcon}
        <CurrencyIcon currencyCode={ccy2Code} showLabel={false} size={32} />
      </Wrapper>
    );
  }

  const workflowIcon = getWorkflowIcon(type);
  return (
    <Wrapper>
      {currencyIcon1}
      {!!workflowIcon && <>{arrowIcon}</>}
      {!!workflowIcon && <>{workflowIcon}</>}
    </Wrapper>
  );
};

interface Props extends ReceiptInfoProps {
  title?: string;
  hideCcyInfo?: boolean;
  records: Record<string, JSX.Element | string>;
  cls?: string;
}

export const Receipt = ({
  records,
  hideCcyInfo = false,
  title,
  ccyCodes,
  type,
  cls = 'text-sm',
}: Props) => {
  /**
   * Store
   */
  const isClient = DataStore.useStoreState(s => s.user.isClient);

  /**
   * DOM
   */

  return (
    <div
      className={cx('mx-10', {
        'pt-6': !!title,
      })}
    >
      {/* header  */}
      <>
        {/* logo  */}
        {isClient && <AppLogo cls="h-8 w-auto" variant="dark" />}
        {/* header  */}
        {title && (
          <div className="flex flex-row items-start my-4">
            <div
              className={cx('font-semibold', {
                'text-center mt-10': isClient,
              })}
            >
              {title}
            </div>
          </div>
        )}
        {/* info */}
        {!hideCcyInfo && <ReceiptInfo type={type} ccyCodes={ccyCodes} />}
      </>

      {/* rows  */}
      {Object.entries(records).map(([label, value], index, arr) => {
        const isLast = index === arr.length - 1;
        if (!value) {
          return;
        }
        return (
          <span
            key={label}
            className={cx(
              'flex flex-row justify-between items-center py-3 gap-2',
              {
                'border-b': !isLast,
              }
            )}
          >
            {!!label && (
              <span
                className={cx(
                  cls,
                  'sm:whitespace-nowrap text-gray-400 text-sm font-normal'
                )}
              >
                {label}
              </span>
            )}
            {!!value && (
              <span
                className={cx(
                  'text-primary font-bold text-right text-wrap break-all ',
                  cls,
                  {
                    'w-full': !label,
                  }
                )}
              >
                {value}
              </span>
            )}
          </span>
        );
      })}
    </div>
  );
};
