import {
  Enriched,
  ActionId,
  AllowedWorkflowType,
  EnrichedAssetHolding,
} from '../../types';

type AllowedFilterBy = keyof Pick<
  Enriched.ListAccountItem,
  | 'canTrade'
  | 'hasBalance'
  | 'canSendCash'
  | 'canSendCrypto'
  | 'canReceiveCash'
  | 'canReceiveCrypto'
  | 'canSendAccountTransfer'
  | 'canReceiveAccountTransfer'
>;

export const workflowMap: Record<
  ActionId | 'TransferReceive',
  AllowedFilterBy[]
> = {
  [ActionId.Transfer]: ['hasBalance', 'canSendAccountTransfer'],
  [ActionId.Buy]: ['canTrade'],
  [ActionId.AddCash]: ['canReceiveCash'],
  [ActionId.Receive]: ['canReceiveCrypto'],
  [ActionId.Sell]: ['hasBalance', 'canTrade'],
  [ActionId.Send]: ['hasBalance', 'canSendCrypto'],
  [ActionId.WithdrawCash]: ['hasBalance', 'canSendCash'],
  TransferReceive: ['canReceiveAccountTransfer'],
};

type Props = {
  capabilities: AllowedFilterBy[];
  assetAccounts?: EnrichedAssetHolding[];
  portfolioAccounts: Enriched.ListAccountItem[];
};

export const filterAvailableAccounts = ({
  capabilities,
  assetAccounts,
  portfolioAccounts,
}: Props): Enriched.ListAccountItem[] => {
  if (!assetAccounts) {
    return portfolioAccounts;
  }
  const ids = new Set(
    assetAccounts
      .filter(acc => capabilities.every(key => acc[key]))
      .map(({ account }) => account?.accountId)
  );

  return portfolioAccounts.filter(({ account }) => ids.has(account?.accountId));
};

export const getAccountsByCapabilities = ({
  capabilities,
  assetAccounts,
  portfolioAccounts,
}: Props): Enriched.ListAccountItem[] => {
  if (!capabilities) {
    return portfolioAccounts;
  }

  if (!assetAccounts) {
    return portfolioAccounts.filter(acc => capabilities.every(key => acc[key]));
  }

  return filterAvailableAccounts({
    capabilities,
    assetAccounts,
    portfolioAccounts,
  });
};

export const getAccountsByWorkflow = ({
  workflowType,
  assetAccounts,
  portfolioAccounts,
}: Omit<Props, 'capabilities'> & {
  workflowType: AllowedWorkflowType | 'workflow-transfer-destination';
}) => {
  if (workflowType === 'workflow-add-cash') {
    return getAccountsByCapabilities({
      capabilities: workflowMap.AddCash,
      assetAccounts,
      portfolioAccounts,
    });
  }
  if (workflowType === 'workflow-receive') {
    return getAccountsByCapabilities({
      capabilities: workflowMap.Receive,
      assetAccounts,
      portfolioAccounts,
    });
  }
  if (workflowType === 'workflow-buy') {
    return getAccountsByCapabilities({
      capabilities: workflowMap.Buy,
      assetAccounts,
      portfolioAccounts,
    });
  }
  if (workflowType === 'workflow-sell') {
    return getAccountsByCapabilities({
      capabilities: workflowMap.Sell,
      assetAccounts,
      portfolioAccounts,
    });
  }
  if (workflowType === 'workflow-send') {
    return getAccountsByCapabilities({
      capabilities: workflowMap.Send,
      assetAccounts,
      portfolioAccounts,
    });
  }
  if (workflowType === 'workflow-withdraw-cash') {
    return getAccountsByCapabilities({
      capabilities: workflowMap.WithdrawCash,
      assetAccounts,
      portfolioAccounts,
    });
  }
  if (workflowType === 'workflow-transfer') {
    return getAccountsByCapabilities({
      capabilities: workflowMap.Transfer,
      assetAccounts,
      portfolioAccounts,
    });
  }
  if (workflowType === 'workflow-transfer-destination') {
    return getAccountsByCapabilities({
      capabilities: workflowMap.TransferReceive,
      assetAccounts,
      portfolioAccounts,
    });
  }

  return portfolioAccounts;
};
