import is from 'is';
import cx from 'classnames';
import { Cell, Props as CellProps } from './cell';

interface Props<C, R> {
  cls?: string;
  title?: string | JSX.Element;
  columns: CellProps<C, R>[];
  titleCls?: string;
  headerCls?: string;
  borderCls?: string;
  padding?: string;
  leftTitleIcon?: JSX.Element;
  rightTitleIcon?: JSX.Element;
  containerCls?: string;
  borderColor?: string;
  clsTitle?: string;
  handleSort?: (arg) => void;
}

export const AppTableHeadings = <C, R>({
  cls = '',
  title,
  columns,
  titleCls,
  leftTitleIcon,
  rightTitleIcon,
  headerCls = 'typo-b5 font-semibold',
  padding = 'px-4 md:px-8 py-2 gap-x-2',
  containerCls = 'pt-8',
  borderColor = 'grey-bright',
  borderCls = 'rounded-t-4 border-1 grey-bright bg-white border',
  clsTitle,
  handleSort,
}: Props<C, R>) => {
  /**
   * DOM
   */
  const hasTitle = title !== undefined;
  return (
    <div
      className={cx(cls, borderCls, {
        [containerCls]: hasTitle,
      })}
    >
      {/* title  */}
      {hasTitle && (
        <>
          {is.string(title) ? (
            <span
              className={cx(
                'flex flex-row gap-x-2 px-4 md:px-8 pb-4 items-center',
                clsTitle
              )}
            >
              {leftTitleIcon}
              <h4 className={cx(titleCls, 'text-primary font-bold')}>
                {title}
              </h4>
              {rightTitleIcon}
            </span>
          ) : (
            <>{title}</>
          )}
        </>
      )}
      {/* columns/ headers  */}
      {!!columns?.length && (
        <div
          className={cx(
            padding,
            borderColor,
            { 'border-t-1': title },
            'flex flex-row items-center text-gray-300 justify-between'
          )}
        >
          {columns.map(col => {
            return (
              <Cell<C, R>
                {...col}
                key={JSON.stringify(col.name)}
                type="column"
                cls={cx(headerCls, 'text-xs font-bold text-grey-dark')}
                padding="p-0"
                onSort={data => {
                  col.onSort(data);
                  if (!handleSort) return;
                  handleSort(data);
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
