import { FC, isValidElement } from 'react';
import { AppIcon, BaseComponentProps, IconSize } from 'common';
import is from 'is';
import cx from 'classnames';
import { DefaultProps } from './widget-default';

export type WidgetCardProps = {
  subText?: JSX.Element | string;
  ctaText?: JSX.Element | string;
  handleButtonClick?: () => void;
  iconBg?: string;
  iconCls?: string;
  iconSize?: IconSize;
  iconHeight?: IconSize;
  cls?: string;
  clsTitleWrapper?: string;
  fill?: string;
  asDialog?: boolean;
  ctaCls?: string;
} & BaseComponentProps &
  DefaultProps;

export const Widgetcard: FC<WidgetCardProps> = ({
  image,
  icon,
  title,
  subText,
  ctaText,
  handleButtonClick,
  children,
  iconBg,
  iconCls,
  titleCls = 'text-2xl',
  iconSize = 'lg',
  cls = 'flex flex-col',
  clsTitleWrapper,
  fill,
  asDialog = false,
  titleSpacingCls = 'py-2',
  ctaSpacingCls = 'pt-8',
  imgCls,
  ctaCls = 'app-button-outline w-336',
  iconHeight = 'lg',
}) => {
  /**
   * DOM
   */
  return (
    <div className={cx(cls, 'px-6 pt-6 pb-8 bg-white border rounded-lg')}>
      <div
        className={cx('flex flex-col items-center', {
          'pt-9': asDialog,
        })}
      >
        {!!icon && (
          <>
            {isValidElement(icon) ? (
              icon
            ) : (
              <AppIcon
                icon={icon}
                size={iconSize}
                height={iconHeight}
                bg={iconBg}
                cls={iconCls}
                fill={fill}
              />
            )}
          </>
        )}
        {!!image && <img className={imgCls} src={image} />}
        {(title || subText) && (
          <div
            className={cx(
              'flex flex-col text-center text-grey-darker text-sm',
              clsTitleWrapper,
              { 'ml-1': asDialog }
            )}
          >
            <p
              className={cx(
                'text-center text-primary font-bold',
                titleSpacingCls,
                titleCls
              )}
            >
              {title}
            </p>
            {is.string(subText) ? <>{subText && <p>{subText}</p>}</> : subText}
          </div>
        )}
      </div>
      {ctaText && (
        <div className={cx(ctaSpacingCls, 'flex flex-col items-center')}>
          {is.string(ctaText) ? (
            <button
              onClick={handleButtonClick}
              className={cx(ctaCls, 'text-sm pt-4')}
            >
              {ctaText}
            </button>
          ) : (
            ctaText
          )}
        </div>
      )}
      {children}
    </div>
  );
};
