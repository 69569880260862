import { AssetDetail } from './detail';
import { RequireAuth } from '~/components/auth/require-auth';

export const AssetPage = {
  Detail: () => (
    <RequireAuth>
      <AssetDetail />
    </RequireAuth>
  ),
};
