import { FC } from 'react';
import { OnboardingLayout } from '../../components/layouts/onboarding';
import { DataStore } from '../../store';
import { Button } from '../../components/forms/button';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { SegmentTrackType, useTrackEvent } from 'common';
import { RequireAuth } from '~/components/auth/require-auth';
import { OpenLayout } from '~/components/layouts/open-layout';

export const EmailVerified: FC = () => {
  /**
   * Store
   */
  const isKycVerified = DataStore.useStoreState(
    state => state.user.isKycVerified
  );
  const isAuthenticated = DataStore.useStoreState(a => a.user.isAuthenticated);
  const trackEvent = useTrackEvent();

  /**
   * DOM
   */
  return (
    <RequireAuth>
      <OpenLayout
        icon="check"
        title={
          <>
            <span data-testid="success">Your email is confirmed.</span>
            <br />
            {!isKycVerified && <span>Next step is to get verified.</span>}
          </>
        }
      >
        <div className="text-center flex flex-col items-center">
          {/* // TODO(Hadrien): Not sure If I should be removing this or not ?  */}
          {!isKycVerified && (
            <div className="text-grey-darker">
              To start depositing on Stablehouse, we need to ask you to go
              through our KYC (Know Your Customer) process. For that, you’ll now
              be taken to our third-party verification service.
            </div>
          )}
          {!isKycVerified && isAuthenticated ? (
            <>
              <Button
                as={Link}
                to={APP_ROUTES.AUTH_KYC_WIZARD}
                className="app-button-accent font-bold inline-block mt-3"
              >
                Get Verified
              </Button>
              <div className="mt-5 font-bold mb-4">
                <Link
                  to={APP_ROUTES.AUTH_PORTFOLIO}
                  onClick={() =>
                    trackEvent(SegmentTrackType.KycSkippedAndGoesToDashboard)
                  }
                >
                  I’ll do it later
                </Link>
              </div>
            </>
          ) : (
            <Button
              as={Link}
              to={
                !isKycVerified
                  ? APP_ROUTES.NON_AUTH_HOME
                  : APP_ROUTES.AUTH_PORTFOLIO
              }
              className="app-button-accent font-bold inline-block mt-3"
            >
              {!isKycVerified ? `Sign in` : `Continue`}
            </Button>
          )}
        </div>
      </OpenLayout>
    </RequireAuth>
  );
};
