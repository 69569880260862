import { FC } from 'react';
import { SettingsSecurity } from '~/components/settings/settings-security';
import { SettingsLayout, SettingsMenuIds } from './layout';

export const Security: FC = () => {
  /**
   * DOM
   */
  return (
    <SettingsLayout hasPaddings={false} menuId={SettingsMenuIds.SECURITY}>
      <SettingsSecurity />
    </SettingsLayout>
  );
};
