import cx from 'classnames';
import {
  AppContainer,
  AppIcon,
  BalanceChange,
  dataTestIds,
  formatUsd,
} from 'common';
import { AnimatedAmount } from '~/components/animated-amount/animated-amount';

interface Props {
  title?: JSX.Element | null;
  titleCls?: string;
  amountCls?: string;
  balance: number | null;
  balanceChange?: BalanceChange | null;
  additionalContent?: JSX.Element;
  rightAddOnContent?: JSX.Element;
  withBorder?: boolean;
  refresh?: boolean;
  cls?: string;
  wrapperCls?: string;
  contentCls?: string;
}

export const PortfolioHero = ({
  balance,
  balanceChange,
  amountCls = 'text-4xl md:text-5xl',
  title = (
    <p className="text-sm font-medium text-gray-400">Portfolio balance</p>
  ),
  titleCls,
  rightAddOnContent,
  additionalContent,
  withBorder = true,
  refresh = false,
  cls = 'px-4 md:px-8 py-6',
  wrapperCls,
  contentCls,
}: Props) => {
  /**
   * DOM
   */
  return (
    <AppContainer
      cls={cls}
      wrapperCls={wrapperCls}
      withBorder={withBorder}
      bg={withBorder ? 'bg-white' : ''}
      containerWidth="lg"
    >
      {/* total USD balance  */}
      {title && <div className={cx('mb-1', titleCls)}>{title}</div>}
      <div className={contentCls}>
        {balance !== null && (
          <div
            data-testid={dataTestIds.components.portfolioHero.root}
            className="flex flex-row items-center justify-between"
          >
            <span
              className={cx(
                'font-semibold text-primary md:leading-tight',
                amountCls
              )}
            >
            <AnimatedAmount value={formatUsd(balance)} />
            </span>
            {refresh && (
              <AppIcon
                icon="refresh"
                size={11}
                height={14}
                bg="bg-transparent"
              />
            )}
            {rightAddOnContent && <>{rightAddOnContent}</>}
          </div>
        )}
        {!!balanceChange && (
          <p className="mt-1 text-xs text-grey-darker">
            <span>{balanceChange.valueFormatted}</span>{' '}
            <span
              className={cx('font-bold', {
                'text-failure': balanceChange.value < 0,
                'text-success': balanceChange.value >= 0,
              })}
            >
              {balanceChange.percentageFormatted}
            </span>
          </p>
        )}
      </div>
      {additionalContent && <div>{additionalContent}</div>}
    </AppContainer>
  );
};
