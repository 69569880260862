import { parse } from 'query-string';
import { FC } from 'react';
import { useAsync } from 'react-async-hook';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppInlineLoader } from '../../components/app-loader/index';
import { OnboardingLayout } from '../../components/layouts/onboarding';
import { APP_ROUTES } from '../../routes';
import { DataStore } from '../../store';
import { OpenLayout } from '~/components/layouts/open-layout';

/**
 * TODO: this whole page needs design re-work
 */
export const ConfirmEmailAddress: FC = () => {
  /**
   * Store
   */
  const verifyEmailAddress = DataStore.useStoreActions(
    a => a.user.verifyEmailAddress
  );

  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = parse(search);
  const { loading, status } = useAsync(async () => {
    await verifyEmailAddress(parsed && (parsed.token as string));
    navigate(APP_ROUTES.AUTH_EMAIL_VERIFICATION_SUCCESSFUL);
  }, [parsed && parsed.token]);

  /**
   * DOM
   */
  return (
    <OnboardingLayout testid="page-confirm-email-address" userIcon={false}>
      <OpenLayout
        title={
          <>
            {/* status = error  */}
            {status === 'error' && (
              <div data-testid="failure">This link is no longer valid.</div>
            )}
            {/* loading  */}
            {loading && (
              <>
                <div data-testid="loading">
                  Please wait while we verify your email address...
                </div>
                <AppInlineLoader bgColor="white" />
              </>
            )}
          </>
        }
        icon="circle-shield-cross"
      >
        <div className="text-center">
          {/* actions  */}
          <div className="mt-5 underline font-bold mb-4">
            <Link to={APP_ROUTES.AUTH_PORTFOLIO}>Back to sign in</Link>
          </div>
        </div>
      </OpenLayout>
    </OnboardingLayout>
  );
};
