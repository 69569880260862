import { API } from 'api';
import { Enriched } from '../../types';
import { formatters } from '../formatters';
import { activitySign, SIGN } from '../get-activity-sign';

export const enrichTransaction = (
  activity: API.ActivityTransaction,
  fiatCurrencyCodes: string[]
): Enriched.ActivityTransaction => {
  const amount1 = formatters.getAmount(
    activity.amount1,
    activity.currency1?.decimals
  );

  const displayCode1 =
    activity.currency1?.displayCode ?? activity.currency1?.code ?? '';
  const displayCode2 =
    activity.currency2?.displayCode ?? activity.currency2?.code ?? '';

  const amount1WithCurrencyCode = [
    API.ActivityLabel.Received,
    API.ActivityLabel.Sent,
  ].includes(activity.activityLabel)
    ? `${activity.amount1} ${displayCode1}`
    : formatters.getCurrency(
        amount1,
        activity.currency1?.decimals,
        displayCode1,
        fiatCurrencyCodes,
        false
      );

  // Note: "Converted" is treated as a "Buy" UI-wise.
  const isBuy =
    activity.activityLabel === API.ActivityLabel.Bought ||
    activity.activityLabel === API.ActivityLabel.Converted;

  const amount1Sign = isBuy
    ? SIGN.PLUS
    : activity.activityLabel === API.ActivityLabel.Sold
      ? SIGN.MINUS
      : activity.activityLabel === API.ActivityLabel.Converted
        ? SIGN.MINUS
        : activitySign[activity.type];

  const amount1WithCurrencyCodeAndSign = formatters.forceSign(
    amount1WithCurrencyCode,
    amount1Sign
  );

  const price = formatters.getCurrency(
    activity.price,
    activity.currency2?.decimals,
    displayCode2,
    fiatCurrencyCodes
  );

  const priceContra = formatters.getCurrency(
    activity.priceContra,
    activity.currency1?.decimals,
    displayCode1,
    fiatCurrencyCodes
  );

  const result: Enriched.ActivityTransaction = {
    ...activity,
    timeline: null,
    formatted: {
      amount1,
      amount1WithCurrencyCode,
      amount1WithCurrencyCodeAndSign,
      amount2: null,
      amount2WithCurrencyCode: null,
      amount2WithCurrencyCodeAndSign: null,
      price,
      priceContra,
      primaryAmountWithCurrencyCodeAndSign: amount1WithCurrencyCode,
      secondaryAmountWithCurrencyCodeAndSign: null,
    },
  };

  const isTrade = activity.type === API.ActivityType.Trade;

  if (isTrade && activity.amount2) {
    const amount2 = formatters.getAmount(
      activity.amount2,
      activity.currency2?.decimals
    );

    const amount2WithCurrencyCode = formatters.getCurrency(
      amount2,
      activity.currency2?.decimals,
      displayCode2,
      fiatCurrencyCodes,
      false
    );

    const amount2Sign =
      activity.activityLabel === API.ActivityLabel.Bought
        ? SIGN.MINUS
        : activity.activityLabel === API.ActivityLabel.Sold
          ? SIGN.PLUS
          : activity.activityLabel === API.ActivityLabel.Converted
            ? SIGN.PLUS
            : activitySign[activity.type];

    const amount2WithCurrencyCodeAndSign = formatters.forceSign(
      amount2WithCurrencyCode,
      amount2Sign
    );

    result.formatted.amount2 = amount2;
    result.formatted.amount2WithCurrencyCode = amount2WithCurrencyCode;
    result.formatted.amount2WithCurrencyCodeAndSign =
      amount2WithCurrencyCodeAndSign;

    if (isBuy) {
      result.formatted.primaryAmountWithCurrencyCodeAndSign =
        formatters.forceSign(amount2WithCurrencyCode, SIGN.PLUS);
      result.formatted.secondaryAmountWithCurrencyCodeAndSign =
        formatters.forceSign(amount1WithCurrencyCode, SIGN.MINUS);
    } else {
      result.formatted.primaryAmountWithCurrencyCodeAndSign =
        formatters.forceSign(amount1WithCurrencyCode, SIGN.MINUS);
      result.formatted.secondaryAmountWithCurrencyCodeAndSign =
        formatters.forceSign(amount2WithCurrencyCode, SIGN.PLUS);
    }
  }

  return result;
};
