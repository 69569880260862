import { FC, useCallback, useState } from 'react';
import { DASHBOARD_SELECTED_DIALOG_TYPE } from '~/store/types';
import {
  AllowedWorkflowType,
  Enriched,
  getWorkflowName,
  FormHeader,
  AppIcon,
  getAccountsByWorkflow,
} from 'common';
import { getAccountInfo } from '~/utils/get-account-details';
import { API } from 'api';
import cx from 'classnames';
import { DataStore } from '~/store';

interface Props {
  workflowType: DASHBOARD_SELECTED_DIALOG_TYPE;
  onClose: () => void;
  onNext: (account: Enriched.ListAccountItem) => void;
}

export const AccountSelector: FC<Props> = ({
  onClose,
  onNext,
  workflowType,
}) => {
  /**
   * Store
   */
  const assetAccounts = DataStore.useStoreState(
    s => s.portfolio.assetHoldings?.accounts
  );
  const portfolioAccounts = DataStore.useStoreState(s => s.portfolio.accounts);

  /**
   * State
   */
  const [value, setValue] = useState<Enriched.ListAccountItem | null>(null);

  /**
   * Methods
   */
  const handleOnNext = useCallback(
    (account: Enriched.ListAccountItem) => {
      if (!onNext || !account) {
        return;
      }
      setValue(account);
      onNext(account);
      onClose();
    },
    [setValue, onClose]
  );

  /**
   * DOM
   */
  return (
    <div className="flex flex-col">
      <div>
        <FormHeader
          title={`Choose account to ${getWorkflowName(
            workflowType as AllowedWorkflowType
          ).toLocaleLowerCase()} ${
            workflowType === 'workflow-add-cash' ? 'into' : 'from'
          } `}
        />
        <div className="flex flex-col flex-1 mx-6 sm:mx-10 mb-8 gap-y-3 min-h-250 max-h-485 overflow-y-auto">
          {getAccountsByWorkflow({
            assetAccounts,
            portfolioAccounts,
            workflowType: workflowType as AllowedWorkflowType,
          })?.map(account => {
            const iconType =
              account?.account?.accountType === API.AccountType.Pool ||
              account?.account?.accountType === API.AccountType.Interest
                ? 'negative-circle-transfer'
                : 'negative-circle-vault';

            return (
              <div
                key={account.account?.accountId}
                className={cx(
                  'flex items-center gap-x-2 px-4 gap-y-3 py-2.5 rounded items-center cursor-pointer hover-bg-grey-brighter bg-grey-brightest',
                  {
                    'bg-gray-100':
                      value?.account?.accountId === account.account?.accountId,
                  }
                )}
                onClick={() => {
                  handleOnNext(account);
                }}
              >
                <AppIcon icon={iconType} size="md" />
                <div>
                  <div className="font-bold text-sm text-secondary">
                    {account?.account?.label}
                  </div>
                  <div className="font-normal text-xs text-grey-darker">
                    {getAccountInfo(account?.account)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
