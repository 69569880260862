import { datadogRum } from '@datadog/browser-rum';
import {
  AnalyticsContext,
  BaseComponentProps,
  SegmentTrackType,
  useSystemConfig,
} from 'common';
import { FC } from 'react';
import { useBraze } from './braze-context';
import { useHeap } from './heap-context';
import { useSegment } from './segment-context';

export const AnalyticsContextProvider: FC<BaseComponentProps> = ({
  children,
}) => {
  /**
   * State
   */
  const segment = useSegment();
  const braze = useBraze();
  const heap = useHeap();
  const { envName } = useSystemConfig();

  /**
   * DOM
   */
  return (
    <AnalyticsContext.Provider
      value={{
        getEnvironmentName: () => envName,
        track: (eventName: SegmentTrackType, eventProperties: any) => {
          const env = envName;

          const value = {
            ...eventProperties,
            environment: env,
          };
          segment?.track(eventName, value);
          braze?.logCustomEvent(eventName, value);
          heap?.track(eventName, eventProperties);
          datadogRum.addAction(eventName, value);
        },
        braze: braze,
        page: (page: any) => segment?.page(page),
        identify: (userId: string, traits?: Record<string, unknown>) => {
          segment?.identify(userId, traits);
          datadogRum.setUser({
            id: userId,
            ...traits,
          });
        },
      }}
    >
      <>{children}</>
    </AnalyticsContext.Provider>
  );
};
