import { FC } from 'react';
import QRCode from 'qrcode.react';
import { CopyableText } from '../copyable-text';
import { IconContent } from '../icon-content';
import { DataStore } from '../../store';
import { AppIcon, Note, useComputedConfig } from 'common';
import { APP_ROUTES } from '../../routes';
import { Link } from 'react-router-dom';
import { AdminLevelAccess } from './admin-level-access';
import cx from 'classnames';

export const SettingsUserProfile: FC = () => {
  /**
   * Store
   */
  const username = DataStore.useStoreState(s => s.user.decodedToken?.username);
  const email = DataStore.useStoreState(s => s.user.decodedToken?.email);
  const tag = DataStore.useStoreState(s => s.settings.tag);
  const allowStabletag = DataStore.useStoreState(
    s => !!s.portfolio.accountDetail?.settings?.allowStabletag
  );
  const kycTier = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.kycTier || 0
  );

  /**
   * Hooks
   */
  const { tenant } = useComputedConfig();

  /**
   * DOM
   */
  const showStableTag = allowStabletag && kycTier !== 0;
  return (
    <div>
      <Note bgColor="bg-gray-100 mb-10" cls="px-4 md:px-8 py-5">
        <IconContent
          icon={<AppIcon icon="pencil-line" bg="bg-transparent" size={20} />}
          content={
            <div className="ml-1">
              <div className="text-primary typo-b2 font-bold">
                Editing your profile
              </div>
              <div className="typo-b2">
                For security reasons, if you&apos;d like to amend your profile
                or edit your verification details, please{' '}
                <Link
                  className="text-primary"
                  to={APP_ROUTES.NON_AUTH_CONTACT_US}
                >
                  <span
                    className={cx('underline cursor-pointer', {
                      'hover-text-accent': tenant === 'Apex',
                    })}
                  >
                    contact support.
                  </span>
                </Link>
              </div>
            </div>
          }
        />
      </Note>

      {/* user name  */}
      <div className="flex flex-col">
        <div className="mr-3 font-bold">{username}</div>
        <div className="text-gray-400">{email}</div>
      </div>

      {/* tag  */}
      {showStableTag && (
        <>
          <div className="flex flex-col mt-6">
            <div className="text-gray-400">StableTag:</div>
            <div className="flex items-center">
              <CopyableText cls="flex items-center" text={tag} />
            </div>
            {/* qr code  */}
            {tag && (
              <QRCode
                value={tag || ''}
                size={140}
                className="border border-grey-bright p-4 mt-4"
              />
            )}
            <IconContent
              testid="qrcode-section"
              cls="mt-4"
              content={
                'Use your StableTag to receive funds from other Stablehouse users'
              }
            />
          </div>
        </>
      )}

      <AdminLevelAccess />
    </div>
  );
};
