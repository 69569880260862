import { FC, MouseEventHandler } from 'react';
import { FormFooter } from './form-footer';
import { AppIcon } from 'common';
import cx from 'classnames';

interface SuccessfulOperationStepProps {
  title: string;
  description: string;
  onBack: () => void;
  titleCls?: string;
  containerCls?: string;
  descriptionCls?: string;
}

export const SuccessfulOperationStep: FC<SuccessfulOperationStepProps> = ({
  title,
  description,
  onBack,
  containerCls,
  titleCls = 'p-10',
  descriptionCls = 'mb-10',
}) => {
  const handleBack: MouseEventHandler = e => {
    e.preventDefault();
    onBack();
  };

  /**
   * DOM
   */
  return (
    <div className="flex flex-col">
      {/* header  */}
      <div
        className={cx(
          'flex flex-col items-center px-10 pt-10 text-center',
          containerCls
        )}
      >
        <AppIcon
          icon="check-circle"
          bg="bg-success"
          size={48}
          cls="mt-10 mb-5 text-white"
        />
        <h4 className={cx('text-primary font-bold mb-2', titleCls)}>{title}</h4>
        {description && (
          <p className={cx('text-grey-darker', descriptionCls)}>
            {description}
          </p>
        )}
      </div>

      {/* actions  */}
      <FormFooter submitText="Close" onSubmit={handleBack} />
    </div>
  );
};
