import { API } from 'api';

import { EnrichedCurrency } from '../../types';
import { getBlockchainNetworkName } from '../get-blockchain-network-name';

export function enrichCurrency(currency: API.Currency): EnrichedCurrency {
  const currencyCode = currency.code as string;

  const result: EnrichedCurrency = {
    ...currency,
    apy: currency.apy ? Number(currency.apy) : undefined,
    code: currencyCode,
    displayCode: currency.displayCode ?? currencyCode,
    name: currency.name as string,
    network: getBlockchainNetworkName(currency.blockchain),
    isAssetOfTypeFiat: !!(currency.type === API.CurrencyType.Fiat),
    isAssetOfTypeFund: !!(currency.type === API.CurrencyType.Fund),
    isAssetOfTypeCrypto: !!(currency.type === API.CurrencyType.Digital),
  };

  return result;
}
